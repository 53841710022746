import React, { Fragment, Component } from "react";
import { Button, Row, Col, Form, InputGroup, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import ParticipantsListTable from "./ParticipantsListTable";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import "react-widgets/dist/css/react-widgets.css";
import $ from "jquery";
import ParticipantStatus from "../../forms/dropdowns/ParticipantStatus";
import EventFilter from "../../forms/dropdowns/EventFilter";
import PaymentStatusDropdown from "../../forms/dropdowns/PaymentStatusDropdown";
import RowsDropdown from "../../forms/dropdowns/RowsDropdown";
import { faEraser, faLongArrowAltLeft, faLongArrowAltRight, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoPermission from "../../other/NoPermission";
import ParticipantLabels from "../../forms/dropdowns/ParticipantLabels";

class ParticipantsList extends Component {
    state = {
        showNOR: this.props.numberOfRecords,
        displayNOR: this.props.numberOfRecords,
        participantsList: this.props.dataList,
        originalParticipants: this.props.dataList,
        searchedPhrase: "",
        loading: this.props.dataList ? false : true,
        selectedPartStat: "all",
        paymentStatusSelected: "all",
        selectedPartLabels: [],
        participantLabels: null,
    };

    handleSearch = (e) => {
        const searchedPhrase = e.target.value.toLowerCase();
        const { originalParticipants } = this.state;
        this.setState({ searchedPhrase: searchedPhrase });
        if (searchedPhrase !== "") {
            let filteredParts = originalParticipants;
            //console.log(filteredParts)
            filteredParts = filteredParts.filter(
                (el) =>
                    (el.part_ident_number && el.part_ident_number.toString().toLowerCase().includes(searchedPhrase)) ||
                    el.user_surname.toString().toLowerCase().includes(searchedPhrase) ||
                    el.user_name.toString().toLowerCase().includes(searchedPhrase) ||
                    (el.user_nickname && el.user_nickname.toString().toLowerCase().includes(searchedPhrase)) //el.user_nickname może być nullem
            );
            this.setState({ participantsList: filteredParts });
        } else {
            this.setState({ participantsList: originalParticipants });
        }
    };

    handleDownloadData = () => {
        this.props.onResizeWindow("tableNotLoaded");
        this.setState({ loading: true });
        this.getPartList();
    };

    getPartList = async () => {
        const { fetchURL, onShowAlert, onGetDataList, t } = this.props;

        try {
            const response = await fetch(`${fetchURL}/participants-list`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const participantsList = fetchedData.participantsList;
                        this.setState({ participantsList: participantsList, originalParticipants: participantsList }, () => {
                            this.setState({ loading: false });
                            onGetDataList(participantsList);
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        const { onResizeWindow } = this.props;
        this.getPartList();
        onResizeWindow("tableNotLoaded");
        $(window).on("resize", function () {
            onResizeWindow("tableLoaded");
        });
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if (!this.state.loading) {
            $("#loadTable").fadeIn(200);
            if ($(".light-border").outerHeight() && $(".rest-info").outerHeight()) {
                onResizeWindow("tableLoaded");
            }
        }
    }

    render() {
        const { participantsList, searchedPhrase, loading, displayNOR, showNOR, selectedPartStat, paymentStatusSelected, noPermission, selectedPartLabels, participantLabels } = this.state;
        const { fetchURL, onCheckUserPrivilage, onChangeNavAndPage, currentPage, eventFilterValue, activeEvent, t, onShowAlert, onShowHomePage } = this.props;

        function searchForLabels(partLabelsList, selectedPartLabels) {
            const textOnlyLabels = [];
            selectedPartLabels.forEach((labelValue) => {
                participantLabels.forEach((pl) => {
                    if (labelValue === pl.value) textOnlyLabels.push(pl.text);
                });
            });

            let containsLabel = false;
            textOnlyLabels.forEach((label) => {
                if (partLabelsList.includes(label)) containsLabel = true;
            });

            if (containsLabel) return true;
            else return false;
        }

        function filterList(e) {
            if (
                (eventFilterValue === "all" || e.event_code === eventFilterValue) &&
                (paymentStatusSelected === "all" || paymentStatusSelected === e.payment_status_code) &&
                (selectedPartStat === "all" || e.part_stat_code === selectedPartStat) &&
                (selectedPartLabels.length === 0 || searchForLabels(e.part_labels_list, selectedPartLabels) === true)
            )
                return true;
            else return false;
        }

        let filteredPartList = [];
        if (participantsList) {
            //wyfiltrowana lista uczestników o wydarzenia, status wpłat i status uczestnika
            filteredPartList = participantsList.filter(filterList);
        }
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: currentPage.params };

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="oneNavbar" />
                ) : (
                    <Fragment>
                        <Container fluid className="pt-2 second-bar">
                            <Row>
                                <Col lg={3} xs={12}>
                                    <Form.Label>{t("form.searching")}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="text" placeholder={t("form.search")} onChange={this.handleSearch} value={searchedPhrase} />
                                        {searchedPhrase && (
                                            <InputGroup.Append>
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() =>
                                                        this.setState({
                                                            searchedPhrase: "",
                                                            participantsList: this.state.originalParticipants,
                                                        })
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faEraser} />
                                                </Button>
                                            </InputGroup.Append>
                                        )}
                                    </InputGroup>
                                </Col>
                                <Col xl={1} lg={2} md={2} xs={4}>
                                    <RowsDropdown
                                        t={t}
                                        defaultValue={this.props.numberOfRecords}
                                        onChangeValue={(event, { value }) => {
                                            this.setState({
                                                showNOR: value,
                                                displayNOR: value,
                                            });
                                            this.props.onChangeNOR(value);
                                        }}
                                    />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <EventFilter t={t} eventFilterValue={this.props.eventFilterValue} eventsList={this.props.eventsList} onChangeEventFilter={this.props.onChangeEventFilter} />
                                </Col>

                                <Col lg={2} md={3} xs={5}>
                                    <PaymentStatusDropdown
                                        t={t}
                                        onChangeStatusValue={(event, { value }) => {
                                            this.setState({ paymentStatusSelected: value });
                                        }}
                                        defaultValue={paymentStatusSelected}
                                    />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <ParticipantStatus
                                        defaultValue="all"
                                        onPartStatSelected={(value) => {
                                            this.setState({ selectedPartStat: value });
                                        }}
                                        fetchURL={fetchURL}
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        onShowAlert={onShowAlert}
                                    />
                                </Col>
                                <Col lg={2} xs={12} className="text-left text-lg-right mt-lg-3 mt-lg-0 mb-3">
                                    <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync mr-3">
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>

                                    {onCheckUserPrivilage("ACCR_PART_ACTIONS") && (
                                        <Fragment>
                                            {activeEvent ? (
                                                <Button
                                                    variant="outline-primary"
                                                    type="button"
                                                    onClick={() => onChangeNavAndPage({ nav: "participants", subpage: "addParticipant", params: "" }, currentPageParams)}
                                                >
                                                    {t("participant.addPart")}
                                                </Button>
                                            ) : (
                                                <OverlayTrigger key="left" placement="left" overlay={<Tooltip id={"tooltipDisabledNoChanges"}>{t("participant.noActiveEvent")}</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        <Button disabled variant="primary" style={{ pointerEvents: "none" }}>
                                                            {t("participant.addPart")}
                                                        </Button>
                                                    </span>
                                                </OverlayTrigger>
                                            )}
                                        </Fragment>
                                    )}
                                </Col>

                                <Col lg={3} md={4} xs={6}>
                                    <ParticipantLabels
                                        defaultValue={[]}
                                        onPartLabelSelected={(value) => this.setState({ selectedPartLabels: value })}
                                        fetchURL={fetchURL}
                                        onLoadPartLabels={(participantLabels) => {
                                            this.setState({ participantLabels: participantLabels });
                                        }}
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                    />
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="content-body">
                            {loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <Fragment>
                                    <div id="loadTable" style={{ display: "none" }}>
                                        <Fragment>
                                            <Row>
                                                <Col>
                                                    <div className="table-responsive table-fixed-head">
                                                        {filteredPartList.length > 0 ? (
                                                            <Fragment>
                                                                <ParticipantsListTable
                                                                    participantsList={filteredPartList}
                                                                    displayNOR={displayNOR}
                                                                    onChangeNavAndPage={onChangeNavAndPage}
                                                                    onCheckUserPrivilage={onCheckUserPrivilage}
                                                                    activeEvent={activeEvent}
                                                                    t={t}
                                                                    onShowHomePage={onShowHomePage}
                                                                />
                                                                <div className="light-border"></div>
                                                            </Fragment>
                                                        ) : (
                                                            <div className="text-center">{t("notFound")}</div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="rest-info py-3">
                                                <Col>
                                                    {t("loaded")} {displayNOR > filteredPartList.length ? filteredPartList.length : displayNOR} {t("from")} {filteredPartList.length} {t("records")}
                                                </Col>
                                                <Col className="text-center">
                                                    {displayNOR >= filteredPartList.length ? (
                                                        <p className="opacity-4">
                                                            <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                            <span className="px-3">{t("thatsAll")}</span>
                                                            <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                        </p>
                                                    ) : (
                                                        <Button onClick={() => this.setState({ displayNOR: showNOR + displayNOR })} variant="outline-primary">
                                                            {t("loadMore")}
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col></Col>
                                            </Row>
                                        </Fragment>
                                    </div>
                                </Fragment>
                            )}
                        </Container>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default ParticipantsList;
