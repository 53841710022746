import React, { Fragment } from "react";
import { Form, Alert, InputGroup } from "react-bootstrap";
import $ from "jquery";
const AmountInput = (props) => {
    const { value, name, label, onChangeValue, alert, req, id, t } = props;
    return (
        <Fragment>
            <Form.Group>
                <Form.Label>
                    {label} {req && <span className="required">*</span>}
                </Form.Label>
                <InputGroup className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder={t("form.typeAmount")}
                        value={value}
                        name={name}
                        id={id}
                        onChange={(e) => {
                            $(`#${id}`).removeClass("danger");
                            onChangeValue(e);
                        }}
                        className={req ? "req" : ""}
                    />
                    <InputGroup.Append>
                        <InputGroup.Text id={`${name}_currency`}>{t("currency")}</InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
                {alert && (
                    <Alert variant="warning" className="alert-small">
                        {alert}
                    </Alert>
                )}
            </Form.Group>
        </Fragment>
    );
};

export default AmountInput;
