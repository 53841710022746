import React from "react";
import $ from "jquery";

const LoadingSpinnerInner = () => {
    const width = $(window).width();
    const styleWidth = (width - 40) / 2;

    return (
        <div className="loading-spinner" style={{ width: width, height: "100%", position: "static" }}>
            <div className="sk-chase" style={{ top: "calc((100% - 40) / 2)", left: styleWidth }}>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
            </div>
        </div>
    );
};

export default LoadingSpinnerInner;
