import React, { Component, Fragment } from "react";
import { Alert, Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import AlertNoPermission from "../../alerts/AlertNoPermission";
import $ from "jquery";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

class ParticipantsListForInnerTransfer extends Component {
    state = { usersFullName: null, noPermission: false };

    getUsersFullname = async () => {
        const { fetchURL, onShowAlert, activeEvent, partID, t } = this.props;
        const passData = {
            activeEvent: activeEvent,
            partID: partID,
        };
        try {
            const response = await fetch(`${fetchURL}/participants-list-for-inner-transfer`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ usersFullName: fetchedData.fullName });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.getUsersFullname();
    }

    render() {
        const { usersFullName, noPermission } = this.state;
        const { labelTxt, onChangePartFullnameValue, defaultValue, t } = this.props;
        return (
            <Fragment>
                <Form.Label>
                    {labelTxt} <span className="required">*</span>
                </Form.Label>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : usersFullName ? (
                    usersFullName.length > 0 ? (
                        <Dropdown
                            defaultValue={defaultValue}
                            id="fullnameDropdown"
                            className="req mb-3"
                            placeholder={t("selectOrType")}
                            fluid
                            search
                            selection
                            options={usersFullName}
                            onChange={(event, { value }) => {
                                onChangePartFullnameValue(event, { value });
                                $(`#fullnameDropdown`).removeClass("danger");
                            }}
                            noResultsMessage={t("participant.personNotFound")}
                        />
                    ) : (
                        <Alert className="alert-small" variant="danger">
                            {t("participant.noOtherParts")}
                        </Alert>
                    )
                ) : (
                    <LoadingProgressBar t={t} />
                )}
            </Fragment>
        );
    }
}

export default ParticipantsListForInnerTransfer;
