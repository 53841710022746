import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import AlertNoPermission from "../../alerts/AlertNoPermission";

class ParticipantStatus extends Component {
    state = { participantStatusesList: [], noPermission: false };

    getParticipantStatus = async () => {
        const { fetchURL, defaultValue, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/participant-statuses`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const partStatList = fetchedData.partStatList;
                        if (defaultValue === "all") {
                            partStatList.push({
                                key: partStatList.length + 1,
                                value: "all",
                                text: t("selectAll"),
                            });
                        }

                        this.setState({ participantStatusesList: partStatList });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.getParticipantStatus();
    }

    render() {
        const { participantStatusesList, noPermission } = this.state;
        const { defaultValue, onPartStatSelected, req, t } = this.props;
        return (
            <Fragment>
                <Form.Label>
                    {t("participant.status")} {req && <span className="required">*</span>}
                </Form.Label>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : (
                    <Dropdown
                        defaultValue={defaultValue}
                        id="partStatDropdown"
                        className={req ? "req mb-3" : "mb-3"}
                        placeholder={t("participant.clickToChooseStatus")}
                        fluid
                        selection
                        options={participantStatusesList}
                        onChange={(event, { value }) => {
                            onPartStatSelected(value);
                        }}
                    />
                )}
            </Fragment>
        );
    }
}

export default ParticipantStatus;
