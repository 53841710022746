import React, { Fragment } from "react";
import AddEditUser from "../../pages/users/AddEditUser";
import UserDetails from "../../pages/users/UserDetails";
import UsersList from "../../pages/users/UsersList";

const Users = (props) => {
    const {
        fetchURL,
        onCheckUserPrivilage,
        scannerHeight,
        onChangeValuesInFormes,
        onCancelChangeValuesInFormes,
        onChangeNavAndPage,
        windowHeight,
        currentPage,
        onResizeWindow,
        onBackToPreviousPage,
        onShowAlert,
        dataList,
        onGetDataList,
        numberOfRecords,
        onChangeNOR,
        activeEvent,
        moduleName,
        onCheckAdmin,
        t,
        onShowHomePage,
    } = props;
    return (
        <Fragment>
            {currentPage.nav === "users" && (
                <Fragment>
                    {currentPage.subpage === "usersList" && (
                        <UsersList
                            fetchURL={fetchURL}
                            onResizeWindow={onResizeWindow}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            windowHeight={windowHeight}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onShowAlert={onShowAlert}
                            dataList={dataList}
                            onGetDataList={onGetDataList}
                            numberOfRecords={numberOfRecords}
                            onChangeNOR={onChangeNOR}
                            onCheckAdmin={onCheckAdmin}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {(currentPage.subpage === "addUser" || currentPage.subpage === "editUser") && (
                        <AddEditUser
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            fetchURL={fetchURL}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            scannerHeight={scannerHeight}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            onResizeWindow={onResizeWindow}
                            onBackToPreviousPage={onBackToPreviousPage}
                            onShowAlert={onShowAlert}
                            currentPage={currentPage}
                            onChangeNavAndPage={onChangeNavAndPage}
                            activeEvent={activeEvent}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {currentPage.subpage === "userDetails" && (
                        <UserDetails
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            fetchURL={fetchURL}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            scannerHeight={scannerHeight}
                            userID={currentPage.params}
                            onResizeWindow={onResizeWindow}
                            onBackToPreviousPage={onBackToPreviousPage}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onShowAlert={onShowAlert}
                            currentPage={currentPage}
                            moduleName={moduleName}
                            onCheckAdmin={onCheckAdmin}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Users;
