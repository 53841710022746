import React, { Fragment } from "react";
import { Button, Modal, Table } from "react-bootstrap";

const AvalPeriodDetailsModal = (props) => {
    const { t, onClose, show, avalType, avalPeriodData } = props;

    const avalColumnCount = []; //pierwszy element w tablicy - nazwa labelki, drugi el. - ile razy występuje (rowSpan), trzeci el. - w którym momencie występuje

    avalPeriodData.forEach((aval, i) => {
        const label = aval.aval_period_label;
        if (avalColumnCount.filter((e) => e[0] === label).length === 0) avalColumnCount.push([label, 1, i]);
        else
            avalColumnCount.forEach((el) => {
                if (el[0] === label) el[1] += 1;
            });
    });

    var labelIndex = 0;

    function insertRowSpanTD(e, i) {
        //i = current avalType index
        if (avalColumnCount[labelIndex]) {
            if (i === avalColumnCount[labelIndex][2]) {
                const insert = (
                    <td rowSpan={avalColumnCount[labelIndex][1]} className="text-center">
                        {e.aval_period_label}
                        <br />
                        {e.aval_period_date}
                    </td>
                );
                labelIndex++;
                return insert;
            }
        }
    }
    return (
        <Fragment>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("settings.availabilities")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table size="sm" bordered>
                        <tbody>
                            {avalPeriodData.map((e, i) => (
                                <Fragment key={i}>
                                    <tr>
                                        {avalType === "BAR" || avalType === "ACCR" ? (
                                            <Fragment>
                                                {insertRowSpanTD(e, i)}
                                                <td className="text-center">
                                                    {e.aval_period_start} - {e.aval_period_end}
                                                </td>
                                            </Fragment>
                                        ) : (
                                            <td className="text-center">
                                                {e.aval_period_label}
                                                <br />
                                                {e.aval_period_date}
                                            </td>
                                        )}
                                    </tr>
                                </Fragment>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        {t("close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default AvalPeriodDetailsModal;
