import React, { Fragment } from "react";
import { Row, Col, Image } from "react-bootstrap";

const LogoAndTitle = (props) => {
    const { title } = props;
    return (
        <Fragment>
            <Row className="pt-5">
                <Col lg={{ span: 6, offset: 3 }} className="text-center">
                    <Image id="mainLogo" fluid src="images/lyzkon_logo_white.svg" className="my-5 w-75" />
                    <h1 className="h3 mb-4">{title}</h1>
                </Col>
            </Row>
        </Fragment>
    );
};

export default LogoAndTitle;
