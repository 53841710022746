import React, { Component, Fragment } from "react";
import { Container, Row, Col, Button, Form, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import $ from "jquery";
import TooltipWithDisabledButton from "../../../tooltips/TooltipWithDisabledButton";
import NavDetails from "../../../navigation/NavDetails";
import ShopCategories from "../../../forms/dropdowns/ShopCategories";
import TextForm from "../../../forms/text-inputs/TextForm";
import TextareaForm from "../../../forms/TextareaForm";
import { Switch } from "@material-ui/core";
import AmountInput from "../../../forms/text-inputs/AmountInput";
import NumberForm from "../../../forms/number-inputs/NumberForm";
import LoadingSpinnerInner from "../../../spinners/LoadingSpinnerInner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faQuestionCircle, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import NoPermission from "../../../other/NoPermission";
import ShopItemLimitation from "../../../forms/dropdowns/ShopItemLimitation";

class AddEditShopItem extends Component {
    state = {
        loading: true,
        shopItem: [
            {
                shcat_code: "",
                shit_code: "",
                shit_priority: "",
                shit_quantity: "",
                shit_price: "",
                shit_available: true,
                shit_limited: null,
            },
        ],

        shopItemVariants: null,
        shopItemLabels: null,
        changedValues: false,
        imageFile: null,
        imageFileURL: null,
        showShopItemVariants: false,
        alertUploadImage: "",
        noPermission: false,
        langs: null,
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { shopItem, shopItemVariants, showShopItemVariants, imageFile, langs } = this.state;
        const { onShowAlert, t } = this.props;
        onShowAlert("");
        let errorMsg = "";
        $(".form-control.req").each(function () {
            if ($(this).val() === "" || $(this).val() === "default") {
                $(this).addClass("danger");
                errorMsg = t("error.reqFields");
            } else $(this).removeClass("danger");
        });

        if (!shopItem[0].shcat_code) {
            $(`#shopCategoriesDropdown`).addClass("danger");
            errorMsg += t("error.shopCatEmpty");
        }

        if (showShopItemVariants) {
            let vNameErr,
                vQuantityErr = false;

            shopItemVariants.forEach((e, i) => {
                if (e.shit_variant_name === "") {
                    $(`#shitVariantName${i}`).addClass("danger");
                    vNameErr = true;
                }
            });
            if (shopItem[0].shit_quantity) {
                let quantitySum = 0;
                shopItemVariants.forEach((e, i) => {
                    if (e.shit_variant_quantity === "") {
                        $(`#shitVariantQuantity${i}`).addClass("danger");
                        vQuantityErr = true;
                    } else {
                        $(`#shitVariantQuantity${i}`).removeClass("danger");
                        let shitVariantQuantityInt = parseInt(e.shit_variant_quantity, 10);
                        quantitySum += shitVariantQuantityInt;
                    }
                });
                if (quantitySum !== parseInt(shopItem[0].shit_quantity, 10)) {
                    errorMsg += t("error.invalidItemVariantsSum");
                    $(`.shitVariantQuantity .form-control`).addClass("danger");
                }
            }

            if (vNameErr) errorMsg += t("error.noVariantsName");
            if (vQuantityErr) errorMsg += t("error.invalidVariantQuantityValue");
        }

        langs.forEach((e, i) => {
            if (!e.shit_name) {
                $(`#shitName${i}`).addClass("danger");
                errorMsg += t("error.shitNameEmpty");
            }
            if (!e.shit_description) {
                $(`#shitDescr${i}`).addClass("danger");
                errorMsg += t("error.shitDescrEmpty");
            }
        });

        if (errorMsg) {
            onShowAlert({ variant: "danger", message: errorMsg });
        } else {
            const passData = {
                shopItem: shopItem,
                shopItemVariants: shopItemVariants,
                activeEvent: this.props.activeEvent,
                shopItemLabels: this.state.shopItemLabels,
                langs: langs,
            };
            //console.log(passData, shopItem[0].blob_id);
            if (imageFile && !shopItem[0].blob_id) {
                const imageData = new FormData();
                imageData.append("file", this.state.imageFile);
                this.insertBlobItem(imageData, passData);
            } else {
                this.insertOrUpdateShopItem(passData, shopItem[0].blob_id);
            }
        }
    };

    insertBlobItem = async (imageData, passData) => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.addingNewImg") });

        try {
            const response = await fetch(`${fetchURL}/insert-shop-image`, {
                credentials: "include",
                method: "POST",
                body: imageData,
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        const imageID = fetchedData.imageID;
                        this.insertOrUpdateShopItem(passData, imageID);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    insertOrUpdateShopItem = async (passData, imageID) => {
        const { fetchURL, onChangeNavAndPage, onShowAlert, currentPage, t } = this.props;
        onShowAlert({ variant: "info", message: currentPage.subpage === "editShopItem" ? t("info.editingProduct") : t("info.addingProduct") });
        if (imageID) {
            passData.imageID = imageID;
        }
        passData.shopItemID = currentPage.params.shopItemID;
        try {
            const response = await fetch(`${fetchURL}/${currentPage.subpage === "addShopItem" ? "insert-shop-item" : "update-shop-item"}`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false)
                        onShowAlert({
                            variant: "danger",
                            message: fetchedData.details ? `${t(`error.${fetchedData.code}`)}: ${fetchedData.details.slice(0, -2)}.` : t(`error.${fetchedData.code}`),
                        });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onShowAlert({
                            variant: "success",
                            message: currentPage.subpage === "addShopItem" ? t("success.shitAdded") : t("success.shitEdited"),

                            fading: 3000,
                        });
                        onChangeNavAndPage({ nav: "shop", subpage: "shopItemsList", params: "" }, null);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    addShopItemView = async () => {
        const { fetchURL, onShowAlert, t } = this.props;

        try {
            const response = await fetch(`${fetchURL}/add-shop-item-view`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else this.getLanguages();
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    getLanguages = async () => {
        const { fetchURL, onShowAlert, t } = this.props;

        try {
            const response = await fetch(`${fetchURL}/get-languages`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else this.setState({ loading: false, langs: fetchedData.langs });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    //akutalizacja danych {t('from')} formularza w stanie participantData
    handleShopItemData(name, data) {
        const { onChangeValuesInFormes } = this.props;
        const { shopItem } = this.state;
        const list = [...shopItem];
        list[0][name] = data;
        this.setState({ changedValues: true, shopItem: list });
        onChangeValuesInFormes();
    }

    handleAmountValidation = (e) => {
        $("#amountInput").removeClass("danger");
        let amount = e.target.value;

        const oldAmount = this.state.shopItem[0].shit_price;
        const reg = /^(\d+(\.|,)?\d{0,2})$/;

        if (!reg.test(amount) && amount !== "") {
            amount = oldAmount;
        }
        this.handleShopItemData("shit_price", amount);
    };

    handleShopItemVariantData = (name, value, index) => {
        const { onChangeValuesInFormes } = this.props;
        const { shopItemVariants } = this.state;
        const list = [...shopItemVariants];
        list[index][name] = value;
        this.setState({ changedValues: true, shopItemVariants: list });
        onChangeValuesInFormes();
    };

    addNewVariant = () => {
        const { shopItemVariants } = this.state;
        const { onChangeValuesInFormes } = this.props;
        const list = [
            ...shopItemVariants,
            {
                shit_variant_name: "",
                shit_variant_quantity: "",
                shit_variant_id: null,
            },
        ];
        this.setState({ changedValues: true, shopItemVariants: list });
        onChangeValuesInFormes();
    };

    handleNumberValue = (e) => {
        const reg = /^([0-9]*)$/;

        if (e.target.value !== "") {
            if (reg.test(e.target.value)) {
                this.handleShopItemData(e.target.name, e.target.value);
            }
        } else this.handleShopItemData(e.target.name, e.target.value);
    };

    handleUploadFile = (e) => {
        const { t } = this.props;
        const image = e.target.files[0];
        const imgType = image.type;
        $("#removeImageFile").show();
        /* 1024x1024x16 = 16MB */
        let errorMsg = "";
        if (image.size > 16777216) errorMsg += t("error.toMuchWeight");
        if (!imgType.includes("image")) errorMsg += t("error.invalidFileFormat");

        if (errorMsg) this.setState({ changedValues: true, alertUploadImage: { variant: "danger", message: errorMsg } });
        else {
            //jeżeli wcześniej był przypisany jakiś obrazek do produktu i jest próba załadowania nowego - wyczyść id obrazka
            const { shopItem } = this.state;
            const list = [...shopItem];
            list[0].blob_id = null;
            this.setState({ changedValues: true, alertUploadImage: "", imageFile: image, imageFileURL: URL.createObjectURL(image), shopItem: list });
        }
        this.props.onChangeValuesInFormes();
    };

    removeVariant = (index) => {
        const { shopItemVariants } = this.state;
        const list = [...shopItemVariants];
        list.splice(index, 1);
        this.setState({ shopItemVariants: list, changedValues: true });
        this.props.onChangeValuesInFormes();
    };

    handleRemoveImage = () => {
        const shopItem = this.state.shopItem[0];
        this.handleShopItemData(shopItem.shit_image, "");
        this.setState({ imageFile: null, alertUploadImage: "", imageFileURL: null });
        document.getElementById("uploadFile").value = "";
        $("#removeImageFile").hide();
    };

    getProductData = async (shopItemID) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            shopItemID: shopItemID,
        };
        try {
            const response = await fetch(`${fetchURL}/shop-item-details`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        const shopItem = fetchedData.shopItem;
                        const shopItemVariants = fetchedData.shopItemVariants;
                        let blob,
                            buffer,
                            bufferBase64 = null;

                        if (shopItem[0].blob_data) {
                            blob = shopItem[0].blob_data;
                            buffer = Buffer.from(blob);
                            bufferBase64 = buffer.toString("base64");
                        }
                        this.setState(
                            {
                                shopItem: shopItem,
                                shopItemVariants: shopItemVariants,
                                showShopItemVariants: !shopItemVariants ? false : true,
                                shopItemLabels: fetchedData.shopItemLabels,
                                imageFile: shopItem[0].blob_data ? { data: bufferBase64, name: shopItem[0].blob_name, type: shopItem[0].blob_file_type } : null,
                                langs: fetchedData.langs,
                            },
                            () => this.setState({ loading: false })
                        );
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    handleShopItemLangData = (name, value, langCode) => {
        const { langs } = this.state;
        const list = [...langs];
        list.forEach((e, i) => {
            if (e.lang_code === langCode) {
                list[i][name] = value;
            }
        });
        this.setState({ langs: list, changedValues: true });
        this.props.onChangeValuesInFormes();
    };

    componentDidMount() {
        const { onResizeWindow, currentPage } = this.props;
        onResizeWindow("basic");
        $("#uploadFile").on("change", function () {
            $("span").text($(this).val());
        });
        if (currentPage.subpage === "editShopItem") this.getProductData(currentPage.params.shopItemID);
        else this.addShopItemView();
    }

    componentDidUpdate() {
        const { loading, shopItem } = this.state;
        const { onResizeWindow } = this.props;

        if ($(".scrolled-content").outerHeight() && !loading) onResizeWindow("basic");
        if (!loading) $(".scrolled-content").fadeIn();
        if (loading && shopItem[0].event_code) {
            this.setState({ loading: false });
        }
    }

    render() {
        const { loading, changedValues, imageFile, showShopItemVariants, shopItemVariants, alertUploadImage, imageFileURL, noPermission, shopItemLabels, langs } = this.state;
        const { onCancelChangeValuesInFormes, fetchURL, onBackToPreviousPage, currentPage, activeEvent, t, onShowHomePage } = this.props;

        const shopItem = this.state.shopItem[0];
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : !activeEvent ? (
                    <Alert variant="danger">{t("shop.noActiveEvent")}</Alert>
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails
                                    t={t}
                                    header={currentPage.subpage === "addShopItem" ? t("shop.addProduct") : t("shop.editProduct")}
                                    changedValues={changedValues}
                                    onChangeView={onBackToPreviousPage}
                                    onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                                />
                                <Col></Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                                <div className="scrolled-content" style={{ display: "none" }}>
                                    <Container className="py-3">
                                        <Row>
                                            <Col xs={12}>
                                                <p className="h5 my-3 text-uppercase">{t("shop.basicData")}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {langs.map((e, i) => (
                                                <Fragment key={i}>
                                                    <Col xs={12} lg={5}>
                                                        <TextForm
                                                            req
                                                            value={e.shit_name}
                                                            onChangeValue={(event) => {
                                                                this.handleShopItemLangData(event.target.name, event.target.value, e.lang_code);
                                                                $(`#shitName${i}`).removeClass("danger");
                                                            }}
                                                            labelTxt={`${t("shop.productNameLabel")} ${e["lang_code"]}`}
                                                            placeholderTxt={t("shop.productNamePlaceholder")}
                                                            name="shit_name"
                                                            id={`shitName${i}`}
                                                        />
                                                    </Col>
                                                    <Col xs={12} lg={7}>
                                                        <TextareaForm
                                                            req
                                                            value={e.shit_description}
                                                            onChangeValue={(event) => {
                                                                this.handleShopItemLangData(event.target.name, event.target.value, e.lang_code);
                                                                $(`#shitDescr${i}`).removeClass("danger");
                                                            }}
                                                            labelTxt={`${t("shop.productDescrLabel")} ${e["lang_code"]}`}
                                                            placeholderTxt={t("shop.productDescrPlaceholder")}
                                                            name="shit_description"
                                                            id={`shitDescr${i}`}
                                                            rows={1}
                                                        />
                                                    </Col>
                                                </Fragment>
                                            ))}
                                        </Row>
                                        <Row>
                                            <Col lg={8}>
                                                <Row>
                                                    <Col>
                                                        <TextForm
                                                            req
                                                            value={shopItem.shit_code}
                                                            onChangeValue={(e) => {
                                                                this.handleShopItemData(e.target.name, e.target.value);
                                                                $("#shitCode").removeClass("danger");
                                                            }}
                                                            labelTxt={t("shop.productCodeLabel")}
                                                            placeholderTxt={t("shop.productCodePlaceholder")}
                                                            name="shit_code"
                                                            id="shitCode"
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <ShopCategories
                                                            fetchURL={fetchURL}
                                                            defaultValue={shopItem.shcat_code}
                                                            onShopCategoriesSelected={(event, value) => {
                                                                $(`#shopCategoriesDropdown`).removeClass("danger");
                                                                this.handleShopItemData("shcat_code", value);
                                                            }}
                                                            t={t}
                                                            onShowHomePage={onShowHomePage}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <AmountInput
                                                            id="shitPrice"
                                                            value={shopItem.shit_price}
                                                            name="shit_price"
                                                            label={t("shop.itemPrice")}
                                                            onChangeValue={(e) => {
                                                                this.handleAmountValidation(e);
                                                                $("#shitPrice").removeClass("danger");
                                                            }}
                                                            req
                                                            t={t}
                                                            onShowHomePage={onShowHomePage}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <NumberForm
                                                            t={t}
                                                            onShowHomePage={onShowHomePage}
                                                            id="shitQuantity"
                                                            placement="top"
                                                            value={shopItem.shit_quantity}
                                                            name="shit_quantity"
                                                            labelTxt={t("shop.quantity")}
                                                            onChangeValue={(e) => this.handleNumberValue(e)}
                                                            helper={t("shop.itemQuantityHelper")}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <NumberForm
                                                            t={t}
                                                            onShowHomePage={onShowHomePage}
                                                            value={shopItem.shit_priority}
                                                            name="shit_priority"
                                                            labelTxt={t("shop.priority")}
                                                            onChangeValue={(e) => {
                                                                this.handleNumberValue(e);
                                                                $("#shitPriority").removeClass("danger");
                                                            }}
                                                            helper={t("shop.priorityHelper")}
                                                            req
                                                            placement="top"
                                                            id="shitPriority"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>{t("shop.itemAvailability")}</Form.Label>
                                                            <Switch
                                                                id="shopItemAvailability"
                                                                checked={shopItem.shit_available}
                                                                onChange={(e) => this.handleShopItemData(e.target.name, e.target.checked)}
                                                                name="shit_available"
                                                                inputProps={{ "aria-label": "primary checkbox" }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group id="shopItemLimitation">
                                                            <Form.Label>
                                                                {t("shop.itemLimited")}:{" "}
                                                                <OverlayTrigger placement="bottom" overlay={<Tooltip id={`tooltipShopItemLimitation`}>{t("shop.shopItemLimitationHelper")}</Tooltip>}>
                                                                    <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                                                                </OverlayTrigger>
                                                            </Form.Label>
                                                            <ShopItemLimitation
                                                                fetchURL={fetchURL}
                                                                defaultValue={shopItemLabels}
                                                                onShopLimitationSelected={(event, value) => {
                                                                    $(`#shopItemLimitation`).removeClass("danger");
                                                                    this.setState({ shopItemLabels: value, changedValues: true });
                                                                    this.props.onChangeValuesInFormes();
                                                                }}
                                                                t={t}
                                                                onShowHomePage={onShowHomePage}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={4} className="mb-3">
                                                <Form.Label>{t("shop.itemImg")}</Form.Label>
                                                <input id="uploadFile" className="d-block" type="file" name="picture" onChange={this.handleUploadFile} />
                                                <Button
                                                    variant="outline-secondary"
                                                    id="removeImageFile"
                                                    style={{ display: imageFileURL ? "inline-block" : "none" }}
                                                    size="sm"
                                                    onClick={this.handleRemoveImage}
                                                >
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </Button>
                                                {imageFile &&
                                                    (imageFileURL ? (
                                                        <img src={imageFileURL} alt="add-img" className="d-block add-product-img mt-2" />
                                                    ) : (
                                                        <img src={`data:${imageFile.type};base64,${imageFile.data}`} className="d-block add-product-img mt-2" alt={imageFile.name} />
                                                    ))}
                                                {alertUploadImage && (
                                                    <Alert variant={alertUploadImage.variant} className="alert-small my-2">
                                                        {alertUploadImage.variant === "danger" && <FontAwesomeIcon icon={faExclamation} className="mr-2" />}
                                                        {alertUploadImage.message}
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12}>
                                                <p className="h5 my-3 text-uppercase">{t("shop.productVariants")}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                {!showShopItemVariants ? (
                                                    <Button
                                                        className="mb-3"
                                                        variant="outline-secondary"
                                                        onClick={() =>
                                                            this.setState({
                                                                showShopItemVariants: true,
                                                                shopItemVariants: [
                                                                    {
                                                                        shit_variant_name: "",
                                                                        shit_variant_quantity: "",
                                                                    },
                                                                ],
                                                            })
                                                        }
                                                    >
                                                        {t("shop.addProductVariants")}
                                                    </Button>
                                                ) : (
                                                    currentPage.subpage === "addShopItem" && (
                                                        <Button
                                                            className="mb-1"
                                                            variant="outline-secondary"
                                                            onClick={() =>
                                                                this.setState({
                                                                    showShopItemVariants: false,
                                                                    shopItemVariants: null,
                                                                })
                                                            }
                                                        >
                                                            {t("shop.deleteProductVariants")}
                                                        </Button>
                                                    )
                                                )}
                                            </Col>
                                            {showShopItemVariants && (
                                                <Col lg={9} xs={12}>
                                                    <div className="border p-3 mb-3">
                                                        {shopItemVariants.map((e, i) => (
                                                            <Row key={i}>
                                                                <Col lg={8} xs={6}>
                                                                    <TextForm
                                                                        req
                                                                        value={e.shit_variant_name}
                                                                        onChangeValue={(e) => {
                                                                            this.handleShopItemVariantData(e.target.name, e.target.value, i);
                                                                        }}
                                                                        labelTxt={t("shop.variantNameLabel")}
                                                                        placeholderTxt={t("shop.variantNamePlaceholder")}
                                                                        name="shit_variant_name"
                                                                        id={`shitVariantName${i}`}
                                                                    />
                                                                </Col>
                                                                {shopItem.shit_quantity && (
                                                                    <Col className="shitVariantQuantity">
                                                                        <NumberForm
                                                                            t={t}
                                                                            onShowHomePage={onShowHomePage}
                                                                            value={e.shit_variant_quantity}
                                                                            name="shit_variant_quantity"
                                                                            labelTxt={t("shop.quantity")}
                                                                            onChangeValue={(e) => {
                                                                                document.getElementById(`shitVariantQuantity${i}`).classList.remove("danger");
                                                                                const reg = /^([0-9]*)$/;
                                                                                if (e.target.value !== "") {
                                                                                    if (reg.test(e.target.value)) {
                                                                                        this.handleShopItemVariantData(e.target.name, e.target.value, i);
                                                                                    }
                                                                                }
                                                                            }}
                                                                            helper={t("shop.variantQuantityHelper")}
                                                                            id={`shitVariantQuantity${i}`}
                                                                            req
                                                                        />
                                                                    </Col>
                                                                )}
                                                                {currentPage.subpage === "addShopItem" && (
                                                                    <Col lg={1} xs={3}>
                                                                        {i > 0 && (
                                                                            <Form.Group>
                                                                                <Form.Label className="invisible">{t("delete")}</Form.Label>
                                                                                <Button variant="outline-secondary" className="d-block" onClick={() => this.removeVariant(i)}>
                                                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                                                </Button>
                                                                            </Form.Group>
                                                                        )}
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                        ))}
                                                        <Row>
                                                            <Col xs={12}>
                                                                <Button variant="outline-secondary" size="sm" onClick={this.addNewVariant}>
                                                                    {t("shop.addNextVariant")}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            )}
                                        </Row>
                                    </Container>
                                </div>
                                <div className="content-bottom">
                                    <Container>
                                        <Row>
                                            <Col xs={12} className="py-3">
                                                {!changedValues ? (
                                                    <TooltipWithDisabledButton
                                                        t={t}
                                                        onShowHomePage={onShowHomePage}
                                                        mssgTooltip={currentPage.subpage === "editShopItem" ? t("form.noChanges") : t("info.emptyForm")}
                                                        btnTxt={currentPage.subpage === "editShopItem" ? t("edit") : t("add")}
                                                        tooltipID="tooltipDisabledNotFilled"
                                                        size="lg"
                                                    />
                                                ) : (
                                                    <Button type="submit" variant="primary" size="lg">
                                                        {currentPage.subpage === "editShopItem" ? t("edit") : t("add")}
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Form>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default AddEditShopItem;
