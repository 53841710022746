import React, { Fragment } from "react";

const NoMatch = (props) => {
    return (
        <Fragment>
            <p>{props.t("noMatch")}</p>
        </Fragment>
    );
};

export default NoMatch;
