import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import $ from "jquery";
import DescPopover from "../../popovers/DescPopover";

const NumberForm = (props) => {
    const { labelTxt, name, value, req, onChangeValue, helper, id, placement, t, classN } = props;

    const classList = classN ? classN : "";

    return (
        <Fragment>
            <Form.Group>
                <Form.Label>
                    {labelTxt} {req && <span className="required">*</span>} {helper && <DescPopover placement={placement} popoverTxt={<p>{helper}</p>} />}
                </Form.Label>
                <Form.Control
                    type="text"
                    value={value}
                    name={name}
                    placeholder={t("form.typeNumber")}
                    id={id}
                    onChange={(e) => {
                        $(`#${id}`).removeClass("danger");
                        onChangeValue(e);
                    }}
                    className={req ? classList + " req" : classList}
                />
            </Form.Group>
        </Fragment>
    );
};

export default NumberForm;
