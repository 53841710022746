import React, { Fragment, Component } from "react";
import { Container, Row, Col, Dropdown, Button, Modal } from "react-bootstrap";
import NavDetails from "../../navigation/NavDetails";
import $ from "jquery";
import TableUserDetails from "./TableUserDetails";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import LoadingSpinnerSm from "../../spinners/LoadingSpinnerSm";
import { faSync, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoPermission from "../../other/NoPermission";

class UserDetails extends Component {
    state = {
        userDetails: [],
        noPermission: false,
        noPermissionUsersParts: false,
        loading: true,
        displaySubPage: false,
        displayEdit: false,
        changedValues: false,
        showDeactivateUserModal: false,
        showActivateUserModal: false,
        partList: null,
        charsList: null,
        allowActionUserLvl: null,
    };

    changeUserStatus = async (passData) => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/change-user-status`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                onShowAlert("");
                await response.json().then((fetchedData) => {
                    //console.log(data);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                        this.setState({ loading: true });
                        this.userDetailsView();
                    }
                });
            }
        } catch (err) {
            onShowAlert({
                variant: "danger",
                message: `${t("error.cannotFetchData")} (${err})`,
            });
            this.setState({ loading: false });
        }
    };

    handleChangeUserStatus = (status) => {
        const { userDetails } = this.state;
        const { onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.changingUserStatus") });
        this.setState({ showDeactivateUserModal: false });
        const passData = {
            userID: userDetails[0].user_id,
            newStatus: status,
            currentStatus: userDetails[0].user_stat_code,
        };
        //console.log(passData);
        this.changeUserStatus(passData);
    };

    handleSendActivationMail = async () => {
        const { userDetails } = this.state;
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.sendingEmail") });
        const passData = {
            email: userDetails[0].user_email.toString(),
        };
        try {
            const response = await fetch(`${fetchURL}/send-activation-link`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                        this.setState({ loading: true }, () => this.userDetailsView());
                    }
                });
            }
        } catch (err) {
            onShowAlert({
                variant: "danger",
                message: `${t("error.cannotFetchData")} (${err})`,
            });
            this.setState({ loading: false });
        }
    };

    handleResetPassword = async () => {
        const { userDetails } = this.state;
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.sendingEmail") });
        const userData = {
            id: userDetails[0].user_id,
            email: userDetails[0].user_email,
        };

        try {
            const response = await fetch(`${fetchURL}/user-reset-password`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                        this.setState({ loading: true });
                        this.userDetailsView();
                    }
                });
            }
        } catch (err) {
            onShowAlert({
                variant: "danger",
                message: `${t("error.cannotFetchData")} (${err})`,
            });
            this.setState({ loading: false });
        }
    };

    userDetailsView = async () => {
        const { fetchURL, currentPage, onShowAlert, t } = this.props;
        const passData = {
            userID: currentPage.params.userID,
        };
        try {
            const response = await fetch(`${fetchURL}/user-details-view`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        this.setState({ userDetails: fetchedData.userDetails });
                        this.getUsersParticipants();
                    }
                });
            }
        } catch (err) {
            onShowAlert({
                variant: "danger",
                message: `${t("error.cannotFetchData")} (${err})`,
            });
        }
    };

    getUsersParticipants = async () => {
        const { fetchURL, currentPage, onShowAlert, t } = this.props;
        const passData = {
            userID: currentPage.params.userID,
        };
        try {
            const response = await fetch(`${fetchURL}/users-participants`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermissionUsersParts: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ partList: fetchedData.partList }, () => this.getUsersCharacters());
                });
            }
        } catch (err) {
            onShowAlert({
                variant: "danger",
                message: `${t("error.cannotFetchData")} (${err})`,
            });
        }
    };

    getUsersCharacters = async () => {
        const { fetchURL, currentPage, onShowAlert, t } = this.props;
        const passData = {
            userID: currentPage.params.userID,
        };
        try {
            const response = await fetch(`${fetchURL}/users-characters`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermissionUsersParts: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ charsList: fetchedData.charsList, loading: false });
                });
            }
        } catch (err) {
            onShowAlert({
                variant: "danger",
                message: `${t("error.cannotFetchData")} (${err})`,
            });
        }
    };

    handleRefreshData = () => {
        if (this.state.loading !== true) this.setState({ loading: true });
        this.userDetailsView();
    };

    checkUserLevel = async () => {
        const { fetchURL, onShowAlert, t, currentPage } = this.props;
        const passData = { userID: currentPage.params.userID };
        try {
            const response = await fetch(`${fetchURL}/compare-users-role-level`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    this.setState({ allowActionUserLvl: fetchedData.allowAction ? "Y" : "N" });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    readAvatar = async () => {
        const { fetchURL, onShowAlert, t, currentPage } = this.props;
        const passData = { userID: currentPage.params.userID };
        try {
            const response = await fetch(`${fetchURL}/read-avatar`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status && fetchedData.fileData === "noFile") {
                        this.setState({ avatar: fetchedData.fileData, userLogged: true }, () => this.userDetailsView());
                    } else {
                        const blob = fetchedData.fileData.blob_data;
                        var buffer = Buffer.from(blob);
                        var bufferBase64 = buffer.toString("base64");
                        this.setState({ avatar: { data: bufferBase64, type: fetchedData.fileData.blob_file_type, name: fetchedData.fileData.blob_name }, userLogged: true }, () => {
                            this.userDetailsView();
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        const { onResizeWindow } = this.props;
        this.readAvatar();
        this.checkUserLevel();
        onResizeWindow("basic");
    }

    componentDidUpdate() {
        if ($(".scrolled-content").outerHeight() && !this.state.loading) this.props.onResizeWindow("basic");
        if (!this.state.loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { userDetails, loading, showDeactivateUserModal, noPermission, showActivateUserModal, partList, noPermissionUsersParts, allowActionUserLvl, charsList, avatar } = this.state;
        const { onChangeNavAndPage, onCheckUserPrivilage, userID, onBackToPreviousPage, t, fetchURL, onShowAlert } = this.props;
        const currentPageParams = { nav: "users", subpage: "userDetails", params: userID };

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails t={t} header={t("user.userDetails")} onChangeView={onBackToPreviousPage} />
                                <Col>
                                    {loading ? (
                                        <div className="float-right">
                                            <LoadingSpinnerSm />
                                        </div>
                                    ) : userDetails.length > 0 && onCheckUserPrivilage("COMMON_USER_ACTIONS") ? (
                                        <Dropdown>
                                            <Dropdown.Toggle variant="outline-primary" id="dropdownUserActions" className="float-right mt-1">
                                                {t("dropdowns.actions")}
                                            </Dropdown.Toggle>
                                            {userDetails[0].user_stat_code === "DISABLED" ? (
                                                <Fragment>
                                                    <Dropdown.Menu>
                                                        {this.props.onCheckAdmin(userDetails[0].user_roles_list) && (
                                                            <Fragment>
                                                                <Dropdown.Item as="button" onClick={() => this.setState({ showActivateUserModal: true })}>
                                                                    {t("user.activateUserAgain")}
                                                                </Dropdown.Item>
                                                                <hr />
                                                            </Fragment>
                                                        )}
                                                        <Dropdown.Item as="button" onClick={this.handleRefreshData}>
                                                            {t("dropdowns.refreshData")}
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Fragment>
                                            ) : !allowActionUserLvl ? (
                                                <LoadingSpinnerInner />
                                            ) : (
                                                <Dropdown.Menu>
                                                    {allowActionUserLvl === "Y" && (
                                                        <Fragment>
                                                            {this.props.onCheckAdmin(userDetails[0].user_roles_list) && (
                                                                <Dropdown.Item
                                                                    as="button"
                                                                    onClick={() =>
                                                                        onChangeNavAndPage(
                                                                            {
                                                                                nav: "users",
                                                                                subpage: "editUser",
                                                                                params: userID,
                                                                            },
                                                                            currentPageParams
                                                                        )
                                                                    }
                                                                >
                                                                    {t("dropdowns.editData")}
                                                                </Dropdown.Item>
                                                            )}
                                                            {this.props.onCheckAdmin(userDetails[0].user_roles_list) && (
                                                                <Dropdown.Item
                                                                    as="button"
                                                                    onClick={userDetails[0].user_stat_code !== "ACTIVE" ? this.handleSendActivationMail : this.handleResetPassword}
                                                                >
                                                                    {userDetails[0].user_stat_code !== "ACTIVE" ? t("user.sendActivationLink") : t("user.resetPassword")}
                                                                </Dropdown.Item>
                                                            )}
                                                            {userDetails[0].user_stat_code === "ACTIVE" && this.props.onCheckAdmin(userDetails[0].user_roles_list) && (
                                                                <Dropdown.Item as="button" onClick={() => this.setState({ showDeactivateUserModal: true })}>
                                                                    {t("user.deactivateUser")}
                                                                </Dropdown.Item>
                                                            )}
                                                        </Fragment>
                                                    )}
                                                    <Dropdown.Item as="button" onClick={this.handleRefreshData}>
                                                        {t("dropdowns.refreshData")}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            )}
                                        </Dropdown>
                                    ) : (
                                        <Button variant="outline-primary" onClick={this.handleRefreshData} className="btn-sync float-right mt-1">
                                            <FontAwesomeIcon icon={faSync} />
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div
                                style={{
                                    height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51,
                                }}
                            >
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <div className="scrolled-content" style={{ display: "none" }}>
                                <Container className="py-3">
                                    <Row>
                                        <Col xs={12} className="text-center">
                                            {avatar && avatar !== "noFile" ? (
                                                <div className="mx-auto d-block">
                                                    <img src={`data:${avatar.type};base64,${avatar.data}`} width="200" alt={avatar.name} className="rounded-circle" />
                                                </div>
                                            ) : (
                                                <div className="avatar mx-auto d-block border rounded-circle" style={{ width: "200px", height: "200px" }}>
                                                    <FontAwesomeIcon icon={faUser} />
                                                </div>
                                            )}
                                        </Col>
                                        <Col xs={12}>
                                            {userDetails.map((d, i) => (
                                                <p className="title-details" key={i}>
                                                    {d.user_name}
                                                    {d.user_nickname ? ` "${d.user_nickname}" ` : " "}
                                                    {d.user_surname}
                                                </p>
                                            ))}
                                        </Col>
                                    </Row>

                                    {userDetails.length > 0 && (
                                        <TableUserDetails
                                            userDetails={userDetails}
                                            partList={partList}
                                            charsList={charsList}
                                            currentPageParams={currentPageParams}
                                            onChangeNavAndPage={onChangeNavAndPage}
                                            noPermissionUsersParts={noPermissionUsersParts}
                                            t={t}
                                            fetchURL={fetchURL}
                                            onShowAlert={onShowAlert}
                                            onCheckUserPrivilage={onCheckUserPrivilage}
                                        />
                                    )}
                                </Container>
                            </div>
                        )}

                        {showDeactivateUserModal && (
                            <Modal show={showDeactivateUserModal} onHide={() => this.setState({ showDeactivateUserModal: false })}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{t("user.deactivationModal.title")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {t("user.deactivationModal.text")} {userDetails[0].user_name} {userDetails[0].user_surname}?
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.setState({ showDeactivateUserModal: false })}>
                                        {t("user.deactivationModal.no")}
                                    </Button>
                                    <Button variant="primary" onClick={() => this.handleChangeUserStatus("DISABLED")}>
                                        {t("user.deactivationModal.yes")}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        )}
                        {showActivateUserModal && (
                            <Modal show={showActivateUserModal} onHide={() => this.setState({ showActivateUserModal: false })}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{t("user.activationModal.title")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {t("user.activationModal.text")} {userDetails[0].user_name} {userDetails[0].user_surname}?
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.setState({ showActivateUserModal: false })}>
                                        {t("user.activationModal.no")}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            this.setState({ showActivateUserModal: false });
                                            this.handleChangeUserStatus("ACTIVE");
                                        }}
                                    >
                                        {t("user.activationModal.yes")}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default UserDetails;
