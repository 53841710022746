import React, { Fragment, Component } from "react";
import { Button, Row, Col, Form, InputGroup, Container } from "react-bootstrap";
import BarBalanceListTable from "./BarBalanceListTable";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import "react-widgets/dist/css/react-widgets.css";
import $ from "jquery";
import EventFilter from "../../forms/dropdowns/EventFilter";
import RowsDropdown from "../../forms/dropdowns/RowsDropdown";
import { faEraser, faLongArrowAltLeft, faLongArrowAltRight, faRedoAlt, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoPermission from "../../other/NoPermission";
import BarClientTypesDropdown from "../../forms/dropdowns/BarClientTypesDropdown";
import BarClientStatusesDropdown from "../../forms/dropdowns/BarClientStatusesDropdown";
import { Dropdown } from "semantic-ui-react";

class BarBalanceList extends Component {
    state = {
        showNOR: this.props.numberOfRecords,
        displayNOR: this.props.numberOfRecords,
        barBalanceList: this.props.dataList,
        originalBarBalance: this.props.dataList,
        searchedPhrase: "",
        loading: this.props.dataList ? false : true,
        selectedType: "all",
        selectedStatus: "all",
        selectedBalance: "all",
    };

    handleResetFilters = () => {};

    handleSearch = (e) => {
        const searchedPhrase = e.target.value.toLowerCase();
        const { originalBarBalance } = this.state;
        this.setState({ searchedPhrase: searchedPhrase });
        if (searchedPhrase !== "") {
            let filteredParts = originalBarBalance;
            //console.log(filteredParts)
            filteredParts = filteredParts.filter((el) => el.user_fullname.toString().toLowerCase().includes(searchedPhrase));
            this.setState({ barBalanceList: filteredParts });
        } else {
            this.setState({ barBalanceList: originalBarBalance });
        }
    };

    handleDownloadData = () => {
        this.props.onResizeWindow("tableNotLoaded");
        this.setState({ loading: true });
        this.getBarBalanceList();
    };

    getBarBalanceList = async () => {
        const { fetchURL, onShowAlert, onGetDataList, t } = this.props;
        //console.log("getBarBalanceList");
        try {
            const response = await fetch(`${fetchURL}/bar-balance-list`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const barBalanceList = fetchedData.barBalanceList;
                        this.setState({ barBalanceList: barBalanceList, originalBarBalance: barBalanceList }, () => {
                            this.setState({ loading: false });
                            onGetDataList(barBalanceList);
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        const { onResizeWindow } = this.props;
        //console.log("BarBalanceList");
        this.getBarBalanceList();
        onResizeWindow("tableNotLoaded");
        $(window).on("resize", function () {
            onResizeWindow("tableLoaded");
        });
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if (!this.state.loading) {
            $("#loadTable").fadeIn(200);
            if ($(".light-border").outerHeight() && $(".rest-info").outerHeight()) {
                onResizeWindow("tableLoaded");
            }
        }
    }

    render() {
        const { barBalanceList, searchedPhrase, loading, displayNOR, showNOR, noPermission, selectedType, selectedStatus, selectedBalance } = this.state;
        const { onCheckUserPrivilage, onChangeNavAndPage, eventFilterValue, activeEvent, t, onShowHomePage, currentPage, fetchURL, onShowAlert } = this.props;

        function checkBalance(balance) {
            if (selectedBalance === "POSITIVE") return balance > 0;
            else if (selectedBalance === "NEGATIVE") return balance < 0;
            else return balance === 0;
        }

        function filterList(e) {
            if (
                (eventFilterValue === "all" || e.event_code === eventFilterValue) &&
                (selectedType === "all" || e.client_type === selectedType) &&
                (selectedBalance === "all" || checkBalance(e.client_balance)) &&
                (selectedStatus === "all" || e.client_status === selectedStatus)
            )
                return true;
            else return false;
        }

        let filteredPartList = [];
        if (barBalanceList) {
            //wyfiltrowana lista uczestników o wydarzenia, status wpłat i status uczestnika
            filteredPartList = barBalanceList.filter(filterList);
        }
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: currentPage.params };
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="oneNavbar" />
                ) : (
                    <Fragment>
                        <Container fluid className="pt-2 second-bar">
                            <Row>
                                <Col lg={3} xs={12}>
                                    <Form.Label>{t("form.searching")}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="text" placeholder={t("form.search")} onChange={this.handleSearch} value={searchedPhrase} />
                                        {searchedPhrase && (
                                            <InputGroup.Append>
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() =>
                                                        this.setState({
                                                            searchedPhrase: "",
                                                            barBalanceList: this.state.originalBarBalance,
                                                        })
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faEraser} />
                                                </Button>
                                            </InputGroup.Append>
                                        )}
                                    </InputGroup>
                                </Col>
                                <Col xl={1} lg={2} md={2} xs={4}>
                                    <RowsDropdown
                                        t={t}
                                        defaultValue={this.props.numberOfRecords}
                                        onChangeValue={(event, { value }) => {
                                            this.setState({
                                                showNOR: value,
                                                displayNOR: value,
                                            });
                                            this.props.onChangeNOR(value);
                                        }}
                                    />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <EventFilter t={t} eventFilterValue={this.props.eventFilterValue} eventsList={this.props.eventsList} onChangeEventFilter={this.props.onChangeEventFilter} />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <BarClientTypesDropdown t={t} selectedType={selectedType} onSelectType={(v) => this.setState({ selectedType: v })} fetchURL={fetchURL} onShowAlert={onShowAlert} />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <BarClientStatusesDropdown
                                        t={t}
                                        selectedStatus={selectedStatus}
                                        onSelectStatus={(v) => this.setState({ selectedStatus: v })}
                                        fetchURL={fetchURL}
                                        onShowAlert={onShowAlert}
                                    />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <Form.Label className="w-100">{t("barClients.clientBalance")}</Form.Label>
                                    <Dropdown
                                        value={selectedBalance}
                                        className="req mb-3"
                                        placeholder={t("select")}
                                        fluid
                                        selection
                                        options={[
                                            {
                                                key: 0,
                                                value: "all",
                                                text: t("selectAll"),
                                            },
                                            {
                                                key: 1,
                                                value: "POSITIVE",
                                                text: t("barClients.positiveBalance"),
                                            },
                                            {
                                                key: 2,
                                                value: "NEGATIVE",
                                                text: t("barClients.negativeBalance"),
                                            },
                                            {
                                                key: 3,
                                                value: "ZERO",
                                                text: t("barClients.zeroBalance"),
                                            },
                                        ]}
                                        onChange={(event, { value }) => {
                                            this.setState({ selectedBalance: value });
                                        }}
                                    />
                                </Col>

                                <Col lg={2} xs={12} className="mt-lg-3 mt-lg-0 mb-3">
                                    <Button variant="outline-primary" onClick={this.handleResetFilters} className="btn-sync mr-3">
                                        <FontAwesomeIcon icon={faRedoAlt} />
                                    </Button>
                                    <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync mr-3">
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="content-body">
                            {loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <Fragment>
                                    <div id="loadTable" style={{ display: "none" }}>
                                        <Fragment>
                                            <Row>
                                                <Col>
                                                    <div className="table-responsive table-fixed-head">
                                                        {filteredPartList.length > 0 ? (
                                                            <Fragment>
                                                                <BarBalanceListTable
                                                                    barBalanceList={filteredPartList}
                                                                    displayNOR={displayNOR}
                                                                    onChangeNavAndPage={onChangeNavAndPage}
                                                                    onCheckUserPrivilage={onCheckUserPrivilage}
                                                                    activeEvent={activeEvent}
                                                                    t={t}
                                                                    onShowHomePage={onShowHomePage}
                                                                    currentPageParams={currentPageParams}
                                                                    fetchURL={fetchURL}
                                                                    onShowAlert={onShowAlert}
                                                                />
                                                                <div className="light-border"></div>
                                                            </Fragment>
                                                        ) : (
                                                            <div className="text-center">{t("notFound")}</div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="rest-info py-3">
                                                <Col>
                                                    {t("loaded")} {displayNOR > filteredPartList.length ? filteredPartList.length : displayNOR} {t("from")} {filteredPartList.length} {t("records")}
                                                </Col>
                                                <Col className="text-center">
                                                    {displayNOR >= filteredPartList.length ? (
                                                        <p className="opacity-4">
                                                            <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                            <span className="px-3">{t("thatsAll")}</span>
                                                            <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                        </p>
                                                    ) : (
                                                        <Button onClick={() => this.setState({ displayNOR: showNOR + displayNOR })} variant="outline-primary">
                                                            {t("loadMore")}
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col></Col>
                                            </Row>
                                        </Fragment>
                                    </div>
                                </Fragment>
                            )}
                        </Container>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default BarBalanceList;
