import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, Component } from "react";
import { Button, Col, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import CharacterDetailsAndVersionsModal from "../../modals/CharacterDetailsAndVersionsModal";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

class CharsParticipants extends Component {
    state = {
        showModal: null,
    };
    render() {
        const { showModal } = this.state;
        const { t, charsList, fetchURL, onShowAlert, userID } = this.props;
        return (
            <Fragment>
                <Row>
                    <Col>
                        <p className="h5 mb-3">{t("user.assignedCharacters")}</p>
                        {!charsList ? (
                            <LoadingProgressBar t={t} />
                        ) : charsList.length === 0 ? (
                            <span className="opacity-4">{t("user.noAssignedChars")}</span>
                        ) : (
                            <Table bordered hover size="sm" className="table-list mb-0">
                                <thead>
                                    <tr>
                                        <th>{t("characters.characterName")}</th>
                                        <th>{t("characters.characterFraction")}</th>
                                        <th>{t("characters.isContinued")}</th>
                                        <th style={{ width: "150px" }}>{t("dropdowns.actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {charsList.map((e, i) => (
                                        <tr key={i}>
                                            <td>{e.char_name}</td>
                                            <td>{e.char_fraction ? e.char_fraction : <span className="opacity-4">{t("lack")}</span>}</td>
                                            <td>{e.char_is_continued === "Y" ? t("yes") : t("no")}</td>

                                            <td className="text-center">
                                                <OverlayTrigger placement="top" overlay={<Tooltip id="charsDetails">{t("characters.showCharDetails")}</Tooltip>}>
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() => this.setState({ showModal: { show: true, uniqueCharID: e.char_unique_id, charName: e.char_name } })}
                                                    >
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </Button>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </Col>
                </Row>
                {showModal && (
                    <CharacterDetailsAndVersionsModal
                        t={t}
                        show={showModal.show}
                        uniqueCharID={showModal.uniqueCharID}
                        onClose={() => this.setState({ showModal: null })}
                        onShowAlert={onShowAlert}
                        fetchURL={fetchURL}
                        userID={userID}
                        charName={showModal.charName}
                    />
                )}
            </Fragment>
        );
    }
}

export default CharsParticipants;
