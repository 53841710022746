import React, { Component, Fragment } from "react";
import NavDetails from "../../navigation/NavDetails";
import { Row, Col, Table, Container, OverlayTrigger, Tooltip, Modal, Button, Dropdown } from "react-bootstrap";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import NoPermission from "../../other/NoPermission";

class GroupDetails extends Component {
    state = { groupData: null, loading: true, groupMembers: null, showDeleteGroupModal: false, noPermission: false };

    getGroupDetails = async (groupID) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            groupID: groupID,
        };
        onShowAlert("");
        try {
            const response = await fetch(`${fetchURL}/group-details`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        this.setState({ groupData: fetchedData.groupData }, () => {
                            this.getGroupMembers(groupID);
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    getGroupMembers = async (groupID) => {
        const { fetchURL, onShowAlert, activeEvent, t } = this.props;
        const passData = {
            groupID: groupID,
            activeEvent: activeEvent,
        };

        try {
            const response = await fetch(`${fetchURL}/group-members`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ groupMembers: fetchedData.groupMembers, loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    deleteGroup = async () => {
        const { fetchURL, currentPage, onChangeNavAndPage, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.deletingGroup") });
        this.setState({ showDeleteGroupModal: false });
        const passData = {
            groupID: currentPage.params.groupID,
        };
        try {
            const response = await fetch(`${fetchURL}/delete-group`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onShowAlert({ variant: "success", message: t("success.deletedGroup"), fading: 2000 });
                        onChangeNavAndPage({ nav: "groups", subpage: "groupsList", params: "" }, null);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        const { currentPage } = this.props;
        this.getGroupDetails(currentPage.params.groupID);
        const { onResizeWindow } = this.props;
        onResizeWindow("basic");
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if ($(".scrolled-content").outerHeight() && !this.state.loading) onResizeWindow("basic");
        if (!this.state.loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { loading, groupData, groupMembers, showDeleteGroupModal, noPermission } = this.state;
        const { currentPage, onCheckUserPrivilage, onChangeNavAndPage, onBackToPreviousPage, t, activeEvent } = this.props;
        const groupID = currentPage.params.groupID;
        const eventCode = currentPage.params.eventCode;
        const currentPageParams = { nav: "groups", subpage: "groupDetails", params: { groupID: groupID, eventCode: eventCode } };

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails t={t} header={t("group.groupDetails")} onChangeView={onBackToPreviousPage} />
                                <Col>
                                    {!onCheckUserPrivilage ? (
                                        <Button varinat="outline-primary" className="float-right" onClick={() => this.setState({ loading: true }, () => this.getGroupDetails(groupID))}>
                                            {t("dropdowns.refreshData")}
                                        </Button>
                                    ) : (
                                        onCheckUserPrivilage("ACCR_GROUP_ACTIONS") && (
                                            <Dropdown>
                                                <Dropdown.Toggle variant="outline-primary" id="dropdownParticipantActions" className="float-right mt-1 d-inline-block">
                                                    {t("dropdowns.actions")}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {activeEvent && activeEvent !== "noActiveEvent" ? (
                                                        <Dropdown.Item
                                                            as="button"
                                                            onClick={() =>
                                                                onChangeNavAndPage(
                                                                    {
                                                                        nav: "groups",
                                                                        subpage: "editGroupMembers",
                                                                        params: { groupID: groupID, eventCode: groupData[0].event_code },
                                                                    },
                                                                    currentPageParams
                                                                )
                                                            }
                                                        >
                                                            {t("group.manageMembers")}
                                                        </Dropdown.Item>
                                                    ) : (
                                                        <OverlayTrigger overlay={<Tooltip id="dropdownParticipantActionsDisabled">{t("group.editGroupMembersDisabled")}</Tooltip>}>
                                                            <span className="d-block">
                                                                <Dropdown.Item as="button" disabled>
                                                                    {t("group.manageMembers")}
                                                                </Dropdown.Item>
                                                            </span>
                                                        </OverlayTrigger>
                                                    )}
                                                    {activeEvent && activeEvent !== "noActiveEvent" ? (
                                                        <Dropdown.Item
                                                            as="button"
                                                            onClick={() =>
                                                                onChangeNavAndPage(
                                                                    { nav: "groups", subpage: "editGroup", params: { groupID: groupID, eventCode: groupData[0].event_code } },
                                                                    currentPageParams
                                                                )
                                                            }
                                                        >
                                                            {t("group.editGroup")}
                                                        </Dropdown.Item>
                                                    ) : (
                                                        <OverlayTrigger overlay={<Tooltip id="editGroupDisabled">{t("group.editGroupDisabled")}</Tooltip>}>
                                                            <span className="d-block">
                                                                <Dropdown.Item as="button" disabled>
                                                                    {t("group.editGroup")}
                                                                </Dropdown.Item>
                                                            </span>
                                                        </OverlayTrigger>
                                                    )}
                                                    <Dropdown.Item as="button" onClick={() => this.setState({ showDeleteGroupModal: true })}>
                                                        {t("group.deleteGroup")}
                                                    </Dropdown.Item>

                                                    <hr />
                                                    <Dropdown.Item as="button" onClick={() => this.setState({ loading: true }, () => this.getGroupDetails(groupID))}>
                                                        {t("dropdowns.refreshData")}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        )
                                    )}
                                </Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <div className="scrolled-content" style={{ display: "none" }}>
                                <Container className="py-3">
                                    {groupData.map((e, i) => (
                                        <Fragment key={i}>
                                            <h2 className="title-details">{e.part_group_name}</h2>
                                            <Table responsive bordered hover className="table-details">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "40%" }}>{t("group.coordinator")}</td>
                                                        <td>
                                                            <Button
                                                                variant="link"
                                                                onClick={() =>
                                                                    onChangeNavAndPage({ nav: "users", subpage: "userDetails", params: { userID: e.coordinator_user_id } }, currentPageParams)
                                                                }
                                                            >
                                                                {e.coordinator_fullname}
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("group.members")}</td>
                                                        <td>
                                                            {!groupMembers ? (
                                                                <LoadingProgressBar t={t} />
                                                            ) : groupMembers.length > 0 ? (
                                                                <ul style={{ listStyleType: "none", paddingInlineStart: "0px" }} className="mb-0">
                                                                    {groupMembers.map((m, k) => (
                                                                        <li key={k}>
                                                                            <Button
                                                                                variant="link"
                                                                                onClick={() =>
                                                                                    onChangeNavAndPage(
                                                                                        {
                                                                                            nav: "participants",
                                                                                            subpage: "participantDetails",
                                                                                            params: { partID: m.part_id, userID: m.user_id },
                                                                                        },
                                                                                        currentPageParams
                                                                                    )
                                                                                }
                                                                            >
                                                                                {m.user_fullname}
                                                                            </Button>
                                                                            {m.part_ident_number ? ` (${m.part_ident_number})` : ``}
                                                                            {m.part_affiliation_verified === "Y" ? (
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={<Tooltip id={`tooltipAffiliationVerified`}>{t("group.partVerified")}</Tooltip>}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faCheckCircle} className="text-success ml-1" />
                                                                                </OverlayTrigger>
                                                                            ) : (
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={<Tooltip id={`tooltipAffiliationVerified`}>{t("group.partNotVerified")}</Tooltip>}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faTimesCircle} className="text-danger ml-1" />
                                                                                </OverlayTrigger>
                                                                            )}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            ) : (
                                                                <p className="opacity-4">{t("group.noMembers")}</p>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("group.groupDescr")}</td>
                                                        <td>{e.part_group_descr}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("group.ifVerified")}</td>
                                                        <td>{e.part_group_verified === "Y" ? <span className="text-success">{t("yes")}</span> : <span className="text-danger">{t("no")}</span>}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("group.assignedAccomod")}</td>
                                                        <td>
                                                            {e.group_accomodation ? (
                                                                <Button
                                                                    variant="link"
                                                                    onClick={() =>
                                                                        onChangeNavAndPage(
                                                                            { nav: "rooms", subpage: "roomDetails", params: { roomID: e.group_accomodation.room_id } },
                                                                            currentPageParams
                                                                        )
                                                                    }
                                                                >
                                                                    {e.group_accomodation.room_name} ({e.group_accomodation.room_code})
                                                                </Button>
                                                            ) : (
                                                                <span className="opacity-4">{t("lack")}</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("event.eventEdition")}</td>
                                                        <td>{e.event_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("createDate")}</td>
                                                        <td>{e.create_date}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("modifyDate")}</td>
                                                        <td>{e.modify_date}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Fragment>
                                    ))}
                                </Container>
                            </div>
                        )}
                        {showDeleteGroupModal && (
                            <Modal show={showDeleteGroupModal} onHide={() => this.setState({ showDeleteGroupModal: false })}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{t("group.deleteGroupModal.title")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{t("group.deleteGroupModal.text")}</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.setState({ showDeleteGroupModal: false })}>
                                        {t("group.deleteGroupModal.no")}
                                    </Button>
                                    <Button variant="primary" onClick={this.deleteGroup}>
                                        {t("group.deleteGroupModal.yes")}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default GroupDetails;
