import React, { Component, Fragment } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import TooltipWithDisabledButton from "../../tooltips/TooltipWithDisabledButton";
import NavDetails from "../../navigation/NavDetails";
import AmountInput from "../../forms/text-inputs/AmountInput";
import $ from "jquery";
import TextareaForm from "../../forms/TextareaForm";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import NoPermission from "../../other/NoPermission";
import PaymentSides from "../../forms/check-radio-boxes/PaymentSides";
import PurposePaymentTypes from "../../forms/check-radio-boxes/PurposePaymentTypes";
import PaymentMethods from "../../forms/check-radio-boxes/PaymentMethods";
import ParticipantsListForInnerTransfer from "../../forms/dropdowns/ParticipantsListForInnerTransfer";
import AssignPaymentToOrder from "../../forms/dropdowns/AssignPaymentToOrder";
import CalendarDate from "../../forms/datetimes/CalendarDate";

class PostPayment extends Component {
    state = {
        loading: true,
        noPermission: false,
        incorrectVerifiedAmountAlert: "",
        changedValues: false,
        orderCode: null,
        anotherPartID: null,
        paymentDate: new Date(),
        paymentSide: null, //  IN/OUT/INNERTRANS
        paymentMethod: null,
        transactionComment: "",
        paymentAmount: "",
        purposePayment: null,
        transactionCommingIN: null,
        transactionCommingOUT: null,
    };

    handleChangePaymentAmount = (e) => {
        $("#paymentAmount").removeClass("danger");
        let amount = e.target.value;
        const oldAmount = this.state.paymentAmount;
        const reg = /^(\d+(\.|,)?\d{0,2})$/;
        if (!reg.test(amount) && amount !== "") amount = oldAmount;
        this.setState({ paymentAmount: amount });
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { paymentMethod, paymentSide, paymentAmount, paymentDate, anotherPartID, transactionComment, purposePayment, transactionCommingOUT, transactionCommingIN, orderCode } = this.state;
        const { currentPage, onShowAlert, t } = this.props;
        onShowAlert("");
        let errorMsg = "";
        const currentPartID = currentPage.params.partID;
        if (!purposePayment) {
            errorMsg += t("error.noTransferPurpose");
            $("#purposePaymentRadio .ui.radio.checkbox label").addClass("danger");
        }

        if (!paymentSide) {
            errorMsg += t("error.paymentSideNotSelected");
            $("#paymentSideRadio .ui.radio.checkbox label").addClass("danger");
        } else {
            if (paymentSide !== "INNERTRANS") {
                if (!paymentMethod) {
                    errorMsg += t("error.paymendMethodNotSelected");
                    $("#paymentMethodRadio .ui.radio.checkbox label").addClass("danger");
                }
            } else {
                if (purposePayment === "PART" && !anotherPartID) {
                    errorMsg += t("error.paymentPartNotSelected");
                    $("#fullnameDropdown").addClass("danger");
                } /* else if (purposePayment === "PART" && !transactionComment) {
                    errorMsg += `Transfer wewnętrzny "Przeksięgowanie - inne" musi zawierać komentarz.`;
                    $("#transactionComment").addClass("danger");
                } */
                if (currentPartID === anotherPartID) {
                    errorMsg += t("error.cannotMakeTransferOnPart");
                    $("#fullnameDropdown").addClass("danger");
                }
            }
        }

        if (paymentAmount === "" || Number(paymentAmount) === 0) {
            errorMsg += t("error.paymentAmountIsZero");
            $(`#paymentAmount`).addClass("danger");
        }

        if (errorMsg) {
            onShowAlert({ variant: "danger", message: errorMsg });
        } else {
            const passData = {
                paymentSide: paymentSide,
                currentPartID: currentPartID,
                anotherPartID: anotherPartID,
                paymentValue: paymentAmount,
                paymentDate: paymentDate,
                transactionComment: transactionComment,
                transactionCommingOUT: transactionCommingOUT,
                transactionCommingIN: transactionCommingIN,
                orderCode: orderCode,
            };
            //console.log(passData);
            this.postPayment(passData);
        }
    };

    postPayment = async (passData) => {
        const { fetchURL, onShowAlert, onBackToPreviousPage, t, onCancelChangeValuesInFormes } = this.props;
        onShowAlert({ variant: "info", message: t("info.registeringTransaction") });
        try {
            const response = await fetch(`${fetchURL}/insert-payment`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                        onCancelChangeValuesInFormes();
                        onBackToPreviousPage();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    handleChangePartFullnameValue = (event, { value }) => {
        const { onChangeValuesInFormes } = this.props;
        this.setState({ anotherPartID: value, changedValues: true });
        onChangeValuesInFormes();
    };

    postPaymentView = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/post-payment-view`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleSelectPaymentType = () => {
        const { paymentMethod, paymentSide, purposePayment } = this.state;
        let transactionCommingIN = null;
        let transactionCommingOUT = null;
        if (paymentSide === "INNERTRANS") {
            if (purposePayment === "PART") {
                transactionCommingOUT = `ACCRPARTOUT`;
                transactionCommingIN = `ACCRPARTIN`;
            } else {
                transactionCommingOUT = `ACCRACCTOUT`;
                transactionCommingIN = `${purposePayment}${paymentMethod}IN`;
            }
        } else if (paymentSide === "IN") transactionCommingIN = `${purposePayment}${paymentMethod}${paymentSide}`;
        else if (paymentSide === "OUT") transactionCommingOUT = `${purposePayment}${paymentMethod}${paymentSide}`;

        //console.log(transactionCommingIN, transactionCommingOUT);
        this.setState({ transactionCommingIN: transactionCommingIN, transactionCommingOUT: transactionCommingOUT });
    };

    componentDidMount() {
        const { onResizeWindow } = this.props;
        this.postPaymentView();
        onResizeWindow("basic");
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if ($(".scrolled-content").outerHeight() && !this.state.loading) {
            onResizeWindow("basic");
        }
        if (!this.state.loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { loading, paymentAmount, incorrectVerifiedAmountAlert, paymentDate, paymentSide, paymentMethod, noPermission, purposePayment, orderCode } = this.state;
        const { onChangeValuesInFormes, fetchURL, onBackToPreviousPage, currentPage, onShowAlert, activeEvent, t, onShowHomePage } = this.props;

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails t={t} header={t("payment.registerTransaction")} onChangeView={onBackToPreviousPage} />
                                <Col></Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <Form onSubmit={this.handleCheckValidation} autoComplete="none">
                                <div className="scrolled-content" style={{ display: "none" }}>
                                    <Container className="py-3">
                                        <Row>
                                            <Col className="text-center">
                                                <p className="title-details">
                                                    {currentPage.params.partName}
                                                    {currentPage.params.partIdentNumber ? ` (${currentPage.params.partIdentNumber})` : ""}
                                                </p>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={4} xs={12}>
                                                <Form.Group id="paymentSideRadio">
                                                    <Form.Label className="d-block">
                                                        {t("payment.paymentSide")} <span className="required">*</span>
                                                    </Form.Label>
                                                    <PaymentSides
                                                        name="paymentSideRadio"
                                                        id="paymentSideRadio"
                                                        onChangeValue={(e, { value }) => {
                                                            $("#paymentSideRadio .ui.radio.checkbox label").removeClass("danger");
                                                            this.setState({
                                                                paymentSide: value,
                                                                changedValues: true,
                                                                purposePayment: null,
                                                                paymentMethod: null,
                                                                transactionCommingIN: null,
                                                                transactionCommingOUT: null,
                                                            });
                                                            onChangeValuesInFormes();
                                                        }}
                                                        paymentSide={paymentSide}
                                                        t={t}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col lg={4} xs={12}>
                                                {(paymentSide === "IN" || paymentSide === "OUT") && (
                                                    <Form.Group id="paymentMethodRadio">
                                                        <Form.Label className="d-block">
                                                            {t("payment.paymentMethod")} <span className="required">*</span>
                                                        </Form.Label>
                                                        <PaymentMethods
                                                            name="paymentMethodRadio"
                                                            id="paymentMethodRadio"
                                                            onChangeValue={(e, { value }) => {
                                                                $("#paymentMethodRadio .ui.radio.checkbox label").removeClass("danger");
                                                                this.setState({ paymentMethod: value, changedValues: true });
                                                                onChangeValuesInFormes();
                                                            }}
                                                            paymentMethod={paymentMethod}
                                                            t={t}
                                                        />
                                                    </Form.Group>
                                                )}
                                                {paymentSide === "INNERTRANS" && (
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {t("payment.innerTransPurpose")} <span className="required">*</span>
                                                        </Form.Label>
                                                        <PurposePaymentTypes
                                                            name="purposePaymentRadio"
                                                            id="purposePaymentRadio"
                                                            onChangeValue={(e, { value }) => {
                                                                $("#purposePaymentRadio .ui.radio.checkbox label").removeClass("danger");
                                                                onChangeValuesInFormes();
                                                                this.setState({ purposePayment: value, changedValues: true, paymentMethod: "ACCT" }, () => this.handleSelectPaymentType());
                                                            }}
                                                            purposePayment={purposePayment}
                                                            paymentSide={paymentSide}
                                                            activeEvent={activeEvent}
                                                            t={t}
                                                        />
                                                    </Form.Group>
                                                )}
                                            </Col>

                                            <Col lg={4} xs={12}>
                                                {(paymentSide === "IN" || paymentSide === "OUT") && (
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {paymentSide === "IN" ? t("payment.payIn") : paymentSide === "OUT" ? t("payment.payOut") : ""}
                                                            <span className="required">*</span>
                                                        </Form.Label>
                                                        {paymentMethod ? (
                                                            <PurposePaymentTypes
                                                                t={t}
                                                                name="purposePaymentRadio"
                                                                id="purposePaymentRadio"
                                                                onChangeValue={(e, { value }) => {
                                                                    $("#purposePaymentRadio .ui.radio.checkbox label").removeClass("danger");
                                                                    this.setState({ purposePayment: value, changedValues: true }, () => this.handleSelectPaymentType());
                                                                    onChangeValuesInFormes();
                                                                }}
                                                                purposePayment={purposePayment}
                                                            />
                                                        ) : (
                                                            <p className="opacity-4">{t("payment.choosePaymentMethod")}</p>
                                                        )}
                                                    </Form.Group>
                                                )}

                                                {paymentSide === "INNERTRANS" && purposePayment === "PART" && (
                                                    <ParticipantsListForInnerTransfer
                                                        defaultValue="default"
                                                        fetchURL={fetchURL}
                                                        onChangePartFullnameValue={this.handleChangePartFullnameValue}
                                                        onShowAlert={onShowAlert}
                                                        partID={currentPage.params.partID}
                                                        activeEvent={activeEvent}
                                                        labelTxt={t("payment.retransferOnPart")}
                                                        t={t}
                                                        onShowHomePage={onShowHomePage}
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                        {purposePayment === "SHOP" && (
                                            <Row>
                                                <Col lg={4} xs={6}>
                                                    <AssignPaymentToOrder
                                                        t={t}
                                                        onShowHomePage={onShowHomePage}
                                                        value={orderCode}
                                                        partID={currentPage.params.partID}
                                                        onShowAlert={onShowAlert}
                                                        fetchURL={fetchURL}
                                                        onSelectOrder={(value) => this.setState({ orderCode: value })}
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                        <Row>
                                            <Col lg={4} xs={6}>
                                                <AmountInput
                                                    req
                                                    id="paymentAmount"
                                                    name="paymentAmount"
                                                    value={paymentAmount}
                                                    label={t("payment.amount")}
                                                    onChangeValue={this.handleChangePaymentAmount}
                                                    alert={incorrectVerifiedAmountAlert}
                                                    t={t}
                                                    onShowHomePage={onShowHomePage}
                                                />
                                            </Col>
                                            <Col lg={4} xs={6}>
                                                <CalendarDate
                                                    req
                                                    value={paymentDate}
                                                    onChangeCalendarDate={(date) => this.setState({ paymentDate: date })}
                                                    id="paymentDate"
                                                    txtLabel={t("payment.paymentDate")}
                                                    t={t}
                                                    onShowHomePage={onShowHomePage}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <TextareaForm
                                                    value={this.state.transactionComment}
                                                    id="transactionComment"
                                                    name="transactionComment"
                                                    labelTxt={t("payment.transactionComment")}
                                                    onChangeValue={(e) => {
                                                        this.setState({ transactionComment: e.target.value, changedValues: true });
                                                        onChangeValuesInFormes();
                                                    }}
                                                    placeholderTxt={t("form.typeComment")}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <div className="content-bottom">
                                    <Container>
                                        <Row>
                                            <Col xs={12} className="py-3">
                                                {!this.state.changedValues ? (
                                                    <TooltipWithDisabledButton t={t} mssgTooltip={t("info.emptyForm")} btnTxt={t("confirm")} tooltipID="tooltipDisabledNotFilled" />
                                                ) : (
                                                    <Button type="submit" variant="primary">
                                                        {t("confirm")}
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Form>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default PostPayment;
