import React, { Fragment } from "react";
import ReceptionistsList from "../../pages/receptionists/ReceptionistsList";

const Receptionists = (props) => {
    const { fetchURL, onCheckUserPrivilage, onChangeNavAndPage, currentPage, windowHeight, onResizeWindow, onShowAlert, numberOfRecords, onChangeNOR, t, onShowHomePage } = props;
    return (
        <Fragment>
            {currentPage.nav === "receptionists" && (
                <Fragment>
                    {currentPage.subpage === "receptionistsList" && (
                        <ReceptionistsList
                            fetchURL={fetchURL}
                            onResizeWindow={onResizeWindow}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            windowHeight={windowHeight}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onShowAlert={onShowAlert}
                            numberOfRecords={numberOfRecords}
                            onChangeNOR={onChangeNOR}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Receptionists;
