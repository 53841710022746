import React, { Component } from "react";
import { Fragment } from "react";
import $ from "jquery";
import TextForm from "./TextForm";
import NoPermission from "../../other/NoPermission";

class IdentNumber extends Component {
    state = { noPermission: false };

    getIdentNumbersList = async () => {
        const { fetchURL, onShowAlert, activeEvent, onSetDefaultIdentNumber, t } = this.props;
        const passData = {
            eventCode: activeEvent.code,
        };

        try {
            const response = await fetch(`${fetchURL}/ident-numbers-list`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (this.props.currentPage.subpage !== "editParticipant") onSetDefaultIdentNumber(fetchedData.identNumber);
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.getIdentNumbersList();
    }

    render() {
        const { noPermission } = this.state;
        const { value, onChangePartIdentNumber, t } = this.props;

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : (
                    <Fragment>
                        <TextForm
                            labelTxt="Numer identa"
                            placeholderTxt="Wpisz numer identa"
                            value={value}
                            onChangeValue={(e) => {
                                $("#partIdentNumber").removeClass("danger");
                                onChangePartIdentNumber(e);
                            }}
                            req
                            name="part_ident_number"
                            id="partIdentNumber"
                        />
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default IdentNumber;
