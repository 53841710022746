import React, { Fragment } from "react";
import { Form, InputGroup } from "react-bootstrap";

const InputGroupForm = (props) => {
    const { value, onChangeValue, labelTxt, placeholderTxt, name, descr, id, descrTxt, req } = props;
    return (
        <Fragment>
            <Form.Label>
                {labelTxt}
                {req && <span className="required"> *</span>}
            </Form.Label>
            <InputGroup className="mb-3">
                <Form.Control placeholder={placeholderTxt} aria-label={name} aria-describedby={descr} value={value} onChange={onChangeValue} id={id} className={req ? "req" : ""} />
                <InputGroup.Append>
                    <InputGroup.Text id={descr}>{descrTxt}</InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
        </Fragment>
    );
};

export default InputGroupForm;
