import React, { Component, Fragment } from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import AlertNoPermission from "../alerts/AlertNoPermission";
import CharacterDetails from "../pages/characters/CharacterDetails";
import LoadingProgressBar from "../spinners/LoadingProgressBar";

class CharacterDetailsModal extends Component {
    state = {
        loading: true,
        charDetails: null,
        noPermission: false,
        alert: null,
    };

    getCharacterData = async () => {
        const { fetchURL, onShowAlert, t, charID, eventCode } = this.props;
        const passData = { charID: charID, eventCode: eventCode };
        onShowAlert("");
        try {
            const response = await fetch(`${fetchURL}/character-details`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        this.setState({ charDetails: fetchedData.charDetails, loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        this.getCharacterData();
    }

    render() {
        const { noPermission, alert, charDetails, loading } = this.state;
        const { show, t, onClose } = this.props;

        return (
            <Fragment>
                <Modal show={show} onHide={onClose} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>{t("characters.characterDetailsModal.title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {noPermission ? (
                            <AlertNoPermission t={t} />
                        ) : alert ? (
                            <Alert variant={alert.variant}>{alert.message}</Alert>
                        ) : loading ? (
                            <LoadingProgressBar t={t} />
                        ) : (
                            <Row>
                                <Col xs={12}>
                                    <CharacterDetails t={t} details={charDetails} appView />
                                </Col>
                            </Row>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" type="button" onClick={onClose}>
                            {t("close")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}

export default CharacterDetailsModal;
