import React, { Component, Fragment } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { faCheck, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertNoPermission from "../alerts/AlertNoPermission";
import LoadingProgressBar from "../spinners/LoadingProgressBar";

class ChangeIdentTypeModal extends Component {
    state = { changedValues: false, noPermission: false, loading: true };

    getPartsToPeriod = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {};
        try {
            const response = await fetch(`${fetchURL}/get-parts-to-period`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        if (fetchedData.status !== true) this.setState({ alert: { variant: fetchedData.status, message: t(`${fetchedData.status}.${fetchedData.code}`) } });
                        const list = [],
                            partsListDownloaded = [],
                            partsListDropdown = fetchedData.partsList;
                        partsListDropdown.forEach((e, i) => {
                            partsListDownloaded.push(e);
                            list.push({
                                key: i + 1,
                                value: e.part_id,
                                text: (
                                    <p>
                                        {e.user_fullname}{" "}
                                        {e.part_a2p_answer === "Y" ? (
                                            <span>
                                                <FontAwesomeIcon icon={faCheck} className="text-success" />
                                            </span>
                                        ) : e.part_a2p_answer === "M" ? (
                                            <span>
                                                <FontAwesomeIcon icon={faQuestion} className="text-warning" />
                                            </span>
                                        ) : (
                                            <Fragment></Fragment>
                                        )}
                                    </p>
                                ),
                            });
                        });

                        //console.log("partsListDownloaded: ", partsListDownloaded);
                        const { partsListSelected } = this.state;
                        //console.log("partsListSelected: ", partsListSelected);

                        if (this.props.option === "edit") {
                            //dodawanie wybranych uczestników w trybie edycji jeśli nie są na liście w dropdownie
                            partsListSelected.forEach((e) => {
                                if (e) {
                                    let partIsOnTheList = false;
                                    list.forEach((el) => {
                                        if (el.value === e.partID) partIsOnTheList = true;
                                    });
                                    if (!partIsOnTheList)
                                        list.push({
                                            key: list.length + 1,
                                            value: e.partID,
                                            text: (
                                                <p>
                                                    {e.partName}{" "}
                                                    {e.partAnswer === "Y" ? (
                                                        <span>
                                                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                                                        </span>
                                                    ) : e.partAnswer === "M" ? (
                                                        <span>
                                                            <FontAwesomeIcon icon={faQuestion} className="text-warning" />
                                                        </span>
                                                    ) : (
                                                        <Fragment></Fragment>
                                                    )}
                                                </p>
                                            ),
                                        });
                                }
                            });
                        }

                        list.push({ key: list.length + 1, value: "default", text: t("lack") });
                        //console.log("partsListDropdown: ", list);

                        this.setState({ partsListDropdown: list, loading: false, partsListDownloaded: partsListDownloaded });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    render() {
        const { changedValues, loading, noPermission } = this.state;
        const { t, onClose } = this.props;
        return (
            <Fragment>
                <Modal show={true} onHide={onClose} size="lg">
                    <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                        <Modal.Header closeButton>
                            <Modal.Title>{t("ident.changeIdentType")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{noPermission ? <AlertNoPermission t={t} /> : loading ? <LoadingProgressBar t={t} /> : <Row></Row>}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary" onClick={onClose}>
                                {t("cancel")}
                            </Button>
                            <Button variant="primary" type="submit" disabled={!changedValues} onClick={this.handleCheckValidation}>
                                {t("save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default ChangeIdentTypeModal;
