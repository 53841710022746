import React, { Fragment, Component } from "react";
import { Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

class TableUsersList extends Component {
    state = {
        currentSortType: "default",
        currentSortingColumn: "sortID",
    };

    handleSorting = (btnID) => {
        const { currentSortType } = this.state;
        let nextSort;
        if (currentSortType === "down") {
            nextSort = "up";
        } else if (currentSortType === "up") {
            nextSort = "default";
        } else if (currentSortType === "default") {
            nextSort = "down";
        }

        this.setState({
            currentSortType: nextSort,
            currentSortingColumn: btnID,
        });
    };

    render() {
        const { currentSortType, currentSortingColumn } = this.state;
        const { users, numOfRecords, onChangeNavAndPage, t, onCheckUserPrivilage } = this.props;

        //sortowanie listy
        const sortTypes = {
            up: {
                fnID: (a, b) => a.user_id - b.user_id,
                fnCreateDate: (a, b) => {
                    if (new Date(a.create_date) > new Date(b.create_date) || isNaN(new Date(a.create_date))) return 1;
                    else return -1;
                },
                fnSurname: (a, b) => {
                    if (a.user_surname.toUpperCase() > b.user_surname.toUpperCase()) return 1;
                    else return -1;
                },
                fnName: (a, b) => {
                    if (a.user_name.toUpperCase() > b.user_name.toUpperCase()) return 1;
                    else return -1;
                },
                fnEmail: (a, b) => {
                    if (a.user_email.toUpperCase() > b.user_email.toUpperCase()) return 1;
                    else return -1;
                },
                fnNickname: (a, b) => {
                    if (a.user_nickname === null) return 1;
                    else if (b.user_nickname === null) return -1;
                    else if (a.user_nickname.toUpperCase() > b.user_nickname.toUpperCase()) return 1;
                    else return -1;
                },
            },
            down: {
                fnID: (a, b) => b.user_id - a.user_id,
                fnCreateDate: (a, b) => {
                    if (isNaN(new Date(a.create_date))) return -1;
                    else if (new Date(a.create_date) > new Date(b.create_date)) return -1;
                    else return 1;
                },
                fnSurname: (a, b) => {
                    if (a.user_surname.toUpperCase() > b.user_surname.toUpperCase()) return -1;
                    else return 1;
                },
                fnName: (a, b) => {
                    if (a.user_name.toUpperCase() > b.user_name.toUpperCase()) return -1;
                    else return 1;
                },
                fnEmail: (a, b) => {
                    if (a.user_email.toUpperCase() > b.user_email.toUpperCase()) return -1;
                    else return 1;
                },
                fnNickname: (a, b) => {
                    //jest to wyodrębnione do osobnego ifa, bo jeśli przejdzie do następnego warunku, a nickname będzie nullem, to wywali błąd Cannot read property 'toUpperCase' of null
                    if (a.user_nickname === null) return -1;
                    else if (b.user_nickname === null) return 1;
                    else if (a.user_nickname.toUpperCase() > b.user_nickname.toUpperCase()) return -1;
                    else return 1;
                },
            },
            default: {
                fnID: (a, b) => a,
                fnCreateDate: (a, b) => a,
                fnSurname: (a, b) => a,
                fnName: (a, b) => a,
                fnNickname: (a, b) => a,
                fnEmail: (a, b) => a,
            },
        };

        let sortedData = [];
        if (currentSortingColumn === "sortID") sortedData = users.sort(sortTypes[currentSortType].fnID);
        else if (currentSortingColumn === "sortCreateDate") sortedData = users.sort(sortTypes[currentSortType].fnCreateDate);
        else if (currentSortingColumn === "sortSurname") sortedData = users.sort(sortTypes[currentSortType].fnSurname);
        else if (currentSortingColumn === "sortName") sortedData = users.sort(sortTypes[currentSortType].fnName);
        else if (currentSortingColumn === "sortNickame") sortedData = users.sort(sortTypes[currentSortType].fnNickname);
        else if (currentSortingColumn === "sortEmail") sortedData = users.sort(sortTypes[currentSortType].fnEmail);
        else sortedData = users.sort(sortTypes[currentSortType].fnID);

        const currentPageParams = { nav: "users", subpage: "usersList", params: "" };
        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th style={{ width: "60px" }}>
                                {t("user.list.userID")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortID" onClick={() => this.handleSorting("sortID")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortID" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                            <th style={{ width: "150px" }}>
                                {t("user.list.surname")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortSurname" onClick={() => this.handleSorting("sortSurname")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortSurname" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                            <th style={{ width: "100px" }}>
                                {t("user.list.nickname")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortNickame" onClick={() => this.handleSorting("sortNickame")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortNickame" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                            <th style={{ width: "150px" }}>
                                {t("user.list.name")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortName" onClick={() => this.handleSorting("sortName")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortName" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                            <th style={{ width: "280px" }}>
                                {t("user.list.email")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortEmail" onClick={() => this.handleSorting("sortEmail")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortEmail" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                            <th style={{ width: "7%" }}>{t("user.list.activity")}</th>
                            <th style={{ width: "20%" }}>{t("user.list.roles")}</th>
                            <th style={{ width: "7%" }}>{t("user.list.phone")}</th>
                            <th style={{ width: "180px" }}>
                                {t("user.list.createDate")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortCreateDate" onClick={() => this.handleSorting("sortCreateDate")}>
                                    <FontAwesomeIcon
                                        icon={currentSortingColumn !== "sortCreateDate" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort}
                                    />
                                </Button>
                            </th>
                            <th style={{ width: "7.2rem" }}>{t("dropdowns.actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData
                            .map((p, i) => (
                                <tr key={i}>
                                    <td>{p.user_id}</td>
                                    <td>{p.user_surname}</td>
                                    <td>{p.user_nickname ? p.user_nickname : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{p.user_name}</td>
                                    <td>{p.user_email}</td>
                                    <td>{p.user_stat_name}</td>
                                    <td>{p.user_roles_list}</td>
                                    <td>{p.user_phone ? p.user_phone : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{p.create_date}</td>

                                    <td className="text-center">
                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`userDetailsTooltip${i}`}>{t("user.seeUserDetails")}</Tooltip>}>
                                            <span className="d-inline-block">
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() => onChangeNavAndPage({ nav: "users", subpage: "userDetails", params: { userID: p.user_id } }, currentPageParams)}
                                                >
                                                    <FontAwesomeIcon icon={faEye} />
                                                </Button>
                                            </span>
                                        </OverlayTrigger>
                                        {p.user_stat_code !== "DISABLED" && p.allowAction && onCheckUserPrivilage("COMMON_USER_ACTIONS") && (
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`userEditTooltip${i}`}>{t("user.editUser")}</Tooltip>}>
                                                <span className="d-inline-block">
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() => onChangeNavAndPage({ nav: "users", subpage: "editUser", params: { userID: p.user_id } }, currentPageParams)}
                                                        className="mx-1"
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>
                                        )}
                                    </td>
                                </tr>
                            ))
                            .slice(0, numOfRecords)}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}

export default TableUsersList;
