import React, { Fragment } from "react";
import { Button, Table } from "react-bootstrap";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

const ParticipantPaymentsListTable = (props) => {
    const { participantPaymentsList, t, currentPageParams, onChangeNavAndPage } = props;
    return (
        <Fragment>
            {participantPaymentsList ? (
                participantPaymentsList.length > 0 ? (
                    <Table bordered hover className="table-list" responsive="lg" id="tablePaymentPartList">
                        <thead>
                            <tr>
                                <th>{t("participant.paymentsList.descr")}</th>
                                <th>{t("participant.paymentsList.innerTransReceiver")}</th>
                                <th>{t("participant.paymentsList.innerTransAddresser")}</th>
                                <th style={{ minWidth: "120px" }}>{t("participant.paymentsList.amount")}</th>
                                <th>{t("participant.paymentsList.registeredBy")}</th>
                                <th>{t("participant.paymentsList.comment")}</th>
                                <th>{t("participant.paymentsList.paymentDate")}</th>
                                <th>{t("participant.paymentsList.paymentDateReg")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {participantPaymentsList.map((e, i) => (
                                <tr key={i}>
                                    <td>{e.payment_type_descr}</td>
                                    <td>
                                        {e.payment_type_code === "ACCRPARTOUT" ? (
                                            <Button
                                                variant="link"
                                                onClick={() =>
                                                    onChangeNavAndPage(
                                                        { nav: "participants", subpage: "participantDetails", params: { partID: e.part_id_out, userID: e.part_out.userID } },
                                                        currentPageParams
                                                    )
                                                }
                                            >
                                                {e.part_out.userFullname}
                                            </Button>
                                        ) : (
                                            <span className="opacity-4">{t("na")}</span>
                                        )}
                                    </td>
                                    <td>
                                        {e.payment_type_code === "ACCRPARTIN" ? (
                                            <Button
                                                variant="link"
                                                onClick={() =>
                                                    onChangeNavAndPage(
                                                        { nav: "participants", subpage: "participantDetails", params: { partID: e.part_id_in, userID: e.part_in.userID } },
                                                        currentPageParams
                                                    )
                                                }
                                            >
                                                {e.part_in.userFullname}
                                            </Button>
                                        ) : (
                                            <span className="opacity-4">{t("na")}</span>
                                        )}
                                    </td>
                                    <td>
                                        {e.payment_amount > 0 ? (
                                            <span className="text-success">
                                                +{e.payment_amount.toFixed(2)} {t("currency")}
                                            </span>
                                        ) : (
                                            <span className="text-danger">
                                                {e.payment_amount.toFixed(2)} {t("currency")}
                                            </span>
                                        )}
                                    </td>
                                    <td>
                                        <Button variant="link" onClick={() => onChangeNavAndPage({ nav: "users", subpage: "userDetails", params: { userID: e.create_user_id } }, currentPageParams)}>
                                            {e.user_fullname}
                                        </Button>
                                    </td>
                                    <td>{e.payment_comment ? e.payment_comment : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{e.payment_date}</td>
                                    <td>{e.create_date}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <p className="opacity-4">{t("payment.noPayments")}</p>
                )
            ) : (
                <LoadingProgressBar t={t} />
            )}
        </Fragment>
    );
};

export default ParticipantPaymentsListTable;
