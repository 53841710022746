import React, { Fragment, Component } from "react";
import $ from "jquery";
import { Alert, Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import NoPermission from "../../other/NoPermission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faSync } from "@fortawesome/free-solid-svg-icons";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import ScheduleTable from "./ScheduleTable";
import ScheduleTableConstr from "./ScheduleTableConstr";

let thead, tbody;

class DutiesMain extends Component {
    state = { loading: true, noPermission: false, accrDuties: null, barDuties: null, constrDuties: null, key: "ACCR" };

    getDutyAssignments = async () => {
        const { fetchURL, onShowAlert, t, activeEvent } = this.props;
        const passData = {
            activeEvent: activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/get-duty-assignments`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const drawTable = fetchedData.accrDuties.length > 0 ? fetchedData.accrDuties : fetchedData.barDuties.length ? fetchedData.barDuties : null;

                        if (drawTable) {
                            let prevLabel = null;
                            drawTable.forEach((e) => {
                                //console.log(e.date, prevLabel, e.date !== prevLabel);
                                if (e.date !== prevLabel) {
                                    prevLabel = e.date;
                                    //console.log("thead push");
                                    thead.push({
                                        date: e.date,
                                        label: (
                                            <span>
                                                {e.date}
                                                <br />
                                                {e.duty_period_label}
                                            </span>
                                        ),
                                    });
                                }

                                let repeat = false;
                                tbody.forEach((t) => {
                                    if (e.start === t.start) {
                                        repeat = true;
                                    }
                                });
                                if (!repeat) {
                                    tbody.push({ start: e.start, label: `${e.start} - ${e.end}` });
                                }
                            });

                            function sorting(a, b) {
                                if (a.start > b.start) return 1;
                                else return -1;
                            }

                            tbody = tbody.sort(sorting);
                            tbody.unshift({ start: null, label: null });

                            //console.log(thead);
                        }

                        this.setState({ loading: false, accrDuties: fetchedData.accrDuties, barDuties: fetchedData.barDuties, constrDuties: fetchedData.constrDuties });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        const { onResizeWindow, activeEvent } = this.props;
        onResizeWindow("tableNotLoaded");
        $(window).on("resize", function () {
            onResizeWindow("tableLoaded");
        });
        thead = [{ date: null, label: null }];
        tbody = [];
        if (activeEvent) this.getDutyAssignments();
        else this.setState({ loading: false });
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if (!this.state.loading) {
            $("#loadTable").fadeIn(200);
            if ($(".light-border").outerHeight() && $(".rest-info").outerHeight()) {
                onResizeWindow("tableLoaded");
            }
        }
    }

    handleDownloadData = () => {
        this.setState({ loading: true }, () => {
            thead = [{ date: null, label: null }];
            tbody = [];
            if (this.props.activeEvent) this.getDutyAssignments();
            else this.setState({ loading: false });
        });
    };

    sendEmail = async (dutyTypeCode) => {
        const { fetchURL, onShowAlert, t, activeEvent } = this.props;
        const passData = {
            activeEvent: activeEvent,
            dutyTypeCode: dutyTypeCode,
        };
        onShowAlert({ variant: "info", message: t(`info.sendingDutyEmails`) });
        try {
            const response = await fetch(`${fetchURL}/schedule-send-email`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else onShowAlert({ variant: "success", message: t(`success.emailsSent`) });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    render() {
        const { noPermission, loading, key, accrDuties, barDuties, constrDuties } = this.state;
        const { t, onChangeNavAndPage, currentPage, activeEvent } = this.props;
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: "" };

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="oneNavbar" />
                ) : (
                    <Fragment>
                        <Container fluid className="pt-2 second-bar">
                            <Row>
                                <Col xs={12} className="text-center text-lg-right mt-lg-3 mt-lg-0 mb-3">
                                    <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync mr-3">
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="content-body" style={{ overflowY: "auto" }}>
                            {loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <div id="loadTable" style={{ display: "none" }}>
                                    <Container fluid>
                                        {!activeEvent ? (
                                            <Row>
                                                <Col className="text-center">
                                                    <Alert variant="info">
                                                        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                                        {t("duties.noActiveEvent")}
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Row>
                                                <Col>
                                                    <Tabs id="duty-schedule" activeKey={key} onSelect={(k) => this.setState({ key: k })}>
                                                        <Tab eventKey="ACCR" title={t("duties.accr")} className="duty-schedule">
                                                            {accrDuties.length === 0 ? (
                                                                <div>
                                                                    <p className="my-3 h5">{t("duties.noAssignedDuties")}</p>
                                                                    <Button
                                                                        variant="primary"
                                                                        className="my-3"
                                                                        onClick={() => {
                                                                            onChangeNavAndPage(
                                                                                {
                                                                                    nav: "duties",
                                                                                    subpage: "createSchedule",
                                                                                    params: { dutyTypeCode: "ACCR", option: "add" },
                                                                                },
                                                                                currentPageParams
                                                                            );
                                                                        }}
                                                                    >
                                                                        {t("duties.createSchedule")}
                                                                    </Button>
                                                                </div>
                                                            ) : (
                                                                <Fragment>
                                                                    <ScheduleTable duties={accrDuties} tbody={tbody} thead={thead} dutyTypeCode="ACCR" />
                                                                    <Button
                                                                        className="my-3"
                                                                        variant="outline-primary"
                                                                        onClick={() => {
                                                                            onChangeNavAndPage(
                                                                                {
                                                                                    nav: "duties",
                                                                                    subpage: "createSchedule",
                                                                                    params: { dutyTypeCode: "ACCR", option: "edit" },
                                                                                },
                                                                                currentPageParams
                                                                            );
                                                                        }}
                                                                    >
                                                                        {t("duties.editSchedule")}
                                                                    </Button>
                                                                    <Button className="my-3 ml-2" variant="outline-primary" onClick={() => this.sendEmails("ACCR")}>
                                                                        {t("duties.sendEmails")}
                                                                    </Button>
                                                                </Fragment>
                                                            )}
                                                        </Tab>
                                                        <Tab eventKey="BAR" title={t("duties.bar")} className="duty-schedule">
                                                            {barDuties.length === 0 ? (
                                                                <div>
                                                                    <p className="my-3 h5">{t("duties.noAssignedDuties")}</p>
                                                                    <Button
                                                                        variant="primary"
                                                                        className="my-3"
                                                                        onClick={() => {
                                                                            onChangeNavAndPage(
                                                                                {
                                                                                    nav: "duties",
                                                                                    subpage: "createSchedule",
                                                                                    params: { dutyTypeCode: "BAR", option: "add" },
                                                                                },
                                                                                currentPageParams
                                                                            );
                                                                        }}
                                                                    >
                                                                        {t("duties.createSchedule")}
                                                                    </Button>
                                                                </div>
                                                            ) : (
                                                                <Fragment>
                                                                    <ScheduleTable duties={barDuties} tbody={tbody} thead={thead} dutyTypeCode="BAR" />
                                                                    <Button
                                                                        className="my-3"
                                                                        variant="outline-primary"
                                                                        onClick={() => {
                                                                            onChangeNavAndPage(
                                                                                {
                                                                                    nav: "duties",
                                                                                    subpage: "createSchedule",
                                                                                    params: { dutyTypeCode: "BAR", option: "edit" },
                                                                                },
                                                                                currentPageParams
                                                                            );
                                                                        }}
                                                                    >
                                                                        {t("duties.editSchedule")}
                                                                    </Button>
                                                                    <Button className="my-3 ml-2" variant="outline-primary" onClick={() => this.sendEmails("BAR")}>
                                                                        {t("duties.sendEmails")}
                                                                    </Button>
                                                                </Fragment>
                                                            )}
                                                        </Tab>
                                                        <Tab eventKey="CONSTR" title={t("duties.constr")} className="duty-schedule">
                                                            {constrDuties.length === 0 ? (
                                                                <div>
                                                                    <p className="my-3 h5">{t("duties.noAssignedDuties")}</p>
                                                                    <Button
                                                                        variant="primary"
                                                                        className="my-3"
                                                                        onClick={() => {
                                                                            onChangeNavAndPage(
                                                                                {
                                                                                    nav: "duties",
                                                                                    subpage: "createSchedule",
                                                                                    params: { dutyTypeCode: "CONSTR", option: "add" },
                                                                                },
                                                                                currentPageParams
                                                                            );
                                                                        }}
                                                                    >
                                                                        {t("duties.createSchedule")}
                                                                    </Button>
                                                                </div>
                                                            ) : (
                                                                <Row>
                                                                    <Col lg={6} xs={12}>
                                                                        <ScheduleTableConstr duties={constrDuties} />
                                                                        <Button
                                                                            className="my-3"
                                                                            variant="outline-primary"
                                                                            onClick={() => {
                                                                                onChangeNavAndPage(
                                                                                    {
                                                                                        nav: "duties",
                                                                                        subpage: "createSchedule",
                                                                                        params: { dutyTypeCode: "CONSTR", option: "edit" },
                                                                                    },
                                                                                    currentPageParams
                                                                                );
                                                                            }}
                                                                        >
                                                                            {t("duties.editSchedule")}
                                                                        </Button>
                                                                        <Button className="my-3 ml-2" variant="outline-primary" onClick={() => this.sendEmails("CONSTR")}>
                                                                            {t("duties.sendEmails")}
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                        </Tab>
                                                    </Tabs>
                                                </Col>
                                            </Row>
                                        )}
                                    </Container>
                                </div>
                            )}
                        </Container>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default DutiesMain;
