import React, { Fragment } from "react";
import MyAccount from "../../pages/account/MyAccount";
import EditMyAccount from "../../pages/account/EditMyAccount";

const Account = (props) => {
    const {
        fetchURL,
        onCheckUserPrivilage,
        scannerHeight,
        onChangeValuesInFormes,
        onCancelChangeValuesInFormes,
        onLogout,
        currentPage,
        onResizeWindow,
        onShowAlert,
        onBackToPreviousPage,
        onChangeNavAndPage,
        t,
        onShowHomePage,
    } = props;
    return (
        <Fragment>
            {currentPage.nav === "account" && (
                <Fragment>
                    {currentPage.subpage === "myAccount" && (
                        <MyAccount
                            fetchURL={fetchURL}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            scannerHeight={scannerHeight}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            onLogout={onLogout}
                            onResizeWindow={onResizeWindow}
                            onShowAlert={onShowAlert}
                            onChangeNavAndPage={onChangeNavAndPage}
                            currentPage={currentPage}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {currentPage.subpage === "editMyAccount" && (
                        <EditMyAccount
                            fetchURL={fetchURL}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            scannerHeight={scannerHeight}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            onLogout={onLogout}
                            onResizeWindow={onResizeWindow}
                            onShowAlert={onShowAlert}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onBackToPreviousPage={onBackToPreviousPage}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Account;
