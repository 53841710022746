import React, { Fragment } from "react";
import AddIdent from "../../pages/idents/AddIdent";
import IdentsList from "../../pages/idents/IdentsList";

const Idents = (props) => {
    const {
        fetchURL,
        onCheckUserPrivilage,
        scannerHeight,
        onChangeValuesInFormes,
        onCancelChangeValuesInFormes,
        currentPage,
        onResizeWindow,
        onShowAlert,
        onBackToPreviousPage,
        onChangeNavAndPage,
        windowHeight,
        dataList,
        onGetDataList,
        numberOfRecords,
        onChangeNOR,
        activeEvent,
        t,
        onShowHomePage,
    } = props;
    return (
        <Fragment>
            {currentPage.nav === "idents" && (
                <Fragment>
                    {currentPage.subpage === "identsList" && (
                        <IdentsList
                            fetchURL={fetchURL}
                            onResizeWindow={onResizeWindow}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            windowHeight={windowHeight}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onShowAlert={onShowAlert}
                            currentPage={currentPage}
                            dataList={dataList}
                            onGetDataList={onGetDataList}
                            numberOfRecords={numberOfRecords}
                            onChangeNOR={onChangeNOR}
                            scannerHeight={scannerHeight}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {currentPage.subpage === "addIdent" && (
                        <AddIdent
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            fetchURL={fetchURL}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            scannerHeight={scannerHeight}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            onResizeWindow={onResizeWindow}
                            onBackToPreviousPage={onBackToPreviousPage}
                            onShowAlert={onShowAlert}
                            onChangeNavAndPage={onChangeNavAndPage}
                            activeEvent={activeEvent}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Idents;
