import React, { Fragment, Component } from "react";
import MainNavbar from "../navigation/MainNavbar";
import { Button, Modal, Alert } from "react-bootstrap";
import Participants from "../navigation/navigation-tabs/Participants";
import Users from "../navigation/navigation-tabs/Users";
import Groups from "../navigation/navigation-tabs/Groups";
import Rooms from "../navigation/navigation-tabs/Rooms";
import Duties from "../navigation/navigation-tabs/Duties";
import Shop from "../navigation/navigation-tabs/Shop";
import Mails from "../navigation/navigation-tabs/Mails";
import Settings from "../navigation/navigation-tabs/Settings";
import Receptionists from "../navigation/navigation-tabs/Receptionists";
import Account from "../navigation/navigation-tabs/Account";
import Footer from "../other/Footer";
import $ from "jquery";
import LoadingSpinnerInner from "../spinners/LoadingSpinnerInner";
import Idents from "../navigation/navigation-tabs/Idents";
import NoPermission from "../other/NoPermission";
import Applications from "../navigation/navigation-tabs/Applications";
import Logs from "../navigation/navigation-tabs/Logs";
import Characters from "../navigation/navigation-tabs/Characters";
import Transactions from "../navigation/navigation-tabs/Transactions";

class LoggedPages extends Component {
    state = {
        changedValuesInFormes: false,
        showConfirmEscapeModal: false,
        windowHeight: 0,
        navigation: "", //dane zawierające nazwę navu i podstrony do ewentualnego przekierowania po zaakceptowaniu modala
        currentPage: {
            nav: "participants", //wyświetlenie aktualnego nav'u
            subpage: "participantsList", //wyświetlenie aktualnej podstrony
            params: "", //parametr dodatkowy do wyświetlenie opowiedniej podstrony
        },
        previousPage: [],
        displayAlert: "",
        activeEvent: null,
        loading: true,
        blockSendingEmails: false,
        notification: null,
        noPermission: false,

        participantsList: null,
        usersList: null,
        identsList: null,
        groupsList: null,
        charsList: null,
        roomsList: null,
        roomsRequestsList: null,
        applicationsList: null,
        shopItemsList: null,
        shopOrdersList: null,
        mailsList: null,
        roomBookingsList: null,
        logsList: null,
        transactionsList: null,
        barBalanceList: null,

        numberOfRecords: 25,
        eventFilterValue: null,
        eventsList: null,

        allowActionUserLvl: null,
    };

    handleChangeNavAndPage = (page, prevPage) => {
        let prevPageArray = null;
        if (prevPage) prevPageArray = [...this.state.previousPage, prevPage];
        else prevPageArray = [...this.state.previousPage];
        this.setState({
            previousPage: prevPageArray,
            currentPage: page,
            showConfirmEscapeModal: false,
            changedValuesInFormes: false,
        });
    };

    handleResizeWindow = (typeOfPage) => {
        if (typeOfPage === "basic") {
            $(".scrolled-content").css({
                height:
                    $(window).height() -
                    $("#mainNavbar").outerHeight() -
                    $(`#borderNavbar`).outerHeight() -
                    $(`.nav-details`).outerHeight() -
                    ($(".content-bottom").outerHeight() ? $(".content-bottom").outerHeight() : 0) -
                    $("footer.small").outerHeight(),
                "overflow-y": "auto",
            });

            $(window).on("resize", function () {
                $(".scrolled-content").css({
                    height:
                        $(window).height() -
                        $("#mainNavbar").outerHeight() -
                        $(`#borderNavbar`).outerHeight() -
                        $(`.nav-details`).outerHeight() -
                        ($(".content-bottom").outerHeight() ? $(".content-bottom").outerHeight() : 0) -
                        $("footer.small").outerHeight(),
                    "overflow-y": "auto",
                });
            });
        } else if (typeOfPage === "tableLoaded") {
            const tableHeight =
                $(window).height() -
                $("#mainNavbar").outerHeight() -
                $(`#borderNavbar`).outerHeight() -
                $(".second-bar").outerHeight() -
                $(".light-border").outerHeight() -
                $(".rest-info").outerHeight() -
                $("footer.small").outerHeight();
            $(".content-body").css("height", "auto");
            $(".table-fixed-head").css("height", tableHeight);
        } else if (typeOfPage === "tableNotLoaded") {
            $(".content-body").css(
                "height",
                $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $(".second-bar").outerHeight() - $("footer.small").outerHeight() - 1
            );
        }
    };

    //sprawdzanie, czy potrzebne jest wyświetlanie modala do potwierdzenia opuszczenia widoku, kiedy w jakimkolwiek formularzu nastąpiły jakieś niezapisane zmiany
    handleChangeValuesInFormes = () => this.setState({ changedValuesInFormes: true });
    handleCancelChangeValuesInFormes = () => this.setState({ changedValuesInFormes: false });

    handleBackToPreviousPage = () => {
        const { previousPage } = this.state;
        const prevPageArray = [...previousPage];
        this.setState({ currentPage: prevPageArray[prevPageArray.length - 1] }, () => {
            prevPageArray.splice(-1, 1);
            this.setState({ previousPage: prevPageArray });
        });
    };

    checkActiveEvent = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/check-active-event-existence`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === "noActiveEvent") {
                        this.getEventsList();
                        this.setState({ loading: false });
                    } else {
                        this.setState({ activeEvent: fetchedData.activeEvent }, () => {
                            this.getEventsList();
                            this.setState({ loading: false });
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleCloseDisplayAlert = () => {
        this.setState({ displayAlert: "" });
    };

    handleChangeEventFilter = (event, { value }) => {
        this.handleResizeWindow("tableNotLoaded");
        this.setState({ eventFilterValue: value });
    };

    getEventsList = async () => {
        const { activeEvent } = this.state;
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/all-events-list`, {
                method: "POST",
                credentials: "include",
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const events = fetchedData.events;
                        events.unshift({
                            key: 0,
                            value: "all",
                            text: t("selectAll"),
                        });
                        this.setState({ eventsList: events, eventFilterValue: activeEvent && activeEvent !== "noActiveEvent" ? activeEvent.code : "all" });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleCheckAdmin = (role) => {
        const { onCheckUserPrivilage } = this.props;
        if (role.includes("Superadmin")) {
            if (onCheckUserPrivilage("CORE_EDIT_SUPERADMIN")) return true;
            else return false;
        } else if (role.includes("Administrator")) {
            if (onCheckUserPrivilage("CORE_EDIT_ADMIN")) return true;
            else return false;
        } else return true;
    };

    componentDidMount() {
        this.checkActiveEvent();

        const windowHeight = $(window).height();
        this.setState({ windowHeight: windowHeight });
        $("#loggedPagesLoadingContainer").css({ height: `${$(window).height() - 59 - 32 - 17}px` });
    }

    render() {
        const {
            changedValuesInFormes,
            showConfirmEscapeModal,
            windowHeight,
            currentPage,
            navigation,
            activeEvent,
            loading,
            blockSendingEmails,
            notification,
            noPermission,
            participantsList,
            usersList,
            identsList,
            groupsList,
            roomsRequestsList,
            roomsList,
            applicationsList,
            shopItemsList,
            shopOrdersList,
            mailsList,
            numberOfRecords,
            eventFilterValue,
            roomBookingsList,
            eventsList,
            logsList,
            charsList,
            transactionsList,
            barBalanceList,
        } = this.state;
        const { fetchURL, onLogout, scannerHeight, onShowAlert, moduleName, onCheckUserPrivilage, t, onShowHomePage } = this.props;
        //console.log(currentPage);
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} onShowHomePage={onShowHomePage} option="fullscreen" />
                ) : (
                    <Fragment>
                        <main role="main" id="homeContainer">
                            <MainNavbar
                                currentPage={currentPage}
                                onChangeNavAndPage={this.handleChangeNavAndPage}
                                changedValuesInFormes={changedValuesInFormes}
                                onShowConfirmModal={(navigation) => {
                                    this.setState({
                                        showConfirmEscapeModal: true,
                                        navigation: navigation,
                                    });
                                }}
                                onCheckUserPrivilage={onCheckUserPrivilage}
                                t={t}
                                onShowHomePage={onShowHomePage}
                            />

                            {loading ? (
                                <div id="loggedPagesLoadingContainer">
                                    <LoadingSpinnerInner />
                                </div>
                            ) : (
                                <Fragment>
                                    <Participants
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        fetchURL={fetchURL}
                                        onCheckUserPrivilage={onCheckUserPrivilage}
                                        onChangeValuesInFormes={this.handleChangeValuesInFormes}
                                        onCancelChangeValuesInFormes={this.handleCancelChangeValuesInFormes}
                                        scannerHeight={scannerHeight}
                                        onChangeNavAndPage={this.handleChangeNavAndPage}
                                        onBackToPreviousPage={this.handleBackToPreviousPage}
                                        currentPage={currentPage}
                                        onCloseDisplayAlert={this.handleCloseDisplayAlert}
                                        onResizeWindow={this.handleResizeWindow}
                                        activeEvent={activeEvent}
                                        onShowAlert={onShowAlert}
                                        dataList={participantsList}
                                        onGetDataList={(list) => this.setState({ participantsList: list })}
                                        numberOfRecords={numberOfRecords}
                                        onChangeNOR={(value) => this.setState({ numberOfRecords: value })}
                                        eventFilterValue={eventFilterValue}
                                        eventsList={eventsList}
                                        onChangeEventFilter={(event, { value }) => this.setState({ eventFilterValue: value })}
                                    />
                                    <Transactions
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        fetchURL={fetchURL}
                                        onCheckUserPrivilage={onCheckUserPrivilage}
                                        onChangeValuesInFormes={this.handleChangeValuesInFormes}
                                        onCancelChangeValuesInFormes={this.handleCancelChangeValuesInFormes}
                                        scannerHeight={scannerHeight}
                                        onChangeNavAndPage={this.handleChangeNavAndPage}
                                        onBackToPreviousPage={this.handleBackToPreviousPage}
                                        currentPage={currentPage}
                                        onCloseDisplayAlert={this.handleCloseDisplayAlert}
                                        onResizeWindow={this.handleResizeWindow}
                                        activeEvent={activeEvent}
                                        onShowAlert={onShowAlert}
                                        dataList={transactionsList}
                                        barBalanceList={barBalanceList}
                                        onGetDataList={(list) => this.setState({ transactionsList: list })}
                                        onGetBarBalanceList={(list) => this.setState({ barBalanceList: list })}
                                        numberOfRecords={numberOfRecords}
                                        onChangeNOR={(value) => this.setState({ numberOfRecords: value })}
                                        eventFilterValue={eventFilterValue}
                                        eventsList={eventsList}
                                        onChangeEventFilter={(event, { value }) => this.setState({ eventFilterValue: value })}
                                    />
                                    <Users
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        fetchURL={fetchURL}
                                        onCheckUserPrivilage={onCheckUserPrivilage}
                                        scannerHeight={scannerHeight}
                                        onChangeValuesInFormes={this.handleChangeValuesInFormes}
                                        onCancelChangeValuesInFormes={this.handleCancelChangeValuesInFormes}
                                        onChangeNavAndPage={this.handleChangeNavAndPage}
                                        windowHeight={windowHeight}
                                        currentPage={currentPage}
                                        onResizeWindow={this.handleResizeWindow}
                                        onCloseDisplayAlert={this.handleCloseDisplayAlert}
                                        onBackToPreviousPage={this.handleBackToPreviousPage}
                                        activeEvent={activeEvent}
                                        onShowAlert={onShowAlert}
                                        dataList={usersList}
                                        onGetDataList={(list) => this.setState({ usersList: list })}
                                        numberOfRecords={numberOfRecords}
                                        onChangeNOR={(value) => this.setState({ numberOfRecords: value })}
                                        moduleName={moduleName}
                                        onCheckAdmin={this.handleCheckAdmin}
                                    />
                                    <Idents
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        fetchURL={fetchURL}
                                        onCheckUserPrivilage={onCheckUserPrivilage}
                                        scannerHeight={scannerHeight}
                                        onChangeValuesInFormes={this.handleChangeValuesInFormes}
                                        onCancelChangeValuesInFormes={this.handleCancelChangeValuesInFormes}
                                        onChangeNavAndPage={this.handleChangeNavAndPage}
                                        windowHeight={windowHeight}
                                        currentPage={currentPage}
                                        onResizeWindow={this.handleResizeWindow}
                                        onCloseDisplayAlert={this.handleCloseDisplayAlert}
                                        onBackToPreviousPage={this.handleBackToPreviousPage}
                                        activeEvent={activeEvent}
                                        onShowAlert={onShowAlert}
                                        dataList={identsList}
                                        onGetDataList={(list) => this.setState({ identsList: list })}
                                        numberOfRecords={numberOfRecords}
                                        onChangeNOR={(value) => this.setState({ numberOfRecords: value })}
                                    />
                                    <Groups
                                        t={t}
                                        fetchURL={fetchURL}
                                        onCheckUserPrivilage={onCheckUserPrivilage}
                                        onChangeValuesInFormes={this.handleChangeValuesInFormes}
                                        onCancelChangeValuesInFormes={this.handleCancelChangeValuesInFormes}
                                        scannerHeight={scannerHeight}
                                        onChangeNavAndPage={this.handleChangeNavAndPage}
                                        currentPage={currentPage}
                                        windowHeight={windowHeight}
                                        onResizeWindow={this.handleResizeWindow}
                                        onCloseDisplayAlert={this.handleCloseDisplayAlert}
                                        onBackToPreviousPage={this.handleBackToPreviousPage}
                                        activeEvent={activeEvent}
                                        onShowAlert={onShowAlert}
                                        dataList={groupsList}
                                        onGetDataList={(list) => this.setState({ groupsList: list })}
                                        numberOfRecords={numberOfRecords}
                                        onChangeNOR={(value) => this.setState({ numberOfRecords: value })}
                                        eventFilterValue={eventFilterValue}
                                        eventsList={eventsList}
                                        onChangeEventFilter={(event, { value }) => this.setState({ eventFilterValue: value })}
                                        onShowHomePage={onShowHomePage}
                                    />
                                    <Characters
                                        t={t}
                                        fetchURL={fetchURL}
                                        onCheckUserPrivilage={onCheckUserPrivilage}
                                        onChangeValuesInFormes={this.handleChangeValuesInFormes}
                                        onCancelChangeValuesInFormes={this.handleCancelChangeValuesInFormes}
                                        scannerHeight={scannerHeight}
                                        onChangeNavAndPage={this.handleChangeNavAndPage}
                                        currentPage={currentPage}
                                        windowHeight={windowHeight}
                                        onResizeWindow={this.handleResizeWindow}
                                        onCloseDisplayAlert={this.handleCloseDisplayAlert}
                                        onBackToPreviousPage={this.handleBackToPreviousPage}
                                        activeEvent={activeEvent}
                                        onShowAlert={onShowAlert}
                                        dataList={charsList}
                                        onGetDataList={(list) => this.setState({ charsList: list })}
                                        numberOfRecords={numberOfRecords}
                                        onChangeNOR={(value) => this.setState({ numberOfRecords: value })}
                                        eventFilterValue={eventFilterValue}
                                        eventsList={eventsList}
                                        onChangeEventFilter={(event, { value }) => this.setState({ eventFilterValue: value })}
                                        onShowHomePage={onShowHomePage}
                                    />
                                    <Rooms
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        fetchURL={fetchURL}
                                        onCheckUserPrivilage={onCheckUserPrivilage}
                                        onChangeValuesInFormes={this.handleChangeValuesInFormes}
                                        onCancelChangeValuesInFormes={this.handleCancelChangeValuesInFormes}
                                        scannerHeight={scannerHeight}
                                        onChangeNavAndPage={this.handleChangeNavAndPage}
                                        currentPage={currentPage}
                                        windowHeight={windowHeight}
                                        onResizeWindow={this.handleResizeWindow}
                                        onBackToPreviousPage={this.handleBackToPreviousPage}
                                        activeEvent={activeEvent}
                                        onCloseDisplayAlert={this.handleCloseDisplayAlert}
                                        onShowAlert={onShowAlert}
                                        dataList={roomsList}
                                        roomsRequestsList={roomsRequestsList}
                                        onGetDataList={(list) => this.setState({ roomsList: list })}
                                        onGetRoomsRequestsList={(list) => this.setState({ roomsRequestsList: list })}
                                        numberOfRecords={numberOfRecords}
                                        onChangeNOR={(value) => this.setState({ numberOfRecords: value })}
                                        eventFilterValue={eventFilterValue}
                                        eventsList={eventsList}
                                        onChangeEventFilter={(event, { value }) => this.setState({ eventFilterValue: value })}
                                        roomBookingsList={roomBookingsList}
                                        onGetRoomBookingsList={(list) => this.setState({ roomBookingsList: list })}
                                    />
                                    <Applications
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        fetchURL={fetchURL}
                                        onCheckUserPrivilage={onCheckUserPrivilage}
                                        onChangeValuesInFormes={this.handleChangeValuesInFormes}
                                        onCancelChangeValuesInFormes={this.handleCancelChangeValuesInFormes}
                                        scannerHeight={scannerHeight}
                                        onChangeNavAndPage={this.handleChangeNavAndPage}
                                        currentPage={currentPage}
                                        windowHeight={windowHeight}
                                        onResizeWindow={this.handleResizeWindow}
                                        onBackToPreviousPage={this.handleBackToPreviousPage}
                                        activeEvent={activeEvent}
                                        onCloseDisplayAlert={this.handleCloseDisplayAlert}
                                        onShowAlert={onShowAlert}
                                        dataList={applicationsList}
                                        onGetDataList={(list) => this.setState({ applicationsList: list })}
                                        numberOfRecords={numberOfRecords}
                                        onChangeNOR={(value) => this.setState({ numberOfRecords: value })}
                                        eventFilterValue={eventFilterValue}
                                        eventsList={eventsList}
                                        onChangeEventFilter={(event, { value }) => this.setState({ eventFilterValue: value })}
                                    />
                                    <Duties
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        fetchURL={fetchURL}
                                        onCheckUserPrivilage={onCheckUserPrivilage}
                                        onChangeValuesInFormes={this.handleChangeValuesInFormes}
                                        onCancelChangeValuesInFormes={this.handleCancelChangeValuesInFormes}
                                        scannerHeight={scannerHeight}
                                        onChangeNavAndPage={this.handleChangeNavAndPage}
                                        currentPage={currentPage}
                                        windowHeight={windowHeight}
                                        onResizeWindow={this.handleResizeWindow}
                                        onBackToPreviousPage={this.handleBackToPreviousPage}
                                        activeEvent={activeEvent}
                                        onCloseDisplayAlert={this.handleCloseDisplayAlert}
                                        onShowAlert={onShowAlert}
                                    />
                                    <Shop
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        fetchURL={fetchURL}
                                        onCheckUserPrivilage={onCheckUserPrivilage}
                                        onChangeValuesInFormes={this.handleChangeValuesInFormes}
                                        onCancelChangeValuesInFormes={this.handleCancelChangeValuesInFormes}
                                        onChangeNavAndPage={this.handleChangeNavAndPage}
                                        currentPage={currentPage}
                                        onResizeWindow={this.handleResizeWindow}
                                        onCloseDisplayAlert={this.handleCloseDisplayAlert}
                                        onBackToPreviousPage={this.handleBackToPreviousPage}
                                        activeEvent={activeEvent}
                                        onShowAlert={onShowAlert}
                                        dataList1={shopItemsList}
                                        onGetDataList1={(list) => this.setState({ shopItemsList: list })}
                                        dataList2={shopOrdersList}
                                        onGetDataList2={(list) => this.setState({ shopOrdersList: list })}
                                        numberOfRecords={numberOfRecords}
                                        onChangeNOR={(value) => this.setState({ numberOfRecords: value })}
                                        eventFilterValue={eventFilterValue}
                                        eventsList={eventsList}
                                        onChangeEventFilter={(event, { value }) => this.setState({ eventFilterValue: value })}
                                    />
                                    <Mails
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        fetchURL={fetchURL}
                                        onCheckUserPrivilage={onCheckUserPrivilage}
                                        onChangeValuesInFormes={this.handleChangeValuesInFormes}
                                        onCancelChangeValuesInFormes={this.handleCancelChangeValuesInFormes}
                                        scannerHeight={scannerHeight}
                                        onChangeNavAndPage={this.handleChangeNavAndPage}
                                        currentPage={currentPage}
                                        windowHeight={windowHeight}
                                        onResizeWindow={this.handleResizeWindow}
                                        onBackToPreviousPage={this.handleBackToPreviousPage}
                                        activeEvent={activeEvent}
                                        onCloseDisplayAlert={this.handleCloseDisplayAlert}
                                        blockSendingEmails={blockSendingEmails}
                                        onBlockSendingEmails={() => this.setState({ blockSendingEmails: true })}
                                        onUnblockSendingEmails={(email, numberOfUsers) =>
                                            this.setState({ blockSendingEmails: false, notification: { type: "emails", email: email, users: numberOfUsers } })
                                        }
                                        onShowAlert={onShowAlert}
                                        dataList={mailsList}
                                        onGetDataList={(list) => this.setState({ mailsList: list })}
                                        numberOfRecords={numberOfRecords}
                                        onChangeNOR={(value) => this.setState({ numberOfRecords: value })}
                                    />
                                    <Settings
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        fetchURL={fetchURL}
                                        onCheckUserPrivilage={onCheckUserPrivilage}
                                        onChangeValuesInFormes={this.handleChangeValuesInFormes}
                                        onCancelChangeValuesInFormes={this.handleCancelChangeValuesInFormes}
                                        scannerHeight={scannerHeight}
                                        onChangeNavAndPage={this.handleChangeNavAndPage}
                                        currentPage={currentPage}
                                        windowHeight={windowHeight}
                                        onResizeWindow={this.handleResizeWindow}
                                        activeEvent={activeEvent}
                                        onBackToPreviousPage={this.handleBackToPreviousPage}
                                        reloadEventInfo={() => this.checkActiveEvent()}
                                        onShowAlert={onShowAlert}
                                        numberOfRecords={numberOfRecords}
                                        onChangeNOR={(value) => this.setState({ numberOfRecords: value })}
                                    />
                                    <Logs
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        fetchURL={fetchURL}
                                        onCheckUserPrivilage={onCheckUserPrivilage}
                                        onChangeValuesInFormes={this.handleChangeValuesInFormes}
                                        onCancelChangeValuesInFormes={this.handleCancelChangeValuesInFormes}
                                        scannerHeight={scannerHeight}
                                        onChangeNavAndPage={this.handleChangeNavAndPage}
                                        currentPage={currentPage}
                                        windowHeight={windowHeight}
                                        onResizeWindow={this.handleResizeWindow}
                                        onBackToPreviousPage={this.handleBackToPreviousPage}
                                        activeEvent={activeEvent}
                                        onCloseDisplayAlert={this.handleCloseDisplayAlert}
                                        onShowAlert={onShowAlert}
                                        dataList={logsList}
                                        onGetDataList={(list) => this.setState({ logsList: list })}
                                        numberOfRecords={numberOfRecords}
                                        onChangeNOR={(value) => this.setState({ numberOfRecords: value })}
                                        eventFilterValue={eventFilterValue}
                                        eventsList={eventsList}
                                        onChangeEventFilter={(event, { value }) => this.setState({ eventFilterValue: value })}
                                    />
                                    <Receptionists
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        fetchURL={fetchURL}
                                        onCheckUserPrivilage={onCheckUserPrivilage}
                                        onChangeValuesInFormes={this.handleChangeValuesInFormes}
                                        onCancelChangeValuesInFormes={this.handleCancelChangeValuesInFormes}
                                        scannerHeight={scannerHeight}
                                        onChangeNavAndPage={this.handleChangeNavAndPage}
                                        currentPage={currentPage}
                                        windowHeight={windowHeight}
                                        onResizeWindow={this.handleResizeWindow}
                                        onBackToPreviousPage={this.handleBackToPreviousPage}
                                        activeEvent={activeEvent}
                                        onCloseDisplayAlert={this.handleCloseDisplayAlert}
                                        onShowAlert={onShowAlert}
                                        numberOfRecords={numberOfRecords}
                                        onChangeNOR={(value) => this.setState({ numberOfRecords: value })}
                                    />
                                    <Account
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        fetchURL={fetchURL}
                                        onCheckUserPrivilage={onCheckUserPrivilage}
                                        scannerHeight={scannerHeight}
                                        onChangeValuesInFormes={this.handleChangeValuesInFormes}
                                        onCancelChangeValuesInFormes={this.handleCancelChangeValuesInFormes}
                                        onLogout={onLogout}
                                        onResizeWindow={this.handleResizeWindow}
                                        onChangeNavAndPage={this.handleChangeNavAndPage}
                                        currentPage={currentPage}
                                        onShowAlert={onShowAlert}
                                        onBackToPreviousPage={this.handleBackToPreviousPage}
                                    />
                                </Fragment>
                            )}
                        </main>
                        {notification && (
                            <Alert variant="dark" onClose={() => this.setState({ notification: null })} dismissible className="alert-notification">
                                {notification.type === "emails" && (
                                    <p>
                                        {t("mails.sendingMailsFinished1")}
                                        {notification.users === 1 ? t("mails.sendingMailsFinished2") : `${notification.users}${t("mails.sendingMailsFinished3")}`}
                                    </p>
                                )}
                            </Alert>
                        )}
                        <Footer />

                        {showConfirmEscapeModal && (
                            <Modal show={showConfirmEscapeModal} onHide={() => this.setState({ showConfirmEscapeModal: false })}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{t("confirmEscapeModal.title")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{t("confirmEscapeModal.text")}</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-secondary" type="button" onClick={() => this.handleChangeNavAndPage(navigation, null, null)}>
                                        {t("confirmEscapeModal.yes")}
                                    </Button>
                                    <Button variant="primary" type="button" onClick={() => this.setState({ showConfirmEscapeModal: false })}>
                                        {t("confirmEscapeModal.no")}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default LoggedPages;
