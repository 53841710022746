import React, { Fragment } from "react";
import { Col, Row, Table } from "react-bootstrap";
import moment from "moment-timezone";
import DescPopover from "../../popovers/DescPopover";
import UsersParticipants from "./UsersParticipants";
import CharsParticipants from "./CharsParticipants";
import AlertNoPermission from "../../alerts/AlertNoPermission";

const TableUserDetails = (props) => {
    const { userDetails, partList, currentPageParams, onChangeNavAndPage, noPermissionUsersParts, t, charsList, fetchURL, onShowAlert, onCheckUserPrivilage } = props;

    return (
        <Fragment>
            <Row>
                <Col>
                    <p className="h5 mb-3">{t("myAccount.dataAccount")}</p>
                    {userDetails.map((d, i) => (
                        <Fragment key={i}>
                            <Table responsive bordered hover className="table-details">
                                <tbody>
                                    <tr>
                                        <td>E-mail</td>
                                        <td>{d.user_email}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("form.pass")}</td>
                                        <td>{d.user_password ? t("itIs") : <span className="opacity-4">{t("lack")}</span>}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t("user.userStatus")}
                                            <DescPopover
                                                popoverTxt={
                                                    <Fragment>
                                                        <p className="mb-1">
                                                            {t("user.statuses.new.title")} - {t("user.statuses.new.desc")}
                                                        </p>
                                                        <p className="mb-1">
                                                            {t("user.statuses.active.title")} - {t("user.statuses.active.desc")}
                                                        </p>
                                                        <p className="mb-1">
                                                            {t("user.statuses.inactive.title")} - {t("user.statuses.inactive.desc")}
                                                        </p>
                                                        <p className="mb-1">
                                                            {t("user.statuses.added.title")} - {t("user.statuses.added.desc")}
                                                        </p>
                                                    </Fragment>
                                                }
                                                popoverTitle={"Opis statusów"}
                                            />
                                        </td>
                                        <td>{d.user_stat_name}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("user.role")}</td>
                                        <td>{d.user_roles_list}</td>
                                    </tr>

                                    <tr>
                                        <td>{t("user.qrCode")}</td>
                                        <td>{d.user_qr_code ? t("itIs") : <span className="opacity-4">{t("lack")}</span>}</td>
                                    </tr>

                                    <tr>
                                        <td>{t("user.phone")}</td>
                                        <td>{d.user_phone ? d.user_phone : <span className="opacity-4">{t("lack")}</span>}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("user.fbLabel")}</td>
                                        <td>{d.user_facebook_link ? d.user_facebook_link : <span className="opacity-4">{t("lack")}</span>}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("user.confirmLink")}</td>
                                        <td>{d.confirm_link ? d.confirm_link : <span className="opacity-4">{t("lack")}</span>}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("user.confirmLinkExpireDate")}</td>
                                        <td>
                                            {d.confirm_exp_date ? (
                                                <span
                                                    className={
                                                        new Date(d.confirm_exp_date) - new Date() < 0
                                                            ? "text-danger"
                                                            : new Date(d.confirm_exp_date) - new Date() < 172800000
                                                            ? "text-warning"
                                                            : ""
                                                    }
                                                >
                                                    {d.confirm_exp_date}
                                                </span>
                                            ) : (
                                                <span className="opacity-4">{t("lack")}</span>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("user.lastLogin")}</td>
                                        <td>
                                            {d.last_login_date ? (
                                                moment(d.last_login_date)
                                                    .format()
                                                    .replace(/T/, " ")
                                                    .replace(/\+(.*)/, "")
                                            ) : (
                                                <span className="opacity-4">{t("lack")}</span>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("user.registered")}</td>
                                        <td>
                                            {moment(d.create_date)
                                                .format()
                                                .replace(/T/, " ")
                                                .replace(/\+(.*)/, "")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("user.modifyDateWithoutPass")}</td>
                                        <td>
                                            {moment(d.modify_date)
                                                .format()
                                                .replace(/T/, " ")
                                                .replace(/\+(.*)/, "")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("user.changedPass")}</td>
                                        <td>
                                            {d.password_change_date ? (
                                                moment(d.password_change_date)
                                                    .format()
                                                    .replace(/T/, " ")
                                                    .replace(/\+(.*)/, "")
                                            ) : (
                                                <span className="opacity-4">{t("lack")}</span>
                                            )}
                                        </td>
                                    </tr>
                                    {onCheckUserPrivilage("ACCR_MEDIC_INFO") ? (
                                        <tr>
                                            <td>{t("user.medLabel")}</td>
                                            <td>{d.user_medical_info ? d.user_medical_info : <span className="opacity-4">{t("lack")}</span>}</td>
                                        </tr>
                                    ) : null}
                                    <tr>
                                        <td>{t("comments")}</td>
                                        <td>{d.user_comment ? d.user_comment : <span className="opacity-4">{t("lack")}</span>}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Fragment>
                    ))}
                </Col>
            </Row>

            {noPermissionUsersParts ? (
                <AlertNoPermission t={t} />
            ) : (
                <Fragment>
                    <Row>
                        <Col>
                            <hr className="my-3" />
                        </Col>
                    </Row>
                    <UsersParticipants t={t} partList={partList} onChangeNavAndPage={onChangeNavAndPage} currentPageParams={currentPageParams} />
                    <Row>
                        <Col>
                            <hr className="my-3" />
                        </Col>
                    </Row>
                    <CharsParticipants
                        t={t}
                        charsList={charsList}
                        onChangeNavAndPage={onChangeNavAndPage}
                        currentPageParams={currentPageParams}
                        fetchURL={fetchURL}
                        onShowAlert={onShowAlert}
                        userID={currentPageParams.params.userID}
                    />
                </Fragment>
            )}
        </Fragment>
    );
};

export default TableUserDetails;
