import React, { Fragment } from "react";
import CreateSchedule from "../../pages/duties/CreateSchedule";
import DutiesMain from "../../pages/duties/DutiesMain";

const Duties = (props) => {
    const {
        fetchURL,
        onCheckUserPrivilage,
        scannerHeight,
        onCancelChangeValuesInFormes,
        currentPage,
        onResizeWindow,
        onShowAlert,
        onBackToPreviousPage,
        onChangeNavAndPage,
        windowHeight,
        dataList,
        onGetDataList,
        numberOfRecords,
        onChangeNOR,
        activeEvent,
        onChangeValuesInFormes,
        t,
        onShowHomePage,
    } = props;

    return (
        <Fragment>
            {currentPage.nav === "duties" && (
                <Fragment>
                    {currentPage.subpage === "dutiesList" && (
                        <DutiesMain
                            fetchURL={fetchURL}
                            onResizeWindow={onResizeWindow}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            windowHeight={windowHeight}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onShowAlert={onShowAlert}
                            currentPage={currentPage}
                            dataList={dataList}
                            onGetDataList={onGetDataList}
                            numberOfRecords={numberOfRecords}
                            onChangeNOR={onChangeNOR}
                            scannerHeight={scannerHeight}
                            t={t}
                            activeEvent={activeEvent}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {currentPage.subpage === "createSchedule" && (
                        <CreateSchedule
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            fetchURL={fetchURL}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            scannerHeight={scannerHeight}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            onResizeWindow={onResizeWindow}
                            onBackToPreviousPage={onBackToPreviousPage}
                            onShowAlert={onShowAlert}
                            onChangeNavAndPage={onChangeNavAndPage}
                            activeEvent={activeEvent}
                            t={t}
                            onShowHomePage={onShowHomePage}
                            currentPage={currentPage}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Duties;
