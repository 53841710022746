import React, { Fragment, Component } from "react";
import { Dropdown } from "semantic-ui-react";
import AlertNoPermission from "../../alerts/AlertNoPermission";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

class ShopItemLimitation extends Component {
    state = {
        noPermission: false,
        labelsList: null,
    };

    getLabels = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/participants-labels`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const partLabels = fetchedData.participantsLabels;

                        this.setState({ labelsList: partLabels });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        this.getLabels();
    }

    render() {
        const { noPermission, labelsList } = this.state;
        const { onShopLimitationSelected, defaultValue, t } = this.props;
        return (
            <Fragment>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : (
                    <Fragment>
                        {labelsList ? (
                            <Dropdown
                                defaultValue={defaultValue}
                                id="shopItemLimitation"
                                className="req mb-3"
                                placeholder={t("participant.clickToChooseLabel")}
                                fluid
                                multiple
                                selection
                                options={labelsList}
                                onChange={(event, { value }) => {
                                    onShopLimitationSelected(event, value);
                                }}
                            />
                        ) : (
                            <LoadingProgressBar t={t} />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default ShopItemLimitation;
