import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";

const VerifiedGroupDropdown = (props) => {
    const { defaultValue, onChangeValue, req, t } = props;
    return (
        <Fragment>
            <Form.Label>
                {t("group.verified")} {req && <span className="required">*</span>}
            </Form.Label>

            <Dropdown
                value={defaultValue}
                id="appStatDropdown"
                className={req ? "req mb-3" : "mb-3"}
                placeholder={t("clickToChoose")}
                fluid
                selection
                options={[
                    {
                        key: 0,
                        value: "all",
                        text: t("selectAll"),
                    },
                    {
                        key: 1,
                        value: "Y",
                        text: t("yes"),
                    },
                    {
                        key: 2,
                        value: "N",
                        text: t("no"),
                    },
                ]}
                onChange={(event, { value }) => {
                    onChangeValue(value);
                }}
            />
        </Fragment>
    );
};

export default VerifiedGroupDropdown;
