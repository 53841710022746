import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import TextForm from "../../forms/text-inputs/TextForm";
import $ from "jquery";

const BarAvalPeriods = (props) => {
    const { avalPeriod, t, onChangeAvalPeriodValue } = props;

    function addPeriod() {
        const { avalPeriod, onChangeAvalPeriodValue } = props;
        const list = [...avalPeriod];
        list.push({
            aval_period_date: list[list.length - 1].aval_period_date,
            aval_period_start: list[list.length - 1].aval_period_start,
            aval_period_end: list[list.length - 1].aval_period_end,
            aval_period_label: list[list.length - 1].aval_period_label,
        });
        onChangeAvalPeriodValue(list);
    }

    function changeValue(name, value, index) {
        const list = [...avalPeriod];
        list[index][name] = value;
        onChangeAvalPeriodValue(list);
    }

    function deletePeriod(i) {
        const list = [...avalPeriod];
        list.splice(i, 1);
        onChangeAvalPeriodValue(list);
    }
    return (
        <Fragment>
            <Col xs={12}>
                <div className="border p-3">
                    <Row>
                        <Col xs={3}>
                            <Form.Label>
                                {t("settings.avalDate")} <span className="required">*</span>
                            </Form.Label>
                        </Col>
                        <Col xs={2}>
                            <Form.Label>
                                {t("settings.avalStart")} <span className="required">*</span>
                            </Form.Label>
                        </Col>
                        <Col xs={2}>
                            <Form.Label>
                                {t("settings.avalEnd")} <span className="required">*</span>
                            </Form.Label>
                        </Col>
                        <Col xs={3}>
                            <Form.Label>{t("settings.avalPeriodLabel")}</Form.Label>
                        </Col>
                        {avalPeriod.map((e, i) => (
                            <Fragment key={i}>
                                <Col xs={3}>
                                    <TextForm
                                        value={e.aval_period_date}
                                        onChangeValue={(e) => {
                                            $(`#barAvalPeriodDate${i}`).removeClass("danger");
                                            changeValue("aval_period_date", e.target.value, i);
                                        }}
                                        placeholderTxt={t("calendar.selectDate")}
                                        name="aval_period_date"
                                        id={`barAvalPeriodDate${i}`}
                                    />
                                </Col>
                                <Col xs={2}>
                                    <TextForm
                                        value={e.aval_period_start}
                                        onChangeValue={(e) => {
                                            $(`#barAvalPeriodStart${i}`).removeClass("danger");
                                            changeValue("aval_period_start", e.target.value, i);
                                        }}
                                        placeholderTxt={t("calendar.selectTimeOnly")}
                                        name="aval_period_start"
                                        id={`barAvalPeriodStart${i}`}
                                    />
                                </Col>
                                <Col xs={2}>
                                    <TextForm
                                        value={e.aval_period_end}
                                        onChangeValue={(e) => {
                                            $(`#barAvalPeriodEnd${i}`).removeClass("danger");
                                            changeValue("aval_period_end", e.target.value, i);
                                        }}
                                        placeholderTxt={t("calendar.selectTimeOnly")}
                                        name="aval_period_end"
                                        id={`barAvalPeriodEnd${i}`}
                                    />
                                </Col>
                                <Col xs={4}>
                                    <TextForm
                                        value={e.aval_period_label}
                                        onChangeValue={(event) => changeValue(event.target.name, event.target.value, i)}
                                        placeholderTxt={t("settings.avalBarAccrPeriodLabelPlaceholder")}
                                        name="aval_period_label"
                                        id={`barAvalPeriodLabel${i}`}
                                    />
                                </Col>

                                <Col xs={1}>
                                    {i > 0 && (
                                        <Button variant="outline-secondary" onClick={() => deletePeriod(i)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    )}
                                </Col>
                            </Fragment>
                        ))}
                        <Col xs={12}>
                            <Button variant="outline-primary" size="sm" onClick={addPeriod}>
                                {t("settings.addNext")}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Fragment>
    );
};

export default BarAvalPeriods;
