import React, { Fragment, Component } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import NavDetails from "../../../navigation/NavDetails";
import NoPermission from "../../../other/NoPermission";
import $ from "jquery";
import LoadingSpinnerInner from "../../../spinners/LoadingSpinnerInner";
import { Dropdown } from "semantic-ui-react";
import TextForm from "../../../forms/text-inputs/TextForm";
import TooltipWithDisabledButton from "../../../tooltips/TooltipWithDisabledButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

class AddOrder extends Component {
    state = {
        loading: true,
        order: [{ part_id: null, items: [{ shit_variant_id: null, shit_quantity: 1, shit_id: null, shitVariantsList: [], shit_price: "" }] }],
        noPermission: false,
        changedValues: false,
        partsList: null,
        shopItemsList: null,
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { order } = this.state;
        const { onShowAlert, t } = this.props;
        onShowAlert("");
        let errorMsg = "";

        const itemsList = order[0].items;
        let errQuant = false,
            errShit = false,
            errShitVariant = false;
        itemsList.forEach((e, i) => {
            if (!e.shit_quantity || e.shit_quantity === 0) {
                $(`#shopItemQuantity${i}`).addClass("danger");
                errQuant = true;
            }
            if (!e.shit_id) {
                $(`#shopItem${i}`).addClass("danger");
                errShit = true;
            }
            if (e.shitVariantsList.length > 0 && !e.shit_variant_id) {
                $(`#shopItemVariant${i}`).addClass("danger");
                errShitVariant = true;
            }
        });
        if (errQuant) errorMsg += t("error.noQuantitySelected");
        if (errShit) errorMsg += t("error.noShitSelected");
        if (errShitVariant) errorMsg += t("error.noShitVariantSelected");

        if (errorMsg) {
            onShowAlert({ variant: "danger", message: errorMsg });
        } else {
            //console.log(this.state.order);
            onShowAlert({ variant: "info", message: t("info.submittingOrder") });
            this.setState({ changedValues: false }, () => this.submitOrder());
        }
    };

    submitOrder = async () => {
        const { fetchURL, onShowAlert, t, activeEvent } = this.props;
        const passData = {
            activeEvent: activeEvent,
            order: this.state.order,
        };

        try {
            const response = await fetch(`${fetchURL}/submit-shop-order`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) {
                        onShowAlert({
                            variant: "danger",
                            message: t(`error.${fetchedData.code}`),
                        });
                        this.setState({ changedValues: true });
                    } else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onShowAlert({ variant: "success", message: t("success.submittedOrder"), fading: 2000 });
                        this.setState({
                            order: [{ part_id: null, items: [{ shit_variant_id: null, shit_quantity: 1, shit_id: null, shitVariantsList: [], shit_price: "" }] }],
                            changedValues: false,
                        });
                        this.props.onCancelChangeValuesInFormes();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    getParticipantsList = async () => {
        const { fetchURL, onShowAlert, t, activeEvent } = this.props;
        const passData = {
            activeEvent: activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/participants-fullname`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false)
                        onShowAlert({
                            variant: "danger",
                            message: t(`error.${fetchedData.code}`),
                        });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        this.setState({ partsList: fetchedData.fullName }, () => this.getShopItemsList());
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ changedValues: false });
        }
    };

    getShopItemsList = async () => {
        const { fetchURL, onShowAlert, t, activeEvent } = this.props;
        const passData = {
            activeEvent: activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/shop-items-dropdown`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false)
                        onShowAlert({
                            variant: "danger",
                            message: t(`error.${fetchedData.code}`),
                        });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        this.setState({ shopItemsList: fetchedData.shopItemsList, loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    addOrderView = async () => {
        const { fetchURL, onShowAlert, t } = this.props;

        try {
            const response = await fetch(`${fetchURL}/add-order-view`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else this.getParticipantsList();
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    getShopItemData = async (shitID, i) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = { shitID: shitID };
        try {
            const response = await fetch(`${fetchURL}/get-shop-item-data`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        const { order } = this.state;
                        const listItems = [...order[0].items],
                            list = [...order];
                        listItems[i].shitVariantsList = fetchedData.shitVariantsList;
                        listItems[i].shit_price = fetchedData.shitPrice;
                        listItems[i].shit_variant_id = null;
                        list[0].items = listItems;
                        this.setState({ order: list });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleChangeOrderData = (name, value, index) => {
        const { order } = this.state;
        const listItems = [...order[0].items],
            list = [...order];

        if (name === "part_id") list[0][name] = value;
        else {
            listItems[index][name] = value;
            list[0].items = listItems;
        }

        if (name === "shit_id") this.setState({ order: list, changedValues: true }, () => this.getShopItemData(value, index));
        else this.setState({ order: list, changedValues: true });
        this.props.onChangeValuesInFormes();
    };

    addNewItem = () => {
        const { order } = this.state;
        const listItems = [...order[0].items],
            list = [...order];
        listItems.push({ shit_variant_id: null, shit_quantity: 1, shit_id: null, shitVariantsList: [], shit_price: "" });
        list[0].items = listItems;
        this.setState({ order: list, changedValues: true });
        this.props.onChangeValuesInFormes();
    };

    handleRemoveShit = (i) => {
        const { order } = this.state;
        const listItems = [...order[0].items],
            list = [...order];
        //console.log(i);
        listItems.splice(i, 1);
        list[0].items = listItems;
        this.setState({ order: list, changedValues: true });
        this.props.onChangeValuesInFormes();
    };

    componentDidMount() {
        const { onResizeWindow } = this.props;
        onResizeWindow("basic");
        $("#uploadFile").on("change", function () {
            $("span").text($(this).val());
        });
        this.addOrderView();
    }

    componentDidUpdate() {
        const { loading } = this.state;
        const { onResizeWindow } = this.props;

        if ($(".scrolled-content").outerHeight() && !loading) onResizeWindow("basic");
        if (!loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { loading, noPermission, changedValues, partsList, order, shopItemsList } = this.state;
        const { activeEvent, onBackToPreviousPage, onCancelChangeValuesInFormes, t, onShowHomePage } = this.props;

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : !activeEvent ? (
                    <Alert variant="danger">{t("shop.noActiveEvent")}</Alert>
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails
                                    t={t}
                                    header={t("shop.addOrder")}
                                    changedValues={changedValues}
                                    onChangeView={onBackToPreviousPage}
                                    onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                                />
                                <Col></Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                                <div className="scrolled-content" style={{ display: "none" }}>
                                    <Container className="py-3">
                                        {partsList.length === 0 ? (
                                            <Row>
                                                <Col>
                                                    <p className="h5 opacity-4">{t("error.noAssignedPartsToEvent")}</p>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Fragment>
                                                <Row>
                                                    <Col xs={12} lg={6}>
                                                        <Form.Label>
                                                            {t("participant.participant")}
                                                            <span className="required"> *</span>
                                                        </Form.Label>
                                                        <Dropdown
                                                            value={order[0].part_id}
                                                            id="partsListDropdown"
                                                            className="req mb-3"
                                                            placeholder={t("selectOrType")}
                                                            fluid
                                                            selection
                                                            search
                                                            options={partsList}
                                                            onChange={(event, { value }) => {
                                                                this.handleChangeOrderData("part_id", value);
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                {order[0].part_id && (
                                                    <Fragment>
                                                        <Row>
                                                            {order[0].items.map((e, i) => (
                                                                <Fragment key={i}>
                                                                    <Col xs={2}>
                                                                        <TextForm
                                                                            value={e.shit_quantity}
                                                                            onChangeValue={(el) => {
                                                                                $(`#shopItemQuantity${i}`).removeClass("danger");
                                                                                this.handleChangeOrderData("shit_quantity", el.target.value, i);
                                                                            }}
                                                                            labelTxt={t("shop.quantity")}
                                                                            name="shop_item_quantity"
                                                                            id={`shopItemQuantity${i}`}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={4}>
                                                                        <Fragment>
                                                                            <Form.Label>{t("shop.productNameLabel")}</Form.Label>
                                                                            <Dropdown
                                                                                value={e.shit_id}
                                                                                id={`shopItem${i}`}
                                                                                className="req mb-3"
                                                                                placeholder={t("selectOrType")}
                                                                                fluid
                                                                                search
                                                                                selection
                                                                                options={shopItemsList}
                                                                                onChange={(event, { value }) => {
                                                                                    $(`#shopItem${i}`).removeClass("danger");
                                                                                    this.handleChangeOrderData("shit_id", value, i);
                                                                                }}
                                                                            />
                                                                        </Fragment>
                                                                    </Col>
                                                                    <Col xs={2}>
                                                                        <TextForm
                                                                            value={e.shit_price}
                                                                            onChangeValue={(el) => {
                                                                                $(`#shopItemPrice${i}`).removeClass("danger");
                                                                                this.handleChangeOrderData("shit_price", el.target.value, i);
                                                                            }}
                                                                            labelTxt={t("shop.itemPrice")}
                                                                            name="shop_item_price"
                                                                            id={`shopItemPrice${i}`}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={2}>
                                                                        {e.shitVariantsList.length > 0 && (
                                                                            <Fragment>
                                                                                <Form.Label>{t("shop.variantNameLabel")}</Form.Label>
                                                                                <Dropdown
                                                                                    value={e.shit_variant_id}
                                                                                    id={`shopItemVariant${i}`}
                                                                                    className="req mb-3"
                                                                                    placeholder={t("selectOrType")}
                                                                                    fluid
                                                                                    search
                                                                                    selection
                                                                                    options={e.shitVariantsList}
                                                                                    onChange={(event, { value }) => {
                                                                                        $(`#shopItemVariant${i}`).removeClass("danger");
                                                                                        this.handleChangeOrderData("shit_variant_id", value, i);
                                                                                    }}
                                                                                />
                                                                            </Fragment>
                                                                        )}
                                                                    </Col>
                                                                    <Col xs={1} className="pt-2">
                                                                        {i > 0 && (
                                                                            <Button className="mt-4" variant="outline-secondary" onClick={() => this.handleRemoveShit(i)}>
                                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                                            </Button>
                                                                        )}
                                                                    </Col>
                                                                </Fragment>
                                                            ))}
                                                            <Col xs={12}>
                                                                <Button variant="outline-primary" onClick={() => this.addNewItem()}>
                                                                    {t("shop.addNewItem")}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Fragment>
                                                )}
                                            </Fragment>
                                        )}
                                    </Container>
                                </div>
                                <div className="content-bottom">
                                    <Container>
                                        <Row>
                                            <Col xs={12} className="py-3">
                                                {!changedValues ? (
                                                    <TooltipWithDisabledButton
                                                        t={t}
                                                        onShowHomePage={onShowHomePage}
                                                        mssgTooltip={t("info.emptyForm")}
                                                        btnTxt={t("shop.submitOrder")}
                                                        tooltipID="tooltipDisabledNotFilled"
                                                        size="lg"
                                                    />
                                                ) : (
                                                    <Button type="submit" variant="primary" size="lg">
                                                        {t("shop.submitOrder")}
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Form>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default AddOrder;
