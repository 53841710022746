import React, { Fragment, Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Login from "../pages/account/Login";
import RememberPass from "../pages/account/RememberPass";
import ContactToAdmin from "../other/ContactToAdmin";
import LogoAndTitle from "../other/LogoAndTitle";

class NotLoggedPages extends Component {
    state = {
        showSubPage: "loginForm",
    };

    handleContactToAdmin = () => {
        this.setState({ showSubPage: "contactToAdmin" });
        this.props.onHideAlertInactiveUser();
    };

    render() {
        const { showSubPage } = this.state;
        const { scannerHeight, fetchURL, moduleTitle, onPassLoggedUserData, onShowAlert, moduleName, t } = this.props;

        return (
            <Fragment>
                <Container>
                    <LogoAndTitle title={t("document.subtitle")} />

                    {showSubPage === "contactToAdmin" ? (
                        <Fragment>
                            <ContactToAdmin t={t} fetchURL={fetchURL} onShowAlert={onShowAlert} />
                            <Row>
                                <Col className="text-center mt-2">
                                    <Button onClick={(e) => this.setState({ showSubPage: e.target.name })} variant="link" name="loginForm">
                                        {t("goBackToLogin")}
                                    </Button>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : showSubPage === "loginForm" ? (
                        <Fragment>
                            <Login
                                onShowAlert={onShowAlert}
                                moduleTitle={moduleTitle}
                                onPassLoggedUserData={onPassLoggedUserData}
                                fetchURL={fetchURL}
                                scannerHeight={scannerHeight}
                                moduleName={moduleName}
                                t={t}
                            />

                            <Row>
                                <Col className="text-center mt-3">
                                    <Button onClick={(e) => this.setState({ showSubPage: e.target.name })} variant="link" name="rememberPass">
                                        {t("dontRememberPass")}
                                    </Button>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : (
                        showSubPage === "rememberPass" && (
                            <Fragment>
                                <RememberPass fetchURL={fetchURL} fading="2000" onShowAlert={onShowAlert} t={t} />
                                <Row>
                                    <Col className="text-center mt-2">
                                        <Button onClick={(e) => this.setState({ showSubPage: e.target.name })} variant="link" name="loginForm">
                                            {t("goBackToLogin")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Fragment>
                        )
                    )}
                </Container>
            </Fragment>
        );
    }
}

export default NotLoggedPages;
