import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import AlertNoPermission from "../../alerts/AlertNoPermission";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

class AssignPaymentToOrder extends Component {
    state = { ordersList: null, noPermission: false, loading: true };

    getPartOrders = async () => {
        const { fetchURL, onShowAlert, t, partID } = this.props;
        const passData = { partID: partID };
        try {
            const response = await fetch(`${fetchURL}/participant-orders-list`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const ordersList = fetchedData.ordersList;
                        if (ordersList.length > 0) {
                            ordersList.unshift({ key: 0, value: "default", text: t("lack") });
                            this.setState({ ordersList: ordersList, loading: false });
                        } else this.setState({ loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.getPartOrders();
    }

    render() {
        const { ordersList, noPermission, loading } = this.state;
        const { value, onSelectOrder, req, t } = this.props;
        return (
            <Fragment>
                <Form.Label>
                    {t("payment.assignToOrder")} {req && <span className="required">*</span>}
                </Form.Label>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : loading ? (
                    <LoadingProgressBar t={t} />
                ) : !ordersList ? (
                    <p className="opacity-4 mb-3">{t("lack")}</p>
                ) : (
                    <Dropdown
                        value={value}
                        id="ordersListDropdown"
                        className={req ? "req mb-3" : "mb-3"}
                        placeholder={t("shop.clickToChooseOrder")}
                        fluid
                        selection
                        options={ordersList}
                        onChange={(event, { value }) => {
                            onSelectOrder(value);
                        }}
                    />
                )}
            </Fragment>
        );
    }
}

export default AssignPaymentToOrder;
