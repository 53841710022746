import React, { Fragment } from "react";
import { Form, Alert } from "react-bootstrap";
import $ from "jquery";

const ParticipantVerifiedDiscount = (props) => {
    $(document).ready(function () {
        $("#partVerifiedDiscount").on("keyup", function () {
            if ($(this).val() > 100) {
                $("#partVerifiedDiscount").addClass("danger");
                $("#partVerifiedDiscountAlert").show();
            } else {
                $("#partVerifiedDiscount").removeClass("danger");
                $("#partVerifiedDiscountAlert").hide();
            }
        });
    });

    const { value, onChangeValue, t } = props;
    return (
        <Fragment>
            <Form.Group>
                <Form.Label>{t("participant.discount.discount")}</Form.Label>
                <Form.Control id="partVerifiedDiscount" type="number" placeholder={t("participant.discount.typeDiscount")} name="part_verified_discount" value={value} onChange={onChangeValue} />
                <Alert variant="danger" className="alert-small mt-2" id="partVerifiedDiscountAlert" style={{ display: "none" }}>
                    {t("participant.discount.discountAlert")}
                </Alert>
            </Form.Group>
        </Fragment>
    );
};

export default ParticipantVerifiedDiscount;
