import React, { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";
const ConfirmDeactivationModal = (props) => {
    const { show, onClose, userName, onDeactivateUser, t } = props;
    return (
        <Fragment>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("user.deactivation.modal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t("user.deactivation.modal.text1")}
                    {userName}
                    {t("user.deactivation.modal.text2")}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={onClose} type="button">
                        {t("cancel")}
                    </Button>
                    <Button variant="primary" onClick={onDeactivateUser}>
                        {t("yes")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default ConfirmDeactivationModal;
