import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { Form } from "react-bootstrap";
import pl from "date-fns/locale/pl";
import $ from "jquery";
registerLocale("pl", pl);

const CalendarDateAndTime = (props) => {
    const { txtLabel, value, onChangeCalendarDate, id, req, t } = props;
    $(`#${id}`).on("click", function () {
        $(this).removeClass("danger");
    });
    return (
        <Form.Group>
            <Form.Label>
                {txtLabel} {req && <span className="required">*</span>}
            </Form.Label>
            {
                <DatePicker
                    placeholderText={t("calendar.selectDateTime")}
                    selected={value} //value needs to be an object, not string
                    onChange={(date) => onChangeCalendarDate(date)}
                    dateFormat="yyyy-MM-dd HH:mm"
                    locale="pl"
                    className="datepicker"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={5}
                    id={id}
                    timeCaption={t("calendar.hour")}
                />
            }
        </Form.Group>
    );
};

export default CalendarDateAndTime;
