import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import AlertNoPermission from "../alerts/AlertNoPermission";
import CharacterStatuses from "../forms/dropdowns/CharacterStatuses";
import TextareaForm from "../forms/TextareaForm";
import LoadingProgressBar from "../spinners/LoadingProgressBar";

class ChangeCharacterStatusModal extends Component {
    state = {
        charStatus: this.props.charStatus,
        changedValues: false,
        comment: "",
    };

    changeCharacterStatus = async () => {
        const { charStatus, comment } = this.state;
        const { fetchURL, onShowAlert, t, charID, onCloseAndRefresh, partID } = this.props;
        const passData = { charID: charID, charStatus: charStatus, comment: comment, partID: partID };
        onShowAlert({ variant: "info", message: t("request.changingStatus") });
        try {
            const response = await fetch(`${fetchURL}/update-character-status`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        onShowAlert({ variant: "success", message: t(`success.changedCharStatus`), fading: 3000 });
                        onCloseAndRefresh();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { charStatus, comment } = this.state;
        const { onShowAlert, t } = this.props;
        onShowAlert("");
        let errorMssg = "";

        if (!charStatus) {
            errorMssg += t("error.emptyCharStatus");
            document.getElementById("charStatDropdown").classList.add("danger");
        }

        if (comment.length > 4000) {
            errorMssg += t("error.c2pCommentToLong");
            document.getElementById("c2pComment").classList.add("danger");
        } else if (charStatus === "REJECTED" && !comment) {
            errorMssg += t("error.noCommentWhileRejected");
            document.getElementById("c2pComment").classList.add("danger");
        }

        if (errorMssg) {
            onShowAlert({ variant: "danger", message: errorMssg });
        } else {
            //console.log(comment, charStatus);
            this.changeCharacterStatus();
        }
    };

    render() {
        const { noPermission, alert, charStatus, loading, changedValues, comment } = this.state;
        const { show, t, onClose, fetchURL, onShowAlert, onShowHomePage } = this.props;

        return (
            <Fragment>
                <Modal show={show} onHide={onClose} size="lg">
                    <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                        <Modal.Header closeButton>
                            <Modal.Title>{t("characters.changeCharStatusModal.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {noPermission ? (
                                <AlertNoPermission t={t} />
                            ) : alert ? (
                                <Alert variant={alert.variant}>{alert.message}</Alert>
                            ) : loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <Row>
                                    <Col xs={12} className="mb-3">
                                        <div dangerouslySetInnerHTML={{ __html: t("characters.changeCharStatusModal.text") }}></div>
                                    </Col>
                                    <Col xs={12}>
                                        <CharacterStatuses
                                            t={t}
                                            value={charStatus}
                                            onChangeValue={(value) => this.setState({ charStatus: value, changedValues: true })}
                                            fetchURL={fetchURL}
                                            onShowAlert={onShowAlert}
                                            req
                                            onShowHomePage={onShowHomePage}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <TextareaForm
                                            value={comment}
                                            onChangeValue={(e) => {
                                                document.getElementById("c2pComment").classList.remove("danger");
                                                this.setState({ [e.target.name]: e.target.value });
                                            }}
                                            labelTxt={t("characters.commentLabel")}
                                            name="comment"
                                            placeholderTxt={t("characters.commentPlaceholder")}
                                            id="c2pComment"
                                            req={charStatus === "REJECTED"}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <p className="mb-3">
                                            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                            {t("characters.changeCharStatusModal.text2")}
                                        </p>
                                    </Col>
                                </Row>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary" type="button" onClick={onClose}>
                                {t("close")}
                            </Button>
                            <Button variant="primary" type="submit" disabled={!changedValues}>
                                {t("change")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default ChangeCharacterStatusModal;
