import React, { Fragment } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import CalendarDateAndTime from "../../../forms/datetimes/CalendarDateAndTime";
import GroupsDropdown from "../../../forms/dropdowns/GroupsDropdown";
import ParticipantLabels from "../../../forms/dropdowns/ParticipantLabels";
import ParticipantStatus from "../../../forms/dropdowns/ParticipantStatus";
import ParticipantVerifiedDiscount from "../../../forms/number-inputs/ParticipantVerifiedDiscount";
import ScanQRCodeForm from "../../../forms/ScanQRCodeForm";
import IdentNumber from "../../../forms/text-inputs/IdentNumber";
import InputGroupForm from "../../../forms/text-inputs/InputGroupForm";
import TextForm from "../../../forms/text-inputs/TextForm";

const PartData = (props) => {
    const {
        participantData,
        alertAssignedUniquePartID,
        onChangePartData,
        fetchURL,
        onShowAlert,
        onPartLabelSelected,
        onChangeVerifiedAmount,
        onChangePartIdentNumber,
        selectedLabels,
        onChangeCalendarDate,
        onPartGroupSelected,
        incorrectVerifiedAmountAlert,
        onChangeVerifiedDiscount,
        onPartStatSelected,
        qrCode,
        activeEvent,
        onChangeQRCodeValue,
        onResetIdent,
        scannerHeight,
        onSetDefaultIdentNumber,
        currentPage,
        t,
        onShowHomePage,
    } = props;
    const part = participantData[0];
    return (
        <Fragment>
            <Row>
                <Col xs={12}>
                    <p className="h4 my-4 form-title">{t("participant.partData")}</p>
                </Col>
                {currentPage.subpage === "editParticipant" && (
                    <Col xs={12} className="mb-3">
                        <p className="mb-0">{t("participant.nameAndSurname")}</p>
                        <p>
                            <b>{part.user_fullname}</b>
                        </p>
                    </Col>
                )}
            </Row>

            <Row>
                <Col lg={3} xs={2}>
                    <IdentNumber
                        partID={part.part_id}
                        fetchURL={fetchURL}
                        value={part.part_ident_number}
                        onShowAlert={onShowAlert}
                        activeEvent={activeEvent}
                        onChangePartIdentNumber={onChangePartIdentNumber}
                        onSetDefaultIdentNumber={onSetDefaultIdentNumber}
                        currentPage={currentPage}
                        t={t}
                        onShowHomePage={onShowHomePage}
                    />
                    {alertAssignedUniquePartID && (
                        <Alert variant={alertAssignedUniquePartID.variant} className="alert-small">
                            {alertAssignedUniquePartID.message}
                        </Alert>
                    )}
                </Col>

                <Col lg={3} xs={6}>
                    <TextForm
                        value={part.part_ident_nickname}
                        onChangeValue={onChangePartData}
                        req
                        labelTxt={t("participant.partNicknameLabel")}
                        placeholderTxt={t("participant.partNicknamePlaceholder")}
                        id="participantNickname"
                        name="part_ident_nickname"
                    />
                </Col>
                <Col lg={6} xs={6}>
                    <ScanQRCodeForm
                        scannerHeight={scannerHeight}
                        onChangeQRCodeValue={onChangeQRCodeValue}
                        fetchURL={fetchURL}
                        onShowAlert={onShowAlert}
                        qrCode={qrCode}
                        onResetIdent={onResetIdent}
                        t={t}
                        onShowHomePage={onShowHomePage}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={5} xs={12}>
                    <ParticipantLabels onPartLabelSelected={onPartLabelSelected} defaultValue={selectedLabels} fetchURL={fetchURL} onLoadPartLabels={() => {}} t={t} onShowHomePage={onShowHomePage} />
                </Col>
                <Col lg={4} sm={6} xs={8}>
                    <ParticipantVerifiedDiscount value={part.part_verified_discount} onChangeValue={onChangeVerifiedDiscount} t={t} onShowHomePage={onShowHomePage} />
                    {selectedLabels && selectedLabels.length > 0 && (
                        <Alert variant="info" className="alert-small">
                            {t("participant.suggestedDiscount")}
                            <b>{part.part_calculated_discount}%</b>.
                        </Alert>
                    )}
                </Col>

                <Col lg={3} md={4} sm={6} xs={8}>
                    <InputGroupForm
                        value={part.part_verified_amount}
                        onChangeValue={onChangeVerifiedAmount}
                        labelTxt={t("participant.verifiedAmountLabel")}
                        placeholderTxt={t("participant.verifiedAmountPlaceholder")}
                        name="part_verified_amount"
                        descr="part_verified_amount"
                        id="partVerifiedAmount"
                        descrTxt={t("currency")}
                        t={t}
                        onShowHomePage={onShowHomePage}
                    />

                    {incorrectVerifiedAmountAlert && (
                        <Alert variant="warning" className="alert-small">
                            {incorrectVerifiedAmountAlert}
                        </Alert>
                    )}
                </Col>

                <Col lg={4} md={6} xs={12}>
                    <ParticipantStatus onPartStatSelected={onPartStatSelected} defaultValue={part.part_stat_code} fetchURL={fetchURL} req t={t} onShowHomePage={onShowHomePage} />
                </Col>
                {part.part_stat_code === "PRESENT" && (
                    <Col lg={4} md={6} xs={12}>
                        <CalendarDateAndTime
                            txtLabel={t("calendar.dateTimeArrival")}
                            onChangeCalendarDate={onChangeCalendarDate}
                            value={part.part_arrival_date}
                            id="part_arrival_date"
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    </Col>
                )}

                <Col lg={4} md={6} xs={12}>
                    <GroupsDropdown
                        onShowAlert={onShowAlert}
                        onPartGroupSelected={onPartGroupSelected}
                        defaultValue={part.part_group_id}
                        fetchURL={fetchURL}
                        txtLabel={t("group.group")}
                        t={t}
                        onShowHomePage={onShowHomePage}
                    />
                </Col>
            </Row>
        </Fragment>
    );
};

export default PartData;
