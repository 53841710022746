import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";

class PaymentStatusDropdown extends Component {
    state = {
        statuses: [
            { key: "1", value: "all", text: this.props.t("selectAll") },
            { key: "2", value: "PAID", text: this.props.t("payment.paid") },
            { key: "3", value: "OVERPAID", text: this.props.t("payment.overpaid") },
            { key: "4", value: "UNDERPAID", text: this.props.t("payment.underpaid") },
        ],
    };

    render() {
        const { statuses } = this.state;
        const { onChangeStatusValue, defaultValue, req, id, t } = this.props;
        return (
            <Fragment>
                <Form.Label>
                    {t("payment.status")}: {req && <span className="required">*</span>}
                </Form.Label>
                <Dropdown className="mb-3" placeholder={t("form.choose")} fluid selection defaultValue={defaultValue} options={statuses} onChange={onChangeStatusValue} id={id} />
            </Fragment>
        );
    }
}

export default PaymentStatusDropdown;
