import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = (props) => {
    return (
        <footer className="small">
            <Container fluid>
                <Row>
                    <Col xs={{ span: 6, offset: 3 }} className="text-center" style={{ paddingTop: "0.375rem" }}>
                        <span>Copyright by Łyżkon</span>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
