import React, { Fragment } from "react";
import ShopItemsList from "../../pages/shop/items/ShopItemsList";
import AddEditShopItem from "../../pages/shop/items/AddEditShopItem";
import ShopItemDetails from "../../pages/shop/items/ShopItemDetails";
import ShopOrdersList from "../../pages/shop/orders/ShopOrdersList";
import ShopOrderDetails from "../../pages/shop/orders/ShopOrderDetails";
import AddOrder from "../../pages/shop/orders/AddOrder";

const Shop = (props) => {
    const {
        fetchURL,
        onCheckUserPrivilage,
        onChangeValuesInFormes,
        onCancelChangeValuesInFormes,
        onChangeNavAndPage,
        currentPage,
        onResizeWindow,
        onCloseDisplayAlert,
        onBackToPreviousPage,
        activeEvent,
        onShowAlert,
        dataList1,
        dataList2,
        onGetDataList1,
        onGetDataList2,
        numberOfRecords,
        onChangeNOR,
        t,
        onShowHomePage,
    } = props;
    return (
        <Fragment>
            {currentPage.nav === "shop" && (
                <Fragment>
                    {currentPage.subpage === "shopItemsList" && (
                        <ShopItemsList
                            onResizeWindow={onResizeWindow}
                            fetchURL={fetchURL}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onCloseDisplayAlert={onCloseDisplayAlert}
                            activeEvent={activeEvent}
                            onShowAlert={onShowAlert}
                            dataList={dataList1}
                            onGetDataList={onGetDataList1}
                            numberOfRecords={numberOfRecords}
                            onChangeNOR={onChangeNOR}
                            eventFilterValue={props.eventFilterValue}
                            eventsList={props.eventsList}
                            onChangeEventFilter={props.onChangeEventFilter}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {(currentPage.subpage === "addShopItem" || currentPage.subpage === "editShopItem") && (
                        <AddEditShopItem
                            onResizeWindow={onResizeWindow}
                            fetchURL={fetchURL}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            onBackToPreviousPage={onBackToPreviousPage}
                            activeEvent={activeEvent}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onShowAlert={onShowAlert}
                            currentPage={currentPage}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {currentPage.subpage === "addShopOrder" && (
                        <AddOrder
                            onResizeWindow={onResizeWindow}
                            fetchURL={fetchURL}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            onBackToPreviousPage={onBackToPreviousPage}
                            activeEvent={activeEvent}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onShowAlert={onShowAlert}
                            currentPage={currentPage}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {currentPage.subpage === "shopItemDetails" && (
                        <ShopItemDetails
                            onResizeWindow={onResizeWindow}
                            fetchURL={fetchURL}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            onBackToPreviousPage={onBackToPreviousPage}
                            currentPage={currentPage}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            onShowAlert={onShowAlert}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}

                    {currentPage.subpage === "shopOrdersList" && (
                        <ShopOrdersList
                            onResizeWindow={onResizeWindow}
                            fetchURL={fetchURL}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onCloseDisplayAlert={onCloseDisplayAlert}
                            activeEvent={activeEvent}
                            onShowAlert={onShowAlert}
                            dataList={dataList2}
                            onGetDataList={onGetDataList2}
                            numberOfRecords={numberOfRecords}
                            onChangeNOR={onChangeNOR}
                            eventFilterValue={props.eventFilterValue}
                            eventsList={props.eventsList}
                            onChangeEventFilter={props.onChangeEventFilter}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}

                    {currentPage.subpage === "shopOrderDetails" && (
                        <ShopOrderDetails
                            onResizeWindow={onResizeWindow}
                            fetchURL={fetchURL}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            onBackToPreviousPage={onBackToPreviousPage}
                            currentPage={currentPage}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            onShowAlert={onShowAlert}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Shop;
