import React, { Fragment, Component } from "react";
import { Container, Row, Col, Dropdown, Button, Modal } from "react-bootstrap";
import $ from "jquery";
import NavDetails from "../../navigation/NavDetails";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import LoadingSpinnerSm from "../../spinners/LoadingSpinnerSm";
import NoPermission from "../../other/NoPermission";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActiveEventDetails from "./ActiveEventDetails";
import InactiveEventsList from "./InactiveEventsList";
import ParticipantLabelsEdit from "./ParticipantLabelsEdit";

class SettingsEdit extends Component {
    state = {
        loading: true,
        loadingEvents: true,
        showEditEventModal: false,
        showDeactivateEventModal: false,
        inactiveEventsList: null,
        noPermission: false,
        activeEvent: null,
        avalPeriodConstr: null,
        avalPeriodBar: null,
        avalPeriodAccr: null,
    };

    updateLablesDiscount = async (partLabels) => {
        const { fetchURL, onCancelChangeValuesInFormes, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.updatingLabels") });
        const passData = {
            partLabels: partLabels,
        };
        try {
            const response = await fetch(`${fetchURL}/update-labels-discount`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false)
                        onShowAlert({
                            variant: "danger",
                            message: fetchedData.details ? `${t(`error.${fetchedData.code}`)}: ${fetchedData.details.slice(0, -2)}.` : t(`error.${fetchedData.code}`),
                        });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                        this.setState({ changedValues: false });
                        onCancelChangeValuesInFormes();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { participantsLabels } = this.state;
        const { onShowAlert, t } = this.props;
        let errorMsg = "";
        onShowAlert("");
        participantsLabels.forEach((e, i) => {
            if (e.discount === "") {
                errorMsg += t("error.discountIsNotANumber");
                $(`#labelDiscount${i}`).addClass("danger");
            } else if (e.discount > 100) {
                errorMsg += t("error.discountHigherThan100");
                $(`#labelDiscount${i}`).addClass("danger");
            }
        });
        if (errorMsg) onShowAlert({ variant: "danger", message: errorMsg });
        else this.updateLablesDiscount(participantsLabels);
    };

    getInactiveEventsList = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/inactive-events-list`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ inactiveEventsList: fetchedData.eventsList }, () => this.setState({ loading: false }));
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    deactivateEvent = async () => {
        const { activeEvent } = this.state;
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.deactivatingEvent") });
        //console.log(activeEvent);
        const passData = {
            eventCode: activeEvent.event_code,
        };
        try {
            const response = await fetch(`${fetchURL}/deactivate-event`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                        this.setState({ showDeactivateEventModal: false });
                    } else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                        this.setState({ showDeactivateEventModal: false, loading: true, activeEvent: null }, () => {
                            this.getInactiveEventsList();
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    getActiveEventDetails = async (eventCode) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            eventCode: eventCode,
        };
        try {
            const response = await fetch(`${fetchURL}/active-event-details`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        this.setState({
                            loading: false,
                            activeEvent: fetchedData.activeEvent[0],
                            avalPeriodConstr: fetchedData.avalPeriodConstr,
                            avalPeriodBar: fetchedData.avalPeriodBar,
                            avalPeriodAccr: fetchedData.avalPeriodAccr,
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    checkAllowEditing = async (eventCode) => {
        const { fetchURL, onShowAlert, t, onChangeNavAndPage } = this.props;

        const passData = {
            eventCode: eventCode,
        };
        try {
            const response = await fetch(`${fetchURL}/check-allow-editing`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status !== true) onShowAlert({ variant: fetchedData.status, message: t(`${fetchedData.status}.${fetchedData.code}`), fading: 5000 });
                    else onChangeNavAndPage({ nav: "settings", subpage: "editActiveEvent", params: "" }, { nav: "settings", subpage: "settingsEdit", params: "" });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    checkActiveEvent = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/check-active-event-existence`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "noActiveEvent") this.getInactiveEventsList();
                    else this.getActiveEventDetails(fetchedData.activeEvent.code);
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        const { onResizeWindow } = this.props;
        onResizeWindow("basic");
        this.checkActiveEvent();
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        const { loading } = this.state;
        if ($(".scrolled-content").outerHeight() && !loading) onResizeWindow("basic");
        if (!loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { loading, showDeactivateEventModal, inactiveEventsList, noPermission, activeEvent, avalPeriodConstr, avalPeriodBar, avalPeriodAccr } = this.state;
        const { onChangeNavAndPage, t, onChangeValuesInFormes, fetchURL, onShowAlert, onShowHomePage } = this.props;
        const currentPageParams = { nav: "settings", subpage: "settingsEdit", params: "" };

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details part-details" id="navDetails">
                            <Row>
                                <NavDetails t={t} header="ustawienia" hideBackButton={true} />
                                <Col className="text-right">
                                    {loading ? (
                                        <LoadingSpinnerSm />
                                    ) : (
                                        <Fragment>
                                            <Button
                                                variant="outline-primary"
                                                className="btn-sync mr-2 d-inline-block"
                                                onClick={() => {
                                                    this.setState({ loading: true }, () => {
                                                        this.props.reloadEventInfo();
                                                        this.checkActiveEvent();
                                                    });
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faSync} />
                                            </Button>
                                            {activeEvent && activeEvent !== "noActiveEvent" ? (
                                                <Dropdown className="mt-1 d-inline-block">
                                                    <Dropdown.Toggle variant="outline-primary" id="dropdownParticipantActions">
                                                        {t("dropdowns.actions")}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item as="button" onClick={() => this.checkAllowEditing(activeEvent.code)}>
                                                            {t("settings.editConvention")}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item as="button" onClick={() => this.setState({ showDeactivateEventModal: true })}>
                                                            {t("settings.deactivateConvention")}
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            ) : (
                                                <Button
                                                    variant="outline-primary"
                                                    className="btn-sync d-inline-block"
                                                    onClick={() => onChangeNavAndPage({ nav: "settings", subpage: "addActiveEvent", params: "" }, currentPageParams)}
                                                >
                                                    {t("settings.addConvention")}
                                                </Button>
                                            )}
                                        </Fragment>
                                    )}
                                </Col>
                            </Row>
                        </Container>

                        {!loading ? (
                            <div className="scrolled-content" style={{ display: "none" }} id="scrollContainer">
                                <Container className="py-3">
                                    {activeEvent && activeEvent !== "noActiveEvent" ? (
                                        <ActiveEventDetails
                                            t={t}
                                            activeEventDetails={activeEvent}
                                            avalPeriodConstr={avalPeriodConstr}
                                            avalPeriodBar={avalPeriodBar}
                                            avalPeriodAccr={avalPeriodAccr}
                                        />
                                    ) : (
                                        <InactiveEventsList t={t} inactiveEventsList={inactiveEventsList} />
                                    )}
                                    <ParticipantLabelsEdit
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        onChangeValuesInFormes={onChangeValuesInFormes}
                                        fetchURL={fetchURL}
                                        onShowAlert={onShowAlert}
                                    />
                                </Container>
                            </div>
                        ) : (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        )}

                        {showDeactivateEventModal && (
                            <Modal show={showDeactivateEventModal} onHide={() => this.setState({ showDeactivateEventModal: false })}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{t("deactivateEventModal.title")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{t("deactivateEventModal.text")}</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.deactivateEvent()}>
                                        {t("deactivateEventModal.yes")}
                                    </Button>
                                    <Button variant="primary" onClick={() => this.setState({ showDeactivateEventModal: false })}>
                                        {t("deactivateEventModal.no")}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default SettingsEdit;
