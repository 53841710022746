import React, { Fragment, Component } from "react";
import { Alert, Button, Col, Container, Form, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import NavDetails from "../../navigation/NavDetails";
import NoPermission from "../../other/NoPermission";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faComment, faEdit, faQuestion } from "@fortawesome/free-solid-svg-icons";
import SelectPartsToDutyModal from "../../modals/SelectPartsToDutyModal";
import CreateScheduleConfirmModal from "../../modals/CreateScheduleConfirmModal";

class CreateSchedule extends Component {
    state = { loading: true, noPermission: false, changedValues: false, periods: null, selectPartsToDutyModal: null, partsAvailability: null, showConfirmModal: false };

    getDutyPeriods = async () => {
        //console.log("getDutyPeriods");
        const { fetchURL, onShowAlert, t, activeEvent, currentPage } = this.props;
        const passData = {
            activeEvent: activeEvent,
            dutyTypeCode: currentPage.params.dutyTypeCode,
        };
        try {
            const response = await fetch(`${fetchURL}/get-duty-periods`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        if (fetchedData.info) onShowAlert({ variant: "info", message: t("info.insertedDutyPeriods") });
                        this.setState({ loading: false, periods: fetchedData.periods });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    getDutyPeriodsWithParticipants = async () => {
        const { fetchURL, onShowAlert, t, activeEvent, currentPage } = this.props;
        const passData = {
            activeEvent: activeEvent,
            dutyTypeCode: currentPage.params.dutyTypeCode,
        };
        //console.log("getperiodswithparts");
        try {
            const response = await fetch(`${fetchURL}/get-duty-periods-with-participants`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        if (fetchedData.info) onShowAlert({ variant: "info", message: t("info.insertedDutyPeriods") });
                        this.setState({ periods: fetchedData.periods }, () => {
                            const listAval = [...this.state.partsAvailability];
                            const { periods } = this.state;
                            listAval.forEach((e, i) => {
                                let countDuties = 0;
                                ////console.log(e);
                                const partID = e.part_id;
                                periods.forEach((p) => {
                                    p.participants.forEach((part) => {
                                        if (part.partID === partID) {
                                            //jest dyżur
                                            countDuties++;
                                        }
                                    });
                                });
                                ////console.log(countDuties);
                                listAval[i].usedDuties = countDuties;
                            });
                            //console.log(listAval);
                            this.setState({ partsAvailability: listAval, loading: false });
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    assignPartsToDuties = async () => {
        const { periods, partsAvailability } = this.state;
        const { fetchURL, onShowAlert, t, currentPage, onCancelChangeValuesInFormes, onChangeNavAndPage } = this.props;

        const passData = {
            partsAvailability: partsAvailability,
            dutyTypeCode: currentPage.params.dutyTypeCode,
            periods: periods,
        };
        //console.log(passData);
        try {
            const response = await fetch(`${fetchURL}/${currentPage.params.option === "add" ? "assign-parts-to-duties" : "edit-schedule"}`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        onCancelChangeValuesInFormes();
                        onShowAlert({ variant: "success", message: t(`success.${currentPage.params.option === "add" ? "assignedParts2Duties" : "scheduleEdited"}`), fading: 2000 });
                        onChangeNavAndPage({ nav: "duties", subpage: "dutiesList", params: "" }, null);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    getPartsAvailability = async () => {
        const { fetchURL, onShowAlert, t, activeEvent, currentPage } = this.props;
        const passData = {
            activeEvent: activeEvent,
            dutyTypeCode: currentPage.params.dutyTypeCode,
        };
        try {
            const response = await fetch(`${fetchURL}/get-parts-availability`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        this.setState({ partsAvailability: fetchedData.partList }, () => {
                            if (currentPage.params.option === "add") this.getDutyPeriods();
                            else this.getDutyPeriodsWithParticipants();
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { periods, partsAvailability } = this.state;
        const { onShowAlert, t } = this.props;
        onShowAlert("");
        let errorMssg = "";
        //console.log(periods);

        let emptySchedule = true;

        for (let i = 0; i < periods.length; i++) {
            const e = periods[i];
            if (e.participants.length > 0) {
                emptySchedule = false;
                break;
            }
        }

        if (emptySchedule) errorMssg += t("error.emptySchedule");

        if (errorMssg) {
            onShowAlert({ variant: "danger", message: errorMssg });
        } else {
            let partsWithNoDuties = [],
                partsWithToMuchDuties = [];
            partsAvailability.forEach((e) => {
                if (e.usedDuties === 0) partsWithNoDuties.push(e.partName);
                else if (typeof e.numberOfDuties !== "string" && e.usedDuties > e.numberOfDuties) partsWithToMuchDuties.push(e.partName);
            });
            ////console.log(partsListWithNoDuties);
            if (partsWithNoDuties.length > 0 || partsWithToMuchDuties.length > 0) {
                this.setState({ showConfirmModal: { partsWithNoDuties: partsWithNoDuties, partsWithToMuchDuties: partsWithToMuchDuties } });
            } else {
                onShowAlert({ variant: "info", message: t("info.assigningPartsToDuties") });
                this.assignPartsToDuties();
            }
        }
    };

    handleSelectParts = (parts) => {
        const { periods, selectPartsToDutyModal, partsAvailability } = this.state;
        const list = [...periods],
            listAval = [...partsAvailability],
            selectedParts = [];
        parts.forEach((e) => {
            if (e) {
                selectedParts.push(e);
            }
        });
        list[selectPartsToDutyModal.index].participants = selectedParts;

        this.setState({ periods: list, changedValues: true, selectPartsToDutyModal: null }, () => {
            //zliczanie ile razy uczestnikowi został przydzielony dyżur
            listAval.forEach((e, i) => {
                let countDuties = 0;
                ////console.log(e);
                const partID = e.part_id;
                periods.forEach((p) => {
                    p.participants.forEach((part) => {
                        if (part.partID === partID) {
                            //jest dyżur
                            countDuties++;
                        }
                    });
                });
                ////console.log(countDuties);
                listAval[i].usedDuties = countDuties;
            });
            //console.log(listAval);
            this.setState({ partsAvailability: listAval, changedValues: true });
            this.props.onChangeValuesInFormes();
        });
    };

    componentDidMount() {
        const { onResizeWindow, activeEvent, onShowAlert } = this.props;
        onShowAlert("");
        if (activeEvent) {
            this.setState({ activeEvent: activeEvent.name });
        }
        onResizeWindow("basic");
        this.getPartsAvailability();
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if ($(".scrolled-content").outerHeight() && !this.state.loading) onResizeWindow("basic");
        if (!this.state.loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { loading, noPermission, changedValues, periods, selectPartsToDutyModal, partsAvailability, showConfirmModal } = this.state;
        const { onBackToPreviousPage, t, onCancelChangeValuesInFormes, activeEvent, fetchURL, onShowAlert, currentPage } = this.props;

        const dutyTypeCode = currentPage.params.dutyTypeCode;

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : !activeEvent || activeEvent === "noActiveEvent" ? (
                    <Alert variant="danger">{t("duties.noActiveEvent")}</Alert>
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails
                                    t={t}
                                    header={t(`duties.${currentPage.params.option === "add" ? "create" : "edit"}${dutyTypeCode}Schedule`)}
                                    changedValues={changedValues}
                                    onChangeView={onBackToPreviousPage}
                                    onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                                />
                                <Col></Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                                <div className="scrolled-content" style={{ display: "none" }}>
                                    <Container className="py-3">
                                        <Row>
                                            <Col xs={12}>
                                                <p className="h5 mb-3 mt-2">{t(`duties.partsList${dutyTypeCode}`)}:</p>
                                            </Col>
                                            {partsAvailability.map((e, i) => (
                                                <Col xs={6} md={4} lg={3} key={i}>
                                                    {e.partName}
                                                    {dutyTypeCode === "CONSTR" ? (
                                                        <Fragment>
                                                            {" "}
                                                            - {e.usedDuties} {t(`duties.${e.usedDuties === 1 ? "oneDuty" : e.usedDuties >= 2 && e.usedDuties <= 4 ? "twoDuties" : "duties"}`)}
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>
                                                            {" "}
                                                            <span className={typeof e.numberOfDuties !== "string" && e.usedDuties > e.numberOfDuties ? "text-danger" : ""}>{e.usedDuties}</span>/
                                                            {e.numberOfDuties}
                                                        </Fragment>
                                                    )}

                                                    {e.comment && (
                                                        <OverlayTrigger key="top" placement="top" overlay={<Tooltip id={`tooltip-top`}>{e.comment}</Tooltip>}>
                                                            <Button variant="link" className="ml-2">
                                                                <FontAwesomeIcon icon={faComment} />
                                                            </Button>
                                                        </OverlayTrigger>
                                                    )}
                                                </Col>
                                            ))}
                                        </Row>
                                        <Row>
                                            <Col className="mt-4">
                                                <Table responsive bordered className="table-details t-bordered">
                                                    <tbody>
                                                        {periods.map((e, i) => (
                                                            <tr key={i}>
                                                                <td className="text-center" style={{ width: "20%" }}>
                                                                    {e.duty_period_label} {e.duty_period_date}
                                                                    {(dutyTypeCode === "ACCR" || dutyTypeCode === "BAR") && (
                                                                        <span>
                                                                            <br />
                                                                            {e.duty_period_start} - {e.duty_period_end}
                                                                        </span>
                                                                    )}
                                                                </td>
                                                                <td className={e.is_larp === "Y" ? "larp" : ""}>
                                                                    {e.participants.length === 0 ? (
                                                                        <span className="opacity-4">{t("lack")}</span>
                                                                    ) : (
                                                                        e.participants.map((p, j) => (
                                                                            <Fragment key={j}>
                                                                                <p className="mb-0">
                                                                                    {p.partName}{" "}
                                                                                    {p.partAnswer === "Y" ? (
                                                                                        <span>
                                                                                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                                                                                        </span>
                                                                                    ) : p.partAnswer === "M" ? (
                                                                                        <span>
                                                                                            <FontAwesomeIcon icon={faQuestion} className="text-warning" />
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span></span>
                                                                                    )}
                                                                                </p>
                                                                            </Fragment>
                                                                        ))
                                                                    )}
                                                                </td>
                                                                <td style={{ width: "20px" }}>
                                                                    <Button
                                                                        className="w-100"
                                                                        variant="outline-primary"
                                                                        onClick={() => this.setState({ selectPartsToDutyModal: { index: i, period: e } })}
                                                                    >
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button type="submit" variant="primary" disabled={!changedValues} size="lg">
                                                    {t("save")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Form>
                        )}
                    </Fragment>
                )}

                {selectPartsToDutyModal && (
                    <SelectPartsToDutyModal
                        t={t}
                        fetchURL={fetchURL}
                        onShowAlert={onShowAlert}
                        activeEvent={activeEvent}
                        show={true}
                        onClose={() => {
                            this.setState({ selectPartsToDutyModal: null });
                            this.props.onShowAlert("");
                        }}
                        onSelectParts={this.handleSelectParts}
                        index={selectPartsToDutyModal.index}
                        period={selectPartsToDutyModal.period}
                        dutyTypeCode={currentPage.params.dutyTypeCode}
                        option={currentPage.params.option}
                    />
                )}

                {showConfirmModal && (
                    <CreateScheduleConfirmModal
                        t={t}
                        onClose={() => {
                            this.setState({ showConfirmModal: null });
                            this.props.onShowAlert("");
                        }}
                        partsList={showConfirmModal}
                        onConfirm={() => {
                            onShowAlert({ variant: "info", message: t("info.assigningPartsToDuties") });
                            this.assignPartsToDuties();
                        }}
                    />
                )}
            </Fragment>
        );
    }
}

export default CreateSchedule;
