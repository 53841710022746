import React, { Fragment } from "react";
import GroupsList from "../../pages/groups/GroupsList";
import GroupDetails from "../../pages/groups/GroupDetails";
import AddEditGroup from "../../pages/groups/AddEditGroup";
import EditGroupMembers from "../../pages/groups/EditGroupMembers";

const Groups = (props) => {
    const {
        fetchURL,
        onCheckUserPrivilage,
        onChangeValuesInFormes,
        onCancelChangeValuesInFormes,
        scannerHeight,
        dataList,
        onGetDataList,
        onChangeNavAndPage,
        currentPage,
        windowHeight,
        onResizeWindow,
        onBackToPreviousPage,
        activeEvent,
        onShowAlert,
        numberOfRecords,
        onChangeNOR,
        onShowHomePage,
        t,
    } = props;

    return (
        <Fragment>
            {currentPage.nav === "groups" && (
                <Fragment>
                    {currentPage.subpage === "groupsList" && (
                        <GroupsList
                            onResizeWindow={onResizeWindow}
                            fetchURL={fetchURL}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            onChangeNavAndPage={onChangeNavAndPage}
                            activeEvent={activeEvent}
                            onShowAlert={onShowAlert}
                            dataList={dataList}
                            onGetDataList={onGetDataList}
                            numberOfRecords={numberOfRecords}
                            onChangeNOR={onChangeNOR}
                            eventFilterValue={props.eventFilterValue}
                            eventsList={props.eventsList}
                            onChangeEventFilter={props.onChangeEventFilter}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {(currentPage.subpage === "addGroup" || currentPage.subpage === "editGroup") && (
                        <AddEditGroup
                            fetchURL={fetchURL}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            scannerHeight={scannerHeight}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            onResizeWindow={onResizeWindow}
                            onBackToPreviousPage={onBackToPreviousPage}
                            activeEvent={activeEvent}
                            onShowAlert={onShowAlert}
                            currentPage={currentPage}
                            onChangeNavAndPage={onChangeNavAndPage}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {currentPage.subpage === "groupDetails" && (
                        <GroupDetails
                            onBackToPreviousPage={onBackToPreviousPage}
                            currentPage={currentPage}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            fetchURL={fetchURL}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onResizeWindow={onResizeWindow}
                            onShowAlert={onShowAlert}
                            activeEvent={activeEvent}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}

                    {currentPage.subpage === "editGroupMembers" && (
                        <EditGroupMembers
                            windowHeight={windowHeight}
                            fetchURL={fetchURL}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            scannerHeight={scannerHeight}
                            currentPage={currentPage}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            onResizeWindow={onResizeWindow}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onBackToPreviousPage={onBackToPreviousPage}
                            onShowAlert={onShowAlert}
                            activeEvent={activeEvent}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Groups;
