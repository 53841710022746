import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Table, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import AlertNoPermission from "../../../alerts/AlertNoPermission";
import ApplicationDetailsModal from "../../../modals/ApplicationDetailsModal";
import ChangeAppStatusModal from "../../../modals/ChangeAppStatusModal";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";

class FilledForms extends Component {
    state = {
        partForms: null,
        loading: true,
        noPermission: false,
        showChangeAppStatusModal: false,
        showApplicationDetailsModal: false,
        selectedPartAppData: null,
    };

    getPartApplications = async (partID) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            partID: partID,
        };

        try {
            const response = await fetch(`${fetchURL}/participant-applications`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ partApplications: fetchedData.applications, loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleDownloadData = () => {
        this.setState({ loading: true });
        this.getPartApplications(this.props.partID);
    };

    componentDidMount() {
        this.getPartApplications(this.props.partID);
    }

    render() {
        const { partApplications, noPermission, loading, showChangeAppStatusModal, showApplicationDetailsModal, selectedPartAppData } = this.state;
        const { t, fetchURL, onShowAlert, onShowHomePage } = this.props;
        return (
            <Fragment>
                <Col xs={12}>
                    <p className="h3 my-3 d-inline-block mr-3 align-middle">{t("participant.applications")}</p>
                </Col>
                <Col lg={8} xs={12}>
                    {noPermission ? (
                        <AlertNoPermission t={t} />
                    ) : loading ? (
                        <LoadingProgressBar t={t} />
                    ) : partApplications.length === 0 ? (
                        <p className="opacity-4 mb-4">{t("applications.noApplications")}</p>
                    ) : (
                        <Table bordered hover className="table-list mb-4" id="partApplicationsTable" size="sm">
                            <thead>
                                <tr>
                                    <th>{t("applications.appTypeName")}</th>
                                    <th>{t("applications.appStatName")}</th>
                                    <th>{t("dropdowns.actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {partApplications.map((e, i) => (
                                    <tr key={i}>
                                        <td>{e.part_app_type_name}</td>
                                        <td>{e.part_app_stat_name}</td>
                                        <td className="text-center">
                                            {(e.part_app_type_code === "CONSTR" || e.part_app_type_code === "BAR" || e.part_app_type_code === "ACCR") && (
                                                <OverlayTrigger placement="top" overlay={<Tooltip id={`partAppDetails${i}`}>{t("applications.applicationDetails")}</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        <Button
                                                            size="sm"
                                                            variant="outline-primary"
                                                            onClick={() =>
                                                                this.setState({
                                                                    selectedPartAppData: { appID: e.part_app_id, partAppType: e.part_app_type_code },
                                                                    showApplicationDetailsModal: true,
                                                                })
                                                            }
                                                            className="mx-1"
                                                        >
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </Button>
                                                    </span>
                                                </OverlayTrigger>
                                            )}
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`partAppStatus${i}`}>{t("applications.changeAppStatus")}</Tooltip>}>
                                                <span className="d-inline-block">
                                                    <Button
                                                        size="sm"
                                                        variant="outline-primary"
                                                        onClick={() =>
                                                            this.setState({
                                                                selectedPartAppData: { appID: e.part_app_id, appStatus: e.part_app_stat_code },
                                                                showChangeAppStatusModal: true,
                                                            })
                                                        }
                                                        className="mx-1"
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </Col>

                {showChangeAppStatusModal && (
                    <ChangeAppStatusModal
                        t={t}
                        onShowHomePage={onShowHomePage}
                        show={showChangeAppStatusModal}
                        onClose={() => this.setState({ showChangeAppStatusModal: false, selectedPartAppData: null })}
                        partAppData={selectedPartAppData}
                        fetchURL={fetchURL}
                        onShowAlert={onShowAlert}
                        onDownloadData={this.handleDownloadData}
                    />
                )}
                {showApplicationDetailsModal && (
                    <ApplicationDetailsModal
                        t={t}
                        onShowHomePage={onShowHomePage}
                        show={showApplicationDetailsModal}
                        onClose={() => this.setState({ showApplicationDetailsModal: false, selectedPartAppData: null })}
                        partAppData={selectedPartAppData}
                        fetchURL={fetchURL}
                        onShowAlert={onShowAlert}
                    />
                )}
            </Fragment>
        );
    }
}

export default FilledForms;
