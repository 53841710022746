import React from "react";
import { Table } from "react-bootstrap";

const MyAccountDetails = (props) => {
    const { t, userData } = props;
    return (
        <Table bordered hover className="table-details" responsive="lg" id="myAccountTable">
            <tbody>
                <tr>
                    <td>{t("user.userFullname")}</td>
                    <td>{userData[0].user_fullname}</td>
                </tr>

                <tr>
                    <td>{t("user.email")}</td>
                    <td>{userData[0].user_email}</td>
                </tr>
                <tr>
                    <td>{t("user.status")}</td>
                    <td>{userData[0].user_stat_name}</td>
                </tr>
                <tr>
                    <td>{t("user.activeRoles")}</td>
                    <td>{userData[0].user_roles_list}</td>
                </tr>
                <tr>
                    <td>{t("ident.qrCode")}</td>
                    <td>{userData[0].user_qr_code ? t("ident.identAssigned") : <span className="opacity-4">{t("lack")}</span>}</td>
                </tr>
                <tr>
                    <td>{t("form.phone")}</td>
                    <td>{userData[0].user_phone ? userData[0].user_phone : <span className="opacity-4">{t("lack")}</span>}</td>
                </tr>
                <tr>
                    <td>{t("user.fbLabel")}</td>
                    <td>{userData[0].user_facebook_link ? userData[0].user_facebook_link : <span className="opacity-4">{t("lack")}</span>}</td>
                </tr>
                <tr>
                    <td>{t("user.medLabel")}</td>
                    <td>{userData[0].user_medical_info ? userData[0].user_medical_info : <span className="opacity-4">{t("lack")}</span>}</td>
                </tr>
                <tr>
                    <td>{t("user.registered")}</td>
                    <td>{userData[0].create_date}</td>
                </tr>
                <tr>
                    <td>{t("user.modifyDate")}</td>
                    <td>{userData[0].modify_date}</td>
                </tr>
                <tr>
                    <td>{t("user.changedPass")}</td>
                    <td>{userData[0].password_change_date ? userData[0].password_change_date : <span className="opacity-4">{t("lack")}</span>}</td>
                </tr>
                <tr>
                    <td>{t("user.lastLogin")}</td>
                    <td>{userData[0].last_login_date ? userData[0].last_login_date : <span className="opacity-4">{t("lack")}</span>}</td>
                </tr>
            </tbody>
        </Table>
    );
};

export default MyAccountDetails;
