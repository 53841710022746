import { faEdit, faEye, faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, Component } from "react";
import { Table, OverlayTrigger, Tooltip, Button } from "react-bootstrap";

class TableApplicationsList extends Component {
    state = {
        currentSortType: "default",
        currentSortingColumn: "sortID",
    };

    handleSorting = (btnID) => {
        const { currentSortType } = this.state;
        let nextSort;
        if (currentSortType === "down") {
            nextSort = "up";
        } else if (currentSortType === "up") {
            nextSort = "default";
        } else if (currentSortType === "default") {
            nextSort = "down";
        }

        this.setState({
            currentSortType: nextSort,
            currentSortingColumn: btnID,
        });
    };

    render() {
        const { currentSortType, currentSortingColumn } = this.state;
        const { applications, numOfRecords, t, onChangeNavAndPage, currentPageParams, onSelectAppPartData, onSelectAppPartDataForDetails } = this.props;

        //sortowanie listy
        const sortTypes = {
            up: {
                fnID: (a, b) => a.part_app_id - b.part_app_id,
                fnCreateDate: (a, b) => {
                    if (new Date(a.create_date) > new Date(b.create_date) || isNaN(new Date(a.create_date))) return 1;
                    else return -1;
                },
                fnUser: (a, b) => {
                    if (a.user_fullname.toUpperCase() > b.user_fullname.toUpperCase()) return 1;
                    else return -1;
                },
            },
            down: {
                fnID: (a, b) => b.part_app_id - a.part_app_id,
                fnCreateDate: (a, b) => {
                    if (isNaN(new Date(a.create_date))) return -1;
                    else if (new Date(a.create_date) > new Date(b.create_date)) return -1;
                    else return 1;
                },

                fnUser: (a, b) => {
                    if (a.user_fullname.toUpperCase() > b.user_fullname.toUpperCase()) return -1;
                    else return 1;
                },
            },
            default: {
                fnID: (a, b) => a,
                fnCreateDate: (a, b) => a,
                fnUser: (a, b) => a,
            },
        };

        let sortedData = [];
        if (currentSortingColumn === "sortID") sortedData = applications.sort(sortTypes[currentSortType].fnID);
        else if (currentSortingColumn === "sortCreateDate") sortedData = applications.sort(sortTypes[currentSortType].fnCreateDate);
        else if (currentSortingColumn === "sortUser") sortedData = applications.sort(sortTypes[currentSortType].fnUser);
        else sortedData = applications.sort(sortTypes[currentSortType].fnID);

        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th style={{ width: "45px" }}>{t("on")}</th>
                            <th>
                                {t("applications.partName")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortUser" onClick={() => this.handleSorting("sortUser")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortUser" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                            <th>{t("applications.eventName")}</th>
                            <th>{t("applications.appTypeName")}</th>
                            <th>{t("applications.appStatName")}</th>
                            <th style={{ width: "180px" }}>
                                {t("applications.createDate")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortCreateDate" onClick={() => this.handleSorting("sortCreateDate")}>
                                    <FontAwesomeIcon
                                        icon={currentSortingColumn !== "sortCreateDate" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort}
                                    />
                                </Button>
                            </th>
                            <th style={{ width: "7.2rem" }}>{t("applications.actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData
                            .map((p, i) => (
                                <tr key={`application_data_${i}`}>
                                    <td>{i + 1}</td>
                                    <td>
                                        <Button
                                            onClick={() =>
                                                onChangeNavAndPage({ nav: "participants", subpage: "participantDetails", params: { partID: p.part_id, userID: p.user_id } }, currentPageParams)
                                            }
                                            variant="link"
                                        >
                                            {p.user_fullname}
                                        </Button>
                                    </td>
                                    <td>{p.event_name}</td>
                                    <td>{p.part_app_type_name}</td>
                                    <td>{p.part_app_stat_name}</td>
                                    <td>{p.create_date}</td>
                                    <td className="text-center">
                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`partAppDetails${i}`}>{t("applications.applicationDetails")}</Tooltip>}>
                                            <span className="d-inline-block">
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() => onSelectAppPartDataForDetails({ appID: p.part_app_id, partAppType: p.part_app_type_code })}
                                                    className="mx-1"
                                                >
                                                    <FontAwesomeIcon icon={faEye} />
                                                </Button>
                                            </span>
                                        </OverlayTrigger>

                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`partAppStatus${i}`}>{t("applications.changeAppStatus")}</Tooltip>}>
                                            <span className="d-inline-block">
                                                <Button variant="outline-primary" onClick={() => onSelectAppPartData({ appID: p.part_app_id, appStatus: p.part_app_stat_code })} className="mx-1">
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                            </span>
                                        </OverlayTrigger>
                                    </td>
                                </tr>
                            ))
                            .slice(0, numOfRecords)}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}

export default TableApplicationsList;
