import React, { Fragment } from "react";
import CharactersApplicationsList from "../../pages/characters/CharactersApplicationsList";

const Characters = (props) => {
    const { fetchURL, onCheckUserPrivilage, dataList, onGetDataList, onChangeNavAndPage, currentPage, onResizeWindow, activeEvent, onShowAlert, numberOfRecords, onChangeNOR, onShowHomePage, t } =
        props;
    return (
        <Fragment>
            {currentPage.nav === "characters" && (
                <Fragment>
                    {currentPage.subpage === "characterApplicationsList" && (
                        <CharactersApplicationsList
                            onResizeWindow={onResizeWindow}
                            fetchURL={fetchURL}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            onChangeNavAndPage={onChangeNavAndPage}
                            activeEvent={activeEvent}
                            onShowAlert={onShowAlert}
                            dataList={dataList}
                            onGetDataList={onGetDataList}
                            numberOfRecords={numberOfRecords}
                            onChangeNOR={onChangeNOR}
                            eventFilterValue={props.eventFilterValue}
                            eventsList={props.eventsList}
                            onChangeEventFilter={props.onChangeEventFilter}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Characters;
