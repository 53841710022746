import React, { Fragment, Component } from "react";
import queryString from "query-string";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogoAndTitle from "../../other/LogoAndTitle";
import Password from "../../forms/Password";
import ContactWithAdminModal from "../../modals/ContactWithAdminModal";

class ResetPass extends Component {
    state = {
        userID: "",
        mssgResetNotAllowed: "",
        password: "",
        repeatPassword: "",
        mssgExpired: "",
        userName: "",
        alert: "",
        contactFormData: null,
        showContactModal: false,
    };

    handleShowAlert = (alert) => {
        this.setState({ alert: alert });
    };

    setPassword = async (dataPass) => {
        const { fetchURL, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/set-password`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dataPass),
            });
            if (response.status === 200) {
                this.handleShowAlert("");
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        this.handleShowAlert({ variant: "success", message: t(`success.setNewPassword`), returnToHomePage: true });
                    } else if (fetchedData.status === "expired_link") {
                        this.handleShowAlert({ variant: "warning", message: t(`warning.${fetchedData.code}`) });
                    } else {
                        this.handleShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    }
                });
            }
        } catch (err) {
            this.handleShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    handleSetPassword = (e) => {
        e.preventDefault();
        const { password, repeatPassword } = this.state;
        const { t } = this.props;
        this.handleShowAlert("");
        let errorMsg = false;
        if (!password || !repeatPassword) {
            this.setState({ password: "", repeatPassword: "" });
            errorMsg = t("error.reqFields");
        } else if (password !== repeatPassword) {
            this.setState({ password: "", repeatPassword: "" });
            errorMsg = t("error.passNotIdentical");
        }

        if (errorMsg) {
            this.handleShowAlert({ variant: "danger", message: errorMsg });
        } else {
            const { location } = this.props;
            const dataPass = {
                password: password,
                repeatPassword: repeatPassword,
                postDataURL: {
                    url: `${location.pathname}${location.search}`,
                    pathname: `${location.pathname}`,
                },
            };

            this.setPassword(dataPass);
        }
    };

    confirmAccount = async (dataPass) => {
        const { fetchURL, t } = this.props;
        this.handleShowAlert("");
        try {
            const response = await fetch(`${fetchURL}/confirm-account`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dataPass),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") this.handleShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === "expired_link") this.handleShowAlert({ variant: "warning", message: t(`warning.${fetchedData.code}`) });
                    else {
                        this.setState({
                            userID: fetchedData.userID,
                            userName: fetchedData.userName,
                        });
                    }
                });
            }
        } catch (err) {
            this.handleShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    logout = async () => {
        const { fetchURL, t } = this.props;
        try {
            await fetch(`${fetchURL}/logout`, {
                credentials: "include",
            });
        } catch (err) {
            this.handleShowAlert({ variant: "danger", message: `${t("error.loggingOutFailed")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.logout();
        const { location, t } = this.props;
        const parsedLocationSearch = queryString.parse(location.search);
        if (location.search === "" || parsedLocationSearch.url === null) {
            this.handleShowAlert({
                variant: "warning",
                message: t("error.resetPassLinkIncorrect"),
                contactBtn: {
                    mailAddress: "",
                    mailTitle: t("mails.incorrectResetPassLinkTitle"),
                    mailSender: "",
                },
            });
        } else {
            const postDataURL = {
                url: `${location.pathname}${location.search}`,
                pathname: `${location.pathname}`,
                generatedString: parsedLocationSearch.url,
            };
            this.confirmAccount(postDataURL);
        }
    }
    render() {
        const { alert, password, repeatPassword, userName, showContactModal, contactFormData } = this.state;
        const { fetchURL, t } = this.props;
        return (
            <Fragment>
                {alert && (
                    <Alert className="alert-basic" variant={alert.variant} onClose={() => this.setState({ alert: "" })} dismissible>
                        {alert.message}
                        {alert.returnToHomePage && (
                            <Button variant={`outline-${alert.variant}`} onClick={() => window.location.assign("/")} className="d-block mt-2">
                                {t("user.login")}
                            </Button>
                        )}
                        {alert.contactBtn && (
                            <Button
                                variant={`outline-${alert.variant}`}
                                onClick={() => this.setState({ showContactModal: true, contactFormData: alert.contactBtn }, () => this.setState({ alert: "" }))}
                                className="d-block mt-2"
                            >
                                {t("confirmAccount.contactWithAdminModal")}
                            </Button>
                        )}
                    </Alert>
                )}
                <Container>
                    <LogoAndTitle title={t("resetPassword.title")} />

                    <Row>
                        <Col lg={{ span: 6, offset: 3 }}>
                            <p className="mb-3">
                                {t("resetPassword.text1")}
                                {userName}
                                {t("resetPassword.text2")}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={{ span: 6, offset: 3 }}>
                            <Form onSubmit={this.handleSetPassword} autoComplete="off">
                                <Password t={t} value1={password} value2={repeatPassword} onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })} option="double" />
                                <Form.Group className="text-center">
                                    <Button variant="primary" type="submit">
                                        {t("resetPassword.setPassword")}
                                    </Button>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center mt-4">
                            <Link to="/" className="btn btn-outline-secondary btn-sm mb-3">
                                {t("resetPassword.goHome")}
                            </Link>
                        </Col>
                    </Row>
                </Container>
                {showContactModal && (
                    <ContactWithAdminModal
                        t={t}
                        contactAdminData={contactFormData}
                        show={showContactModal}
                        onClose={() => this.setState({ showContactModal: false, contactFormData: null })}
                        fetchURL={fetchURL}
                    />
                )}
            </Fragment>
        );
    }
}

export default ResetPass;
