import React, { Component, Fragment } from "react";
import { Col, Row, Table, Container, Button } from "react-bootstrap";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import NavDetails from "../../navigation/NavDetails";
import NoPermission from "../../other/NoPermission";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

class RoomDetails extends Component {
    state = { roomDetails: null, loading: true, noPermission: false };

    getRoomDetails = async (roomID) => {
        const { fetchURL, onShowAlert, activeEvent, t } = this.props;
        const passData = {
            roomID: roomID,
            activeEvent: activeEvent,
        };
        onShowAlert("");
        try {
            const response = await fetch(`${fetchURL}/room-details`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const roomDetails = fetchedData.roomDetails;
                        this.setState({ roomDetails: roomDetails, loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleDownloadData = () => {
        this.setState({ loading: true }, () => this.getRoomDetails(this.props.currentPage.params.roomID));
    };

    componentDidMount() {
        const { currentPage } = this.props;
        this.getRoomDetails(currentPage.params.roomID);
        const { onResizeWindow } = this.props;
        onResizeWindow("basic");
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if ($(".scrolled-content").outerHeight() && !this.state.loading) onResizeWindow("basic");
        if (!this.state.loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { roomDetails, noPermission, loading } = this.state;
        const { onBackToPreviousPage, t } = this.props;
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails t={t} header="szczegóły pokoju" onChangeView={onBackToPreviousPage} />
                                <Col className="text-right">
                                    <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync mr-3">
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <div className="scrolled-content" style={{ display: "none" }}>
                                <Container className="py-3">
                                    <Row>
                                        <Col xs={12}>
                                            <p className="h5 mb-3">Dane pokoju</p>
                                        </Col>
                                        <Col lg={6} md={8} xs={12}>
                                            <Table responsive bordered hover className="table-details">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "30%" }}>Kod pokoju</td>
                                                        <td>{roomDetails[0].room_code}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Budynek</td>
                                                        <td>{roomDetails[0].building_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Numer pokoju</td>
                                                        <td>{roomDetails[0].room_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rozmiar pokoju</td>
                                                        <td>{roomDetails[0].room_size_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Liczba miejsc w pokoju</td>
                                                        <td>{roomDetails[0].room_capacity ? <b>{roomDetails[0].room_capacity}</b> : <span className="opacity-4">Brak informacji</span>}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Liczba drzwi</td>
                                                        <td>{roomDetails[0].room_doors ? <b>{roomDetails[0].room_doors}</b> : <span className="opacity-4">Brak informacji</span>}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Liczba okien</td>
                                                        <td>{roomDetails[0].room_windows ? <b>{roomDetails[0].room_windows}</b> : <span className="opacity-4">Brak informacji</span>}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Opis pokoju</td>
                                                        <td>{roomDetails[0].room_description ? <b>{roomDetails[0].room_description}</b> : <span className="opacity-4">Brak informacji</span>}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default RoomDetails;
