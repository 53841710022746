import React, { Component, Fragment } from "react";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import $ from "jquery";
import TooltipWithDisabledButton from "../../tooltips/TooltipWithDisabledButton";
import NavDetails from "../../navigation/NavDetails";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import NoPermission from "../../other/NoPermission";
import EmptyRooms from "../../forms/dropdowns/EmptyRooms";
import RoomKeepers from "../../forms/dropdowns/RoomKeepers";
import ParticipantsToRoomBooking from "../../forms/dropdowns/ParticipantsToRoomBooking";
import TextForm from "../../forms/text-inputs/TextForm";
import { faExclamation, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class BookRoom extends Component {
    state = {
        loading: true,
        noPermission: false,
        changedValues: false,
        bookingData: [
            {
                room_id: this.props.currentPage.params.roomRequest ? null : this.props.currentPage.params.roomID,
                room_name: this.props.currentPage.params.roomRequest ? this.props.currentPage.params.roomRequest.room_req_name : "",
                room_keeper_part_id: this.props.currentPage.params.roomRequest ? this.props.currentPage.params.roomRequest.part_id : null,
                part_group_id: this.props.currentPage.params.roomRequest ? this.props.currentPage.params.roomRequest.part_group_id : null,
                part_group_name: this.props.currentPage.params.roomRequest ? this.props.currentPage.params.roomRequest.part_group_name : null,
            },
        ],
        selectedParticipants: null,
        roomDetails: null,
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { bookingData, selectedParticipants } = this.state;
        const { onShowAlert, activeEvent, currentPage, t } = this.props;
        onShowAlert("");
        let errorMsg = "";

        if (!bookingData[0].room_id) {
            $("#emptyRoomsListDropdown").addClass("danger");
            errorMsg += t("error.noChosenRoom");
        }

        if (!bookingData[0].room_name) {
            $("#roomReqName").addClass("danger");
            errorMsg += t("error.emptyRoomName");
        }

        if (!bookingData[0].room_keeper_part_id) {
            $("#roomKeepeersDropdown").addClass("danger");
            errorMsg += t("error.noChosenKeeper");
        }

        if (!selectedParticipants || selectedParticipants.length === 0) {
            $("#participantsToRoomBookingDropdown").addClass("danger");
            errorMsg += t("error.atLeastOnePartToReserv");
        }

        if (errorMsg) onShowAlert({ variant: "danger", message: errorMsg });
        else {
            const passData = {
                eventCode: activeEvent.code,
                bookingData: bookingData[0],
                selectedParticipants: selectedParticipants,
                roomRequestID: currentPage.params.roomRequest ? currentPage.params.roomRequest.room_req_id : null,
                roomBookingID: currentPage.params.roomBookingID ? currentPage.params.roomBookingID : null,
            };
            //console.log(passData);
            this.bookRoom(passData);
        }
    };

    handleChangeBookingData = (name, value) => {
        const { bookingData } = this.state;
        const list = [...bookingData];
        list[0][name] = value;
        this.props.onChangeValuesInFormes();
        this.setState({ bookingData: list, changedValues: true }, () => {
            if (bookingData[0].room_id) this.getRoomDetails(bookingData[0].room_id);
            else this.setState({ roomDetails: null });
        });
    };

    bookRoom = async (passData) => {
        const { fetchURL, onShowAlert, onChangeNavAndPage, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.booking") });
        try {
            const response = await fetch(`${fetchURL}/book-room`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                        onChangeNavAndPage({ nav: "rooms", subpage: "roomsRequestsList", params: "" }, null);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    getRoomDetails = async (roomID) => {
        const { fetchURL, onShowAlert, activeEvent, t } = this.props;
        const passData = {
            roomID: roomID,
            activeEvent: activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/room-details`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const roomDetails = fetchedData.roomDetails;
                        this.setState({ roomDetails: roomDetails, loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    bookRoomView = async () => {
        const { fetchURL, onShowAlert, t } = this.props;

        try {
            const response = await fetch(`${fetchURL}/book-room-view`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    getGroupMembers = async (groupID) => {
        const { fetchURL, onShowAlert, activeEvent, t } = this.props;
        const passData = {
            groupID: groupID,
            activeEvent: activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/group-members`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        const membersList = [];
                        fetchedData.groupMembers.forEach((e) => {
                            if (e.part_affiliation_verified === "Y") membersList.push(e.part_id); // tylko zweryfikowani uczestnicy danej grupy
                        });
                        this.setState({ selectedParticipants: membersList, loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleDownloadData = () => {
        const { currentPage } = this.props;
        this.bookRoomView();
        if (currentPage.params.roomRequest && currentPage.params.roomRequest.part_group_id) this.getGroupMembers(currentPage.params.roomRequest.part_group_id);
        else this.setState({ selectedParticipants: [] });

        if (currentPage.params.roomID) this.getRoomDetails(currentPage.params.roomID);
    };

    componentDidMount() {
        const { onResizeWindow } = this.props;
        onResizeWindow("basic");

        $("#uploadFile").on("change", function () {
            $("span").text($(this).val());
        });

        this.handleDownloadData();
    }

    componentDidUpdate() {
        const { loading } = this.state;
        const { onResizeWindow } = this.props;

        if ($(".scrolled-content").outerHeight() && !loading) onResizeWindow("basic");
        if (!loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { loading, changedValues, noPermission, bookingData, selectedParticipants, roomDetails } = this.state;
        const { onCancelChangeValuesInFormes, onBackToPreviousPage, activeEvent, fetchURL, onShowAlert, currentPage, t, onShowHomePage } = this.props;

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails
                                    t={t}
                                    header={t("room.bookRoom")}
                                    changedValues={changedValues}
                                    onChangeView={onBackToPreviousPage}
                                    onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                                />
                                <Col className="text-right">
                                    <Button variant="outline-primary" onClick={() => this.setState({ loading: true }, () => this.handleDownloadData())} className="btn-sync mr-3">
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : !activeEvent ? (
                            <div className="scrolled-content" style={{ display: "none" }}>
                                <Container className="py-3">
                                    <Row>
                                        <Col xs={12}>
                                            <Alert variant="danger">{t("room.noActiveEvent")}</Alert>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        ) : (
                            <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                                <div className="scrolled-content" style={{ display: "none" }}>
                                    <Container className="py-3">
                                        <Row>
                                            <Col xs={12}>
                                                <p className="h3 my-3">{t("room.roomData")}</p>
                                            </Col>
                                            <Col lg={4} xs={12}>
                                                <RoomKeepers
                                                    t={t}
                                                    onShowHomePage={onShowHomePage}
                                                    fetchURL={fetchURL}
                                                    onShowAlert={onShowAlert}
                                                    activeEvent={activeEvent}
                                                    defaultValue={bookingData[0].room_keeper_part_id}
                                                    value={bookingData[0].room_keeper_part_id}
                                                    onFullnameSelected={(value) => this.handleChangeBookingData("room_keeper_part_id", value)}
                                                    req
                                                    labelTxt={t("room.bookingsList.keeper")}
                                                    helper={t("room.unassigedKeeper")}
                                                />
                                                {bookingData[0].room_keeper_part_id && (
                                                    <Alert variant="info" className="alert-small">
                                                        <FontAwesomeIcon icon={faExclamation} className="mr-2" />
                                                        {t("room.dontForget")}
                                                    </Alert>
                                                )}
                                            </Col>
                                            <Col lg={4} xs={12}>
                                                <EmptyRooms
                                                    activeEvent={activeEvent}
                                                    fetchURL={fetchURL}
                                                    onShowAlert={onShowAlert}
                                                    onChangeRoomValue={(value) => {
                                                        this.handleChangeBookingData("room_id", value);
                                                    }}
                                                    value={bookingData[0].room_id}
                                                    req
                                                    disabled={currentPage.params.roomRequest ? false : true}
                                                    id="emptyRoomsListDropdown"
                                                    t={t}
                                                    onShowHomePage={onShowHomePage}
                                                />
                                                {roomDetails && (
                                                    <div className="room-details">
                                                        <p className="mb-2 font-weight-bold">{t("room.roomDetails")}</p>
                                                        <p className="mb-0">
                                                            {t("room.roomsList.building")}: {roomDetails[0].building_name}
                                                        </p>
                                                        <p className="mb-0">
                                                            {t("room.roomPlaceNumber")}:{" "}
                                                            {roomDetails[0].room_capacity ? roomDetails[0].room_capacity : <span className="opacity-4">{t("noInfo")}</span>}
                                                        </p>
                                                        <p className="mb-0">
                                                            {t("room.roomsList.size")}: {roomDetails[0].room_size_name}
                                                        </p>
                                                    </div>
                                                )}
                                            </Col>
                                            <Col lg={4} xs={12}>
                                                <TextForm
                                                    value={bookingData[0].room_name}
                                                    onChangeValue={(e) => this.handleChangeBookingData(e.target.name, e.target.value)}
                                                    req
                                                    labelTxt={t("room.setRoomName")}
                                                    placeholderTxt={t("room.typeRoomName")}
                                                    name="room_name"
                                                    id="roomReqName"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <p className="h3 my-3">{t("participant.participants")}</p>
                                            </Col>
                                            {bookingData[0].part_group_id && (
                                                <Col lg={4} xs={12}>
                                                    <TextForm value={bookingData[0].part_group_name} labelTxt={t("request.groupFromRequest")} readOnly />
                                                </Col>
                                            )}
                                        </Row>
                                        <Row>
                                            {selectedParticipants && (
                                                <Fragment>
                                                    <Col lg={8} xs={12}>
                                                        <ParticipantsToRoomBooking
                                                            fetchURL={fetchURL}
                                                            onShowAlert={onShowAlert}
                                                            onPartSelected={(partID) => {
                                                                this.setState({ selectedParticipants: partID, changedValues: true });
                                                                this.props.onChangeValuesInFormes();
                                                            }}
                                                            activeEvent={activeEvent}
                                                            selectedParticipants={selectedParticipants}
                                                            defaultValue={selectedParticipants}
                                                            labelTxt={t("room.assignPartsToRoom")}
                                                            req
                                                            helper={bookingData[0].part_group_id ? t("room.verifiedMembers") : null}
                                                            t={t}
                                                            onShowHomePage={onShowHomePage}
                                                        />
                                                    </Col>
                                                    {roomDetails && (
                                                        <Fragment>
                                                            {roomDetails[0].room_capacity && (
                                                                <Col lg={4} xs={12}>
                                                                    <Alert
                                                                        className="alert-small mt-lg-1 mt-0"
                                                                        variant={selectedParticipants.length <= roomDetails[0].room_capacity ? "info" : "warning"}
                                                                    >
                                                                        {selectedParticipants.length <= roomDetails[0].room_capacity ? (
                                                                            `${t("room.remainingPlaces")}: ${roomDetails[0].room_capacity - selectedParticipants.length}`
                                                                        ) : (
                                                                            <Fragment>
                                                                                <FontAwesomeIcon icon={faExclamation} className="mr-2" />
                                                                                {t("room.roomOverloaded")}
                                                                            </Fragment>
                                                                        )}
                                                                    </Alert>
                                                                </Col>
                                                            )}
                                                        </Fragment>
                                                    )}
                                                </Fragment>
                                            )}
                                        </Row>
                                    </Container>
                                </div>
                                <div className="content-bottom">
                                    <Container>
                                        <Row>
                                            <Col xs={12} className="py-3">
                                                {!changedValues ? (
                                                    <TooltipWithDisabledButton t={t} mssgTooltip={t("form.noChanges")} btnTxt={t("room.bookRoom")} tooltipID="tooltipDisabledNotFilled" size="lg" />
                                                ) : (
                                                    <Button type="submit" variant="primary" size="lg">
                                                        {t("room.bookRoom")}
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Form>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default BookRoom;
