import React, { Component, Fragment } from "react";
import AlertNoPermission from "../../alerts/AlertNoPermission";
import { Dropdown } from "semantic-ui-react";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

class ShopItemStatuses extends Component {
    state = {
        statusesList: null,
        noPermission: false,
    };

    getShopOrderItemStatuses = async () => {
        const { fetchURL, onShowAlert, t } = this.props;

        try {
            const response = await fetch(`${fetchURL}/shop-order-item-statuses`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ statusesList: fetchedData.shopOrderItemStatuses });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };
    componentDidMount() {
        this.getShopOrderItemStatuses();
    }

    render() {
        const { noPermission, statusesList } = this.state;
        const { onSelectStatus, defaultValue, t, disabled } = this.props;

        function selectedTxt(options, selectedValue) {
            const selectedOption = options.filter((e) => e.value === selectedValue);
            return selectedOption[0].text;
        }

        return (
            <Fragment>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : (
                    <Fragment>
                        {statusesList ? (
                            <Dropdown
                                defaultValue={defaultValue}
                                id="shopItemLimitation"
                                className="req"
                                placeholder={t("shop.clickToChooseStatus")}
                                fluid
                                selection
                                options={statusesList}
                                onChange={(event, data) => {
                                    onSelectStatus(data.value, selectedTxt(data.options, data.value));
                                }}
                                disabled={disabled}
                            />
                        ) : (
                            <LoadingProgressBar t={t} />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default ShopItemStatuses;
