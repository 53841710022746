import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { Form } from "react-bootstrap";
import pl from "date-fns/locale/pl";
import $ from "jquery";
registerLocale("pl", pl);

const RangeDateAndTime = (props) => {
    const { txtLabel, setStartDate, setEndDate, id, req, startDate, endDate, minDate, maxDate, disabled, rangeTime, t } = props;
    $(`#${id}`).on("click", function () {
        $(this).removeClass("danger");
    });

    return (
        <Form.Group>
            <Form.Label>
                {txtLabel} {req && <span className="required">*</span>}
            </Form.Label>
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText={t("calendar.selectStartDateTime")}
                dateFormat="yyyy-MM-dd HH:mm"
                locale="pl"
                className="datepicker mb-1"
                timeFormat="HH:mm"
                timeIntervals={rangeTime ? rangeTime : 5}
                id={`${id}_start`}
                timeCaption="Godzina"
                showTimeSelect
                minDate={minDate}
                maxDate={maxDate}
                disabled={disabled}
            />

            <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={minDate ? minDate : startDate}
                maxDate={maxDate}
                dateFormat="yyyy-MM-dd HH:mm"
                locale="pl"
                className="datepicker"
                timeFormat="HH:mm"
                timeIntervals={rangeTime ? rangeTime : 5}
                id={`${id}_end`}
                timeCaption="Godzina"
                showTimeSelect
                placeholderText={t("calendar.selectEndDateTime")}
                disabled={disabled}
            />
        </Form.Group>
    );
};

export default RangeDateAndTime;
