import React, { Fragment } from "react";
import ApplicationsList from "../../pages/applications/ApplicationsList";

const Applications = (props) => {
    const { fetchURL, onCheckUserPrivilage, currentPage, onResizeWindow, onShowAlert, onChangeNavAndPage, windowHeight, dataList, onGetDataList, numberOfRecords, onChangeNOR, t, onShowHomePage } =
        props;
    return (
        <Fragment>
            {currentPage.nav === "applications" && (
                <Fragment>
                    {currentPage.subpage === "applicationsList" && (
                        <ApplicationsList
                            fetchURL={fetchURL}
                            onResizeWindow={onResizeWindow}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            windowHeight={windowHeight}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onShowAlert={onShowAlert}
                            currentPage={currentPage}
                            dataList={dataList}
                            onGetDataList={onGetDataList}
                            numberOfRecords={numberOfRecords}
                            onChangeNOR={onChangeNOR}
                            eventFilterValue={props.eventFilterValue}
                            eventsList={props.eventsList}
                            onChangeEventFilter={props.onChangeEventFilter}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Applications;
