import React, { Fragment, Component } from "react";
import { Table, Button, OverlayTrigger, Tooltip, Modal, Form } from "react-bootstrap";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextareaForm from "../../forms/TextareaForm";
import { faFileAlt, faHotel, faPaperPlane, faTimesCircle, faComment } from "@fortawesome/free-solid-svg-icons";

class RoomsListTable extends Component {
    state = {
        currentSort: "default",
        sortingColName: "sortID",
        selectedRequestID: null,
        showChangeReqStatToInProgressModal: false,
        showChangeReqStatToRejectedModal: false,
        adminComment: "",
        showCommentModal: false,
        selectedComment: null,
    };

    handleChangeRequestStatus = async (status, comment) => {
        const { fetchURL, onShowAlert, onDownloadData, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.changingReqStatus") });
        const passData = {
            requestStatus: status,
            adminComment: comment,
            selectedRequestID: this.state.selectedRequestID,
        };
        try {
            const response = await fetch(`${fetchURL}/change-request-status`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                        this.setState({ showChangeRequestStatusModal: false, selectedRequestID: null });
                        onDownloadData();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleSorting = (btnID) => {
        const { currentSort } = this.state;
        let nextSort;
        $(".sort-icon").removeClass("fa-sort-down").removeClass("fa-sort-up").removeClass("fa-sort").addClass("fa-sort");
        if (currentSort === "down") {
            nextSort = "up";
            $("#" + btnID + " .fas")
                .removeClass("fa-sort-down")
                .addClass("fa-sort-up");
        } else if (currentSort === "up") {
            nextSort = "default";
            $("#" + btnID + " .fas")
                .removeClass("fa-sort-up")
                .addClass("fa-sort");
        } else if (currentSort === "default") {
            nextSort = "down";
            $("#" + btnID + " .fas")
                .removeClass("fa-sort")
                .addClass("fa-sort-down");
        }

        this.setState({
            currentSort: nextSort,
            sortingColName: btnID,
        });
    };

    handleCheckValidation = (e) => {
        const { adminComment } = this.state;
        const { onShowAlert, t } = this.props;
        onShowAlert("");
        let errorMssg = "";

        if (!adminComment) {
            errorMssg += t("error.emptyAdminComment");
            $("#reqStatusAdminComment").addClass("danger");
        } else if (adminComment.length > 255) {
            errorMssg += t("error.adminCommentTooLong");
            $("#reqStatusAdminComment").addClass("danger");
        }

        if (errorMssg) {
            onShowAlert({ variant: "danger", message: errorMssg });
        } else {
            this.handleChangeRequestStatus("REJECTED", adminComment);
        }
    };

    render() {
        const { currentSort, sortingColName, showChangeReqStatToInProgressModal, showChangeReqStatToRejectedModal, showCommentModal } = this.state;
        const { roomsRequestsList, displayNOR, onChangeNavAndPage, currentPageParams, t, activeEvent } = this.props;

        const sortTypes = {
            up: {
                fnID: (a, b) => a.room_ident_number - b.room_ident_number, //metoda sortowania (patrz: https://www.w3schools.com/jsref/jsref_sort.asp )

                fnRoomCode: (a, b) => {
                    if (a.room_code.toUpperCase() > b.room_code.toUpperCase()) return 1;
                    else return -1;
                },
            },
            down: {
                fnID: (a, b) => b.room_ident_number - a.room_ident_number,

                fnRoomCode: (a, b) => {
                    if (a.room_code.toUpperCase() > b.room_code.toUpperCase()) return -1;
                    else return 1;
                },
            },
            default: {
                fnID: (a, b) => a,
                fnRoomCode: (a, b) => a,
            },
        };

        //sortowanie listy
        let sortedData = [];
        if (sortingColName === "sortID") sortedData = roomsRequestsList.sort(sortTypes[currentSort].fnID);
        else if (sortingColName === "sortRoomCode") sortedData = roomsRequestsList.sort(sortTypes[currentSort].fnRoomCode);
        else sortedData = roomsRequestsList.sort(sortTypes[currentSort].fnID);

        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th>{t("on")}</th>
                            <th>{t("request.requestsList.roomName")}</th>
                            <th>{t("request.requestsList.group")}</th>
                            <th>{t("request.requestsList.requester")}</th>
                            <th>{t("request.requestsList.roomSize")}</th>
                            <th>{t("request.requestsList.comment")}</th>
                            <th>{t("request.requestsList.date")}</th>
                            <th>{t("request.requestsList.status")}</th>

                            {activeEvent && <th style={{ width: "200px", minWidth: "170px" }}>{t("dropdowns.actions")}</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData
                            .map((p, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{p.room_req_name}</td>
                                    <td>{p.part_group_name ? p.part_group_name : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{p.user_fullname ? p.user_fullname : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{p.room_size_name}</td>
                                    <td>{p.room_req_comment ? <i>{p.room_req_comment}</i> : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{p.create_date}</td>
                                    <td>
                                        <span className={`${p.room_req_stat_name === "RESERVED" ? "text-success" : p.room_req_stat_name === "REJECTED" ? "text-danger" : ""}`}>
                                            {p.room_req_stat_name}
                                        </span>
                                    </td>
                                    {activeEvent && (
                                        <td className="text-center">
                                            {activeEvent.code === p.event_code && (
                                                <Fragment>
                                                    {p.room_req_stat_code === "NEW" && (
                                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`changeRequestStatusTooltip${i}`}>{t("request.changeStatusInProgress")}</Tooltip>}>
                                                            <span className="d-inline-block">
                                                                <Button
                                                                    variant="outline-primary"
                                                                    className="mx-1"
                                                                    onClick={() => this.setState({ showChangeReqStatToInProgressModal: true, selectedRequestID: p.room_req_id })}
                                                                >
                                                                    <FontAwesomeIcon icon={faPaperPlane} />
                                                                </Button>
                                                            </span>
                                                        </OverlayTrigger>
                                                    )}

                                                    {p.room_req_stat_code === "RESERVED" ? (
                                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`roomBookingTooltip${i}`}>{t("request.seeBookingDetails")}</Tooltip>}>
                                                            <span className="d-inline-block">
                                                                <Button
                                                                    variant="outline-primary"
                                                                    className="mx-1"
                                                                    onClick={() =>
                                                                        onChangeNavAndPage(
                                                                            {
                                                                                nav: "rooms",
                                                                                subpage: "roomBookingDetails",
                                                                                params: { roomID: p.room_id, roomBookingID: p.room_booking_id, roomRequest: p },
                                                                            },
                                                                            currentPageParams
                                                                        )
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon icon={faFileAlt} />
                                                                </Button>
                                                            </span>
                                                        </OverlayTrigger>
                                                    ) : (
                                                        p.room_req_stat_code !== "REJECTED" && (
                                                            <Fragment>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip id={`bookRoomTooltip${i}`}>{t("request.considerRequest")}</Tooltip>}>
                                                                    <span className="d-inline-block">
                                                                        <Button
                                                                            variant="outline-primary"
                                                                            className="mx-1"
                                                                            onClick={() => onChangeNavAndPage({ nav: "rooms", subpage: "bookRoom", params: { roomRequest: p } }, currentPageParams)}
                                                                        >
                                                                            <FontAwesomeIcon icon={faHotel} />
                                                                        </Button>
                                                                    </span>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip id={`rejectRequestTooltip${i}`}>{t("request.reject")}</Tooltip>}>
                                                                    <span className="d-inline-block">
                                                                        <Button
                                                                            variant="outline-primary"
                                                                            className="mx-1"
                                                                            onClick={() => this.setState({ showChangeReqStatToRejectedModal: true, selectedRequestID: p.room_req_id })}
                                                                        >
                                                                            <FontAwesomeIcon icon={faTimesCircle} />
                                                                        </Button>
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </Fragment>
                                                        )
                                                    )}
                                                    {p.room_req_stat_code === "REJECTED" && (
                                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`rejectedRequestToolip${i}`}>{t("request.seeComment")}</Tooltip>}>
                                                            <span className="d-inline-block">
                                                                <Button
                                                                    variant="outline-primary"
                                                                    className="mx-1"
                                                                    onClick={() => this.setState({ showCommentModal: true, selectedComment: p.room_accr_comment })}
                                                                >
                                                                    <FontAwesomeIcon icon={faComment} />
                                                                </Button>
                                                            </span>
                                                        </OverlayTrigger>
                                                    )}
                                                </Fragment>
                                            )}
                                        </td>
                                    )}
                                </tr>
                            ))
                            .slice(0, displayNOR)}
                    </tbody>
                </Table>

                {showCommentModal && (
                    <Modal show={showCommentModal} onHide={() => this.setState({ showCommentModal: false, selectedComment: null })}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t("request.reqCommentModalTitle")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{this.state.selectedComment}</Modal.Body>
                    </Modal>
                )}

                {showChangeReqStatToInProgressModal && (
                    <Modal show={showChangeReqStatToInProgressModal} onHide={() => this.setState({ showChangeReqStatToInProgressModal: false, selectedRequestID: null })}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t("changeReqStatToInProgressModal.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{t("changeReqStatToInProgressModal.text")}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary" type="button" onClick={() => this.setState({ showChangeReqStatToInProgressModal: false, selectedRequestID: null })}>
                                {t("changeReqStatToInProgressModal.no")}
                            </Button>
                            <Button variant="primary" type="button" onClick={() => this.handleChangeRequestStatus("INPROGRESS", null)}>
                                {t("changeReqStatToInProgressModal.yes")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}

                {showChangeReqStatToRejectedModal && (
                    <Modal show={showChangeReqStatToRejectedModal} onHide={() => this.setState({ showChangeReqStatToRejectedModal: false, selectedRequestID: null })}>
                        <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                            <Modal.Header closeButton>
                                <Modal.Title>{t("changeReqStatToRejectedModal.title")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>{t("changeReqStatToRejectedModal.text")}</p>
                                <TextareaForm
                                    value={this.state.adminComment}
                                    onChangeValue={(e) => {
                                        $("#reqStatusAdminComment").removeClass("danger");
                                        this.setState({ adminComment: e.target.value });
                                    }}
                                    labelTxt={t("changeReqStatToRejectedModal.commentLabel")}
                                    name="admin_comment"
                                    req
                                    placeholderTxt={t("changeReqStatToRejectedModal.commentPlaceholder")}
                                    id="reqStatusAdminComment"
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" type="button" onClick={() => this.setState({ showChangeReqStatToRejectedModal: false, selectedRequestID: null })}>
                                    {t("changeReqStatToRejectedModal.no")}
                                </Button>
                                <Button variant="primary" type="submit">
                                    {t("changeReqStatToRejectedModal.yes")}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default RoomsListTable;
