import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

const UsersParticipants = (props) => {
    const { t, partList, onChangeNavAndPage, currentPageParams } = props;
    return (
        <Row>
            <Col>
                <p className="h5 mb-3">{t("user.assignedParts")}</p>
                {!partList ? (
                    <LoadingProgressBar t={t} />
                ) : partList.length === 0 ? (
                    <span className="opacity-4">{t("user.noAssignedParts")}</span>
                ) : (
                    <Table bordered hover size="sm" className="table-list mb-3">
                        <thead>
                            <tr>
                                <th>{t("event.event")}</th>
                                <th>{t("participant.partIdent")}</th>
                                <th>{t("participant.eventStatus")}</th>
                                <th>{t("participant.labels")}</th>
                                <th>{t("group.group")}</th>
                                <th style={{ width: "150px" }}>{t("dropdowns.actions")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {partList.map((e, i) => (
                                <tr key={i}>
                                    <td>{e.event_name}</td>
                                    <td>{e.part_ident_number ? e.part_ident_number : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{e.part_stat_name}</td>
                                    <td>{e.part_labels_list ? e.part_labels_list : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{e.part_group_name ? e.part_group_name : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td className="text-center">
                                        <OverlayTrigger placement="top" overlay={<Tooltip id="partDetails">{t("participant.showPartDetails")}</Tooltip>}>
                                            <Button
                                                variant="outline-primary"
                                                className=""
                                                onClick={() =>
                                                    onChangeNavAndPage(
                                                        {
                                                            nav: "participants",
                                                            subpage: "participantDetails",
                                                            params: { partID: e.part_id, userID: e.user_id },
                                                        },
                                                        currentPageParams
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon icon={faEye} />
                                            </Button>
                                        </OverlayTrigger>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </Col>
        </Row>
    );
};

export default UsersParticipants;
