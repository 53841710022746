import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import TextForm from "../../forms/text-inputs/TextForm";
import $ from "jquery";

const ConstructionAvalPeriods = (props) => {
    const { avalPeriod, t, onChangeAvalPeriodValue } = props;

    function addPeriod() {
        const { avalPeriod, onChangeAvalPeriodValue } = props;
        const list = [...avalPeriod];
        list.push({ aval_period_date: "", aval_period_label: "" });
        onChangeAvalPeriodValue(list);
    }

    function changeValue(name, value, index) {
        const list = [...avalPeriod];
        list[index][name] = value;
        onChangeAvalPeriodValue(list);
    }

    function deletePeriod(i) {
        const list = [...avalPeriod];
        list.splice(i, 1);
        onChangeAvalPeriodValue(list);
    }

    return (
        <Fragment>
            <Col xs={12} lg={8}>
                <div className="border p-3">
                    <Row>
                        <Col xs={5}>
                            <Form.Label>
                                {t("settings.avalDate")} <span className="required">*</span>
                            </Form.Label>
                        </Col>
                        <Col xs={7}>
                            <Form.Label>{t("settings.avalPeriodLabel")}</Form.Label>
                        </Col>
                        {avalPeriod.map((e, i) => (
                            <Fragment key={i}>
                                <Col xs={5}>
                                    <TextForm
                                        value={e.aval_period_date}
                                        onChangeValue={(event) => {
                                            $(`#constrAvalPeriodDate${i}`).removeClass("danger");
                                            changeValue(event.target.name, event.target.value, i);
                                        }}
                                        placeholderTxt={t("settings.avalDate")}
                                        name="aval_period_date"
                                        id={`constrAvalPeriodDate${i}`}
                                        req
                                    />
                                </Col>
                                <Col xs={5}>
                                    <TextForm
                                        value={e.aval_period_label}
                                        onChangeValue={(event) => {
                                            $("#constrAvalPeriodLabel").removeClass("danger");
                                            changeValue(event.target.name, event.target.value, i);
                                        }}
                                        placeholderTxt={t("settings.avalPeriodLabelPlaceholder")}
                                        name="aval_period_label"
                                        id={`constrAvalPeriodLabel${i}`}
                                        req
                                    />
                                </Col>

                                <Col xs={2}>
                                    {i > 0 && (
                                        <Button variant="outline-secondary" onClick={() => deletePeriod(i)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    )}
                                </Col>
                            </Fragment>
                        ))}
                        <Col xs={12}>
                            <Button variant="outline-primary" size="sm" onClick={addPeriod}>
                                {t("settings.addNext")}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Fragment>
    );
};

export default ConstructionAvalPeriods;
