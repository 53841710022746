import React, { Fragment } from "react";
import { Col, Form, Row } from "react-bootstrap";
import CalendarDate from "../../../forms/datetimes/CalendarDate";
import TextareaForm from "../../../forms/TextareaForm";
import { Checkbox } from "semantic-ui-react";

const RestData = (props) => {
    const { participantData, onChangeCalendarDate, onChangeCheckboxValue, onChangePartData, t } = props;
    const part = participantData[0];
    return (
        <Fragment>
            <Row>
                <Col xs={12}>
                    <p className="h4 mt-4 mb-3 form-title">{t("participant.restData")}</p>
                </Col>
                <Col xs={12}>
                    <p className="h5 mt-3">{t("participant.payment")}</p>
                </Col>
            </Row>
            <Row>
                <Col md={4} xs={6}>
                    <Form.Group>
                        <Form.Label className="invisible">{t("participant.payment")}</Form.Label>
                        <Checkbox
                            label={t("participant.confirmPayment")}
                            onChange={(event, data) => onChangeCheckboxValue(event, data, "part_declar_money_transfer")}
                            checked={part.part_declar_money_transfer === "Y" ? true : false}
                        />
                    </Form.Group>
                </Col>
                {part.part_declar_money_transfer === "Y" && (
                    <Col md={4} xs={6}>
                        <CalendarDate t={t} txtLabel={t("participant.declareMTDate")} onChangeCalendarDate={onChangeCalendarDate} value={part.part_declar_mt_date} id={"part_declar_mt_date"} />
                    </Col>
                )}
            </Row>
            <Row>
                <Col xs={12}>
                    <p className="h5 my-3">{t("participant.agreements")}</p>
                </Col>
                <Col md={6} xs={12}>
                    <Form.Group>
                        <Checkbox
                            label={t("participant.legalAge")}
                            onChange={(event, data) => onChangeCheckboxValue(event, data, "part_declar_legal_age")}
                            checked={part.part_declar_legal_age === "Y" ? true : false}
                        />
                    </Form.Group>
                </Col>
                <Col md={6} xs={12}>
                    <Form.Group>
                        <Checkbox
                            label={t("participant.regulations")}
                            onChange={(event, data) => onChangeCheckboxValue(event, data, "part_declar_tac_agreement")}
                            checked={part.part_declar_tac_agreement === "Y" ? true : false}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <p className="h5 my-3">{t("comments")}</p>
                </Col>
                <Col md={6} xs={12}>
                    <TextareaForm
                        value={part.part_comment_form}
                        name="part_comment_form"
                        labelTxt={t("participant.registrationCommentLabel")}
                        placeholderTxt={t("participant.registrationCommentPlaceholder")}
                        onChangeValue={onChangePartData}
                        id="partCommentForm"
                    />
                </Col>
                <Col md={6} xs={12}>
                    <TextareaForm
                        value={part.part_comment_org}
                        name="part_comment_org"
                        labelTxt={t("participant.partCommentLabel")}
                        placeholderTxt={t("participant.partCommentPlaceholder")}
                        onChangeValue={onChangePartData}
                        id="partCommentOrg"
                    />
                </Col>
            </Row>
        </Fragment>
    );
};

export default RestData;
