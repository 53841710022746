import React, { Fragment } from "react";
import AddEditActiveEvent from "../../pages/settings/AddEditActiveEvent";
import SettingsEdit from "../../pages/settings/SettingsEdit";
const Settings = (props) => {
    const {
        fetchURL,
        onCheckUserPrivilage,
        onChangeValuesInFormes,
        onCancelChangeValuesInFormes,
        scannerHeight,
        currentPage,
        windowHeight,
        onResizeWindow,
        activeEvent,
        onChangeNavAndPage,
        onBackToPreviousPage,
        reloadEventInfo,
        onShowAlert,
        t,
        onShowHomePage,
    } = props;
    return (
        <Fragment>
            {currentPage.nav === "settings" && (
                <Fragment>
                    {currentPage.subpage === "settingsEdit" && (
                        <SettingsEdit
                            fetchURL={fetchURL}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            scannerHeight={scannerHeight}
                            currentPage={currentPage}
                            windowHeight={windowHeight}
                            onResizeWindow={onResizeWindow}
                            activeEvent={activeEvent}
                            onChangeNavAndPage={onChangeNavAndPage}
                            reloadEventInfo={reloadEventInfo}
                            onShowAlert={onShowAlert}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {(currentPage.subpage === "addActiveEvent" || currentPage.subpage === "editActiveEvent") && (
                        <AddEditActiveEvent
                            fetchURL={fetchURL}
                            reloadEventInfo={reloadEventInfo}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            scannerHeight={scannerHeight}
                            currentPage={currentPage}
                            windowHeight={windowHeight}
                            onResizeWindow={onResizeWindow}
                            activeEvent={activeEvent}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onBackToPreviousPage={onBackToPreviousPage}
                            onShowAlert={onShowAlert}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Settings;
