import React, { Fragment, Component } from "react";
import { Button, Table } from "react-bootstrap";

class LogsListTable extends Component {
    render() {
        const { logsList, displayNOR, onChangeNavAndPage, currentPageParams, t } = this.props;

        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th>{t("on")}</th>
                            <th style={{ width: "170px" }}>{t("logs.logsList.date")}</th>
                            <th>{t("logs.logsList.maker")}</th>
                            <th>{t("logs.logsList.descr")}</th>
                            <th>{t("logs.logsList.receiver")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logsList
                            .map((p, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{p.create_date}</td>
                                    <td>
                                        <Button variant="link" onClick={() => onChangeNavAndPage({ nav: "users", subpage: "userDetails", params: { userID: p.creator_id } }, currentPageParams)}>
                                            {p.creator_fullname}
                                        </Button>
                                    </td>
                                    <td>{p.descr}</td>
                                    <td>
                                        {p.user_fullname ? (
                                            <Button
                                                variant="link"
                                                onClick={() =>
                                                    onChangeNavAndPage(
                                                        p.type === "PART"
                                                            ? { nav: "participants", subpage: "participantDetails", params: { partID: p.part_id, userID: p.user_id } }
                                                            : { nav: "users", subpage: "userDetails", params: { userID: p.user_id }, currentPageParams },
                                                        currentPageParams
                                                    )
                                                }
                                            >
                                                {p.user_fullname}
                                            </Button>
                                        ) : (
                                            <span className="opacity-4">{t("lack")}</span>
                                        )}
                                    </td>
                                </tr>
                            ))
                            .slice(0, displayNOR)}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}

export default LogsListTable;
