import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";

class IdentType extends Component {
    state = {
        identTypes: [
            {
                value: "QR",
                text: this.props.t("ident.qrCode"),
            },
            {
                value: "RFID",
                text: "RFID",
                disabled: true,
            },
        ],
    };
    render() {
        const { identTypes } = this.state;
        const { onIdentTypeSelected, defaultValue, t } = this.props;

        return (
            <Fragment>
                <Form.Label>
                    {t("ident.identType")} <span className="required">*</span>
                </Form.Label>

                {defaultValue && (
                    <Dropdown
                        defaultValue={defaultValue}
                        id="identTypeDropdown"
                        className="req mb-3"
                        placeholder={t("form.choose")}
                        fluid
                        selection
                        options={identTypes}
                        onChange={(event, { value }) => onIdentTypeSelected(event, value)}
                    />
                )}
            </Fragment>
        );
    }
}

export default IdentType;
