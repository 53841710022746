import React, { Fragment } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import moment from "moment-timezone";

const QRCodeDetailsModal = (props) => {
    const { onClose, details, t, currentPageParams, onChangeNavAndPage } = props;
    console.log(details);
    return (
        <Modal show={true} onHide={() => onClose()}>
            <Modal.Header closeButton>
                <Modal.Title>{t("ident.qrCodeDetails")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {t("numberOfOccurrencesInDb")}: {details.length}
                </p>
                {details.map((e, i) => (
                    <Table key={i} responsive bordered hover className="table-details">
                        <tbody>
                            <tr>
                                <td>{t("ident.identID")}</td>
                                <td>{e.ident_id}</td>
                            </tr>
                            <tr>
                                <td>{t("ident.identObject")}</td>
                                <td>{t(`ident.objects.${e.ident_object}`)}</td>
                            </tr>
                            <tr>
                                <td>{t("ident.identActivity")}</td>
                                <td>{e.ident_active === "Y" ? t("active") : e.ident_active === "N" ? t("inactive") : <span className="opacity-4">{t("na")}</span>}</td>
                            </tr>
                            <tr>
                                <td>{t("ident.assignedTo")}</td>
                                <td>
                                    {e.object_id ? (
                                        <Fragment>
                                            <b>
                                                <Button
                                                    variant="link"
                                                    onClick={() =>
                                                        onChangeNavAndPage(
                                                            e.ident_object === "USER"
                                                                ? { nav: "users", subpage: "userDetails", params: { userID: e.object_id } }
                                                                : e.ident_object === "PART"
                                                                ? {
                                                                      nav: "participants",
                                                                      subpage: "participantDetails",
                                                                      params: { userID: e.user_id, partID: e.object_id },
                                                                  }
                                                                : null,
                                                            currentPageParams
                                                        )
                                                    }
                                                >
                                                    {e.object_name}
                                                </Button>
                                            </b>
                                        </Fragment>
                                    ) : (
                                        <span className="opacity-4">{t("ident.noAssignment")}</span>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>{t("ident.assignedBy")}</td>
                                <td>{e.auth_user_name}</td>
                            </tr>
                            <tr>
                                <td>{t("ident.assignDate")}</td>
                                <td>
                                    {moment(e.create_date)
                                        .format()
                                        .replace(/T/, " ")
                                        .replace(/\+(.*)/, "")}
                                </td>
                            </tr>
                            {details.ident_object === "PART" && (
                                <tr>
                                    <td>{t("ident.validFor")}</td>
                                    <td>{e.event_name}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                ))}
            </Modal.Body>
        </Modal>
    );
};

export default QRCodeDetailsModal;
