import React, { Fragment } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import LoadingProgressBar from "../spinners/LoadingProgressBar";

const SendMailToChosenUsers = (props) => {
    const { showModal, onCloseModal, tmplName, usersEmails, onSelectEmails, onSend, t } = props;

    return (
        <Fragment>
            <Modal show={showModal} onHide={onCloseModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title className="h5">
                        {t("sendMailToChosenUsersModal.title1")}
                        {tmplName}
                        {t("sendMailToChosenUsersModal.title2")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>{t("sendMailToChosenUsersModal.label")}</Form.Label>
                    {!usersEmails ? (
                        <LoadingProgressBar t={t} />
                    ) : (
                        <Fragment>
                            {usersEmails.length > 0 ? (
                                <Dropdown
                                    id="usersEmailsDropdown"
                                    className="mb-3"
                                    placeholder="Kliknij, aby wybrać adresy"
                                    fluid
                                    multiple
                                    search
                                    selection
                                    options={usersEmails}
                                    onChange={onSelectEmails}
                                />
                            ) : (
                                <p className="opacity-4">{t("sendMailToChosenUsersModal.noUsers")}</p>
                            )}
                        </Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" type="button" onClick={onCloseModal}>
                        {t("cancel")}
                    </Button>
                    <Button variant="primary" type="button" onClick={onSend}>
                        {t("send")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default SendMailToChosenUsers;
