import React from "react";
import { Fragment } from "react";
import { Form, Radio } from "semantic-ui-react";

const PaymentMethods = (props) => {
    const { name, onChangeValue, paymentMethod, t } = props;
    return (
        <Fragment>
            <Form.Field>
                <Radio label={t("payment.method.cash")} name={name} value="CASH" checked={paymentMethod === "CASH"} onChange={onChangeValue} />
            </Form.Field>
            <Form.Field>
                <Radio label={t("payment.method.moneyTransfer")} name={name} value="MTRF" checked={paymentMethod === "MTRF"} onChange={onChangeValue} />
            </Form.Field>
        </Fragment>
    );
};

export default PaymentMethods;
