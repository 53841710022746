import React, { Fragment, Component } from "react";
import { Row, Col, Button, Form, Container } from "react-bootstrap";
import TooltipWithDisabledButton from "../../tooltips/TooltipWithDisabledButton";
import NavDetails from "../../navigation/NavDetails";
import $ from "jquery";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import Email from "../../forms/Email";
import UserStatuses from "../../forms/dropdowns/UserStatuses";
import RolesAndEvents from "../../forms/dropdowns/RolesAndEvents";
import TextForm from "../../forms/text-inputs/TextForm";
import Phone from "../../forms/text-inputs/Phone";
import TextareaForm from "../../forms/TextareaForm";
import ConfirmDeactivationModal from "../../modals/ConfirmDeactivationModal";
import ScanQRCodeForm from "../../forms/ScanQRCodeForm";
import NoPermission from "../../other/NoPermission";

class EditUser extends Component {
    state = {
        userRoles: [
            {
                roleCode: "USER",
                roleEvent: "permanent",
                roleID: 3,
                roleActivity: "Y",
            },
        ],
        userDetails: [
            {
                user_email: "",
                user_name: "",
                user_surname: "",
                user_nickname: "",
                user_phone: "",
                user_facebook_link: "",
                user_medical_info: "",
                user_comment: "",
                user_stat_code: "NEW",
                user_qr_code: null,
            },
        ],

        changedValues: false,
        loggedUserRole: "",

        showScanner: false,
        rolesList: "",
        statusesList: "",
        loading: true,
        eventsList: "",
        fetchedUserRoles: null,
        showConfirmDeactivationModal: false,
        confirmDeactivation: false,
        rowRoleID: 1,
        noPermission: false,
    };

    //walidacja poprawności linka do profilu na Facebooku
    validateFbLink = (fb) => {
        const regExp = /^https:\/\/www.facebook.com\/.+/;
        const regExp2 = /^https:\/\/facebook.com\/.+/;

        if (regExp.test(fb) || regExp2.test(fb)) {
            return "";
        } else {
            $("#userFbLink").addClass("danger");
            return this.props.t("error.invalidFbLink");
        }
    };

    //walidacja poprawności maila
    validateEmail = (email) => {
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            $("#userEmail").addClass("danger");
            return this.props.t("error.invalidEmail");
        } else return "";
    };

    validateRoles = (userRoles) => {
        const { t } = this.props;
        let errorRole = false;
        let errorEvent = false;
        let errorMssg = "";
        let allRolesInactive = true;
        userRoles.forEach((el, i) => {
            if (el.roleCode === "default") {
                $(`#roleCode${i}`).addClass("danger");
                errorRole = true;
            }
            if (el.roleEvent === "default") {
                $(`#roleEvent${i}`).addClass("danger");
                errorEvent = true;
            }
            if (el.roleActivity === "Y") allRolesInactive = false;
        });

        if (errorRole) errorMssg += t("error.invalidRole");
        if (errorEvent) errorMssg += t("error.invalidLimitRoles");
        if (allRolesInactive) errorMssg += t("error.userHasToHaveActiveRole");
        return errorMssg;
    };

    handleUserDetailsValue = (e) => {
        const { onChangeValuesInFormes } = this.props;
        const { name, value, id } = e.target;
        const { userDetails } = this.state;
        const data = [...userDetails];
        data[0][name] = value;
        this.setState({ userDetails: data, changedValues: true });
        $(`#${id}`).removeClass("danger");
        onChangeValuesInFormes();
    };

    handleChangeRolesAndEventsValue = (name, value, id, index) => {
        //zmiana danych w formularzu - role użytkownika
        const { onChangeValuesInFormes } = this.props;
        const { userRoles } = this.state;
        const list = [...userRoles];
        list[index][name] = value;
        this.setState({ userRoles: list, changedValues: true });
        onChangeValuesInFormes();
        $(`#${id}`).removeClass("danger");
    };

    handleAddRoleSelects = () => {
        const { userRoles } = this.state;
        this.setState({
            userRoles: [...userRoles, { roleCode: "default", roleEvent: "default", roleID: "", roleActivity: "Y" }],
        });
    };

    handleRemoveRoleSelects = (index) => {
        const { onChangeValuesInFormes } = this.props;
        const { userRoles } = this.state;
        const list = [...userRoles];
        list.splice(index, 1);
        this.setState({ userRoles: list, changedValues: true });
        onChangeValuesInFormes();
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { userDetails, userRoles } = this.state;
        const { onShowAlert, currentPage, t } = this.props;
        onShowAlert("");
        let errorMsg = "";

        $(".form-control.req").each(function () {
            if ($(this).val() === "" || $(this).val() === "default") {
                $(this).addClass("danger");
                errorMsg = t("error.reqFields");
            }
        });
        if (userDetails[0].user_email) errorMsg += this.validateEmail(userDetails[0].user_email);
        if (userDetails[0].user_facebook_link) errorMsg += this.validateFbLink(userDetails[0].user_facebook_link);
        errorMsg += this.validateRoles(userRoles);

        if (errorMsg) {
            onShowAlert({ variant: "danger", message: errorMsg });
        } else {
            const passData = {
                userDetails: userDetails[0],
                userRoles: userRoles,
                userID: currentPage.params.userID,
            };
            //console.log(passData);
            this.insertOrUpdateUser(passData);
        }
    };

    insertOrUpdateUser = async (passData) => {
        const { fetchURL, onCancelChangeValuesInFormes, onShowAlert, currentPage, onChangeNavAndPage, t } = this.props;
        onShowAlert({ variant: "info", message: currentPage.subpage === "editUser" ? t("info.editingUser") : t("info.addingUser") });

        try {
            const response = await fetch(`${fetchURL}/${currentPage.subpage === "editUser" ? "update-user" : currentPage.subpage === "addUser" ? "insert-user" : ""}`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                        onCancelChangeValuesInFormes();
                        onChangeNavAndPage({ nav: "users", subpage: "usersList", params: "" }, null);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    getUserData = async () => {
        const { fetchURL, currentPage, onShowAlert, t } = this.props;
        onShowAlert("");

        const passData = {
            userID: currentPage.params.userID,
        };
        try {
            const response = await fetch(`${fetchURL}/edit-user-view`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        this.setState({
                            userDetails: fetchedData.userDetails,
                            userRoles: fetchedData.userRoles,
                            loading: false,
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    addUserView = async () => {
        const { fetchURL, onShowAlert, t } = this.props;

        try {
            const response = await fetch(`${fetchURL}/add-user-view`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else this.setState({ loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleUserStatusValue = (event, { value }) => {
        const { userDetails } = this.state;
        const list = [...userDetails];
        list[0].user_stat_code = value;
        this.setState({ userDetails: list, changedValues: true });
        this.props.onChangeValuesInFormes();
    };

    handleChangeRoleActivity = (event, index) => {
        const { userRoles } = this.state;
        const list = [...userRoles];
        list[index].roleActivity = event.target.checked ? "Y" : "N";
        this.setState({ userRoles: list, changedValues: true });
        this.props.onChangeValuesInFormes();
    };

    componentDidMount() {
        const { onResizeWindow, currentPage } = this.props;
        if (currentPage.subpage === "editUser") this.getUserData();
        else this.addUserView();
        onResizeWindow("basic");
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if ($(".scrolled-content").outerHeight() && !this.state.loading) onResizeWindow("basic");
        if (!this.state.loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { changedValues, userRoles, userDetails, loading, showConfirmDeactivationModal, noPermission } = this.state;
        const { scannerHeight, userID, onCancelChangeValuesInFormes, fetchURL, onBackToPreviousPage, onShowAlert, currentPage, activeEvent, t, onShowHomePage } = this.props;

        return (
            <Fragment>
                <Container fluid className="nav-details">
                    <Row>
                        <NavDetails
                            t={t}
                            header={currentPage.subpage === "editUser" ? t("user.editUser") : currentPage.subpage === "addUser" ? t("user.addUser") : ""}
                            changedValues={changedValues}
                            onChangeView={onBackToPreviousPage}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                        />
                        <Col></Col>
                    </Row>
                </Container>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : loading ? (
                    <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                        <LoadingSpinnerInner />
                    </div>
                ) : (
                    <Form
                        onSubmit={
                            userDetails[0].user_stat_code === "DISABLED"
                                ? (e) => {
                                      e.preventDefault();
                                      this.setState({ showConfirmDeactivationModal: true });
                                  }
                                : this.handleCheckValidation
                        }
                    >
                        <div className="scrolled-content" style={{ display: "none" }}>
                            <Container className="py-3">
                                <Row>
                                    <Col xs={12}>
                                        <p className="h5 my-3 text-uppercase">{t("user.basicData")}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={4} xs={12}>
                                        <Email t={t} value={userDetails.map((u) => u.user_email)} onChangeValue={this.handleUserDetailsValue} name="user_email" id="userEmail" />
                                    </Col>
                                    <Col lg={4} xs={12}>
                                        <TextForm
                                            value={userDetails.map((u) => u.user_name)}
                                            onChangeValue={this.handleUserDetailsValue}
                                            req
                                            labelTxt={t("user.nameLabel")}
                                            placeholderTxt={t("user.namePlaceholder")}
                                            name="user_name"
                                            id="userName"
                                        />
                                    </Col>
                                    <Col lg={4} xs={12}>
                                        <TextForm
                                            value={userDetails.map((u) => u.user_surname)}
                                            onChangeValue={this.handleUserDetailsValue}
                                            req
                                            labelTxt={t("user.surnameLabel")}
                                            placeholderTxt={t("user.surnamePlaceholder")}
                                            name="user_surname"
                                            id="userSurname"
                                        />
                                    </Col>
                                    <Col lg={4} xs={12}>
                                        <UserStatuses
                                            userDetails={userDetails}
                                            onChangeStatus={this.handleUserStatusValue}
                                            fetchURL={fetchURL}
                                            req
                                            onShowAlert={onShowAlert}
                                            t={t}
                                            onShowHomePage={onShowHomePage}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <p className="h5 my-3 text-uppercase">{t("user.assignRoles")}</p>
                                    </Col>
                                    <Col xs={12}>
                                        <RolesAndEvents
                                            userRoles={userRoles}
                                            onDropdownValue={this.handleChangeRolesAndEventsValue}
                                            onRemoveRoleSelects={this.handleRemoveRoleSelects}
                                            onAddRoleSelects={this.handleAddRoleSelects}
                                            option={currentPage.subpage === "editUser" ? "edit" : "add"}
                                            onShowAlert={onShowAlert}
                                            fetchURL={fetchURL}
                                            activeEvent={activeEvent}
                                            onChangeRoleActivity={this.handleChangeRoleActivity}
                                            t={t}
                                            onShowHomePage={onShowHomePage}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <p className="h5 my-3 text-uppercase">{t("participant.restData")}</p>
                                    </Col>
                                    <Col lg={4} xs={12}>
                                        <TextForm
                                            value={userDetails.map((u) => (u.user_nickname ? u.user_nickname : ""))}
                                            onChangeValue={this.handleUserDetailsValue}
                                            labelTxt={t("user.nicknameLabel")}
                                            placeholderTxt={t("user.nicknamePlaceholder")}
                                            name="user_nickname"
                                            id="userNickname"
                                        />
                                    </Col>
                                    <Col lg={8} xs={12}>
                                        <ScanQRCodeForm
                                            scannerHeight={scannerHeight}
                                            onChangeQRCodeValue={(code) => {
                                                const { onChangeValuesInFormes } = this.props;
                                                const { userDetails } = this.state;
                                                const data = [...userDetails];
                                                data[0].user_qr_code = code;
                                                this.setState({ userDetails: data, changedValues: true });
                                                onChangeValuesInFormes();
                                            }}
                                            fetchURL={fetchURL}
                                            onShowAlert={onShowAlert}
                                            qrCode={userDetails[0].user_qr_code}
                                            onResetIdent={() => {
                                                const { onChangeValuesInFormes } = this.props;
                                                const { userDetails } = this.state;
                                                const data = [...userDetails];
                                                data[0].user_qr_code = null;
                                                this.setState({ userDetails: data, changedValues: true });
                                                onChangeValuesInFormes();
                                            }}
                                            t={t}
                                            onShowHomePage={onShowHomePage}
                                        />
                                    </Col>
                                    <Col lg={4} xs={12}>
                                        <Phone
                                            t={t}
                                            onShowHomePage={onShowHomePage}
                                            value={userDetails.map((u) => (u.user_phone ? u.user_phone : ""))}
                                            onChangeValue={this.handleUserDetailsValue}
                                            name="user_phone"
                                            id="userPhone"
                                        />
                                    </Col>
                                    <Col lg={8} xs={12}>
                                        <TextForm
                                            value={userDetails.map((u) => (u.user_facebook_link ? u.user_facebook_link : ""))}
                                            labelTxt={t("user.fbLabel")}
                                            placeholderTxt={t("user.fbPlaceholder")}
                                            name="user_facebook_link"
                                            id="userFbLink"
                                            onChangeValue={this.handleUserDetailsValue}
                                        />
                                    </Col>
                                    <Col lg={6} xs={12}>
                                        <TextareaForm
                                            value={userDetails.map((u) => (u.user_medical_info ? u.user_medical_info : ""))}
                                            onChangeValue={this.handleUserDetailsValue}
                                            labelTxt={t("user.medLabel")}
                                            placeholderTxt={t("user.medPlaceholder")}
                                            name="user_medical_info"
                                            id="userMedInfo"
                                        />
                                    </Col>
                                    <Col lg={6} xs={12}>
                                        <TextareaForm
                                            value={userDetails.map((u) => (u.user_comment ? u.user_comment : ""))}
                                            onChangeValue={this.handleUserDetailsValue}
                                            labelTxt={t("user.commentForAdminLabel")}
                                            name="user_comment"
                                            placeholderTxt={t("form.typeComment")}
                                            id="userAdminComment"
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="content-bottom">
                            <Container>
                                <Row>
                                    <Col xs={12} className="py-3">
                                        {!changedValues ? (
                                            <TooltipWithDisabledButton
                                                t={t}
                                                onShowHomePage={onShowHomePage}
                                                mssgTooltip={t("info.noChangesInForm")}
                                                btnTxt={currentPage.subpage === "editUser" ? t("saveChanges") : t("add")}
                                                tooltipID="tooltipDisabledNoChanges"
                                                size="md"
                                            />
                                        ) : (
                                            <Button type="submit" variant="primary" size="md">
                                                {currentPage.subpage === "editUser" ? t("saveChanges") : t("add")}
                                            </Button>
                                        )}
                                        <input type="hidden" name="id" value={userID} />
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Form>
                )}

                {showConfirmDeactivationModal && (
                    <ConfirmDeactivationModal
                        show={showConfirmDeactivationModal}
                        onClose={() => this.setState({ showConfirmDeactivationModal: false })}
                        userName={`${userDetails[0].user_name} ${userDetails[0].user_surname}`}
                        onDeactivateUser={this.handleCheckValidation}
                        t={t}
                        onShowHomePage={onShowHomePage}
                    />
                )}
            </Fragment>
        );
    }
}

export default EditUser;
