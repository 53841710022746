import React, { Component, Fragment } from "react";
import { Alert, Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import AlertNoPermission from "../alerts/AlertNoPermission";
import LoadingProgressBar from "../spinners/LoadingProgressBar";

class ApplicationDetailsModal extends Component {
    state = {
        loading: true,
        appDetails: null,
        avalDetails: null,
        noPermission: false,
        alert: null,
    };

    getApplicationData = async () => {
        const { fetchURL, onShowAlert, t, partAppData } = this.props;
        const passData = { partAppID: partAppData.appID, partAppType: partAppData.partAppType };
        try {
            const response = await fetch(`${fetchURL}/application-details`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "warning") this.setState({ loading: false, alert: { variant: "warning", message: t(`warning.${fetchedData.code}`) } });
                    else this.setState({ appDetails: fetchedData.appDetails, avalDetails: fetchedData.avalDetails, loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        this.getApplicationData();
    }

    render() {
        const { noPermission, alert, appDetails, loading, avalDetails } = this.state;
        const { show, t, onClose, partAppData } = this.props;

        const avalColumnCount = []; //pierwszy element w tablicy - nazwa labelki, drugi el. - ile razy występuje (rowSpan), trzeci el. - w którym momencie występuje

        if (avalDetails) {
            avalDetails.forEach((aval, i) => {
                const label = aval.aval_period_label;
                if (avalColumnCount.filter((e) => e[0] === label).length === 0) avalColumnCount.push([label, 1, i]);
                else
                    avalColumnCount.forEach((el) => {
                        if (el[0] === label) el[1] += 1;
                    });
            });
        }
        var labelIndex = 0;

        function insertRowSpanTD(e, i) {
            //i = current avalType index
            if (avalColumnCount[labelIndex]) {
                if (i === avalColumnCount[labelIndex][2]) {
                    const insert = (
                        <td rowSpan={avalColumnCount[labelIndex][1]} className="text-center">
                            {e.aval_period_label}
                            <br />
                            {e.aval_period_date}
                        </td>
                    );
                    labelIndex++;
                    return insert;
                }
            }
        }

        return (
            <Fragment>
                <Modal show={show} onHide={onClose} size="lg">
                    <Form onSubmit={this.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t("applications.appDetailsModal.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {noPermission ? (
                                <AlertNoPermission t={t} />
                            ) : alert ? (
                                <Alert variant={alert.variant}>{alert.message}</Alert>
                            ) : loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <Row>
                                    <Col xs={12}>
                                        <p className="mb-2">
                                            {t("applications.appDetailsModal.appType")}: {appDetails.type_name}
                                        </p>
                                        <p className="mb-2">
                                            {t("applications.appDetailsModal.appStat")}: {appDetails.stat_name}
                                        </p>
                                        {partAppData.partAppType === "CONSTR" && (
                                            <Fragment>
                                                <p className="mb-2">
                                                    {t("applications.appDetailsModal.skills")}: {appDetails.skills}
                                                </p>
                                                <p className="mb-2">
                                                    {t("applications.appDetailsModal.tools")}: {appDetails.tools}
                                                </p>
                                                <p className="mb-2">
                                                    {t("applications.appDetailsModal.arrivalDate")}: {appDetails.arrival_date}
                                                </p>
                                            </Fragment>
                                        )}
                                        {(partAppData.partAppType === "BAR" || partAppData.partAppType === "ACCR") && (
                                            <Fragment>
                                                <p className="mb-2">
                                                    {t("applications.appDetailsModal.numberOfDuties")}: {appDetails.number_of_duties}
                                                </p>
                                            </Fragment>
                                        )}
                                        <p className="mb-2">
                                            {t("applications.createDate")}: {appDetails.create_date}
                                        </p>
                                        <p className="mb-2">
                                            {t("applications.modifyDate")}: {appDetails.modify_date}
                                        </p>
                                        <p>
                                            {t("applications.appDetailsModal.comment")}: {appDetails.comment ? appDetails.comment : <span className="opacity-4">{t("lack")}</span>}
                                        </p>
                                    </Col>
                                    {avalDetails && (
                                        <Col xs={12}>
                                            <p className="h5 text-uppercase mt-5 mb-3">{t("applications.appDetailsModal.availability")}</p>
                                            <Table size="sm" bordered className="mb-3 aval-detail-table">
                                                <tbody>
                                                    {avalDetails.map((e, i) => (
                                                        <Fragment key={i}>
                                                            <tr>
                                                                {partAppData.partAppType === "BAR" || partAppData.partAppType === "ACCR" ? (
                                                                    <Fragment>
                                                                        {insertRowSpanTD(e, i)}
                                                                        <td className="text-center">
                                                                            {e.aval_period_start} - {e.aval_period_end}
                                                                        </td>
                                                                    </Fragment>
                                                                ) : (
                                                                    <td className="text-center">
                                                                        {e.aval_period_label}
                                                                        <br />
                                                                        {e.aval_period_date}
                                                                    </td>
                                                                )}

                                                                <td className="text-center">
                                                                    {e.answer === "Y" ? (
                                                                        <span className="text-success">{t("yes")}</span>
                                                                    ) : e.answer === "N" ? (
                                                                        <span className="text-danger">{t("no")}</span>
                                                                    ) : (
                                                                        <span className="text-warning">{t("maybe")}</span>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    )}
                                </Row>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary" type="button" onClick={onClose}>
                                {t("close")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default ApplicationDetailsModal;
