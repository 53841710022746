import React, { Component, Fragment } from "react";
import { Row, Col, Button, Form, Container } from "react-bootstrap";
import TooltipWithDisabledButton from "../../../tooltips/TooltipWithDisabledButton";
import $ from "jquery";
import NavDetails from "../../../navigation/NavDetails";
import LoadingSpinnerInner from "../../../spinners/LoadingSpinnerInner";
import ShowQRCode from "../../../other/ShowQRCode";
import NoPermission from "../../../other/NoPermission";
//import EventData from "./EventData";
import AccountData from "./AccountData";
import PartData from "./PartData";
import RestData from "./RestData";

class AddEditParticipant extends Component {
    state = {
        userID: this.props.currentPage.params.userID ? this.props.currentPage.params.userID : null,
        noPermission: false,
        participantData: [
            {
                event_code: this.props.activeEvent ? this.props.activeEvent.id : "",
                part_stat_code: "PRESENT",
                part_ident_number: "",
                part_ident_nickname: "",
                part_calculated_discount: "",
                part_verified_discount: "",
                part_verified_amount: 0,
                part_group_id: "",
                part_declar_mt_date: "",
                part_declar_money_transfer: "N",
                part_declar_legal_age: "N",
                part_declar_tac_agreement: "N",
                part_comment_form: "",
                part_arrival_date: new Date(),
                part_affiliation_verified: "",
            },
        ],
        userData: [
            {
                user_email: "",
                user_name: "",
                user_nickname: "",
                user_surname: "",
                user_phone: "",
                user_medical_info: "",
                user_comment: "",
                user_facebook_link: "",
                user_stat_code: "ADDED",
            },
        ],

        qrCode: "",
        userRoles: [
            {
                roleCode: "USER",
            },
        ],

        changedValues: false,
        rolesList: null,
        showScanner: false,
        rowRoleID: 1,
        eventsList: [],

        showOptionDataAccount: null,
        selectedLabels: [],
        alertAssignedUser: "",
        loading: true,
        incorrectVerifiedAmountAlert: "",
        alertAssignedUniquePartID: "",

        activeEvent: "",
    };

    //walidacja poprawności maila
    validateEmail = (email) => {
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            $("#participantEmail").addClass("danger");
            return this.props.t("error.invalidEmail");
        } else return "";
    };

    //walidacja poprawności linka do profilu na Facebooku
    validateFbLink = (fb) => {
        const regExp = /^https:\/\/www.facebook.com\/.+/;
        const regExp2 = /^https:\/\/facebook.com\/.+/;

        if (regExp.test(fb) || regExp2.test(fb)) {
            return "";
        } else {
            $("#participantFbLink").addClass("danger");
            return this.props.t("error.invalidFbLink");
        }
    };

    //walidacja ról użytkownika
    validateRoles = (userRoles) => {
        const { t } = this.props;
        let errorRole = false;
        let errorEvent = false;
        let errorMssg = "";
        userRoles.forEach((el, i) => {
            if (el.roleCode === "default") {
                $(`#roleCode${i}`).addClass("danger");
                errorRole = true;
            }
            if (el.roleEvent === "default") {
                $(`#roleEvent${i}`).addClass("danger");
                errorEvent = true;
            }
        });

        if (errorRole) errorMssg += t("error.invalidRole");
        if (errorEvent) errorMssg += t("error.invalidEditionRole");
        return errorMssg;
    };

    //sprawdzanie poprawności wypełnionego formularza
    handleCheckValidation = (e) => {
        e.preventDefault();
        const { participantData, userRoles, showOptionDataAccount, alertAssignedUser, userData, alertAssignedUniquePartID, userID, qrCode, selectedLabels } = this.state;
        const { activeEvent, onShowAlert, currentPage, t } = this.props;
        onShowAlert("");
        let errorMsg = "";

        if (!activeEvent) {
            //sprawdzenie czy został wybrany jakikolwiek nieaktywny event
            if (!participantData[0].event_code) {
                $("#inactiveEventDropdown").addClass("danger");
                errorMsg += t("error.noChosenActiveEvent");
            }
        }

        //sprawdzanie, czy został poprawnie wybrany lub utworzony użytkownik
        if (!userID) {
            if (showOptionDataAccount === "chooseExistingUser") {
                if (!this.state.userID) errorMsg += t("error.noChosenAnyUser");
                if (alertAssignedUser) errorMsg += t("error.userHasAssignedPart");
            } else if (showOptionDataAccount === "createNewAccount") {
                $(".form-control.req").each(function () {
                    if ($(this).val() === "" || $(this).val() === "default") {
                        $(this).addClass("danger");
                        errorMsg = t("error.reqFields");
                    } else $(this).removeClass("danger");
                });

                errorMsg += userData[0].user_email ? this.validateEmail(userData[0].user_email) : "";
                errorMsg += userData[0].user_facebook_link !== "" && userData[0].user_facebook_link !== null ? this.validateFbLink(userData[0].user_facebook_link) : "";
                errorMsg += this.validateRoles(userRoles);
            } else {
                $("#chooseUser").addClass("danger");
                errorMsg += t("error.chooseOrCreateUser");
            }
        }

        if (!participantData[0].part_ident_number) {
            errorMsg += t("error.identValueEmpty");
            $("#partIdentNumber").addClass("danger");
        }

        if (!participantData[0].part_ident_nickname) {
            errorMsg += t("error.participantNicknameEmpty");
            $("#participantNickname").addClass("danger");
        }

        //sprawdzenie, czy została wybrana/wpisana data przyjazdu jeśli uczestnik jest obecny
        if (participantData[0].part_stat_code === "PRESENT" && !participantData[0].part_arrival_date) {
            errorMsg += t("error.emptyArrivalDate");
            $("#part_arrival_date").addClass("danger");
        }

        //sprawdzanie, czy został wpisany poprawny numer identa
        if (alertAssignedUniquePartID) {
            errorMsg += t("error.invalidIdentValue");
            $("#partIdentNumber").addClass("danger");
        }

        //jeśli wystąpiły błędy w walidacji, wyświetl je
        if (errorMsg) {
            onShowAlert({ variant: "danger", message: errorMsg });
        } else {
            //jeśli została wybrana opcja "createNewAccount" - dodaj nowego użytkownika i uczestnika, jeśli nie - dodaj tylko uczestnika
            if (currentPage.subpage === "addParticipant" && showOptionDataAccount === "createNewAccount") {
                //dane nowo utworzonego użytkownika przekazywane do kontrolera
                const passData = {
                    userRoles: userRoles,
                    userDetails: userData[0],
                };
                this.insertUser(passData);
            } else if (currentPage.subpage === "addParticipant" && showOptionDataAccount === "chooseExistingUser") {
                const passData = {
                    participantData: participantData,
                    qrCode: qrCode,
                    selectedLabels: selectedLabels,
                    userID: userID,
                    activeEvent: this.props.activeEvent,
                };
                //console.log(passData);
                this.insertOrUpdateParticipant(passData);
            } else {
                //edycja
                const passData = {
                    participantData: participantData,
                    qrCode: qrCode,
                    selectedLabels: selectedLabels,
                    partID: currentPage.params.partID,
                    activeEvent: this.props.activeEvent,
                };
                //console.log(passData);
                this.insertOrUpdateParticipant(passData);
            }
        }
    };

    //dodawanie lub edycja uczestnika
    insertOrUpdateParticipant = async (passData) => {
        const { fetchURL, onCancelChangeValuesInFormes, onShowAlert, onChangeNavAndPage, currentPage, t } = this.props;
        //console.log("passData:", passData);
        if (!currentPage.params.partID && currentPage.subpage === "addParticipant") onShowAlert({ variant: "info", message: t("info.addingPart") });
        if (currentPage.params.partID && currentPage.subPage === "editParticipant") onShowAlert({ variant: "info", message: t("info.editingPart") });

        try {
            const response = await fetch(`${fetchURL}/${currentPage.subpage === "addParticipant" ? "insert-participant" : "update-participant"}`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission")
                        onShowAlert({
                            variant: "danger",
                            message: fetchedData.details ? `${t(`error.${fetchedData.code}`)}: ${t(`error.${fetchedData.details.slice(0, -2)}`)}.` : t(`error.${fetchedData.code}`),
                        });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onCancelChangeValuesInFormes();
                        onShowAlert({
                            variant: "success",
                            message:
                                currentPage.subpage === "addParticipant"
                                    ? this.state.userID
                                        ? t("participant.userAndPartAddedSuccessfully")
                                        : t("participant.partAddedSuccessfully")
                                    : t("participant.partEditedSuccessfully"),
                            fading: 2000,
                        });
                        if (currentPage.subpage === "addParticipant") onChangeNavAndPage({ nav: "participants", subpage: "participantsList", params: "" }, null);
                        else
                            onChangeNavAndPage(
                                {
                                    nav: "participants",
                                    subpage: "participantDetails",
                                    params: { userID: this.state.participantData[0].user_id, partID: currentPage.params.partID },
                                },
                                null
                            );
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    //dodawanie nowego użytkownika i później uczestnika
    insertUser = async (passData) => {
        const { participantData, qrCode, selectedLabels } = this.state;
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.addingNewUser") });

        try {
            const response = await fetch(`${fetchURL}/insert-user`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.noPermission`) });
                    if (fetchedData.status === false)
                        onShowAlert({
                            variant: "danger",
                            message: fetchedData.details ? `${t(`error.${fetchedData.code}`)}: ${fetchedData.details.slice(0, -2)}.` : t(`error.${fetchedData.code}`),
                        });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        //po poprawnym dodaniu nowego użytkownika dodaj uczestnika
                        const passPartData = {
                            participantData: participantData,
                            qrCode: qrCode,
                            selectedLabels: selectedLabels,
                            userID: fetchedData.passedID,
                            activeEvent: this.props.activeEvent,
                        };
                        //console.log(passPartData);
                        this.setState({ userID: fetchedData.passedID }, () => this.insertOrUpdateParticipant(passPartData));
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleScanQRCode = (code) => {
        const { onChangeValuesInFormes } = this.props;
        this.setState({ showScanner: false, changedValues: true });
        if (code) {
            this.setState({
                qrCode: code,
            });
        }
        onChangeValuesInFormes();
    };

    //zmiana danych w formularzu - dane użytkownika
    handleChangeUserData = (e) => {
        const { onChangeValuesInFormes } = this.props;
        const { name, value, id } = e.target;
        const { userData } = this.state;
        const list = [...userData];
        list[0][name] = value;
        this.setState({ userData: list, changedValues: true });
        onChangeValuesInFormes();
        $(`#${id}`).removeClass("danger");
    };

    //zmiana danych w formularzu - dane uczestnika
    handleChangePartData = (e) => {
        const { name, value, id } = e.target;
        this.handleChangeParticipantData(name, value);
        $(`#${id}`).removeClass("danger");
    };

    //zmiana danych w formularzu - status uczestnika
    handlePartStatSelected = (value) => {
        //console.log(value);
        this.handleChangeParticipantData("part_stat_code", value);
        if (value !== "PRESENT") {
            this.handleChangeParticipantData("part_arrival_date", "");
        }
    };

    //zmiana danych w formularzu - checkboxy
    handleChangeCheckbox = (e, data, name) => {
        let checkboxValue = "N";
        if (data.checked === true) checkboxValue = "Y";
        this.handleChangeParticipantData(name, checkboxValue);

        if (name === "part_declar_money_transfer") {
            let partDeclareDate = "";
            checkboxValue === "Y" ? (partDeclareDate = new Date()) : (partDeclareDate = "");
            this.handleChangeParticipantData("part_declar_mt_date", partDeclareDate);
        }
    };

    //sprawdzanie, czy wpisany numer identa jest unikalny per konwent
    checkUniquePartIdentNumber = async (partIdentNumber, eventCode) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            partIdentNumber: partIdentNumber,
            eventCode: eventCode,
        };
        try {
            const response = await fetch(`${fetchURL}/check-unique-part-ident-number`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === "assigned") this.setState({ alertAssignedUniquePartID: { variant: "danger", message: t(`error.${fetchedData.code}`) } });
                    else this.setState({ alertAssignedUniquePartID: "" });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    //sprawdzanie uprawnień do wejścia na widok
    addParticipantView = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/add-participant-view`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else this.setState({ loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleChangePartIdentNumber = (e) => {
        const { onChangeValuesInFormes, activeEvent, t } = this.props;
        const { participantData } = this.state;
        //console.log(activeEvent);
        const { value, name } = e.target;
        $("#partIdentNumber").removeClass("danger");
        const list = [...participantData];
        list[0][name] = value;
        this.setState({ changedValues: true, participantData: list }, () => {
            //sprawdzenie, czy format nr identa jest czterocyfrowy
            const re = /^\d{4}$/;
            if (!re.test(String(value).toLowerCase())) {
                this.setState({ alertAssignedUniquePartID: { variant: "danger", message: t("error.4digitIdent") } });
            } else this.checkUniquePartIdentNumber(value, activeEvent.code);
        });
        onChangeValuesInFormes();
    };

    //akutalizacja danych {t('from')} formularza w stanie participantData
    handleChangeParticipantData(name, data) {
        const { onChangeValuesInFormes } = this.props;
        const { participantData } = this.state;
        const list = [...participantData];
        list[0][name] = data;
        this.setState({ changedValues: true, participantData: list });
        onChangeValuesInFormes();
    }

    handleChangeRolesAndEventsValue = (name, value, id, index) => {
        //zmiana danych w formularzu - role użytkownika
        const { onChangeValuesInFormes } = this.props;
        const { userRoles } = this.state;
        const list = [...userRoles];
        list[index][name] = value;
        this.setState({ userRoles: list, changedValues: true });
        onChangeValuesInFormes();
        $(`#${id}`).removeClass("danger");
    };

    handleRemoveRoleSelects = (index) => {
        //usuń rolę użytkownika
        const { userRoles } = this.state;
        const list = [...userRoles];
        list.splice(index, 1);
        this.setState({ userRoles: list });
    };

    handleAddNewRole = () => {
        const { userRoles } = this.state;
        this.setState({
            userRoles: [
                ...userRoles,
                {
                    roleCode: "default",
                    roleEvent: "default",
                    roleID: "",
                },
            ],
        });
    };
    //zmiana danych w formularzu - wybór nieaktywnego wydarzenia
    /* handleInactiveEventSelected = (event, value) => {
        this.handleChangeParticipantData("event_code", value);
        const { participantData } = this.props;
        if (participantData[0].user_id) this.checkIfUserHasPart(participantData[0].user_id, participantData[0].event_code);
    }; */

    handlePartLabelSelected = (value, participantsLabels, editOnlyCalculatedDiscout) => {
        //zmiana danych w formularzu - etykiety uczestnika
        const selectedLabels = value;
        const allLabels = participantsLabels;
        const discountArray = [];
        selectedLabels.forEach((selLabel) => {
            allLabels.forEach((label) => {
                if (selLabel === label.value) {
                    discountArray.push(label.discount);
                }
            });
        });

        //algorytm obliczania zniżki
        //TO DO - w przyszłości do udoskonalenia (trzeba uwzględnić liczbę dyżurów dla barmanów/akredytacji itd.)
        let calcDiscount = 0; //wyliczona suma zniżek
        if (discountArray.length > 0) {
            discountArray.forEach((el) => {
                calcDiscount += el;
            });
        }

        if (editOnlyCalculatedDiscout) {
            const { participantData } = this.state;
            const list = [...participantData];
            list[0]["part_calculated_discount"] = calcDiscount;
            this.setState({ participantData: list });
        } else {
            this.handleChangeParticipantData("part_calculated_discount", calcDiscount);
            this.handleChangeParticipantData("part_verified_discount", calcDiscount > 100 ? 100 : calcDiscount);
            //wylicz cenę akredytacji na podstawie zaznaczonych etykiet i przypisanych do nich zniżek
            let priceAfterDiscout = (calcDiscount * this.props.activeEvent.event_price) / 100;
            priceAfterDiscout = this.props.activeEvent.event_price - priceAfterDiscout;
            if (priceAfterDiscout <= 0) priceAfterDiscout = 0;
            this.handleChangeParticipantData("part_verified_amount", priceAfterDiscout.toFixed(2));
        }

        this.setState({ selectedLabels: value });
    };

    handleChangeVerifiedDiscount = (e) => {
        const discount = e.target.value;
        let priceAfterDiscout = (discount * this.props.activeEvent.event_price) / 100;
        priceAfterDiscout = this.props.activeEvent.event_price - priceAfterDiscout;
        if (priceAfterDiscout <= 0) priceAfterDiscout = 0;
        this.handleChangeParticipantData("part_verified_discount", discount);
        this.handleChangeParticipantData("part_verified_amount", priceAfterDiscout.toFixed(2));
    };

    handleChangeVerifiedAmount = (e) => {
        //zmiana danych w formularzu - ostateczna wartość akredytacji
        let price = e.target.value;
        const reg = /^(\d+(\.|,)?\d{0,2})$/;
        if (!reg.test(price) && price !== "") this.setState({ incorrectVerifiedAmountAlert: this.props.t("error.invalidPriceValue") });
        else this.setState({ incorrectVerifiedAmountAlert: "" });
        this.handleChangeParticipantData("part_verified_amount", price);
    };

    //pobierz dane uczestnika
    getPartData = async (partID) => {
        const { fetchURL, onShowAlert, t } = this.props;

        onShowAlert("");
        const passData = {
            partID: partID,
        };

        try {
            const response = await fetch(`${fetchURL}/participant-details`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                        this.setState({ loading: false });
                    } else {
                        const participantData = fetchedData.participantData;

                        //parsowanie daty w postaci stringa na datę w postaci obiektu (tak, by react-datepicker mógł poprawnie tę wartość przeczytać)
                        const partDeclareDate = participantData[0].part_declar_mt_date;
                        const list = [...participantData];
                        if (list[0].part_declar_mt_date) list[0].part_declar_mt_date = new Date(partDeclareDate.substring(0, 4), partDeclareDate.substring(5, 7), partDeclareDate.substring(8, 10));
                        if (list[0].part_arrival_date) list[0].part_arrival_date = new Date(participantData[0].part_arrival_date);

                        this.setState({ participantData: list, qrCode: list[0].ident_value ? list[0].ident_value : "", userID: list[0].user_id }, () => this.getPartLabels(partID));
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    handleShowOptionDataAccount = (option) => {
        $("#chooseUser").removeClass("danger");
        this.setState({ showOptionDataAccount: option });
    };

    //akutalizacja danych {t('from')} formularza w stanie participantData
    changeDataInParticipantData(name, data) {
        const { onChangeValuesInFormes } = this.props;
        const { participantData } = this.state;
        const list = [...participantData];
        list[0][name] = data;
        this.setState({ changedValues: true, participantData: list });
        onChangeValuesInFormes();
    }

    //pobierz listę wszystkich etykiet
    getParticipantLabels = async (selectedLabels) => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/participants-labels`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.handlePartLabelSelected(selectedLabels, fetchedData.participantsLabels, true);
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    //lista etykiet przypisanych do uczestnika
    getPartLabels = async (partID) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            partID: partID,
        };

        try {
            const response = await fetch(`${fetchURL}/participant-labels`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                        this.setState({ loading: false });
                    } else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        this.setState({ selectedLabels: fetchedData.partLabels }, () => {
                            this.getParticipantLabels(fetchedData.partLabels);
                            this.setState({ loading: false });
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleSetDefaultIdentNumber = (value) => {
        const { participantData } = this.state;
        const list = [...participantData];
        list[0].part_ident_number = value;
        this.setState({ participantData: list });
    };

    handleResetIdent = () => {
        this.setState({ qrCode: null, changedValues: true });
        this.props.onChangeValuesInFormes();
    };

    handleChangeQRCodeValue = (code) => {
        this.setState({ qrCode: code, changedValues: true });
        this.props.onChangeValuesInFormes();
    };

    componentDidMount() {
        const { participantData } = this.state;
        const { onResizeWindow, activeEvent, currentPage, onShowAlert, t } = this.props;
        //jeśli istnieje aktywny event - pobierz jego cenę za akredytację (jeśli nie ma ustawionej ceny - wstaw 0 zł)
        if (activeEvent && activeEvent !== "noActiveEvent") {
            const list = [...participantData];
            list[0].part_verified_amount = !activeEvent.event_price ? 0 : activeEvent.event_price.toFixed(2);
            this.setState({ participantData: list, activeEvent: activeEvent.name });
            if (currentPage.subpage === "editParticipant") this.getPartData(currentPage.params.partID);
            else this.addParticipantView();
        } else {
            onShowAlert({ variant: "danger", message: t("participant.noActiveEvent") });
        }
        onResizeWindow("basic");
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if ($(".scrolled-content").outerHeight() && !this.state.loading) onResizeWindow("basic");
        if (!this.state.loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const {
            userRoles,
            participantData,
            qrCode,
            changedValues,
            showScanner,
            showOptionDataAccount,
            userData,
            selectedLabels,
            alertAssignedUser,
            loading,
            incorrectVerifiedAmountAlert,
            alertAssignedUniquePartID,
            noPermission,
        } = this.state;
        const { scannerHeight, onCancelChangeValuesInFormes, fetchURL, activeEvent, onBackToPreviousPage, onShowAlert, currentPage, t, onShowHomePage, onCheckUserPrivilage } = this.props;

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails
                                    t={t}
                                    header={currentPage.subpage === "addParticipant" ? t("participant.addPart") : t("participant.edit")}
                                    changedValues={changedValues}
                                    onChangeView={onBackToPreviousPage}
                                    onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                                />
                                <Col></Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            activeEvent && (
                                <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                                    <div className="scrolled-content" style={{ display: "none" }}>
                                        <Container className="py-3">
                                            <Row>
                                                <Col xs={12}>
                                                    {currentPage.subpage === "addParticipant" && (
                                                        <Fragment>
                                                            <AccountData
                                                                activeEvent={activeEvent}
                                                                participantData={participantData}
                                                                userData={userData}
                                                                showOptionDataAccount={showOptionDataAccount}
                                                                userRoles={userRoles}
                                                                onShowAlert={onShowAlert}
                                                                fetchURL={fetchURL}
                                                                onAddNewRole={this.handleAddNewRole}
                                                                onChangeUserData={this.handleChangeUserData}
                                                                onFullnameSelected={(event, { value }) => {
                                                                    this.setState({ userID: value });
                                                                    //this.checkIfUserHasPart(value);
                                                                }}
                                                                onShowOptionDataAccount={this.handleShowOptionDataAccount}
                                                                onChangeRolesAndEventsValue={this.handleChangeRolesAndEventsValue}
                                                                onRemoveRoleSelects={this.handleRemoveRoleSelects}
                                                                alertAssignedUser={alertAssignedUser}
                                                                currentPage={currentPage}
                                                                t={t}
                                                                onShowHomePage={onShowHomePage}
                                                                scannerHeight={scannerHeight}
                                                                onCheckUserPrivilage={onCheckUserPrivilage}
                                                            />

                                                            <Row>
                                                                <Col xs={12}>
                                                                    <hr className="my-3" />
                                                                </Col>
                                                            </Row>
                                                        </Fragment>
                                                    )}
                                                    <PartData
                                                        participantData={participantData}
                                                        currentPage={currentPage}
                                                        fetchURL={fetchURL}
                                                        onShowAlert={onShowAlert}
                                                        onPartLabelSelected={this.handlePartLabelSelected}
                                                        onChangePartData={this.handleChangePartData}
                                                        onShowScanner={() => this.setState({ showScanner: true })}
                                                        onChangeVerifiedAmount={this.handleChangeVerifiedAmount}
                                                        onChangePartIdentNumber={this.handleChangePartIdentNumber}
                                                        selectedLabels={selectedLabels}
                                                        incorrectVerifiedAmountAlert={incorrectVerifiedAmountAlert}
                                                        onChangeVerifiedDiscount={this.handleChangeVerifiedDiscount}
                                                        onPartStatSelected={this.handlePartStatSelected}
                                                        onChangeCalendarDate={(date) => this.handleChangeParticipantData("part_arrival_date", date)}
                                                        onPartGroupSelected={(event, value) => this.handleChangeParticipantData("part_group_id", value)}
                                                        qrCode={qrCode}
                                                        activeEvent={activeEvent}
                                                        alertAssignedUniquePartID={alertAssignedUniquePartID}
                                                        onChangeQRCodeValue={this.handleChangeQRCodeValue}
                                                        onResetIdent={this.handleResetIdent}
                                                        onSetDefaultIdentNumber={this.handleSetDefaultIdentNumber}
                                                        t={t}
                                                        onShowHomePage={onShowHomePage}
                                                    />

                                                    <Row>
                                                        <Col xs={12}>
                                                            <hr className="my-3" />
                                                        </Col>
                                                    </Row>
                                                    <RestData
                                                        participantData={participantData}
                                                        onChangeCalendarDate={(date) => this.handleChangeParticipantData("part_declar_mt_date", date)}
                                                        onChangeCheckboxValue={this.handleChangeCheckbox}
                                                        onChangePartData={this.handleChangePartData}
                                                        t={t}
                                                        onShowHomePage={onShowHomePage}
                                                    />
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                    <div className="content-bottom">
                                        <Container>
                                            <Row>
                                                <Col xs={12} className="py-3">
                                                    {!changedValues ? (
                                                        <TooltipWithDisabledButton
                                                            t={t}
                                                            onShowHomePage={onShowHomePage}
                                                            mssgTooltip={t("info.emptyForm")}
                                                            btnTxt={currentPage.subpage === "addParticipant" ? t("add") : t("edit")}
                                                            tooltipID="tooltipDisabledNotFilled"
                                                            size="lg"
                                                        />
                                                    ) : (
                                                        <Button type="submit" variant="primary" size="lg">
                                                            {currentPage.subpage === "addParticipant" ? t("add") : t("edit")}
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </Form>
                            )
                        )}
                        {showScanner && (
                            <ShowQRCode
                                qrcode={qrCode}
                                onScanQRCode={(code) => {
                                    const { onChangeValuesInFormes } = this.props;
                                    this.setState({ showScanner: false, changedValues: true });
                                    if (code) {
                                        this.setState({
                                            qrCode: code,
                                        });
                                    }
                                    onChangeValuesInFormes();
                                }}
                                qrReaderHeight={scannerHeight}
                                showScanner={showScanner}
                                onHideScanner={() => this.setState({ showScanner: false })}
                                t={t}
                                onShowHomePage={onShowHomePage}
                                onShowAlert={onShowAlert}
                            />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default AddEditParticipant;
