import React, { Fragment, Component } from "react";
import { Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faSort, faSortDown, faSortUp, faUsers } from "@fortawesome/free-solid-svg-icons";

class TableGroupsList extends Component {
    state = {
        currentSortType: "default",
        currentSortingColumn: "sortID",
    };

    handleSorting = (btnID) => {
        const { currentSortType } = this.state;
        let nextSort;
        if (currentSortType === "down") {
            nextSort = "up";
        } else if (currentSortType === "up") {
            nextSort = "default";
        } else if (currentSortType === "default") {
            nextSort = "down";
        }

        this.setState({
            currentSortType: nextSort,
            currentSortingColumn: btnID,
        });
    };

    render() {
        const { currentSortType, currentSortingColumn } = this.state;
        const { groupsList, displayNOR, onChangeNavAndPage, t, onCheckUserPrivilage, activeEvent } = this.props;

        const sortTypes = {
            up: {
                fnID: (a, b) => a.part_group_id - b.part_group_id, //metoda sortowania (patrz: https://www.w3schools.com/jsref/jsref_sort.asp )
                fnArrivalDate: (a, b) => {
                    if (new Date(a.group_arrival_date) > new Date(b.group_arrival_date)) return 1;
                    else return -1;
                },
                fnGroupName: (a, b) => {
                    if (a.part_group_name.toUpperCase() > b.part_group_name.toUpperCase()) return 1;
                    else return -1;
                },
                fnNickname: (a, b) => {
                    if (a.user_nickname.toUpperCase() > b.user_nickname.toUpperCase()) return 1;
                    else return -1;
                },
            },
            down: {
                fnID: (a, b) => b.part_group_id - a.part_group_id,
                fnGroupName: (a, b) => {
                    if (a.part_group_name.toUpperCase() > b.part_group_name.toUpperCase()) return -1;
                    else return 1;
                },
            },
            default: {
                fnID: (a, b) => a,
                fnGroupName: (a, b) => a,
            },
        };

        //sortowanie listy
        let sortedData = [];
        if (currentSortingColumn === "sortID") sortedData = groupsList.sort(sortTypes[currentSortType].fnID);
        else if (currentSortingColumn === "sortGroupName") sortedData = groupsList.sort(sortTypes[currentSortType].fnGroupName);
        else sortedData = groupsList.sort(sortTypes[currentSortType].fnID);

        const currentPageParams = { nav: "groups", subpage: "groupsList", params: "" };
        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th>{t("on")}</th>
                            <th>
                                {t("group.groupName.label")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortGroupName" onClick={() => this.handleSorting("sortGroupName")}>
                                    <FontAwesomeIcon
                                        icon={currentSortingColumn !== "sortGroupName" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort}
                                    />
                                </Button>
                            </th>
                            <th>{t("group.coordinator")}</th>
                            <th>{t("group.membersAmount")}</th>
                            <th>{t("group.ifVerified")}</th>
                            <th>{t("createDate")}</th>
                            {onCheckUserPrivilage("ACCR_GROUP_ACTIONS") && (
                                <th style={activeEvent && activeEvent !== "noActiveEvent" ? { width: "200px", minWidth: "170px" } : { width: "80px", minWidth: "80px" }}>{t("dropdowns.actions")}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData
                            .map((p, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{p.part_group_name}</td>
                                    <td>{p.coordinator_fullname}</td>
                                    <td>{p.count_part}</td>
                                    <td>{p.part_group_verified === "Y" ? <span className="text-success">{t("yes")}</span> : <span className="text-danger">{t("no")}</span>}</td>

                                    <td>{p.create_date}</td>
                                    {onCheckUserPrivilage("ACCR_GROUP_ACTIONS") && (
                                        <td className="text-center">
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`groupDetailsTooltip${i}`}>{t("group.groupDetails")}</Tooltip>}>
                                                <span className="d-inline-block">
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() =>
                                                            onChangeNavAndPage(
                                                                { nav: "groups", subpage: "groupDetails", params: { groupID: p.part_group_id, eventCode: p.event_code } },
                                                                currentPageParams
                                                            )
                                                        }
                                                        className="mx-1"
                                                    >
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>
                                            {activeEvent && activeEvent !== "noActiveEvent" && (
                                                <Fragment>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`editGroupMembersTooltip${i}`}>{t("group.manageMembers")}</Tooltip>}>
                                                        <span className="d-inline-block">
                                                            <Button
                                                                variant="outline-primary"
                                                                onClick={() =>
                                                                    onChangeNavAndPage(
                                                                        { nav: "groups", subpage: "editGroupMembers", params: { groupID: p.part_group_id, eventCode: p.event_code } },
                                                                        currentPageParams
                                                                    )
                                                                }
                                                                className="mx-1"
                                                            >
                                                                <FontAwesomeIcon icon={faUsers} />
                                                            </Button>
                                                        </span>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`editGroupTooltip${i}`}>{t("edit")}</Tooltip>}>
                                                        <span className="d-inline-block">
                                                            <Button
                                                                variant="outline-primary"
                                                                onClick={() =>
                                                                    onChangeNavAndPage(
                                                                        { nav: "groups", subpage: "editGroup", params: { groupID: p.part_group_id, eventCode: p.event_code } },
                                                                        currentPageParams
                                                                    )
                                                                }
                                                                className="mx-1"
                                                            >
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </Button>
                                                        </span>
                                                    </OverlayTrigger>
                                                </Fragment>
                                            )}
                                        </td>
                                    )}
                                </tr>
                            ))
                            .slice(0, displayNOR)}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}

export default TableGroupsList;
