import React, { Fragment, Component } from "react";
import { Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faEye, faTimes } from "@fortawesome/free-solid-svg-icons";

class TableShopItemsList extends Component {
    state = {
        currentSort: "default",
        sortingColName: "sortID",
    };

    handleSorting = (btnID) => {
        const { currentSort } = this.state;
        let nextSort;
        $(".sort-icon").removeClass("fa-sort-down").removeClass("fa-sort-up").removeClass("fa-sort").addClass("fa-sort");
        if (currentSort === "down") {
            nextSort = "up";
            $("#" + btnID + " .fas")
                .removeClass("fa-sort-down")
                .addClass("fa-sort-up");
        } else if (currentSort === "up") {
            nextSort = "default";
            $("#" + btnID + " .fas")
                .removeClass("fa-sort-up")
                .addClass("fa-sort");
        } else if (currentSort === "default") {
            nextSort = "down";
            $("#" + btnID + " .fas")
                .removeClass("fa-sort")
                .addClass("fa-sort-down");
        }

        this.setState({
            currentSort: nextSort,
            sortingColName: btnID,
        });
    };

    render() {
        const { currentSort, sortingColName } = this.state;
        const { shopItemsList, displayNOR, onCheckUserPrivilage, onChangeNavAndPage, t } = this.props;

        const sortTypes = {
            up: {
                fnID: (a, b) => a.shit_id - b.shit_id, //metoda sortowania (patrz: https://www.w3schools.com/jsref/jsref_sort.asp )

                fnShopItemCode: (a, b) => {
                    if (a.shit_code.toUpperCase() > b.shit_code.toUpperCase()) return 1;
                    else return -1;
                },
                fnShopItemName: (a, b) => {
                    if (a.shit_name.toUpperCase() > b.shit_name.toUpperCase()) return 1;
                    else return -1;
                },
            },
            down: {
                fnID: (a, b) => b.shit_id - a.shit_id,
                fnShopItemCode: (a, b) => {
                    if (a.shit_code.toUpperCase() > b.shit_code.toUpperCase()) return -1;
                    else return 1;
                },
                fnShopItemName: (a, b) => {
                    if (a.shit_name.toUpperCase() > b.shit_name.toUpperCase()) return -1;
                    else return -1;
                },
            },
            default: {
                fnID: (a, b) => a,
                fnShopItemCode: (a, b) => a,
                fnShopItemName: (a, b) => a,
            },
        };

        //sortowanie listy
        let sortedData = [];
        if (sortingColName === "sortShopItemCode") sortedData = shopItemsList.sort(sortTypes[currentSort].fnShopItemCode);
        else if (sortingColName === "sortShopItemName") sortedData = shopItemsList.sort(sortTypes[currentSort].fnShopItemName);
        else sortedData = shopItemsList.sort(sortTypes[currentSort].fnShopItemCode);

        const currentPageParams = { nav: "shop", subpage: "shopItemsList", params: "" };

        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th>{t("on")}</th>
                            <th>
                                {t("shop.itemsList.code")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortShopItemCode" onClick={this.handleSorting.bind(this, "sortShopItemCode")}>
                                    <i className="sort-icon fas fa-sort" />
                                </Button>
                            </th>
                            <th>{t("shop.itemsList.img")}</th>
                            <th>
                                {t("shop.itemsList.name")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortShopItemName" onClick={this.handleSorting.bind(this, "sortShopItemName")}>
                                    <i className="sort-icon fas fa-sort" />
                                </Button>
                            </th>

                            <th>{t("shop.itemsList.category")}</th>
                            <th>{t("shop.itemsList.price")}</th>
                            <th>{t("shop.itemsList.amount")}</th>
                            <th>{t("shop.itemsList.availability")}</th>
                            <th>{t("shop.itemsList.limited")}</th>
                            <th style={{ width: "170px", minWidth: "170px" }}>{t("dropdowns.actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData
                            .map((p, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{p.shit_code}</td>
                                    <td className="text-center">
                                        {p.blob_data ? (
                                            <img src={`data:${p.blob_file_type};base64,${p.blob_data}`} style={{ width: "3rem", height: "3rem", objectFit: "cover" }} alt={p.blob_name} />
                                        ) : (
                                            <img src="images/placeholder_img_dark.jpg" alt="placeholder_img" style={{ width: "3rem", height: "3rem", objectFit: "cover" }} />
                                        )}
                                    </td>
                                    <td>{p.shit_name}</td>

                                    <td>{p.shcat_name}</td>
                                    <td>
                                        {p.shit_price.toFixed(2).replace(".", ",")} {t("currency")}
                                    </td>
                                    <td>{p.shit_quantity === null ? <span className="opacity-4">{t("shop.unlimited")}</span> : p.shit_quantity}</td>
                                    <td className="text-center">
                                        {p.shit_available === "Y" ? (
                                            <span className="text-success">
                                                <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                        ) : (
                                            <span className="text-danger">
                                                <FontAwesomeIcon icon={faTimes} />
                                            </span>
                                        )}
                                    </td>
                                    <td className="text-center">{p.shit_limited === "N" ? <span className="opacity-4">{t("shop.itemsList.all")}</span> : p.part_labels_list}</td>

                                    <td className="text-center">
                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`shopItemView${i}`}>{t("preview")}</Tooltip>}>
                                            <span className="d-inline-block">
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() => onChangeNavAndPage({ nav: "shop", subpage: "shopItemDetails", params: { shopItemID: p.shit_id } }, currentPageParams)}
                                                    className="mx-1"
                                                >
                                                    <FontAwesomeIcon icon={faEye} />
                                                </Button>
                                            </span>
                                        </OverlayTrigger>
                                        {onCheckUserPrivilage("ACCR_SHOP_ITEMS_ACTIONS") && (
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`shopItemEdit${i}`}>{t("edit")}</Tooltip>}>
                                                <span className="d-inline-block">
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() => onChangeNavAndPage({ nav: "shop", subpage: "editShopItem", params: { shopItemID: p.shit_id } }, currentPageParams)}
                                                        className="mx-1"
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>
                                        )}
                                    </td>
                                </tr>
                            ))
                            .slice(0, displayNOR)}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}

export default TableShopItemsList;
