import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Col, Button } from "react-bootstrap";
import ConfirmModal from "../modals/ConfirmModal";
class NavDetails extends Component {
    state = {
        showConfirmEscapeModal: false,
    };

    //pojawienie modala jeśli nastąpiły zmiany w formularzu
    handleShowConfirmModal = () => {
        this.setState({ showConfirmEscapeModal: true });
        this.props.onCancelChangeValuesInFormes();
    };

    handleClose = () => {
        this.setState({ showConfirmEscapeModal: false });
    };

    render() {
        const { header, changedValues, hideBackButton, onChangeView, t } = this.props;
        const { showConfirmEscapeModal } = this.state;
        return (
            <Fragment>
                <Col md={3}>
                    {!hideBackButton && (
                        <Button name="previousRoute" variant="outline-secondary" className="mt-1" type="button" onClick={changedValues ? this.handleShowConfirmModal : onChangeView}>
                            <span className="d-none d-md-inline">{t("undo")}</span>
                            <span className="d-md-none">
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </span>
                        </Button>
                    )}
                </Col>
                <Col md={6} className="text-center">
                    <h1 className="h4 my-2">{header}</h1>
                </Col>
                {showConfirmEscapeModal && <ConfirmModal t={t} show={showConfirmEscapeModal} onClose={this.handleClose} onChangeView={onChangeView} />}
            </Fragment>
        );
    }
}

export default NavDetails;
