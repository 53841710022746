import React, { Component, Fragment } from "react";
import { Form, Alert } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import AlertNoPermission from "../../alerts/AlertNoPermission";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import $ from "jquery";
import DescPopover from "../../popovers/DescPopover";

class ParticipantsToRoomBooking extends Component {
    state = { participantsList: null, noPermission: false };

    getParticipantsToRoomBooking = async () => {
        const { fetchURL, onShowAlert, activeEvent, defaultValue, t } = this.props;
        const passData = { activeEvent: activeEvent, selectedParts: defaultValue ? defaultValue : null };
        try {
            const response = await fetch(`${fetchURL}/participants-to-room-booking`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ participantsList: fetchedData.partList });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.getParticipantsToRoomBooking();
    }

    render() {
        const { participantsList, noPermission } = this.state;
        const { onPartSelected, selectedParticipants, req, labelTxt, helper, t } = this.props;

        return (
            <Fragment>
                <Form.Label className="w-100">
                    {labelTxt} {req && <span className="required">*</span>}
                    {helper && (
                        <div className="float-right">
                            <DescPopover placement="top" popoverTxt={<p>{helper}</p>} />
                        </div>
                    )}
                </Form.Label>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : participantsList ? (
                    participantsList.length > 0 ? (
                        <Dropdown
                            value={selectedParticipants}
                            id="participantsToRoomBookingDropdown"
                            className="req mb-3"
                            placeholder={t("selectOrType")}
                            fluid
                            search
                            selection
                            multiple
                            options={participantsList}
                            onChange={(event, { value }) => {
                                $("#participantsToRoomBookingDropdown").removeClass("danger");
                                onPartSelected(value);
                            }}
                            noResultsMessage={t("participant.personNotFound")}
                        />
                    ) : (
                        <Alert variant="warning" className="alert-small">
                            {t("participant.noAvailableParts")}
                        </Alert>
                    )
                ) : (
                    <LoadingProgressBar t={t} />
                )}
            </Fragment>
        );
    }
}

export default ParticipantsToRoomBooking;
