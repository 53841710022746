import React, { Fragment } from "react";
import { Col, Form, Row } from "react-bootstrap";

const PaymentAmountsDropdown = (props) => {
    const { t, selectedPaymentAmountStart, onSelectPaymentAmountStart, selectedPaymentAmountEnd, onSelectPaymentAmountEnd } = props;
    return (
        <Fragment>
            <Row>
                <Col xs={12}>
                    <Form.Label className="w-100">{t("payment.list.amount")}</Form.Label>
                </Col>
                <Col xs={12}>
                    <Row>
                        <Col xs={6}>
                            <Form.Control
                                type="number"
                                value={selectedPaymentAmountStart}
                                placeholder={t("amountStart")}
                                onChange={(e) => onSelectPaymentAmountStart(e.target.value)}
                                className="mb-3"
                            />
                        </Col>
                        <Col xs={6}>
                            <Form.Control type="number" value={selectedPaymentAmountEnd} placeholder={t("amountEnd")} onChange={(e) => onSelectPaymentAmountEnd(e.target.value)} className="mb-3" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Fragment>
    );
};

export default PaymentAmountsDropdown;
