import React, { Component, Fragment } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import TooltipWithDisabledButton from "../tooltips/TooltipWithDisabledButton";

class ShopItemSendEmailModal extends Component {
    state = { shopOrderEmailTitle: `${this.props.t("shopItemSendEmailModal.emailTitle")}${this.props.addressee.orderCode}`, shopOrderEmailTxt: "" };

    sendEmail = async () => {
        const { shopOrderEmailTitle, shopOrderEmailTxt } = this.state;
        const { fetchURL, addressee, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.sendingEmail") });
        const passData = {
            shopOrderEmailTitle: shopOrderEmailTitle,
            shopOrderEmailTxt: shopOrderEmailTxt,
            shopOrderEmailAddress: addressee.email,
        };
        try {
            const response = await fetch(`${fetchURL}/shop-order-send-email`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else this.props.shopOrderSentEmail();
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        this.sendEmail();
    };

    render() {
        const { shopOrderEmailTitle, shopOrderEmailTxt } = this.state;
        const { show, addressee, onCloseModal, t } = this.props;
        return (
            <Fragment>
                <Modal show={show} onHide={onCloseModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t("shopItemSendEmailModal.modalTitle")}
                            {addressee.orderCode}
                        </Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                        <Modal.Body>
                            <p className="mb-2">
                                {t("shopItemSendEmailModal.addressee")}: {addressee.email}
                            </p>
                            <p className="mb-2">
                                {t("shopItemSendEmailModal.mssgTitle")}: {shopOrderEmailTitle}
                            </p>
                            <Form.Label>{t("shopItemSendEmailModal.mssgText")}: </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder={t("form.typeMessage")}
                                name="shop_order_email_txt"
                                value={shopOrderEmailTxt}
                                onChange={(e) => this.setState({ shopOrderEmailTxt: e.target.value })}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            {!shopOrderEmailTxt ? (
                                <TooltipWithDisabledButton t={t} mssgTooltip={t("error.emptyMssg")} btnTxt={t("send")} tooltipID="tooltipDisabledNotFilled" />
                            ) : (
                                <Button type="submit" variant="primary">
                                    {t("send")}
                                </Button>
                            )}
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default ShopItemSendEmailModal;
