import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { Form } from "react-bootstrap";
import pl from "date-fns/locale/pl";
import $ from "jquery";
registerLocale("pl", pl);

const RangeDate = (props) => {
    const { txtLabel, setStartDate, setEndDate, id, req, startDate, endDate, t } = props;
    $(`#${id}`).on("click", function () {
        $(this).removeClass("danger");
    });
    return (
        <Form.Group>
            <Form.Label>
                {txtLabel} {req && <span className="required">*</span>}
            </Form.Label>

            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
                placeholderText={t("calendar.selectStartDate")}
                locale="pl"
                className="datepicker mb-1"
                id={`${id}_start`}
            />
            <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="yyyy-MM-dd"
                placeholderText={t("calendar.selectEndDate")}
                locale="pl"
                className="datepicker"
                id={`${id}_end`}
            />
        </Form.Group>
    );
};

export default RangeDate;
