import React, { Fragment, Component } from "react";
import { Button, Row, Col, Container, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import RoomsListTable from "./RoomsListTable";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import "react-widgets/dist/css/react-widgets.css";
import $ from "jquery";
import EventFilter from "../../forms/dropdowns/EventFilter";
import RowsDropdown from "../../forms/dropdowns/RowsDropdown";
import BuildingsDropdown from "../../forms/dropdowns/BuildingsDropdown";
import RoomsSizeDropdown from "../../forms/dropdowns/RoomsSizeDropdown";
import { faLongArrowAltLeft, faLongArrowAltRight, faSync, faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoPermission from "../../other/NoPermission";
import { Dropdown } from "semantic-ui-react";

class RoomsList extends Component {
    state = {
        showNOR: this.props.numberOfRecords,
        displayNOR: this.props.numberOfRecords,
        roomsList: this.props.dataList,
        originalRooms: this.props.dataList,
        loading: this.props.dataList ? false : true,
        selectedRoomStat: "all",
        selectedBuilding: "all",
        selectedRoomSize: "all",
        selectedReservationStatus: "all",
        noPermission: false,
    };

    handleDownloadData = () => {
        this.props.onResizeWindow("tableNotLoaded");
        this.setState({ loading: true });
        this.getRoomsList();
    };

    getRoomsList = async () => {
        const { fetchURL, onShowAlert, onGetDataList, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/rooms-list`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const roomsList = fetchedData.roomsList;

                        this.setState({ roomsList: roomsList, originalRooms: roomsList }, () => {
                            this.setState({ loading: false });
                            onGetDataList(roomsList);
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        const { onResizeWindow } = this.props;
        this.getRoomsList();
        onResizeWindow("tableNotLoaded");
        $(window).on("resize", function () {
            onResizeWindow("tableLoaded");
        });
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if (!this.state.loading) {
            $("#loadTable").fadeIn(200);
            if ($(".light-border").outerHeight() && $(".rest-info").outerHeight()) {
                onResizeWindow("tableLoaded");
            }
        }
    }

    render() {
        const { roomsList, loading, displayNOR, showNOR, selectedBuilding, selectedRoomSize, noPermission, selectedReservationStatus } = this.state;
        const { fetchURL, onCheckUserPrivilage, onChangeNavAndPage, currentPage, onShowAlert, activeEvent, t, eventFilterValue, eventsList, onChangeEventFilter, onShowHomePage } = this.props;
        let filteredRoomsList = null;
        if (roomsList) {
            filteredRoomsList = roomsList.filter(
                (e) =>
                    (eventFilterValue === "all" || e.event_code === eventFilterValue) &&
                    (selectedBuilding === "all" || selectedBuilding === e.building_code) &&
                    (selectedRoomSize === "all" || selectedRoomSize === e.room_size_code) &&
                    (selectedReservationStatus === "all" || (selectedReservationStatus === "RESERVED" && e.room_booking_id) || (selectedReservationStatus === "FREE" && !e.room_booking_id))
            );
        }
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: currentPage.params };
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="oneNavbar" />
                ) : (
                    <Fragment>
                        <Container fluid className="pt-2 second-bar">
                            <Row>
                                <Col xl={1} lg={2} md={2} xs={4}>
                                    <RowsDropdown
                                        t={t}
                                        defaultValue={this.props.numberOfRecords}
                                        onChangeValue={(event, { value }) => {
                                            this.setState({
                                                showNOR: value,
                                                displayNOR: value,
                                            });
                                            this.props.onChangeNOR(value);
                                        }}
                                    />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <EventFilter t={t} onShowHomePage={onShowHomePage} eventFilterValue={eventFilterValue} eventsList={eventsList} onChangeEventFilter={onChangeEventFilter} />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <BuildingsDropdown
                                        fetchURL={fetchURL}
                                        defaultValue="all"
                                        filter
                                        onChangeBuildingValue={(event, { value }) => {
                                            this.props.onResizeWindow("tableNotLoaded");
                                            this.setState({ selectedBuilding: value });
                                        }}
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                    />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <RoomsSizeDropdown
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        fetchURL={fetchURL}
                                        defaultValue="all"
                                        filter
                                        onChangeRoomsSizeValue={(event, { value }) => {
                                            this.props.onResizeWindow("tableNotLoaded");
                                            this.setState({ selectedRoomSize: value });
                                        }}
                                    />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <Form.Label>{t("room.bookStatus")}:</Form.Label>
                                    <Dropdown
                                        className="mb-3"
                                        placeholder={t("form.choose")}
                                        fluid
                                        selection
                                        value={selectedReservationStatus}
                                        options={[
                                            { key: 0, value: "all", text: t("selectAll") },
                                            { key: 1, value: "RESERVED", text: t("room.reserved") },
                                            { key: 2, value: "FREE", text: t("room.unoccupied") },
                                        ]}
                                        onChange={(event, { value }) => this.setState({ selectedReservationStatus: value })}
                                    />
                                </Col>

                                <Col lg={3} xs={12} className="text-left text-lg-right mt-lg-3 mt-lg-0 mb-3">
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltipRoomsMap">{t("room.roomsMap")}</Tooltip>}>
                                        <Button
                                            variant="outline-primary"
                                            className="mr-3"
                                            onClick={() => {
                                                onChangeNavAndPage({ nav: "rooms", subpage: "roomsMap", params: "" }, currentPageParams);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faMap} />
                                        </Button>
                                    </OverlayTrigger>
                                    <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync mr-3">
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                    {onCheckUserPrivilage("ACCR_ROOMS_ACTIONS") && (
                                        <Button variant="outline-primary" type="button" onClick={() => onChangeNavAndPage({ nav: "rooms", subpage: "addRoom", params: "" }, currentPageParams)}>
                                            {t("room.addRoom")}
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="content-body">
                            {loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <div id="loadTable" style={{ display: "none" }}>
                                    <Row>
                                        <Col>
                                            <div className="table-responsive table-fixed-head">
                                                {filteredRoomsList.length > 0 ? (
                                                    <Fragment>
                                                        <RoomsListTable
                                                            roomsList={filteredRoomsList}
                                                            displayNOR={displayNOR}
                                                            fetchURL={fetchURL}
                                                            onChangeNavAndPage={onChangeNavAndPage}
                                                            currentPageParams={currentPageParams}
                                                            onShowAlert={onShowAlert}
                                                            activeEvent={activeEvent}
                                                            onCheckUserPrivilage={onCheckUserPrivilage}
                                                            t={t}
                                                            onShowHomePage={onShowHomePage}
                                                        />
                                                        <div className="light-border"></div>
                                                    </Fragment>
                                                ) : (
                                                    <div className="text-center">{t("notFound")}</div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="rest-info py-3">
                                        <Col>
                                            {t("loaded")} {displayNOR > filteredRoomsList.length ? filteredRoomsList.length : displayNOR} {t("from")} {filteredRoomsList.length} {t("records")}
                                        </Col>
                                        <Col className="text-center">
                                            {displayNOR >= filteredRoomsList.length ? (
                                                <p className="opacity-4">
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                    <span className="px-3">{t("thatsAll")}</span>
                                                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                </p>
                                            ) : (
                                                <Button onClick={() => this.setState({ displayNOR: showNOR + displayNOR })} variant="outline-primary">
                                                    {t("loadMore")}
                                                </Button>
                                            )}
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </div>
                            )}
                        </Container>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default RoomsList;
