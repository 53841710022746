import React, { Fragment, Component } from "react";
import { Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileAlt, faHotel } from "@fortawesome/free-solid-svg-icons";

class RoomsListTable extends Component {
    state = {
        currentSort: "default",
        sortingColName: "sortID",
        assignGroupToRoom: null,

        showRoomDetailsModal: false,
        showAssignGroup2Room: false,
    };

    handleSorting = (btnID) => {
        const { currentSort } = this.state;
        let nextSort;
        $(".sort-icon").removeClass("fa-sort-down").removeClass("fa-sort-up").removeClass("fa-sort").addClass("fa-sort");
        if (currentSort === "down") {
            nextSort = "up";
            $("#" + btnID + " .fas")
                .removeClass("fa-sort-down")
                .addClass("fa-sort-up");
        } else if (currentSort === "up") {
            nextSort = "default";
            $("#" + btnID + " .fas")
                .removeClass("fa-sort-up")
                .addClass("fa-sort");
        } else if (currentSort === "default") {
            nextSort = "down";
            $("#" + btnID + " .fas")
                .removeClass("fa-sort")
                .addClass("fa-sort-down");
        }

        this.setState({
            currentSort: nextSort,
            sortingColName: btnID,
        });
    };

    render() {
        const { currentSort, sortingColName } = this.state;
        const { roomsList, displayNOR, onChangeNavAndPage, currentPageParams, onCheckUserPrivilage, t } = this.props;

        const sortTypes = {
            up: {
                fnID: (a, b) => a.room_ident_number - b.room_ident_number, //metoda sortowania (patrz: https://www.w3schools.com/jsref/jsref_sort.asp )

                fnRoomCode: (a, b) => {
                    if (a.room_code.toUpperCase() > b.room_code.toUpperCase()) return 1;
                    else return -1;
                },
            },
            down: {
                fnID: (a, b) => b.room_ident_number - a.room_ident_number,

                fnRoomCode: (a, b) => {
                    if (a.room_code.toUpperCase() > b.room_code.toUpperCase()) return -1;
                    else return 1;
                },
            },
            default: {
                fnID: (a, b) => a,
                fnRoomCode: (a, b) => a,
            },
        };

        //sortowanie listy
        let sortedData = [];
        if (sortingColName === "sortID") sortedData = roomsList.sort(sortTypes[currentSort].fnID);
        else if (sortingColName === "sortRoomCode") sortedData = roomsList.sort(sortTypes[currentSort].fnRoomCode);
        else sortedData = roomsList.sort(sortTypes[currentSort].fnID);

        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th>{t("on")}</th>
                            <th>
                                {t("room.roomsList.code")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortRoomCode" onClick={this.handleSorting.bind(this, "sortRoomCode")}>
                                    <i className="sort-icon fas fa-sort" />
                                </Button>
                            </th>
                            <th>{t("room.roomsList.building")}</th>
                            <th>{t("room.roomsList.number")}</th>
                            <th>{t("room.roomsList.size")}</th>
                            <th>{t("room.roomsList.reservStatus")}</th>
                            <th style={{ width: "200px", minWidth: "170px" }}>{t("dropdowns.actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData
                            .map((p, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{p.room_code ? p.room_code : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{p.building_name}</td>
                                    <td>{p.room_number}</td>
                                    <td>{p.room_size_name}</td>
                                    <td>{p.room_booking_id ? t("room.reserved") : <span className="text-success">{t("room.unoccupied")}</span>}</td>
                                    <td className="text-center">
                                        {p.room_booking_id ? (
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`roomBookingTooltip${i}`}>{t("request.seeBookingDetails")}</Tooltip>}>
                                                <span className="d-inline-block">
                                                    <Button
                                                        variant="outline-primary"
                                                        className="mx-1"
                                                        onClick={() =>
                                                            onChangeNavAndPage({ nav: "rooms", subpage: "roomBookingDetails", params: { roomBookingID: p.room_booking_id } }, currentPageParams)
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faFileAlt} />
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>
                                        ) : (
                                            onCheckUserPrivilage("ACCR_ROOM_BOOKING_ACTION") && (
                                                <OverlayTrigger placement="top" overlay={<Tooltip id={`bookRoomTooltip${i}`}>{t("room.bookRoom")}</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        <Button
                                                            variant="outline-primary"
                                                            className="mx-1"
                                                            onClick={() => onChangeNavAndPage({ nav: "rooms", subpage: "bookRoom", params: { roomID: p.room_id } }, currentPageParams)}
                                                        >
                                                            <FontAwesomeIcon icon={faHotel} />
                                                        </Button>
                                                    </span>
                                                </OverlayTrigger>
                                            )
                                        )}
                                        {onCheckUserPrivilage("ACCR_ROOMS_DETAILS") && (
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`roomDetailsTooltip${i}`}>{t("room.roomDetails")}</Tooltip>}>
                                                <span className="d-inline-block">
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() => onChangeNavAndPage({ nav: "rooms", subpage: "roomDetails", params: { roomID: p.room_id } }, currentPageParams)}
                                                        className="mx-1"
                                                    >
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>
                                        )}
                                    </td>
                                </tr>
                            ))
                            .slice(0, displayNOR)}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}

export default RoomsListTable;
