import React, { Fragment, Component } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";

class GenerateCodesModal extends Component {
    state = { loading: true, noPermission: false, changedValues: false, amount: "", word: "", size: 543, bgColor: "ffffff", qrCode: "" };

    generateCodesView = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/generate-codes-view`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleDownloadImage = (src) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = src;
        img.onload = () => {
            // create Canvas
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            // create <a> tag
            const a = document.createElement("a");
            a.download = "qrcode.png";
            a.href = canvas.toDataURL("image/png");
            a.click();
        };
    };

    generateRandomString = (myLength) => {
        const chars = "AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890";
        const randomArray = Array.from({ length: myLength }, (v, k) => chars[Math.floor(Math.random() * chars.length)]);

        const randomString = randomArray.join("");
        return randomString;
    };

    handleGenerateAndDownload = () => {
        const { amount, size, bgColor } = this.state;

        for (let i = 0; i < amount; i++) {
            let genString = this.generateRandomString(9);
            this.handleDownloadImage(`http://api.qrserver.com/v1/create-qr-code/?data=${genString}!&size=${size}x${size}&bgcolor=${bgColor}&qzone=2`);
        }
    };

    render() {
        const { amount } = this.state;
        const { show, onClose, t } = this.props;
        return (
            <Fragment>
                <Modal show={show} onHide={onClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("ident.generateCodes")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>{t("ident.amount")}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={amount}
                                        name="ident_amount"
                                        placeholder={t("form.typeNumber")}
                                        id="identAmount"
                                        onChange={(e) => {
                                            this.setState({ amount: e.target.value });
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" onClick={onClose} type="button">
                            {t("cancel")}
                        </Button>
                        <Button variant="primary" type="button" onClick={this.handleGenerateAndDownload}>
                            {t("generate")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}

export default GenerateCodesModal;
