import React, { Fragment } from "react";
import { Button } from "react-bootstrap";

const BtnNav = (props) => {
    const { navName, title, currentPage, changedValuesInFormes, onShowConfirmModal, onChangeNavAndPage, pageName } = props;

    return (
        <Fragment>
            <Button
                name={navName}
                variant="link"
                disabled={currentPage.nav === navName ? true : false}
                className={currentPage.nav === navName ? "nav-link active" : "nav-link"}
                id={`${navName}Nav`}
                type="button"
                onClick={() => {
                    changedValuesInFormes ? onShowConfirmModal({ nav: navName, subpage: pageName, params: "" }) : onChangeNavAndPage({ nav: navName, subpage: pageName, params: "" }, null);
                }}
            >
                {title}
            </Button>
        </Fragment>
    );
};

export default BtnNav;
