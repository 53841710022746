import React, { Component, Fragment } from "react";
import { Button, Row, Col, Form, InputGroup, FormControl, Container, Alert } from "react-bootstrap";
import $ from "jquery";
import TooltipWithDisabledButton from "../../tooltips/TooltipWithDisabledButton";
import TextForm from "../../forms/text-inputs/TextForm";
import RangeDateAndTime from "../../forms/datetimes/RangeDateAndTime";
import NavDetails from "../../navigation/NavDetails";
import RangeDate from "../../forms/datetimes/RangeDate";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import NoPermission from "../../other/NoPermission";
import { Checkbox } from "semantic-ui-react";
import CalendarDateAndTime from "./../../forms/datetimes/CalendarDateAndTime";
import ConstructionAvalPeriods from "./ConstructionAvalPeriods";
import BarAvalPeriods from "./BarAvalPeriods";
import AccrAvalPeriods from "./AccrAvalPeriods";

class AddActiveEvent extends Component {
    state = {
        changedValues: false,
        disableAvalPeriodEdition: false,
        event: [
            {
                event_name: "",
                event_code: "",
                event_price: "",
                allow_users_apply: false,
                allow_users_apply_deadline: null,
                allow_users_order: false,
                allow_users_order_deadline: null,
                old_allow_users_apply: false,
                event_start_date: null,
                event_end_date: null,
                larp_start_datetime: null,
                larp_end_datetime: null,
                allow_users_applications: false,
                allow_users_characters: false,
            },
        ],
        loading: true,
        showLarpInputs: false,
        avalPeriodConstr: null,
        avalPeriodBar: null,
        avalPeriodAccr: null,
    };

    handleEditEventValue = (e) => {
        const { name, value } = e.target;
        const { event } = this.state;
        const list = [...event];
        list[0][name] = value;
        this.setState({ event: list, changedValues: true });
        this.props.onChangeValuesInFormes();
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { event, avalPeriodBar, avalPeriodAccr, avalPeriodConstr } = this.state;
        const { onShowAlert, t } = this.props;
        onShowAlert("");
        let errorMsg = "";
        $(".form-control.req").each(function () {
            if ($(this).val() === "" || $(this).val() === "default") {
                $(this).addClass("danger");
                errorMsg = t("error.reqFields");
            } else $(this).removeClass("danger");
        });

        const eventStartDate = new Date(event[0].event_start_date);
        const eventEndDate = new Date(event[0].event_end_date);
        const larpStartDateTime = new Date(event[0].larp_start_datetime);
        const larpEndDateTime = new Date(event[0].larp_end_datetime);

        const tomorrow = eventEndDate;
        tomorrow.setDate(eventEndDate.getDate() + 1);

        if (!event[0].event_start_date && !event[0].event_end_date) {
            errorMsg += t("event.emptyEventDates");
            $(`#event_date_start`).addClass("danger");
            $(`#event_date_end`).addClass("danger");
        } else if (event[0].event_start_date && event[0].event_end_date) {
            //sprawdzanie poprawności dat
            if (eventStartDate.getTime() >= eventEndDate.getTime()) {
                errorMsg += t("event.startDateEqualOrLaterThanEndDate");
                $(`#event_date_start`).addClass("danger");
            } else {
                //sprawdzanie poprawności dat larpa - czy zawierają się w datach konwentu (o ile zostały uwzględnione)
                if (event[0].larp_start_datetime || event[0].larp_end_datetime) {
                    if (larpStartDateTime.getTime() < eventStartDate.getTime || larpStartDateTime.getTime() > tomorrow) {
                        errorMsg += t("event.larpStartDateNotIncluded");
                    }
                    if (larpEndDateTime.getTime() < eventStartDate.getTime || larpEndDateTime.getTime() > tomorrow) {
                        errorMsg += t("event.larpEndDateNotIncluded");
                    }
                }
            }
        } else if (event[0].event_start_date || event[0].event_end_date) {
            if (!event[0].event_start_date) {
                errorMsg += t("event.eventStartDateEmpty");
                $(`#event_date_start`).addClass("danger");
            } else if (!event[0].event_end_date) {
                errorMsg += t("event.eventEndDateEmpty");
                $(`#event_date_end`).addClass("danger");
            }
        }

        if (event[0].larp_start_datetime || event[0].larp_end_datetime) {
            if (larpStartDateTime.getTime() >= larpEndDateTime.getTime()) {
                errorMsg += t("event.startLARPDateEqualOrLaterThanEndDate");
                $(`#larp_date_start`).addClass("danger");
            }
        } else if (event[0].larp_start_datetime || event[0].larp_end_datetime) {
            if (!event[0].larp_start_datetime) {
                errorMsg += t("event.larpStartDateEmpty");
                $(`#larp_date_start`).addClass("danger");
            } else if (!event[0].larp_end_datetime) {
                errorMsg += t("event.larpEndDateEmpty");
                $(`#larp_date_end`).addClass("danger");
            }
        }

        if (event[0].event_price <= 0) {
            errorMsg += t("error.eventPriceLowerThan0");
        }

        if (avalPeriodConstr) {
            avalPeriodConstr.forEach((e, i) => {
                if (!e.aval_period_date) {
                    $(`#constrAvalPeriodDate${i}`).addClass("danger");
                    errorMsg += t("error.emptyPeriodDate");
                }
                if (!e.aval_period_label) {
                    $(`#constrAvalPeriodLabel${i}`).addClass("danger");
                    errorMsg += t("error.emptyPeriodLabel");
                }
            });
        }

        if (avalPeriodBar) {
            avalPeriodBar.forEach((e, i) => {
                const startTime = e.aval_period_start;
                const endTime = e.aval_period_end;
                if (!startTime) {
                    $(`#barAvalPeriodStart${i}`).addClass("danger");
                    errorMsg += t("error.emptyStartTime");
                }
                if (!endTime) {
                    $(`#barAvalPeriodEnd${i}`).addClass("danger");
                    errorMsg += t("error.emptyEndTime");
                }
                if (!e.aval_period_date) {
                    $(`#barAvalPeriodDate${i}`).addClass("danger");
                    errorMsg += t("error.emptyPeriodDate");
                }
                /* let midnight = new Date(endTime);
                midnight.setHours(0, 0, 0);
                if (startTime > endTime && endTime.getTime() !== midnight.getTime()) {
                    $(`#barAvalPeriodStart${i}`).addClass("danger");
                    errorMsg += t("error.startDateOlderThanEndDate");
                } */
            });
        }
        if (avalPeriodAccr) {
            avalPeriodAccr.forEach((e, i) => {
                const startTime = e.aval_period_start;
                const endTime = e.aval_period_end;
                /*let midnight = new Date(endTime);
                midnight.setHours(0, 0, 0);
                if (startTime > endTime && endTime.getTime() !== midnight.getTime()) {
                    $(`#accrAvalPeriodStart${i}`).addClass("danger");
                    errorMsg += t("error.startDateOlderThanEndDate");
                } */
                if (!startTime) {
                    $(`#accrAvalPeriodStart${i}`).addClass("danger");
                    errorMsg += t("error.emptyStartTime");
                }
                if (!endTime) {
                    $(`#accrAvalPeriodEnd${i}`).addClass("danger");
                    errorMsg += t("error.emptyEndTime");
                }
                if (!e.aval_period_date) {
                    $(`#accrAvalPeriodDate${i}`).addClass("danger");
                    errorMsg += t("error.emptyPeriodDate");
                }
            });
        }

        if (errorMsg) {
            onShowAlert({ variant: "danger", message: errorMsg });
        } else {
            //console.log(event);
            this.insertOrUpdateEvent();
        }
    };

    insertOrUpdateEvent = async () => {
        const { fetchURL, onChangeNavAndPage, onShowAlert, currentPage, t } = this.props;
        onShowAlert({ variant: "info", message: currentPage.subpage === "addActiveEvent" ? t("info.addingNewEvent") : t("info.editingNewEvent") });

        const passData = {
            event: this.state.event[0],
            avalPeriodConstr: this.state.avalPeriodConstr,
            avalPeriodBar: this.state.avalPeriodBar,
            avalPeriodAccr: this.state.avalPeriodAccr,
        };
        try {
            const response = await fetch(`${fetchURL}/${currentPage.subpage === "addActiveEvent" ? "insert-event" : "update-active-event"}`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === "warning") {
                        this.props.reloadEventInfo();
                        onShowAlert({ variant: "warning", message: t(`warning.${fetchedData.code}`), fading: 10000 });
                        onChangeNavAndPage({ nav: "settings", subpage: "settingsEdit", params: "" }, null);
                    } else {
                        this.props.reloadEventInfo();
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 3000 });
                        onChangeNavAndPage({ nav: "settings", subpage: "settingsEdit", params: "" }, null);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    addActiveEventView = async () => {
        const { fetchURL, onShowAlert, t } = this.props;

        try {
            const response = await fetch(`${fetchURL}/add-active-event-view`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else this.setState({ loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleChangeDate = (date, name) => {
        const { event } = this.state;
        const list = [...event];
        list[0][name] = date;
        this.setState({ event: list, changedValues: true });
        this.props.onChangeValuesInFormes();
    };

    getActiveEventDetails = async (eventCode) => {
        const { fetchURL, onShowAlert, t } = this.props;

        const passData = {
            eventCode: eventCode,
        };
        try {
            const response = await fetch(`${fetchURL}/active-event-details`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status !== true) onShowAlert({ variant: fetchedData.status, message: t(`${fetchedData.status}.${fetchedData.code}`), fading: 3000 });
                    else {
                        const event = fetchedData.activeEvent;
                        let showEventInputs = false,
                            showLarpInputs = false;

                        if (event[0].event_start_date && event[0].event_end_date) {
                            event[0].event_start_date = new Date(event[0].event_start_date);
                            event[0].event_end_date = new Date(event[0].event_end_date);
                            showEventInputs = true;
                        }
                        if (event[0].larp_start_datetime && event[0].larp_end_datetime) {
                            event[0].larp_start_datetime = new Date(event[0].larp_start_datetime);
                            event[0].larp_end_datetime = new Date(event[0].larp_end_datetime);
                            showLarpInputs = true;
                        }

                        if (fetchedData.avalPeriodBar)
                            fetchedData.avalPeriodBar.forEach((e) => {
                                e.aval_period_start = e.aval_period_start.replace(e.aval_period_date, "");
                                e.aval_period_end = e.aval_period_end.replace(e.aval_period_date, "");
                            });

                        if (fetchedData.avalPeriodAccr)
                            fetchedData.avalPeriodAccr.forEach((e) => {
                                e.aval_period_start = e.aval_period_start.replace(e.aval_period_date, "");
                                e.aval_period_end = e.aval_period_end.replace(e.aval_period_date, "");
                            });

                        this.setState({
                            event: event,
                            loading: false,
                            old_allow_users_apply: event[0].allow_users_apply,
                            showEventInputs: showEventInputs,
                            showLarpInputs: showLarpInputs,
                            avalPeriodConstr: fetchedData.avalPeriodConstr,
                            avalPeriodBar: fetchedData.avalPeriodBar,
                            avalPeriodAccr: fetchedData.avalPeriodAccr,
                            disableAvalPeriodEdition: fetchedData.status === "info" ? t(`info.${fetchedData.code}`) : false,
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        const { onResizeWindow, currentPage, activeEvent } = this.props;
        onResizeWindow("basic");
        if (currentPage.subpage === "editActiveEvent") this.getActiveEventDetails(activeEvent.code);
        else this.addActiveEventView();
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if ($(".scrolled-content").outerHeight() && !this.state.loading) onResizeWindow("basic");
        if (!this.state.loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { loading, changedValues, event, noPermission, showLarpInputs, avalPeriodConstr, avalPeriodBar, avalPeriodAccr, disableAvalPeriodEdition } = this.state;
        const { onCancelChangeValuesInFormes, onBackToPreviousPage, currentPage, t } = this.props;

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails
                                    t={t}
                                    header={currentPage.subpage === "editActiveEvent" ? t("event.editActiveEvent") : t("event.addActiveEvent")}
                                    changedValues={changedValues}
                                    onChangeView={onBackToPreviousPage}
                                    onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                                />
                                <Col></Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                                <div className="scrolled-content" style={{ display: "none" }}>
                                    <Container className="py-3">
                                        <Row>
                                            <Col xs={12}>
                                                <p className="h4 my-4 form-title">{t("settings.basicData")}</p>
                                            </Col>
                                            <Col lg={4} xs={12}>
                                                <TextForm
                                                    value={event[0].event_name}
                                                    onChangeValue={this.handleEditEventValue}
                                                    req
                                                    labelTxt={t("event.eventNameLabel")}
                                                    placeholderTxt={t("event.eventNamePlaceholder")}
                                                    name="event_name"
                                                    id="eventName"
                                                    helper={false}
                                                />
                                            </Col>
                                            {currentPage.subpage === "addActiveEvent" && (
                                                <Col lg={4} xs={12}>
                                                    <TextForm
                                                        value={event[0].event_code}
                                                        onChangeValue={this.handleEditEventValue}
                                                        req
                                                        labelTxt={t("event.eventCodeLabel")}
                                                        placeholderTxt={t("event.eventCodePlaceholder")}
                                                        name="event_code"
                                                        id="eventCode"
                                                        helper={false}
                                                    />
                                                </Col>
                                            )}
                                            <Col md={3} xs={12}>
                                                <Form.Label>
                                                    {t("event.accrPriceLabel")} <span className="required">*</span>
                                                </Form.Label>
                                                <InputGroup className="mb-3">
                                                    <FormControl
                                                        placeholder={t("event.accrPricePlaceholder")}
                                                        aria-label="eventPrice"
                                                        aria-describedby="eventPrice"
                                                        value={event[0].event_price}
                                                        onChange={(e) => {
                                                            this.handleEditEventValue(e);
                                                            $(`#event_price`).removeClass("danger");
                                                        }}
                                                        className="req"
                                                        name="event_price"
                                                        id="event_price"
                                                    />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text id="eventPrice">{t("currency")}</InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4} xs={12}>
                                                <RangeDate
                                                    req
                                                    txtLabel={t("calendar.eventDuration")}
                                                    setStartDate={(date) => this.handleChangeDate(date, "event_start_date")}
                                                    setEndDate={(date) => this.handleChangeDate(date, "event_end_date")}
                                                    startDate={event[0].event_start_date}
                                                    endDate={event[0].event_end_date}
                                                    id="event_date"
                                                    t={t}
                                                />
                                            </Col>
                                            <Col lg={4} xs={12}>
                                                {showLarpInputs ? (
                                                    <RangeDateAndTime
                                                        txtLabel={t("settings.larpDuration")}
                                                        setStartDate={(date) => this.handleChangeDate(date, "larp_start_datetime")}
                                                        setEndDate={(date) => this.handleChangeDate(date, "larp_end_datetime")}
                                                        startDate={event[0].larp_start_datetime}
                                                        endDate={event[0].larp_end_datetime}
                                                        id="larp_date"
                                                        minDate={event[0].event_start_date}
                                                        maxDate={event[0].event_end_date}
                                                        disabled={!event[0].event_start_date || !event[0].event_end_date ? true : false}
                                                        rangeTime={30}
                                                        t={t}
                                                    />
                                                ) : (
                                                    <Fragment>
                                                        <Form.Label>{t("settings.larpDuration")}</Form.Label>
                                                        <p className="opacity-4">{t("calendar.noDates")}</p>
                                                        <Button variant="outline-primary" size="sm" onClick={() => this.setState({ showLarpInputs: true })}>
                                                            {t("calendar.addDates")}
                                                        </Button>
                                                    </Fragment>
                                                )}
                                            </Col>
                                            <Col xs={12}>
                                                <Row>
                                                    <Col xs={12}>
                                                        <Checkbox
                                                            className="mt-4"
                                                            label={t("settings.allowRegister")}
                                                            checked={event[0].allow_users_apply}
                                                            onChange={(e, data) => {
                                                                const { event } = this.state;
                                                                const list = [...event];
                                                                list[0].allow_users_apply = data.checked;
                                                                this.setState({ event: list, changedValues: true });
                                                                this.props.onChangeValuesInFormes();
                                                            }}
                                                        />
                                                    </Col>

                                                    {!this.state.old_allow_users_apply && event[0].allow_users_apply && (
                                                        <Col lg={8} xs={12}>
                                                            <Alert variant="info" className="alert-small">
                                                                {t("settings.createEmailTmplAlert")}
                                                            </Alert>
                                                        </Col>
                                                    )}
                                                    {event[0].allow_users_apply && (
                                                        <Col xs={12} lg={6} xl={4}>
                                                            <CalendarDateAndTime
                                                                txtLabel={t("calendar.deadlineRegister")}
                                                                onChangeCalendarDate={(date) => {
                                                                    const list = [...event];
                                                                    list[0].allow_users_apply_deadline = date;
                                                                    this.setState({ event: list, changedValues: true });
                                                                    this.props.onChangeValuesInFormes();
                                                                }}
                                                                value={event[0].allow_users_apply_deadline ? new Date(event[0].allow_users_apply_deadline) : null}
                                                                id="allow_users_apply_deadline"
                                                                t={t}
                                                            />
                                                        </Col>
                                                    )}
                                                </Row>
                                            </Col>
                                            <Col xs={12}>
                                                <Row>
                                                    <Col xs={12}>
                                                        <Checkbox
                                                            className="mt-2"
                                                            label={t("settings.allowOrder")}
                                                            checked={event[0].allow_users_order}
                                                            onChange={(e, data) => {
                                                                const { event } = this.state;
                                                                const list = [...event];
                                                                list[0].allow_users_order = data.checked;
                                                                this.setState({ event: list, changedValues: true });
                                                                this.props.onChangeValuesInFormes();
                                                            }}
                                                        />
                                                    </Col>
                                                    {event[0].allow_users_order && (
                                                        <Col xs={12} lg={6} xl={4}>
                                                            {
                                                                <CalendarDateAndTime
                                                                    txtLabel={t("calendar.deadlineOrder")}
                                                                    onChangeCalendarDate={(date) => {
                                                                        const list = [...event];
                                                                        list[0].allow_users_order_deadline = date;
                                                                        this.setState({ event: list, changedValues: true });
                                                                        this.props.onChangeValuesInFormes();
                                                                    }}
                                                                    value={event[0].allow_users_order_deadline ? new Date(event[0].allow_users_order_deadline) : null}
                                                                    id="allow_users_order_deadline"
                                                                    t={t}
                                                                />
                                                            }
                                                        </Col>
                                                    )}
                                                </Row>
                                            </Col>
                                            <Col xs={12}>
                                                <Checkbox
                                                    className="mt-2"
                                                    label={t("settings.allowApply")}
                                                    checked={event[0].allow_users_applications}
                                                    onChange={(e, data) => {
                                                        const { event } = this.state;
                                                        const list = [...event];
                                                        list[0].allow_users_applications = data.checked;
                                                        this.setState({ event: list, changedValues: true });
                                                        this.props.onChangeValuesInFormes();
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12}>
                                                <Checkbox
                                                    className="mt-2"
                                                    label={t("settings.allowCharacters")}
                                                    checked={event[0].allow_users_characters}
                                                    onChange={(e, data) => {
                                                        const { event } = this.state;
                                                        const list = [...event];
                                                        list[0].allow_users_characters = data.checked;
                                                        this.setState({ event: list, changedValues: true });
                                                        this.props.onChangeValuesInFormes();
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <p className="h4 my-4 form-title">{t("settings.availabilities")}</p>
                                            </Col>
                                            {disableAvalPeriodEdition ? (
                                                <Alert variant="info">{disableAvalPeriodEdition}</Alert>
                                            ) : (
                                                <Fragment>
                                                    <Col xs={12}>
                                                        <p className="h5 my-3 form-title">{t("settings.constrForm")}</p>
                                                    </Col>
                                                    {avalPeriodConstr ? (
                                                        <ConstructionAvalPeriods
                                                            t={t}
                                                            onChangeAvalPeriodValue={(list) => {
                                                                this.setState({ avalPeriodConstr: list, changedValues: true });
                                                                this.props.onChangeValuesInFormes();
                                                            }}
                                                            avalPeriod={avalPeriodConstr}
                                                        />
                                                    ) : (
                                                        <Col xs={12}>
                                                            <Button
                                                                size="sm"
                                                                variant="primary"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        avalPeriodConstr: [
                                                                            {
                                                                                aval_period_date: "",
                                                                                aval_period_label: "",
                                                                            },
                                                                        ],
                                                                    })
                                                                }
                                                            >
                                                                {t("settings.addAvalOption")}
                                                            </Button>
                                                        </Col>
                                                    )}
                                                    <Col xs={12}>
                                                        <p className="h5 my-3 mt-4 form-title">{t("settings.barForm")}</p>
                                                    </Col>
                                                    {avalPeriodBar ? (
                                                        <BarAvalPeriods
                                                            t={t}
                                                            onChangeAvalPeriodValue={(list) => {
                                                                this.setState({ avalPeriodBar: list, changedValues: true });
                                                                this.props.onChangeValuesInFormes();
                                                            }}
                                                            avalPeriod={avalPeriodBar}
                                                        />
                                                    ) : (
                                                        <Col xs={12}>
                                                            <Button
                                                                size="sm"
                                                                variant="primary"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        avalPeriodBar: [
                                                                            {
                                                                                aval_period_date: "",
                                                                                aval_period_start: "",
                                                                                aval_period_end: "",
                                                                                aval_period_label: "",
                                                                            },
                                                                        ],
                                                                    })
                                                                }
                                                            >
                                                                {t("settings.addAvalOption")}
                                                            </Button>
                                                        </Col>
                                                    )}
                                                    <Col xs={12}>
                                                        <p className="h5 my-3 mt-4 form-title">{t("settings.accrForm")}</p>
                                                    </Col>
                                                    {avalPeriodAccr ? (
                                                        <AccrAvalPeriods
                                                            t={t}
                                                            onChangeAvalPeriodValue={(list) => {
                                                                this.setState({ avalPeriodAccr: list, changedValues: true });
                                                                this.props.onChangeValuesInFormes();
                                                            }}
                                                            avalPeriod={avalPeriodAccr}
                                                        />
                                                    ) : (
                                                        <Col xs={12}>
                                                            <Button
                                                                size="sm"
                                                                variant="primary"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        avalPeriodAccr: [
                                                                            {
                                                                                aval_period_date: null,
                                                                                aval_period_start: null,
                                                                                aval_period_end: null,
                                                                                aval_period_label: "",
                                                                            },
                                                                        ],
                                                                    })
                                                                }
                                                            >
                                                                {t("settings.addAvalOption")}
                                                            </Button>
                                                            <Button
                                                                size="sm"
                                                                variant="primary"
                                                                onClick={() => {
                                                                    this.props.onChangeValuesInFormes();
                                                                    this.setState({ avalPeriodAccr: avalPeriodBar, changedValues: true });
                                                                }}
                                                                disabled={!avalPeriodBar}
                                                                className="ml-2"
                                                            >
                                                                {t("settings.copyAvalPeriods")}
                                                            </Button>
                                                        </Col>
                                                    )}
                                                </Fragment>
                                            )}
                                        </Row>
                                    </Container>
                                </div>
                                <div className="content-bottom">
                                    <Container>
                                        <Row>
                                            <Col xs={12} className="py-3">
                                                {!changedValues ? (
                                                    <TooltipWithDisabledButton
                                                        t={t}
                                                        mssgTooltip={t("form.noChanges")}
                                                        btnTxt={currentPage.subpage === "editActiveEvent" ? t("edit") : t("add")}
                                                        tooltipID="tooltipDisabledNotFilled"
                                                        size="lg"
                                                    />
                                                ) : (
                                                    <Button type="submit" variant="primary" size="lg">
                                                        {currentPage.subpage === "editActiveEvent" ? t("edit") : t("add")}
                                                    </Button>
                                                )}
                                                <Button onClick={onBackToPreviousPage} variant="outline-primary" className="ml-3">
                                                    {t("cancel")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Form>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default AddActiveEvent;
