import React, { Component, Fragment } from "react";
import LogoAndTitle from "./LogoAndTitle";
import { Form, Row, Col, Button } from "react-bootstrap";
import Email from "./../forms/Email";
import $ from "jquery";

const validateEmail = (email) => {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

class ContactToAdmin extends Component {
    state = {
        email: "",
        name: "",
        title: "",
        message: "",
    };

    handleChangeValue = (e) => {
        const { id } = e.target;
        $(`#${id}`).removeClass("danger");
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSendMssg = (e) => {
        e.preventDefault();
        const { title, message, email, name } = this.state;
        const { onShowAlert, t } = this.props;
        onShowAlert("");
        let errorMsg = false;

        $(".form-control.req").each(function () {
            if ($(this).val() === "" || $(this).val() === "default") {
                $(this).addClass("danger");
                errorMsg = t("error.reqFields");
            } else $(this).removeClass("danger");
        });

        if (email !== "" && !validateEmail(email)) {
            errorMsg += t("error.invalidEmail");
            $("#userEmail").addClass("danger");
        }
        if (errorMsg) {
            onShowAlert({ variant: "danger", message: errorMsg });
        } else {
            const data = {
                email: email,
                title: title,
                name: name,
                message: message,
            };

            this.sendEmail(data);
        }
    };

    sendEmail = async (dataPass) => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/send-email`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dataPass),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(data);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    render() {
        const { email, title, message, name } = this.state;
        const { t, onShowHomePage } = this.props;
        return (
            <Fragment>
                <LogoAndTitle title={"contactWithAdminModal.contactWithAdmin"} />
                <Row>
                    <Col lg={{ span: 8, offset: 2 }}>
                        <div dangerouslySetInnerHTML={{ __html: t("contactWithAdminModal.text") }}></div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ span: 8, offset: 2 }}>
                        <Form onSubmit={this.handleSendMssg} autoComplete="off">
                            <Email t={t} onShowHomePage={onShowHomePage} value={email} name="email" onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })} />
                            <Form.Group>
                                <Form.Label>{t("form.nameAndSurname")}</Form.Label>
                                <Form.Control
                                    id="mailNameAndSurname"
                                    type="text"
                                    placeholder={t("form.introduce")}
                                    value={name}
                                    name="name"
                                    onChange={this.handleChangeValue}
                                    autoComplete="nope"
                                    className="req"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{t("contactWithAdminModal.titleLabel")}</Form.Label>
                                <Form.Control
                                    id="mailTitle"
                                    type="text"
                                    placeholder={t("contactWithAdminModal.titlePlaceholder")}
                                    value={title}
                                    name="title"
                                    onChange={this.handleChangeValue}
                                    autoComplete="nope"
                                    className="req"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{t("contactWithAdminModal.mssgLabel")}</Form.Label>
                                <Form.Control
                                    id="mailText"
                                    as="textarea"
                                    rows="5"
                                    placeholder={t("contactWithAdminModal.mssgPlaceholder")}
                                    name="message"
                                    value={message}
                                    onChange={this.handleChangeValue}
                                    className="req"
                                />
                            </Form.Group>
                            <Form.Group className="text-center">
                                <Button variant="primary" type="submit">
                                    {t("send")}
                                </Button>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default ContactToAdmin;
