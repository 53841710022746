import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Fragment } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import ShowQRCode from "../other/ShowQRCode";

class ScanQRCodeForm extends Component {
    state = {
        showScanner: false,
        alertIdentNotUnique: "",
    };

    checkIdentUniqueness = async (identCode) => {
        const { fetchURL, onShowAlert, onChangeQRCodeValue, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.scanningCode") });

        const passData = {
            identCode: identCode,
        };
        try {
            const response = await fetch(`${fetchURL}/check-ident-uniqueness`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notUnique") onShowAlert({ variant: "warning", message: t(`warning.${fetchedData.code}`), fading: 3000 });
                    else {
                        onShowAlert("");
                        onChangeQRCodeValue(fetchedData.hashedCode);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleScanQRCode = (identCode) => {
        this.setState({ showScanner: false });
        if (identCode) this.checkIdentUniqueness(identCode);
    };

    render() {
        const { showScanner, alertIdentNotUnique } = this.state;
        const { scannerHeight, qrCode, onResetIdent, t, onShowAlert } = this.props;
        return (
            <Fragment>
                <Form.Label>{t("ident.ident")}</Form.Label>
                <Form.Control id="qrCode" type="hidden" name="qrCode" value={qrCode ? qrCode : ""} />
                <div>
                    <p className="d-inline-block mr-2">
                        <span className={qrCode ? "" : "opacity-4"}>{qrCode ? t("ident.codeScanned") : t("lack")}</span>
                    </p>
                    <Button className="mb-3" variant="outline-secondary" onClick={() => this.setState({ showScanner: true })}>
                        {qrCode ? t("ident.scanNewCode") : t("ident.assignCode")}
                    </Button>
                    {qrCode && (
                        <Button variant="outline-secondary" onClick={onResetIdent} className="ml-2 mb-3">
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    )}
                </div>
                {alertIdentNotUnique && (
                    <Alert variant="danger" className="d-block alert-small w-100">
                        {alertIdentNotUnique}
                    </Alert>
                )}
                {showScanner && (
                    <ShowQRCode
                        qrcode={qrCode}
                        onScanQRCode={this.handleScanQRCode}
                        qrReaderHeight={scannerHeight}
                        showScanner={showScanner}
                        onHideScanner={() => this.setState({ showScanner: false })}
                        t={t}
                        onShowAlert={onShowAlert}
                    />
                )}
            </Fragment>
        );
    }
}

export default ScanQRCodeForm;
