import React, { Fragment } from "react";
import ParticipantsList from "../../pages/participants/ParticipantsList";
import ParticipantDetails from "../../pages/participants/participant-details/ParticipantDetails";
import AddEditParticipant from "../../pages/participants/add-edit-participant/AddEditParticipant";
import AccreditParticipant from "../../pages/participants/AccreditParticipant";
import PostPayment from "../../pages/participants/PostPayment";
import PaymentsList from "../../pages/participants/PaymentsList";

const Participants = (props) => {
    const {
        fetchURL,
        onCheckUserPrivilage,
        onChangeValuesInFormes,
        onCancelChangeValuesInFormes,
        scannerHeight,
        onGetDataList,
        onChangeNavAndPage,
        dataList,
        currentPage,
        onResizeWindow,
        numberOfRecords,
        activeEvent,
        onBackToPreviousPage,
        onShowAlert,
        onChangeNOR,
        t,
        onShowHomePage,
    } = props;
    return (
        <Fragment>
            {currentPage.nav === "participants" && (
                <Fragment>
                    {currentPage.subpage === "participantsList" && (
                        <ParticipantsList
                            onResizeWindow={onResizeWindow}
                            fetchURL={fetchURL}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            onChangeNavAndPage={onChangeNavAndPage}
                            currentPage={currentPage}
                            activeEvent={activeEvent}
                            onShowAlert={onShowAlert}
                            onGetDataList={onGetDataList}
                            dataList={dataList}
                            numberOfRecords={numberOfRecords}
                            onChangeNOR={onChangeNOR}
                            eventFilterValue={props.eventFilterValue}
                            eventsList={props.eventsList}
                            onChangeEventFilter={props.onChangeEventFilter}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {(currentPage.subpage === "addParticipant" || currentPage.subpage === "editParticipant") && (
                        <AddEditParticipant
                            fetchURL={fetchURL}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            scannerHeight={scannerHeight}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onResizeWindow={onResizeWindow}
                            onBackToPreviousPage={onBackToPreviousPage}
                            activeEvent={activeEvent}
                            onShowAlert={onShowAlert}
                            currentPage={currentPage}
                            t={t}
                            onShowHomePage={onShowHomePage}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                        />
                    )}
                    {currentPage.subpage === "participantDetails" && (
                        <ParticipantDetails
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            fetchURL={fetchURL}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onResizeWindow={onResizeWindow}
                            onBackToPreviousPage={onBackToPreviousPage}
                            onShowAlert={onShowAlert}
                            currentPage={currentPage}
                            t={t}
                            onShowHomePage={onShowHomePage}
                            activeEvent={activeEvent}
                        />
                    )}
                    {currentPage.subpage === "accreditParticipant" && (
                        <AccreditParticipant
                            fetchURL={fetchURL}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            currentPage={currentPage}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            scannerHeight={scannerHeight}
                            onResizeWindow={onResizeWindow}
                            onBackToPreviousPage={onBackToPreviousPage}
                            onShowAlert={onShowAlert}
                            activeEvent={activeEvent}
                            onChangeNavAndPage={onChangeNavAndPage}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {currentPage.subpage === "paymentsList" && (
                        <PaymentsList
                            fetchURL={fetchURL}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            currentPage={currentPage}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            onResizeWindow={onResizeWindow}
                            onBackToPreviousPage={onBackToPreviousPage}
                            onShowAlert={onShowAlert}
                            onChangeNavAndPage={onChangeNavAndPage}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {currentPage.subpage === "postPayment" && (
                        <PostPayment
                            fetchURL={fetchURL}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            currentPage={currentPage}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            onResizeWindow={onResizeWindow}
                            onBackToPreviousPage={onBackToPreviousPage}
                            onShowAlert={onShowAlert}
                            onChangeNavAndPage={onChangeNavAndPage}
                            activeEvent={activeEvent}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Participants;
