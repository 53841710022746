import React, { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";
const LogoutModal = (props) => {
    const { show, onClose, onLogout, t } = props;
    return (
        <Fragment>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("logout.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("logout.text")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={onClose} type="button">
                        {t("cancel")}
                    </Button>
                    <Button variant="primary" type="button" onClick={onLogout}>
                        {t("logout.logoutBtn")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default LogoutModal;
