import React, { Fragment, Component } from "react";
import { Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

class TableReceptionistsList extends Component {
    state = {
        currentSort: "default",
        sortingColName: "sortID",
    };

    handleSorting = (btnID) => {
        const { currentSort } = this.state;
        let nextSort;
        $(".fas").removeClass("fa-sort-down").removeClass("fa-sort-up").removeClass("fa-sort").addClass("fa-sort");
        if (currentSort === "down") {
            nextSort = "up";
            $("#" + btnID + " .fas")
                .removeClass("fa-sort-down")
                .addClass("fa-sort-up");
        } else if (currentSort === "up") {
            nextSort = "default";
            $("#" + btnID + " .fas")
                .removeClass("fa-sort-up")
                .addClass("fa-sort");
        } else if (currentSort === "default") {
            nextSort = "down";
            $("#" + btnID + " .fas")
                .removeClass("fa-sort")
                .addClass("fa-sort-down");
        }

        this.setState({
            currentSort: nextSort,
            sortingColName: btnID,
        });
    };

    render() {
        const { currentSort, sortingColName } = this.state;
        const { receptionists, numOfRecords, onChangeNavAndPage, t, onCheckUserPrivilage } = this.props;
        const sortTypes = {
            up: {
                fnID: (a, b) => a.user_id - b.user_id, //metoda sortowania (patrz: https://www.w3schools.com/jsref/jsref_sort.asp )
                /* fnEmail: (a, b) => {
                    if (a.user_email.toUpperCase() > b.user_email.toUpperCase()) return 1;
                    else return -1;
                }, */
            },
            down: {
                fnID: (a, b) => b.user_id - a.user_id,
                /* fnEmail: (a, b) => {
                    if (a.user_email.toUpperCase() > b.user_email.toUpperCase()) return -1;
                    else return 1;
                }, */
            },
            default: {
                fnID: (a, b) => a,
                /* fnEmail: (a, b) => a, */
            },
        };

        let sortedData = [];

        if (sortingColName === "sortID") sortedData = receptionists.sort(sortTypes[currentSort].fnID);
        /* else if (sortingColName === "sortEmail") 
            sortedData = receptionists.sort(sortTypes[currentSort].fnEmail); */ else sortedData = receptionists.sort(sortTypes[currentSort].fnID);

        const currentPageParams = { nav: "receptionists", subpage: "receptionistsList", params: "" };
        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th>{t("on")}</th>
                            <th>{t("receptionist.receptionistsList.surname")}</th>
                            <th>{t("receptionist.receptionistsList.nickname")}</th>
                            <th>{t("receptionist.receptionistsList.name")}</th>
                            <th>{t("receptionist.receptionistsList.role")}</th>
                            <th>{t("receptionist.receptionistsList.status")}</th>
                            <th>{t("receptionist.receptionistsList.lastLogin")}</th>
                            {onCheckUserPrivilage("COMMON_USER_DETAILS") && <th>{t("dropdowns.actions")}</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData
                            .map((p, i) => (
                                <tr key={`${p.user_id}_${p.user_name}`}>
                                    <td>{i + 1}</td>
                                    <td>{p.user_surname}</td>
                                    <td>{p.user_nickname ? p.user_nickname : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{p.user_name}</td>
                                    <td>{p.user_role_name}</td>
                                    <td>{p.user_stat_name}</td>
                                    <td>{p.last_login_date ? p.last_login_date : <span className="opacity-4">{t("lack")}</span>}</td>

                                    {onCheckUserPrivilage("COMMON_USER_DETAILS") && (
                                        <td className="text-center">
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`receptionistDetailsTooltip${i}`}>{t("user.seeUserDetails")}</Tooltip>}>
                                                <span className="d-inline-block">
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() => onChangeNavAndPage({ nav: "users", subpage: "userDetails", params: { userID: p.user_id } }, currentPageParams)}
                                                    >
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>
                                        </td>
                                    )}
                                </tr>
                            ))
                            .slice(0, numOfRecords)}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}

export default TableReceptionistsList;
