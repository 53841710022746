import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, Component } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import AlertNoPermission from "../../../alerts/AlertNoPermission";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";

class BarClient extends Component {
    state = {
        noPermission: false,
        loading: true,
        barClientOrders: null,
        barClientData: null,
        barClientSummary: null,
        showModal: null,
    };

    getPartBarOrders = async (partID) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            partID: partID,
        };

        try {
            const response = await fetch(`${fetchURL}/bar-client-details`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ barClientData: fetchedData.barClientData, barClientOrders: fetchedData.barClientOrders, barClientSummary: fetchedData.barClientSummary, loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    sendReminder = async (userID, eventCode) => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t(`info.sendingEmail`) });
        const passData = {
            userID: userID,
            eventCode: eventCode,
        };
        try {
            const response = await fetch(`${fetchURL}/send-reminder-for-bar-balance`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        onShowAlert({ variant: "success", message: t(`info.messageSentSuccessfully`), fading: 3000 });
                        this.setState({ showModal: null });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        this.getPartBarOrders(this.props.partID);
    }

    render() {
        const { loading, noPermission, barClientData, barClientOrders, barClientSummary, showModal } = this.state;
        const { t, onChangeNavAndPage, currentPageParams } = this.props;
        return (
            <Fragment>
                <Col xs={12}>
                    <p className="h3 my-3 d-inline-block mr-3 align-middle">{t("barClients.title")}</p>
                </Col>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : (
                    <Fragment>
                        <Col xs={12} className="mb-3">
                            {loading ? (
                                <LoadingProgressBar t={t} />
                            ) : !barClientData ? (
                                <p className="opacity-4">{t("barClients.noBarClient")}</p>
                            ) : (
                                <Row>
                                    <Col lg={8} xs={12}>
                                        <Table bordered hover className="table-details" responsive="lg" id={`barClientDetailsTable`}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "40%" }}>{t("barClients.barClientType")}</td>
                                                    <td>{t(`barClients.types.${barClientData.bar_client_type_code}`)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("barClients.barClientStatus")}</td>
                                                    <td>
                                                        <span
                                                            className={
                                                                barClientData.bar_client_stat_code === "BANNED"
                                                                    ? "text-danger"
                                                                    : barClientData.bar_client_stat_code === "INACTIVE"
                                                                    ? "text-info"
                                                                    : "text-primary"
                                                            }
                                                        >
                                                            {t(`barClients.statuses.${barClientData.bar_client_stat_code}`)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{t("barClients.barClientLimit")}</td>
                                                    <td>
                                                        {!barClientData.amount_limit ? (
                                                            <span className="opacity-4">{t("barClients.noLimit")}</span>
                                                        ) : (
                                                            <span>
                                                                {barClientData.amount_limit} {t("currency")}
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{t("barClients.barClientFree")}</td>
                                                    <td>
                                                        {!barClientData.amount_free || barClientData.amount_free === 0 ? (
                                                            <span className="opacity-4">{t("lack")}</span>
                                                        ) : (
                                                            <span>
                                                                {barClientData.amount_free} {t("currency")}
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{t("barClients.ordersSum")}</td>
                                                    <td>
                                                        <span>
                                                            {barClientSummary.sum_orders_value} {t("currency")}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{t("barClients.transactionResult")}</td>
                                                    <td>
                                                        {barClientData.payment_amount_bar ? (
                                                            <span>
                                                                {barClientData.payment_amount_bar} {t("currency")}
                                                            </span>
                                                        ) : (
                                                            <span className="opacity-4">{t("payment.noPayments")}</span>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{t("barClients.clientBalance")}</td>
                                                    <td>
                                                        <span className={barClientSummary.client_balance < 0 ? "text-danger" : barClientSummary.client_balance === 0 ? "" : "text-primary"}>
                                                            {barClientSummary.client_balance} {t("currency")}
                                                        </span>
                                                        {barClientSummary.client_balance < 0 && (
                                                            <Button
                                                                className="ml-2"
                                                                variant="outline-secondary"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        showModal: {
                                                                            option: "sendReminder",
                                                                            eventCode: barClientSummary.event_code,
                                                                            userID: this.props.userID,
                                                                            userFullname: barClientSummary.user_fullname,
                                                                            amount: -1 * barClientSummary.client_balance,
                                                                        },
                                                                    })
                                                                }
                                                            >
                                                                {t("payment.remindAboutPayment")}
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{t("barClients.createdBy")}</td>
                                                    <td>
                                                        <Button
                                                            variant="link"
                                                            onClick={() =>
                                                                onChangeNavAndPage({ nav: "users", subpage: "userDetails", params: { userID: barClientData.create_user_id } }, currentPageParams)
                                                            }
                                                        >
                                                            {barClientData.user_fullname}
                                                        </Button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{t("barClients.createDate")}</td>
                                                    <td>{barClientData.create_date}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("barClients.modifyDate")}</td>
                                                    <td>{barClientData.modify_date}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <p className="mb-3 mt-4 h5">{t("barClients.barClientOrders")}</p>
                                        {barClientOrders.length === 0 ? (
                                            <p className="opacity-4">{t("barClients.noOrders")}</p>
                                        ) : (
                                            <Table bordered hover size="sm" className="table-list">
                                                <thead>
                                                    <tr>
                                                        <th>{t("no.")}</th>
                                                        <th>{t("barClients.barOrders.amount")}</th>
                                                        <th>{t("barClients.barOrders.products")}</th>
                                                        <th>{t("barClients.barOrders.status")}</th>
                                                        <th>{t("barClients.barOrders.date")}</th>
                                                        <th>{t("barClients.barOrders.creator")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {barClientOrders.map((e, i) => (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td>
                                                                {barClientData.bar_client_type_code === "INNER" ? e.inner_price : e.price} {t("currency")}{" "}
                                                                {e.ingame_currency_price > 0 && (
                                                                    <span>
                                                                        {e.ingame_currency_price} {t("currencyIngame")}
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {e.amount}x {e.bar_item_name}
                                                            </td>
                                                            <td>
                                                                {e.bar_order_stat_code === "EXECUTED" ? (
                                                                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                                                                ) : e.bar_order_stat_code === "CANCELED" ? (
                                                                    <FontAwesomeIcon icon={faTimes} className="text-danger" />
                                                                ) : (
                                                                    <span></span>
                                                                )}
                                                            </td>
                                                            <td>{e.create_date}</td>
                                                            <td>
                                                                <Button
                                                                    variant="link"
                                                                    onClick={() =>
                                                                        onChangeNavAndPage({ nav: "users", subpage: "userDetails", params: { userID: e.create_user_id } }, currentPageParams)
                                                                    }
                                                                >
                                                                    {e.user_fullname}
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        )}
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Fragment>
                )}

                {showModal && (
                    <Fragment>
                        {showModal.option === "sendReminder" && (
                            <Modal show={true} onHide={() => this.setState({ showModal: null })}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{t("barClients.sendReminderModal.title")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p className="mb-3">
                                        {t("barClients.sendReminderModal.text")} <b>{showModal.userFullname}</b>?
                                    </p>
                                    <p>
                                        {t("barClients.sendReminderModal.text2")}: {showModal.amount} {t("currency")}
                                    </p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-secondary" onClick={() => this.setState({ showModal: null })}>
                                        {t("cancel")}
                                    </Button>
                                    <Button variant="primary" type="button" onClick={() => this.sendReminder(showModal.userID, showModal.eventCode)}>
                                        {t("yes")}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default BarClient;
