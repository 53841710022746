import React from "react";
import { Fragment } from "react";
import { Form, Radio } from "semantic-ui-react";

const PurposePaymentTypes = (props) => {
    const { onChangeValue, purposePayment, name, paymentSide, activeEvent, t } = props;
    return (
        <Fragment>
            {paymentSide !== "INNERTRANS" && (
                <Form.Field>
                    <Radio label={t("payment.purpose.accr")} name={name} value="ACCR" checked={purposePayment === "ACCR"} onChange={onChangeValue} />
                </Form.Field>
            )}
            <Form.Field>
                <Radio label={t("payment.purpose.bar")} name={name} value="BAR" checked={purposePayment === "BAR"} onChange={onChangeValue} />
            </Form.Field>
            <Form.Field>
                <Radio label="Sklepik" name={name} value="SHOP" checked={purposePayment === "SHOP"} onChange={onChangeValue} />
            </Form.Field>
            {paymentSide === "INNERTRANS" && activeEvent && (
                <Form.Field>
                    <Radio label={t("payment.purpose.part")} name={name} value="PART" checked={purposePayment === "PART"} onChange={onChangeValue} />
                </Form.Field>
            )}
        </Fragment>
    );
};

export default PurposePaymentTypes;
