import React from "react";
import { Fragment } from "react";
import { Alert } from "react-bootstrap";

const AlertNoPermission = (props) => {
    const { t } = props;
    return (
        <Fragment>
            <Alert variant="danger" className="w-100 alert-small">
                {t("alerts.noPermission")}
            </Alert>
        </Fragment>
    );
};

export default AlertNoPermission;
