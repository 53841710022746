import React, { Fragment, Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import $ from "jquery";
import Email from "../../forms/Email";
import Password from "../../forms/Password";
import ShowQRCode from "../../other/ShowQRCode";

class Login extends Component {
    state = {
        loggedIn: false,
        email: "",
        password: "",
        qrCode: "",
        showScanner: false,
    };

    //walidacja poprawności maila
    validateEmail = (email) => {
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            $("#userEmail").addClass("danger");
            return this.props.t("error.invalidEmail");
        } else return "";
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        const { onShowAlert, t } = this.props;
        onShowAlert("");
        let errorMsg = "";
        if (!email) {
            errorMsg += t("error.emptyEmail");
            $("#userEmail").addClass("danger");
        } else {
            const validate = this.validateEmail(email);
            if (validate) {
                errorMsg += validate;
                $("#userEmail").addClass("danger");
            }
        }

        if (!password) {
            errorMsg += t("error.emptyPassword");
            $("#userPassword").addClass("danger");
        }

        if (errorMsg) {
            onShowAlert({ variant: "danger", message: errorMsg });
        } else {
            const userdata = {
                email: this.state.email,
                password: this.state.password,
            };
            this.login(userdata);
        }
    };

    login = async (loginData) => {
        const { fetchURL, onShowAlert, onPassLoggedUserData, moduleName, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.logging") });
        const passData = {
            loginData: loginData,
            moduleName: moduleName,
        };
        try {
            const response = await fetch(`${fetchURL}/login`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "inactive profile" || fetchedData.status === "reset pass") {
                        onShowAlert({
                            variant: "danger",
                            message: t(`error.${fetchedData.code}`),
                            contactBtn: {
                                mailAddress: "",
                                mailTitle: fetchedData.status === "inactive profile" ? t("user.inactiveAccount") : fetchedData.status === "reset pass" ? t("user.resetPass") : "",
                                mailSender: "",
                            },
                        });
                    } else onPassLoggedUserData(fetchedData.userPrivilages, fetchedData.cookieExpDate);
                });
            }
        } catch (err) {
            this.handleShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleScanQRCode = (code) => {
        this.setState({ showScanner: false });
        const userQRcode = { qrCode: code };
        this.login(userQRcode);
    };

    render() {
        const { email, password, qrCode, showScanner } = this.state;
        const { scannerHeight, onShowAlert, t } = this.props;
        return (
            <Fragment>
                <Row>
                    <Col lg={{ span: 6, offset: 3 }}>
                        <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                            <Email
                                t={t}
                                value={email}
                                onChangeValue={(e) => {
                                    this.setState({ [e.target.name]: e.target.value });
                                    $(`#userEmail`).removeClass("danger");
                                }}
                                id="userEmail"
                                name="email"
                            />
                            <Password t={t} value1={password} name="password" onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })} option="single" />
                            <Form.Group className="text-center">
                                <Button variant="primary" type="submit">
                                    {t("user.login")}
                                </Button>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center mt-4">
                        <Button
                            variant="dark"
                            size="lg"
                            onClick={() => {
                                onShowAlert("");
                                this.setState({ showScanner: true });
                            }}
                            name="qrCode"
                        >
                            {t("ident.scanQRCode")}
                        </Button>
                    </Col>
                </Row>
                {showScanner && (
                    <ShowQRCode
                        qrcode={qrCode}
                        onScanQRCode={this.handleScanQRCode}
                        qrReaderHeight={scannerHeight}
                        showScanner={showScanner}
                        onHideScanner={() => this.setState({ showScanner: false })}
                        t={t}
                        onShowAlert={onShowAlert}
                    />
                )}
            </Fragment>
        );
    }
}

export default Login;
