import React, { Component, Fragment } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import PartToDutyDropdown from "../forms/dropdowns/PartToDutyDropdown";
import { faCheck, faInfoCircle, faPlus, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertNoPermission from "../alerts/AlertNoPermission";
import $ from "jquery";
import LoadingProgressBar from "../spinners/LoadingProgressBar";

class SelectPartsToDutyModal extends Component {
    state = { changedValues: false, partsListSelected: [], partsListDropdown: [], partsListDownloaded: [], noPermission: false, loading: true, alert: null };

    getPartsToPeriod = async () => {
        const { fetchURL, onShowAlert, t, period, activeEvent, dutyTypeCode } = this.props;
        const passData = {
            periodID: period.duty_period_id,
            activeEvent: activeEvent,
            dutyTypeCode: dutyTypeCode,
        };
        try {
            const response = await fetch(`${fetchURL}/get-parts-to-period`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        if (fetchedData.status !== true) this.setState({ alert: { variant: fetchedData.status, message: t(`${fetchedData.status}.${fetchedData.code}`) } });
                        const list = [],
                            partsListDownloaded = [],
                            partsListDropdown = fetchedData.partsList;
                        partsListDropdown.forEach((e, i) => {
                            partsListDownloaded.push(e);
                            list.push({
                                key: i + 1,
                                value: e.part_id,
                                text: (
                                    <p>
                                        {e.user_fullname}{" "}
                                        {e.part_a2p_answer === "Y" ? (
                                            <span>
                                                <FontAwesomeIcon icon={faCheck} className="text-success" />
                                            </span>
                                        ) : e.part_a2p_answer === "M" ? (
                                            <span>
                                                <FontAwesomeIcon icon={faQuestion} className="text-warning" />
                                            </span>
                                        ) : (
                                            <Fragment></Fragment>
                                        )}
                                    </p>
                                ),
                            });
                        });

                        //console.log("partsListDownloaded: ", partsListDownloaded);
                        const { partsListSelected } = this.state;
                        //console.log("partsListSelected: ", partsListSelected);

                        if (this.props.option === "edit") {
                            //dodawanie wybranych uczestników w trybie edycji jeśli nie są na liście w dropdownie
                            partsListSelected.forEach((e) => {
                                if (e) {
                                    let partIsOnTheList = false;
                                    list.forEach((el) => {
                                        if (el.value === e.partID) partIsOnTheList = true;
                                    });
                                    if (!partIsOnTheList)
                                        list.push({
                                            key: list.length + 1,
                                            value: e.partID,
                                            text: (
                                                <p>
                                                    {e.partName}{" "}
                                                    {e.partAnswer === "Y" ? (
                                                        <span>
                                                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                                                        </span>
                                                    ) : e.partAnswer === "M" ? (
                                                        <span>
                                                            <FontAwesomeIcon icon={faQuestion} className="text-warning" />
                                                        </span>
                                                    ) : (
                                                        <Fragment></Fragment>
                                                    )}
                                                </p>
                                            ),
                                        });
                                }
                            });
                        }

                        list.push({ key: list.length + 1, value: "default", text: t("lack") });
                        //console.log("partsListDropdown: ", list);

                        this.setState({ partsListDropdown: list, loading: false, partsListDownloaded: partsListDownloaded });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    handleSelectPart = (partID, i) => {
        const { partsListSelected, partsListDropdown, partsListDownloaded } = this.state;
        const list = [...partsListSelected],
            original = [...partsListDownloaded];

        partsListDropdown.forEach((e) => {
            if (e.value === partID) {
                if (e.value !== "default") {
                    let part = original.filter((p) => p.part_id === partID);
                    list[i] = { partID: partID, partName: part[0].user_fullname, partAnswer: part[0].part_a2p_answer };
                } else list[i] = null;
            }
        });
        this.setState({ partsListSelected: list, changedValues: true });
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { partsListSelected } = this.state;
        const { onShowAlert, t, onSelectParts } = this.props;
        onShowAlert("");
        let errorMssg = "",
            currentPart = [];

        partsListSelected.forEach((el, i) => {
            if (el) {
                if (currentPart.includes(el.partID)) {
                    errorMssg += t("error.partRepeats");
                    $(`#partToDutyDropdown${i}`).addClass("danger");
                } else currentPart.push(el.partID);
            }
        });

        if (errorMssg) {
            onShowAlert({ variant: "danger", message: errorMssg });
        } else {
            onSelectParts(partsListSelected);
        }
    };

    componentDidMount() {
        const { period, dutyTypeCode } = this.props;
        const selectedParts = period.participants,
            partList = [];
        let length;
        if (dutyTypeCode === "CONSTR") length = selectedParts.length === 0 ? 1 : selectedParts.length;
        else length = 4;
        for (let i = 0; i < length; i++) {
            if (selectedParts[i]) partList.push(selectedParts[i]);
            else partList.push(null);
        }
        this.setState({ partsListSelected: partList }, () => {
            this.getPartsToPeriod();
        });
    }

    render() {
        const { changedValues, partsListSelected, loading, noPermission, partsListDropdown, alert } = this.state;
        const { show, t, onClose, period, dutyTypeCode } = this.props;
        //console.log(partsListSelected);
        return (
            <Fragment>
                <Modal show={show} onHide={onClose} size="lg">
                    <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {t("duties.partsToDutyModal.title")}:<br />
                                {period.duty_period_label} {period.duty_period_date}{" "}
                                {(dutyTypeCode === "ACCR" || dutyTypeCode === "BAR") && (
                                    <span>
                                        {period.duty_period_start} - {period.duty_period_end}
                                    </span>
                                )}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {noPermission ? (
                                <AlertNoPermission t={t} />
                            ) : loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <Row>
                                    {alert && (
                                        <Col xs={12} className="text-center mb-3">
                                            <Alert variant="info">
                                                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                                {alert.message}
                                            </Alert>
                                        </Col>
                                    )}
                                    <Col xs={12}>
                                        <p className="mb-3">
                                            {t(`duties.partsToDutyModal.${dutyTypeCode === "CONSTR" ? "textConstr" : "text"}`)}
                                            {!alert && (
                                                <Fragment>
                                                    <br />
                                                    <FontAwesomeIcon icon={faCheck} /> - {t("duties.partsToDutyModal.text1")}
                                                    <br />
                                                    <FontAwesomeIcon icon={faQuestion} /> - {t("duties.partsToDutyModal.text2")}
                                                </Fragment>
                                            )}
                                        </p>
                                    </Col>
                                    <Col xs={12}>
                                        <Row>
                                            {partsListSelected.map((e, i) => (
                                                <Col xs={12} lg={{ span: 8, offset: 2 }} key={i}>
                                                    <PartToDutyDropdown
                                                        value={e ? e.partID : "default"}
                                                        onSelectPart={(partID) => this.handleSelectPart(partID, i)}
                                                        t={t}
                                                        partsList={partsListDropdown}
                                                        i={i}
                                                    />
                                                </Col>
                                            ))}
                                            {dutyTypeCode === "CONSTR" && (
                                                <Col xs={12} className="text-center my-3">
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() => {
                                                            const { partsListSelected } = this.state;
                                                            const list = [...partsListSelected];
                                                            list.push(null);
                                                            this.setState({ partsListSelected: list, changedValues: true });
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                                        {t("duties.addPart")}
                                                    </Button>
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary" onClick={onClose}>
                                {t("cancel")}
                            </Button>
                            <Button variant="primary" type="submit" disabled={!changedValues} onClick={this.handleCheckValidation}>
                                {t("save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default SelectPartsToDutyModal;
