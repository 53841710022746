import React, { Fragment, Component } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import TooltipWithDisabledButton from "../../tooltips/TooltipWithDisabledButton";
import NavDetails from "../../navigation/NavDetails";
import $ from "jquery";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import TextareaForm from "../../forms/TextareaForm";
import TextForm from "../../forms/text-inputs/TextForm";
import Phone from "../../forms/text-inputs/Phone";
import ShowQRCode from "../../other/ShowQRCode";
import LogoutModal from "../../modals/LogoutModal";
import NoPermission from "../../other/NoPermission";

class EditMyAccount extends Component {
    state = {
        userData: [],
        loading: true,
        changedValues: false,
        changedValuesPass: false,
        modalShow: false,
        showScanner: false,
        addFbLink: false,
        scanDone: false,
        noPermission: false,
    };

    validateFbLink = (fb) => {
        if (fb !== "" && fb !== null) {
            const regExp = /^https:\/\/www.facebook.com\/.+/;
            const regExp2 = /^https:\/\/facebook.com\/.+/;

            if (regExp.test(fb) || regExp2.test(fb)) {
                return "";
            } else {
                $("#userFbLink").addClass("danger");
                return this.props.t("error.invalidFbLink");
            }
        } else return "";
    };

    //check if changes in forms are done
    handleChangeForm = (e) => {
        const { name, value, id } = e.target;
        const { userData } = this.state;
        $(`#${id}`).removeClass("danger");
        this.props.onChangeValuesInFormes();

        if (name === "password" || name === "repeatPassword") {
            this.setState({
                [e.target.id]: value,
                changedValuesPass: true,
            });
        } else {
            const data = [...userData];
            data[0][name] = value;
            this.setState({ userData: data, changedValues: true });
            $(`#${id}`).removeClass("danger");
        }
        if (!$(`#${id}`).hasClass("changed")) $(`#${id}`).addClass("changed");
    };

    //check Validation of Data Inputs
    handleCheckValidation = (e) => {
        e.preventDefault();
        const { userData } = this.state;
        const { onShowAlert, t } = this.props;
        let errorMsg = "";

        $(".form-control.req").each(function () {
            if ($(this).val() === "" || $(this).val() === "default") {
                errorMsg += t("error.reqFields");
                $(this).addClass("danger");
            }
        });
        errorMsg += this.validateFbLink(userData[0].user_facebook_link);

        if (errorMsg) {
            onShowAlert({ variant: "danger", message: errorMsg });
        } else {
            //console.log(userData);
            this.updateMyAccount();
        }
    };

    updateMyAccount = async () => {
        const { userData } = this.state;
        const { fetchURL, onShowAlert, onCancelChangeValuesInFormes, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.updatingData") });
        const accountData = {
            userData: userData[0],
        };
        try {
            const response = await fetch(`${fetchURL}/update-my-account`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(accountData),
            });
            if (response.status === 200) {
                onShowAlert("");
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === "warning") onShowAlert({ variant: "warning", message: t(`warning.${fetchedData.code}`) });
                    else {
                        onCancelChangeValuesInFormes();
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    handleDropdownValue = (name, value, id, index) => {
        const { userRoles } = this.state;
        const list = [...userRoles];
        list[index][name] = value;
        this.setState({ userRoles: list, changedValues: true });
        this.props.onChangeValuesInFormes();
        $(`#${id}`).removeClass("danger");
    };

    //changed state of scanned QR code form ShowQRCode component
    handleScanQRCode = (childData) => {
        this.setState({ showScanner: false, changedValues: true });
        const { userData } = this.state;
        let data = [...userData];
        data[0]["user_qr_code"] = childData;

        this.setState({
            userQrCode: data,
        });

        this.props.onChangeValuesInFormes();
    };

    editMyAccount = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/edit-my-account`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const userData = fetchedData.userData;
                        userData.forEach((el) => {
                            el.user_nickname = el.user_nickname ? el.user_nickname : "";
                            el.user_phone = el.user_phone ? el.user_phone : "";
                            el.user_facebook_link = el.user_facebook_link ? el.user_facebook_link : "";
                            el.user_medical_info = el.user_medical_info ? el.user_medical_info : "";
                            el.user_qr_code = el.user_qr_code ? el.user_qr_code : "";
                        });

                        this.setState({ userData: userData, loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.editMyAccount();
        const { onResizeWindow } = this.props;
        onResizeWindow("basic");
    }

    componentDidUpdate() {
        if ($(".scrolled-content").outerHeight() && !this.state.loading) this.props.onResizeWindow("basic");
        if (!this.state.loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { userData, loading, modalShow, changedValues, showScanner, noPermission } = this.state;
        const { onLogout, scannerHeight, onBackToPreviousPage, onCancelChangeValuesInFormes, t, onShowAlert } = this.props;

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails
                                    t={t}
                                    header={t("myAccount.editMyAccount")}
                                    changedValues={changedValues}
                                    onChangeView={onBackToPreviousPage}
                                    onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                                />
                                <Col></Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <Form autoComplete="off" onSubmit={this.handleCheckValidation}>
                                <div className="scrolled-content" style={{ display: "none" }}>
                                    <Container className="py-3">
                                        <Row>
                                            <Col xs={12}>
                                                <p className="h3 mt-3 mb-4">{t("myAccount.dataAccount")}</p>
                                            </Col>
                                            <Col lg={4} xs={12}>
                                                <TextForm
                                                    value={userData[0].user_name}
                                                    onChangeValue={this.handleChangeForm}
                                                    req
                                                    labelTxt={t("user.nameLabel")}
                                                    placeholderTxt={t("user.namePlaceholder")}
                                                    name="user_name"
                                                    id="userName"
                                                />
                                            </Col>
                                            <Col lg={4} xs={12}>
                                                <TextForm
                                                    value={userData[0].user_nickname}
                                                    onChangeValue={this.handleChangeForm}
                                                    labelTxt={t("user.nicknameLabel")}
                                                    placeholderTxt={t("user.nicknamePlaceholder")}
                                                    name="user_nickname"
                                                    id="userNickname"
                                                />
                                            </Col>
                                            <Col lg={4} xs={12}>
                                                <TextForm
                                                    value={userData[0].user_surname}
                                                    onChangeValue={this.handleChangeForm}
                                                    req
                                                    labelTxt={t("user.surnameLabel")}
                                                    placeholderTxt={t("user.surnamePlaceholder")}
                                                    name="user_surname"
                                                    id="userSurname"
                                                />
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Label>{t("ident.qrCode")}</Form.Label>

                                                <Form.Control id="qr_codeAddUser" type="hidden" name="user_qr_code" value={userData[0].user_qr_code ? t("ident.codeScanned") : ""} />

                                                <div className="mb-2">
                                                    {userData[0].user_qr_code ? (
                                                        <span key="user_qr_code">{userData[0].user_qr_code && t("ident.codeScanned")}</span>
                                                    ) : (
                                                        <span key="user_qr_code" className="opacity-4">
                                                            {t("lack")}
                                                        </span>
                                                    )}
                                                </div>
                                                <Button variant="outline-secondary" onClick={() => this.setState({ showScanner: true })} className="mb-3">
                                                    {userData[0].user_qr_code ? t("ident.scanNewCode") : t("ident.assignCode")}
                                                </Button>
                                            </Col>
                                            <Col lg={4} xs={12}>
                                                <Phone t={t} value={userData[0].user_phone} onChangeValue={this.handleChangeForm} />
                                            </Col>
                                            <Col lg={8} xs={12}>
                                                <TextForm
                                                    value={userData[0].user_facebook_link}
                                                    onChangeValue={this.handleChangeForm}
                                                    labelTxt={t("user.fbLabel")}
                                                    placeholderTxt={t("user.fbPlaceholder")}
                                                    name="user_facebook_link"
                                                    id="userFbLink"
                                                />
                                            </Col>
                                            <Col xs={12}>
                                                <TextareaForm
                                                    value={userData[0].user_medical_info}
                                                    onChangeValue={this.handleChangeForm}
                                                    labelTxt={t("user.medLabel")}
                                                    name="user_medical_info"
                                                    placeholderTxt={t("user.medPlaceholder")}
                                                    id="userMedInfo"
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <div className="content-bottom">
                                    <Container>
                                        <Row>
                                            <Col xs={12} className="py-3">
                                                {!changedValues ? (
                                                    <TooltipWithDisabledButton t={t} mssgTooltip={t("info.noChangesInForm")} btnTxt={t("changeData")} tooltipID="tooltipDisabledNoChanges" size="lg" />
                                                ) : (
                                                    <Button type="submit" variant="primary" size="lg">
                                                        {t("changeData")}
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Form>
                        )}
                        {showScanner && (
                            <ShowQRCode
                                qrcode={userData[0].user_qr_code}
                                onScanQRCode={this.handleScanQRCode}
                                qrReaderHeight={scannerHeight}
                                showScanner={showScanner}
                                onHideScanner={() => this.setState({ showScanner: false })}
                                t={t}
                                onShowAlert={onShowAlert}
                            />
                        )}
                        <LogoutModal t={t} onLogout={onLogout} show={modalShow} onClose={() => this.setState({ modalShow: false })} />
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default EditMyAccount;
