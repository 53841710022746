import React, { Fragment, Component } from "react";
import { Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faEdit, faEye, faHandHoldingUsd, faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

class ParticipantsListTable extends Component {
    state = {
        currentSortType: "default",
        currentSortingColumn: "sortID",
    };

    handleSorting = (btnID) => {
        const { currentSortType } = this.state;
        let nextSort;
        if (currentSortType === "down") {
            nextSort = "up";
        } else if (currentSortType === "up") {
            nextSort = "default";
        } else if (currentSortType === "default") {
            nextSort = "down";
        }

        this.setState({
            currentSortType: nextSort,
            currentSortingColumn: btnID,
        });
    };

    render() {
        const { currentSortType, currentSortingColumn } = this.state;
        const { participantsList, displayNOR, onChangeNavAndPage, onCheckUserPrivilage, activeEvent, t } = this.props;

        const sortTypes = {
            up: {
                fnID: (a, b) => a.part_ident_number - b.part_ident_number, //metoda sortowania (patrz: https://www.w3schools.com/jsref/jsref_sort.asp )
                fnArrivalDate: (a, b) => {
                    if (new Date(a.part_arrival_date) > new Date(b.part_arrival_date) || isNaN(new Date(a.part_arrival_date))) return 1;
                    else return -1;
                },
                fnSurname: (a, b) => {
                    if (a.user_surname.toUpperCase() > b.user_surname.toUpperCase()) return 1;
                    else return -1;
                },
                fnName: (a, b) => {
                    if (a.user_surname.toUpperCase() > b.user_surname.toUpperCase()) return 1;
                    else return -1;
                },
                fnNickname: (a, b) => {
                    //jest to wyodrębnione do osobnego ifa, bo jeśli przejdzie do następnego warunku, a nickname będzie nullem, to wywali błąd Cannot read property 'toUpperCase' of null
                    if (a.part_ident_nickname === null) return 1;
                    else if (b.part_ident_nickname === null) return -1;
                    else if (a.part_ident_nickname.toUpperCase() > b.part_ident_nickname.toUpperCase()) return 1;
                    else return -1;
                },
            },
            down: {
                fnID: (a, b) => b.part_ident_number - a.part_ident_number,
                fnArrivalDate: (a, b) => {
                    if (isNaN(new Date(a.part_arrival_date))) return -1;
                    else if (new Date(a.part_arrival_date) > new Date(b.part_arrival_date)) return -1;
                    else return 1;
                },
                fnSurname: (a, b) => {
                    if (a.user_surname.toUpperCase() > b.user_surname.toUpperCase()) return -1;
                    else return 1;
                },
                fnName: (a, b) => {
                    if (a.user_surname.toUpperCase() > b.user_surname.toUpperCase()) return -1;
                    else return 1;
                },
                fnNickname: (a, b) => {
                    //jest to wyodrębnione do osobnego ifa, bo jeśli przejdzie do następnego warunku, a nickname będzie nullem, to wywali błąd Cannot read property 'toUpperCase' of null
                    if (a.part_ident_nickname === null) return -1;
                    else if (b.part_ident_nickname === null) return 1;
                    else if (a.part_ident_nickname.toUpperCase() > b.part_ident_nickname.toUpperCase()) return -1;
                    else return 1;
                },
            },
            default: {
                fnID: (a, b) => a,
                fnArrivalDate: (a, b) => a,
                fnSurname: (a, b) => a,
                fnName: (a, b) => a,
                fnNickname: (a, b) => a,
            },
        };

        //sortowanie listy
        let sortedData = [];
        if (currentSortingColumn === "sortID") sortedData = participantsList.sort(sortTypes[currentSortType].fnID);
        else if (currentSortingColumn === "sortArrivalDate") sortedData = participantsList.sort(sortTypes[currentSortType].fnArrivalDate);
        else if (currentSortingColumn === "sortSurname") sortedData = participantsList.sort(sortTypes[currentSortType].fnSurname);
        else if (currentSortingColumn === "sortName") sortedData = participantsList.sort(sortTypes[currentSortType].fnName);
        else if (currentSortingColumn === "sortNickame") sortedData = participantsList.sort(sortTypes[currentSortType].fnNickname);
        else sortedData = participantsList.sort(sortTypes[currentSortType].fnID);

        const currentPageParams = { nav: "participants", subpage: "participantsList", params: "" };

        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th>{t("on")}</th>
                            <th style={{ width: "9rem", minWidth: "6rem" }}>
                                <span className="d-xl-inline d-lg-inline d-md-inline d-none">{t("participant.partIdent")}</span>
                                <span className="d-md-none d-inline">{t("participant.idNo")}</span>

                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortID" onClick={() => this.handleSorting("sortID")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortID" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                            <th>
                                {t("user.surnameLabel")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortSurname" onClick={() => this.handleSorting("sortSurname")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortSurname" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                            <th>{t("user.nicknameLabel")}</th>
                            <th>
                                {t("user.nameLabel")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortName" onClick={() => this.handleSorting("sortName")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortName" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                            <th>{t("group.groupName.label")}</th>
                            <th>
                                {t("participant.partNicknameLabel")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortNickame" onClick={() => this.handleSorting("sortNickame")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortNickame" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                            <th>{t("participant.accrTransactionStatus")}</th>
                            <th>{t("participant.partStatus")}</th>
                            <th>{t("participant.labels")}</th>
                            <th>
                                {t("calendar.dateTimeArrival")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortArrivalDate" onClick={() => this.handleSorting("sortArrivalDate")}>
                                    <FontAwesomeIcon
                                        icon={currentSortingColumn !== "sortArrivalDate" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort}
                                    />
                                </Button>
                            </th>
                            <th
                                style={
                                    activeEvent && activeEvent !== "noActiveEvent"
                                        ? !onCheckUserPrivilage("ACCR_ACCREDIT_PART") && !onCheckUserPrivilage("ACCR_PART_ACTIONS")
                                            ? { width: "100px" }
                                            : { width: "230px", minWidth: "230px" }
                                        : { width: "130px", minWidth: "130px" }
                                }
                            >
                                {t("dropdowns.actions")}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData
                            .map((p, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{p.part_ident_number ? <span style={{ letterSpacing: "1px" }}>{p.part_ident_number}</span> : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{p.user_surname}</td>
                                    <td>{p.user_nickname ? p.user_nickname : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{p.user_name}</td>
                                    <td>{p.part_group_name ? p.part_group_name : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{p.part_ident_nickname ? p.part_ident_nickname : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>
                                        {p.payment_status_code === "PAID" ? (
                                            <span className="text-success">{t("payment.paid")}</span>
                                        ) : p.payment_status_code === "OVERPAID" ? (
                                            <span className="text-primary">{t("payment.overpaid")}</span>
                                        ) : (
                                            <span className="text-danger">{t("payment.underpaid")}</span>
                                        )}
                                    </td>
                                    <td>
                                        <span
                                            className={
                                                p.part_stat_code === "ABSENT" ? "text-warning" : p.part_stat_code === "ABORTED" ? "opacity-4" : p.part_stat_code === "KICKEDOUT" ? "text-danger" : ""
                                            }
                                        >
                                            {p.part_stat_name}
                                        </span>
                                    </td>
                                    <td>{p.part_labels_list ? p.part_labels_list : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{p.part_arrival_date ? p.part_arrival_date : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td className="text-center">
                                        {onCheckUserPrivilage("ACCR_ACCREDIT_PART") && (
                                            <Fragment>
                                                {p.part_stat_code === "ABSENT" && activeEvent && (
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`partAccrTooltip${i}`}>{t("participant.accreditPart")}</Tooltip>}>
                                                        <span className="d-inline-block">
                                                            <Button
                                                                variant="outline-primary"
                                                                onClick={() =>
                                                                    onChangeNavAndPage(
                                                                        { nav: "participants", subpage: "accreditParticipant", params: { partID: p.part_id, userID: p.user_id } },
                                                                        currentPageParams
                                                                    )
                                                                }
                                                                className="mx-1"
                                                            >
                                                                <FontAwesomeIcon icon={faAddressCard} />
                                                            </Button>
                                                        </span>
                                                    </OverlayTrigger>
                                                )}

                                                <OverlayTrigger placement="top" overlay={<Tooltip id={`partPaymentTooltip${i}`}>{t("payment.paymentsList")}</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        <Button
                                                            variant="outline-primary"
                                                            onClick={() =>
                                                                onChangeNavAndPage(
                                                                    { nav: "participants", subpage: "paymentsList", params: { partID: p.part_id, userID: p.user_id } },
                                                                    currentPageParams
                                                                )
                                                            }
                                                            className="mx-1"
                                                        >
                                                            <FontAwesomeIcon icon={faHandHoldingUsd} />
                                                        </Button>
                                                    </span>
                                                </OverlayTrigger>
                                            </Fragment>
                                        )}
                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`partDetailsTooltip${i}`}>{t("preview")}</Tooltip>}>
                                            <span className="d-inline-block">
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() =>
                                                        onChangeNavAndPage({ nav: "participants", subpage: "participantDetails", params: { partID: p.part_id, userID: p.user_id } }, currentPageParams)
                                                    }
                                                    className="mx-1"
                                                >
                                                    <FontAwesomeIcon icon={faEye} />
                                                </Button>
                                            </span>
                                        </OverlayTrigger>

                                        {onCheckUserPrivilage("ACCR_PART_ACTIONS") && activeEvent && (
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`editPartTooltip${i}`}>{t("edit")}</Tooltip>}>
                                                <span className="d-inline-block">
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() =>
                                                            onChangeNavAndPage({ nav: "participants", subpage: "editParticipant", params: { partID: p.part_id, userID: p.user_id } }, currentPageParams)
                                                        }
                                                        className="mx-1"
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>
                                        )}
                                    </td>
                                </tr>
                            ))
                            .slice(0, displayNOR)}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}

export default ParticipantsListTable;
