import React from "react";

const LoadingSpinnerSm = () => {
    
    return (
        <div className="loading-spinner-sm" >
            <div className="sk-chase" >
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
            </div>
        </div>
    );
}
 
export default LoadingSpinnerSm;