import React, { Fragment } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
//import RolesAndEvents from "../../../forms/dropdowns/RolesAndEvents";
import UsersListAvailableToAssign from "../../../forms/dropdowns/UsersListAvailableToAssign";
import Email from "../../../forms/Email";
import Phone from "../../../forms/text-inputs/Phone";
import TextForm from "../../../forms/text-inputs/TextForm";
import TextareaForm from "../../../forms/TextareaForm";

const AccountData = (props) => {
    const {
        activeEvent,
        userData,
        showOptionDataAccount,
        fetchURL,
        alertAssignedUser,
        onChangeUserData,
        onFullnameSelected,
        onShowOptionDataAccount,
        onShowAlert,
        onShowHomePage,
        t,
        onCheckUserPrivilage,
    } = props;
    const user = userData[0];

    return (
        <Fragment>
            <Row id="chooseUser">
                <Col xs={12}>
                    <p className="h4 my-3 form-title">{t("user.accountData")}</p>
                </Col>
                {showOptionDataAccount === "createNewAccount" ? (
                    <Fragment>
                        <Col xs={12}>
                            <p className="h5 my-3">{t("participant.accountData.data")}</p>
                        </Col>
                        <Col lg={4} xs={12}>
                            <Email t={t} onShowHomePage={onShowHomePage} value={user.user_email} onChangeValue={onChangeUserData} id="userEmail" name="user_email" />
                        </Col>
                        <Col lg={4} xs={12}>
                            <TextForm
                                value={user.user_name}
                                onChangeValue={onChangeUserData}
                                req
                                labelTxt={t("user.nameLabel")}
                                placeholderTxt={t("user.namePlaceholder")}
                                name="user_name"
                                id="userName"
                            />
                        </Col>
                        <Col lg={4} xs={12}>
                            <TextForm
                                value={user.user_surname}
                                onChangeValue={onChangeUserData}
                                req
                                labelTxt={t("user.surnameLabel")}
                                placeholderTxt={t("user.surnamePlaceholder")}
                                name="user_surname"
                                id="userSurname"
                            />
                        </Col>
                        <Col lg={4} xs={12}>
                            <TextForm
                                value={user.user_nickname}
                                onChangeValue={onChangeUserData}
                                labelTxt={t("user.nicknameLabel")}
                                placeholderTxt={t("user.nicknamePlaceholder")}
                                name="user_nickname"
                                id="userNickname"
                            />
                        </Col>
                        <Col lg={4} xs={12}>
                            <Phone t={t} value={user.user_phone} onChangeValue={onChangeUserData} name="user_phone" id="userPhone" />
                        </Col>
                        <Col lg={4} xs={12}>
                            <TextForm
                                value={user.user_facebook_link}
                                onChangeValue={onChangeUserData}
                                labelTxt={t("user.fbLabel")}
                                placeholderTxt={t("user.fbPlaceholder")}
                                name="user_facebook_link"
                                id="userFbLink"
                            />
                        </Col>
                        <Col md={6} xs={12}>
                            <TextareaForm
                                value={user.user_medical_info}
                                onChangeValue={onChangeUserData}
                                labelTxt={t("user.medLabel")}
                                name="user_medical_info"
                                placeholderTxt={t("user.medPlaceholder")}
                                id="userMedInfo"
                            />
                        </Col>
                        <Col md={6} xs={12}>
                            <TextareaForm
                                value={user.user_comment}
                                onChangeValue={onChangeUserData}
                                labelTxt={t("participant.accountData.adminCommentLabel")}
                                name="user_comment"
                                placeholderTxt={t("participant.accountData.adminCommentPlaceholder")}
                                id="userAdminComment"
                            />
                        </Col>
                        {/*<Col xs={12}>
                                <p className="h5 my-3">{t('assign')} role</p>
                            </Col>
                             <Col xs={12}>
                                <RolesAndEvents
                                    userRoles={userRoles}
                                    onDropdownValue={onChangeRolesAndEventsValue}
                                    onRemoveRoleSelects={onRemoveRoleSelects}
                                    onAddRoleSelects={onAddNewRole}
                                    option={currentPage.subpage === "editParticipant" ? "edit" : "add"}
                                    onShowAlert={onShowAlert}
                                    fetchURL={fetchURL}
                                    activeEvent={activeEvent}
                                />
                            </Col> */}
                    </Fragment>
                ) : showOptionDataAccount === "chooseExistingUser" ? (
                    <Fragment>
                        <Col md={6} xs={12}>
                            <UsersListAvailableToAssign
                                t={t}
                                onShowHomePage={onShowHomePage}
                                labelTxt={t("ident.user")}
                                onFullnameSelected={onFullnameSelected}
                                defaultValue=""
                                fetchURL={fetchURL}
                                activeEvent={activeEvent}
                                onShowAlert={onShowAlert}
                            />
                            {alertAssignedUser && (
                                <Alert variant="danger" className="alert-small">
                                    {alertAssignedUser}
                                </Alert>
                            )}
                        </Col>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Col xs={12} className="mb-3">
                            <Button variant="outline-primary" onClick={() => onShowOptionDataAccount("chooseExistingUser")}>
                                {t("participant.accountData.chooseExistingUser")}
                            </Button>
                            {onCheckUserPrivilage("COMMON_USER_ACTIONS") && (
                                <Fragment>
                                    <span className="mx-4 align-middle">{t("or")}</span>
                                    <Button variant="outline-primary" onClick={() => onShowOptionDataAccount("createNewAccount")}>
                                        {t("participant.accountData.createNewAccount")}
                                    </Button>
                                </Fragment>
                            )}
                        </Col>
                    </Fragment>
                )}
            </Row>
        </Fragment>
    );
};

export default AccountData;
