import React, { Component, Fragment } from "react";
import QrReader from "react-qr-reader";
import { Modal, Button } from "react-bootstrap";
import $ from "jquery";

class ShowQRCode extends Component {
    state = {
        result: "",
        showModal: false,
        showScanner: this.props.showScanner,
    };

    handleScan = (data) => {
        if (data) {
            /* this.setState({
                result: data,
                showModal: true,
            }); */
            console.log(data);
            this.setState({ showScanner: false });
            this.props.onScanQRCode(data);
        }
    };

    handleError = (err) => {
        console.error(err);
    };

    handleClose = () => {
        this.setState({ showModal: false, showScanner: false });
        this.props.onScanQRCode(this.state.result);
    };

    handleCloseScanner = () => {
        this.setState({ showScanner: false });
    };

    componentDidMount() {
        this.props.onShowAlert("");
        const height = this.props.qrReaderHeight - 42;
        $(".qrReader>section").css("padding-top", `${height}px`);
        $(".qrReader>section>div").css("height", `${height}px`);
        $(".qrReader>section>div+video").css("height", `${height}px`);

        $(".qrCodeScanner .close").removeClass("close").addClass("btn btn-primary");
        $(".qrCodeScanner .btn > span").text(this.props.t("close"));

        document.addEventListener("keydown", (event) => {
            if (event.keyCode === 27) this.props.onHideScanner();
        });
    }

    render() {
        const { showModal, showScanner } = this.state;
        const { onHideScanner, t } = this.props;
        return (
            <Fragment>
                <Modal show={showScanner} onHide={onHideScanner} backdrop="static" keyboard={false} className="qrCodeScanner" style={{ height: this.props.qrReaderHeight }}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <QrReader delay={300} onError={this.handleError} onScan={this.handleScan} className="qrReader" constraints={{ facingMode: "environment" }} />
                    </Modal.Body>
                </Modal>

                {showModal && (
                    <Modal show={showModal} onHide={this.handleClose}>
                        <Modal.Body>
                            <p>{t("info.qrCodeScanned")}</p>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                {t("close")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default ShowQRCode;
