import React, { Fragment } from "react";
import { Nav, Button } from "react-bootstrap";
const PartDetailsNavbar = (props) => {
    const { t, onCheckUserPrivilage } = props;
    return (
        <Fragment>
            <Nav id="partDetailsNavbar">
                <Nav.Item>
                    <Button variant="link" onClick={props.scrollToDetail.bind(this, "participationPartDetail")}>
                        {t("partDetailsNavbar.part")}
                    </Button>
                </Nav.Item>
                <Nav.Item>
                    <Button variant="link" onClick={props.scrollToDetail.bind(this, "userPartDetail")}>
                        {t("partDetailsNavbar.user")}
                    </Button>
                </Nav.Item>
                {/* <Nav.Item>
                    <Button variant="link" onClick={props.scrollToDetail.bind(this, "roomsPartDetail")}>
                        {t("partDetailsNavbar.accomodation")}
                    </Button>
                </Nav.Item> */}
                <Nav.Item>
                    <Button variant="link" onClick={props.scrollToDetail.bind(this, "shopPartDetail")}>
                        {t("partDetailsNavbar.shop")}
                    </Button>
                </Nav.Item>
                {onCheckUserPrivilage("ACCR_APPLICATIONS_VIEW") && (
                    <Nav.Item>
                        <Button variant="link" onClick={props.scrollToDetail.bind(this, "formsPartDetail")}>
                            {t("partDetailsNavbar.forms")}
                        </Button>
                    </Nav.Item>
                )}

                <Nav.Item>
                    <Button variant="link" onClick={props.scrollToDetail.bind(this, "characterPartDetail")}>
                        {t("partDetailsNavbar.characters")}
                    </Button>
                </Nav.Item>
                <Nav.Item>
                    <Button variant="link" onClick={props.scrollToDetail.bind(this, "barClient")}>
                        {t("partDetailsNavbar.barClient")}
                    </Button>
                </Nav.Item>
            </Nav>
        </Fragment>
    );
};

export default PartDetailsNavbar;
