import { faEllipsisH, faSyringe, faUser, faUserTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, Component } from "react";
import { Button, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import ChangeIdentTypeModal from "../../modals/ChangeIdentTypeModal";

class TableIdentsList extends Component {
    state = {
        showModal: null,
    };

    handleCloseModal = () => this.setState({ showModal: null });

    render() {
        const { idents, numOfRecords, t, currentPageParams, onChangeNavAndPage, onShowAlert, fetchURL } = this.props;
        const { showModal } = this.state;
        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th>{t("on")}</th>
                            <th style={{ width: "4rem" }}>{t("ident.identObject")}</th>
                            <th>{t("ident.identType")}</th>
                            <th>{t("ident.identActivity")}</th>
                            <th>{t("ident.assignedTo")}</th>
                            <th>{t("ident.assignedBy")}</th>
                            <th>{t("ident.assignDate")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {idents
                            .map((p, i) => (
                                <tr key={`ident_data_${i}`}>
                                    <td>{i + 1}</td>
                                    <td style={{ textAlign: "center" }}>
                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltipAddQRCode${i}`}>{t(`ident.objects.${p.ident_object ? p.ident_object : "OTHER"}`)}</Tooltip>}>
                                            <Button className="mr-3" variant="link">
                                                <FontAwesomeIcon
                                                    icon={p.ident_object === "USER" ? faUser : p.ident_object === "PART" ? faUserTag : p.ident_object === "ITEM" ? faSyringe : faEllipsisH}
                                                />
                                            </Button>
                                        </OverlayTrigger>
                                    </td>
                                    <td>{p.ident_type}</td>
                                    <td>{p.ident_active === "Y" ? t("active") : p.ident_active === "N" ? t("inactive") : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>
                                        {p.object_name ? (
                                            <Button
                                                variant="link"
                                                onClick={() =>
                                                    onChangeNavAndPage(
                                                        p.ident_object === "USER"
                                                            ? { nav: "users", subpage: "userDetails", params: { userID: p.object_id } }
                                                            : {
                                                                  nav: "participants",
                                                                  subpage: "participantDetails",
                                                                  params: { partID: p.object_id, userID: p.user_id },
                                                              },
                                                        currentPageParams
                                                    )
                                                }
                                            >
                                                {p.object_name}
                                            </Button>
                                        ) : (
                                            <span className="opacity-4">{t("lack")}</span>
                                        )}
                                    </td>
                                    <td>
                                        {p.auth_user_name ? (
                                            <Button
                                                variant="link"
                                                onClick={() => onChangeNavAndPage({ nav: "users", subpage: "userDetails", params: { userID: p.assign_user_id } }, currentPageParams)}
                                            >
                                                {p.auth_user_name}
                                            </Button>
                                        ) : (
                                            <span className="opacity-4">{t("lack")}</span>
                                        )}
                                    </td>
                                    <td>{p.assign_date ? p.assign_date : <span className="opacity-4">{t("lack")}</span>}</td>
                                </tr>
                            ))
                            .slice(0, numOfRecords)}
                    </tbody>
                </Table>

                {showModal && (
                    <Fragment>
                        {showModal.option === "changeIdentType" && (
                            <ChangeIdentTypeModal identValue={showModal.identValue} onClose={this.handleCloseModal} fetchURL={fetchURL} onShowAlert={onShowAlert} t={t} />
                        )}
                        {showModal.option === "deactivateIdent" && (
                            <Modal show={true} onHide={this.handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{t("ident.deactivateIdentModal.title")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>
                                        {t("ident.deactivateIdentModal.deactivate")} <b>{showModal.identValue}</b> {t("ident.deactivateIdentModal.assignedTo")} <b>{showModal.identObject}</b>
                                    </p>
                                    <p>{t("ident.deactivateIdentModal.text")}</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-secondary">{t("ident.deactivateIdentModal.confirm")}</Button>
                                    <Button variant="primary" type="button" onClick={this.handleCloseModal}>
                                        {t("ident.deactivateIdentModal.decline")}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default TableIdentsList;
