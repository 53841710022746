import React, { Component, Fragment } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import Moment from "react-moment";
import "moment-timezone";
import AvalPeriodDetailsModal from "../../modals/AvalPeriodDetailsModal";

class ActiveEventDetails extends Component {
    state = {
        showAvalPeriodDetailsModal: false,
        selectedAvalPeriods: null,
    };

    render() {
        const { showAvalPeriodDetailsModal, selectedAvalPeriods } = this.state;
        const { t, activeEventDetails, avalPeriodConstr, avalPeriodBar, avalPeriodAccr } = this.props;
        return (
            <Fragment>
                <Row>
                    <Col xs={12}>
                        <p className="h4 mb-4 mt-3 form-title">{t("settings.activeConvention")}</p>
                    </Col>
                    <Col xs={12}>
                        <p className="h5 mb-3">
                            {t("settings.currentConvention")}: <strong>{activeEventDetails.event_name}</strong> ({activeEventDetails.event_code})
                        </p>
                    </Col>
                    <Col xs={12} lg={7}>
                        <Table size="sm">
                            <tbody>
                                <tr>
                                    <td width="50%">{t("settings.accrPrice")}:</td>
                                    <td>
                                        <strong>
                                            {activeEventDetails.event_price} {t("currency")}
                                        </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t("settings.conventionDuration")}:</td>
                                    <td>
                                        {activeEventDetails.event_start_date && activeEventDetails.event_end_date ? (
                                            <Fragment>
                                                {t("calendar.from")}{" "}
                                                <b>
                                                    <Moment format="YYYY-MM-DD">{activeEventDetails.event_start_date}</Moment>
                                                </b>{" "}
                                                {t("calendar.to")}{" "}
                                                <b>
                                                    <Moment format="YYYY-MM-DD">{activeEventDetails.event_end_date}</Moment>
                                                </b>
                                            </Fragment>
                                        ) : (
                                            <span className="opacity-4">{t("lack")}</span>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t("settings.larpDuration")}:</td>
                                    <td>
                                        {activeEventDetails.larp_start_datetime && activeEventDetails.larp_end_datetime ? (
                                            <Fragment>
                                                {t("calendar.from")}{" "}
                                                <b>
                                                    <Moment format="YYYY-MM-DD HH:mm">{activeEventDetails.larp_start_datetime}</Moment>
                                                </b>{" "}
                                                {t("calendar.to")}{" "}
                                                <b>
                                                    <Moment format="YYYY-MM-DD HH:mm">{activeEventDetails.larp_end_datetime}</Moment>
                                                </b>
                                            </Fragment>
                                        ) : (
                                            <span className="opacity-4">{t("lack")}</span>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t("settings.registrationAvailable")}:</td>
                                    <td>
                                        {activeEventDetails.allow_users_apply ? (
                                            <Fragment>
                                                {<span className="text-success">{t("yes")}</span>}
                                                {activeEventDetails.allow_users_apply_deadline && (
                                                    <span>
                                                        , {t("until")} <Moment format="YYYY-MM-DD HH:mm">{activeEventDetails.allow_users_apply_deadline}</Moment>
                                                    </span>
                                                )}
                                            </Fragment>
                                        ) : (
                                            <span className="text-danger">{t("no")}</span>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t("settings.ordersAvailable")}:</td>
                                    <td>
                                        {activeEventDetails.allow_users_order ? (
                                            <Fragment>
                                                {<span className="text-success">{t("yes")}</span>}
                                                {activeEventDetails.allow_users_order_deadline && (
                                                    <span>
                                                        , {t("until")} <Moment format="YYYY-MM-DD HH:mm">{activeEventDetails.allow_users_order_deadline}</Moment>
                                                    </span>
                                                )}
                                            </Fragment>
                                        ) : (
                                            <span className="text-danger">{t("no")}</span>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t("settings.applicationsAvailable")}:</td>
                                    <td>{activeEventDetails.allow_users_applications ? <span className="text-success">{t("yes")}</span> : <span className="text-danger">{t("no")}</span>}</td>
                                </tr>
                                <tr>
                                    <td>{t("settings.charactersAvailable")}:</td>
                                    <td>{activeEventDetails.allow_users_characters ? <span className="text-success">{t("yes")}</span> : <span className="text-danger">{t("no")}</span>}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col lg={5} xs={12}>
                        <Table size="sm">
                            <tbody>
                                <tr>
                                    <td colSpan={2} className="text-uppercase">
                                        {t("settings.availabilities")}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%">{t("settings.constrForm")}:</td>
                                    <td>
                                        {avalPeriodConstr ? (
                                            <Button
                                                variant="link"
                                                onClick={() => this.setState({ showAvalPeriodDetailsModal: true, selectedAvalPeriods: { avalType: "CONSTR", data: avalPeriodConstr } })}
                                            >
                                                {t("settings.seeDetails")}
                                            </Button>
                                        ) : (
                                            <span className="opacity-4">{t("lack")}</span>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%">{t("settings.barForm")}:</td>
                                    <td>
                                        {avalPeriodBar ? (
                                            <Button variant="link" onClick={() => this.setState({ showAvalPeriodDetailsModal: true, selectedAvalPeriods: { avalType: "BAR", data: avalPeriodBar } })}>
                                                {t("settings.seeDetails")}
                                            </Button>
                                        ) : (
                                            <span className="opacity-4">{t("lack")}</span>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%">{t("settings.accrForm")}:</td>
                                    <td>
                                        {avalPeriodAccr ? (
                                            <Button variant="link" onClick={() => this.setState({ showAvalPeriodDetailsModal: true, selectedAvalPeriods: { avalType: "ACCR", data: avalPeriodAccr } })}>
                                                {t("settings.seeDetails")}
                                            </Button>
                                        ) : (
                                            <span className="opacity-4">{t("lack")}</span>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                {showAvalPeriodDetailsModal && (
                    <AvalPeriodDetailsModal
                        t={t}
                        avalType={selectedAvalPeriods.avalType}
                        avalPeriodData={selectedAvalPeriods.data}
                        show={showAvalPeriodDetailsModal}
                        onClose={() => this.setState({ showAvalPeriodDetailsModal: false, selectedAvalPeriods: null })}
                    />
                )}
            </Fragment>
        );
    }
}

export default ActiveEventDetails;
