import React, { Fragment } from "react";
import RoomsList from "../../pages/rooms/RoomsList";
import RoomsRequestsList from "../../pages/rooms/RoomsRequestsList";
import RoomsMap from "../../pages/rooms/RoomsMap";
import AddRoom from "../../pages/rooms/AddRoom";
import RoomDetails from "../../pages/rooms/RoomDetails";
import BookRoom from "../../pages/rooms/BookRoom";
import RoomBookingDetails from "../../pages/rooms/RoomBookingDetails";
import RoomBookingsList from "../../pages/rooms/RoomBookingsList";

const Rooms = (props) => {
    const {
        fetchURL,
        onCheckUserPrivilage,
        onChangeValuesInFormes,
        onCancelChangeValuesInFormes,
        onChangeNavAndPage,
        currentPage,
        onResizeWindow,
        activeEvent,
        onBackToPreviousPage,
        onShowAlert,
        dataList,
        onGetDataList,
        numberOfRecords,
        onChangeNOR,
        onGetRoomsRequestsList,
        roomsRequestsList,
        t,
        onShowHomePage,
    } = props;
    return (
        <Fragment>
            {currentPage.nav === "rooms" && (
                <Fragment>
                    {currentPage.subpage === "roomsList" && (
                        <RoomsList
                            onResizeWindow={onResizeWindow}
                            fetchURL={fetchURL}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            onChangeNavAndPage={onChangeNavAndPage}
                            currentPage={currentPage}
                            activeEvent={activeEvent}
                            onShowAlert={onShowAlert}
                            dataList={dataList}
                            onGetDataList={onGetDataList}
                            numberOfRecords={numberOfRecords}
                            onChangeNOR={onChangeNOR}
                            eventFilterValue={props.eventFilterValue}
                            eventsList={props.eventsList}
                            onChangeEventFilter={props.onChangeEventFilter}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {currentPage.subpage === "roomsRequestsList" && (
                        <RoomsRequestsList
                            onResizeWindow={onResizeWindow}
                            fetchURL={fetchURL}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            onChangeNavAndPage={onChangeNavAndPage}
                            currentPage={currentPage}
                            activeEvent={activeEvent}
                            onShowAlert={onShowAlert}
                            dataList={roomsRequestsList}
                            onGetDataList={onGetRoomsRequestsList}
                            numberOfRecords={numberOfRecords}
                            onChangeNOR={onChangeNOR}
                            eventFilterValue={props.eventFilterValue}
                            eventsList={props.eventsList}
                            onChangeEventFilter={props.onChangeEventFilter}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {currentPage.subpage === "roomsMap" && (
                        <RoomsMap
                            onResizeWindow={onResizeWindow}
                            fetchURL={fetchURL}
                            onChangeNavAndPage={onChangeNavAndPage}
                            currentPage={currentPage}
                            activeEvent={activeEvent}
                            onShowAlert={onShowAlert}
                            t={t}
                            onShowHomePage={onShowHomePage}
                            onBackToPreviousPage={onBackToPreviousPage}
                        />
                    )}
                    {currentPage.subpage === "addRoom" && (
                        <AddRoom
                            onResizeWindow={onResizeWindow}
                            fetchURL={fetchURL}
                            currentPage={currentPage}
                            activeEvent={activeEvent}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            onBackToPreviousPage={onBackToPreviousPage}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onShowAlert={onShowAlert}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {currentPage.subpage === "roomDetails" && (
                        <RoomDetails
                            onResizeWindow={onResizeWindow}
                            fetchURL={fetchURL}
                            currentPage={currentPage}
                            activeEvent={activeEvent}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            onBackToPreviousPage={onBackToPreviousPage}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onShowAlert={onShowAlert}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {currentPage.subpage === "bookRoom" && (
                        <BookRoom
                            onResizeWindow={onResizeWindow}
                            fetchURL={fetchURL}
                            currentPage={currentPage}
                            activeEvent={activeEvent}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            onBackToPreviousPage={onBackToPreviousPage}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onShowAlert={onShowAlert}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {currentPage.subpage === "roomBookingDetails" && (
                        <RoomBookingDetails
                            onResizeWindow={onResizeWindow}
                            fetchURL={fetchURL}
                            currentPage={currentPage}
                            activeEvent={activeEvent}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            onBackToPreviousPage={onBackToPreviousPage}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onShowAlert={onShowAlert}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {currentPage.subpage === "roomBookings" && (
                        <RoomBookingsList
                            onResizeWindow={onResizeWindow}
                            fetchURL={fetchURL}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            onChangeNavAndPage={onChangeNavAndPage}
                            currentPage={currentPage}
                            activeEvent={activeEvent}
                            onShowAlert={onShowAlert}
                            dataList={props.roomBookingsList}
                            onGetDataList={props.onGetRoomBookingsList}
                            numberOfRecords={numberOfRecords}
                            onChangeNOR={onChangeNOR}
                            eventFilterValue={props.eventFilterValue}
                            eventsList={props.eventsList}
                            onChangeEventFilter={props.onChangeEventFilter}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Rooms;
