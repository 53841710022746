import React, { Component } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import TooltipWithDisabledButton from "../../tooltips/TooltipWithDisabledButton";
import $ from "jquery";

class ParticipantLabelsEdit extends Component {
    state = {
        changedValues: false,
        participantsLabels: null,
        loading: true,
    };

    getPartLabels = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/participants-labels`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ participantsLabels: fetchedData.participantsLabels, loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleChangeDiscountLabel = (e, index) => {
        const { participantsLabels } = this.state;
        const list = [...participantsLabels];
        list[index]["discount"] = e.target.value;
        this.setState({ participantsLabels: list, changedValues: true });
        this.props.onChangeValuesInFormes();
        $(`#${e.target.id}`).removeClass("danger");
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { participantsLabels } = this.state;
        const { onShowAlert, t } = this.props;
        let errorMsg = "";
        onShowAlert("");
        participantsLabels.forEach((e, i) => {
            if (e.discount === "") {
                errorMsg += t("error.discountIsNotANumber");
                $(`#labelDiscount${i}`).addClass("danger");
            } else if (e.discount > 100) {
                errorMsg += t("error.discountHigherThan100");
                $(`#labelDiscount${i}`).addClass("danger");
            }
        });
        if (errorMsg) onShowAlert({ variant: "danger", message: errorMsg });
        else this.updateLablesDiscount(participantsLabels);
    };

    updateLablesDiscount = async (partLabels) => {
        const { fetchURL, onCancelChangeValuesInFormes, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.updatingLabels") });
        const passData = {
            partLabels: partLabels,
        };
        try {
            const response = await fetch(`${fetchURL}/update-labels-discount`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false)
                        onShowAlert({
                            variant: "danger",
                            message: fetchedData.details ? `${t(`error.${fetchedData.code}`)}: ${fetchedData.details.slice(0, -2)}.` : t(`error.${fetchedData.code}`),
                        });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                        this.setState({ changedValues: false });
                        onCancelChangeValuesInFormes();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        this.getPartLabels();
    }

    render() {
        const { participantsLabels, loading, changedValues } = this.state;
        const { t } = this.props;
        return (
            <Row>
                <Col xs={12}>
                    <p className="h4 my-4 form-title">{t("settings.partLabels")}</p>
                </Col>
                {loading ? (
                    <Col xs={12} className="text-center">
                        <LoadingProgressBar t={t} />
                    </Col>
                ) : (
                    <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                        <Col xs={12}>
                            <Table bordered hover className="table-list" responsive="lg" id="partLabelsTable" size="sm">
                                <thead>
                                    <tr>
                                        <th>{t("settings.labelName")}</th>
                                        <th>{t("participant.discount.discount")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {participantsLabels.map((label, i) => (
                                        <tr key={i}>
                                            <td>{label.text}</td>
                                            <td>
                                                <Form.Control
                                                    type="number"
                                                    placeholder={t("participant.discount.typeDiscount")}
                                                    value={label.discount}
                                                    name="labelDiscount"
                                                    id={`labelDiscount${i}`}
                                                    onChange={(e) => this.handleChangeDiscountLabel(e, i)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                        <Col xs={12} className="my-3">
                            {!changedValues ? (
                                <TooltipWithDisabledButton t={t} mssgTooltip={t("info.noChangesInForm")} btnTxt={t("saveChanges")} tooltipID="tooltipDisabledNotFilled" variant="outline-primary" />
                            ) : (
                                <Button type="submit" variant="outline-primary">
                                    {t("saveChanges")}
                                </Button>
                            )}
                        </Col>
                    </Form>
                )}
            </Row>
        );
    }
}

export default ParticipantLabelsEdit;
