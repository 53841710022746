import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import AlertNoPermission from "../../alerts/AlertNoPermission";

class ParticipantLabels extends Component {
    state = { participantsLabels: [], noPermission: false };

    getParticipantLabels = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/participants-labels`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        this.setState({ participantsLabels: fetchedData.participantsLabels });
                        this.props.onLoadPartLabels(fetchedData.participantsLabels);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.getParticipantLabels();
    }

    render() {
        const { participantsLabels, noPermission } = this.state;
        const { defaultValue, onPartLabelSelected, t } = this.props;
        return (
            <Fragment>
                <Form.Label>{t("participant.labels")}</Form.Label>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : (
                    <Dropdown
                        defaultValue={defaultValue}
                        id="partLabelDropdown"
                        className="mb-3"
                        placeholder={t("participant.clickToChooseLabels")}
                        fluid
                        multiple
                        selection
                        options={participantsLabels}
                        onChange={(event, { value }) => {
                            onPartLabelSelected(value, participantsLabels);
                        }}
                    />
                )}
            </Fragment>
        );
    }
}

export default ParticipantLabels;
