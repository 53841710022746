import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import AlertNoPermission from "../../alerts/AlertNoPermission";

class InactiveEvents extends Component {
    state = { inactiveEvents: [], noPermission: false };

    getEvents = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/inactive-events-list`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ inactiveEvents: fetchedData.inactiveEvents });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.getEvents();
    }

    render() {
        const { inactiveEvents, noPermission } = this.state;
        const { defaultValue, onInactiveEventSelected, t } = this.props;

        return (
            <Fragment>
                <Form.Label>
                    {t("event.event")} <span className="required">*</span>
                </Form.Label>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : (
                    <Dropdown
                        defaultValue={defaultValue}
                        id="inactiveEventDropdown"
                        className="req mb-3"
                        placeholder={t("event.clickToChoose")}
                        fluid
                        selection
                        options={inactiveEvents}
                        onChange={(event, { value }) => {
                            onInactiveEventSelected(event, value);
                        }}
                    />
                )}
            </Fragment>
        );
    }
}

export default InactiveEvents;
