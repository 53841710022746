import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";
import { Checkbox } from "semantic-ui-react";

const ParticipantsListForGroup = (props) => {
    const { partList, onChangeCheckbox, onChangeSwitch, t } = props;
    return (
        <Fragment>
            {partList.map((e, i) => (
                <Row key={i}>
                    {e.coordinator ? (
                        <Fragment>
                            <Col md={8} xs={6}>
                                <p className="pt-2 mb-2 pl-4 ml-2 pb-1" style={{ lineHeight: "17px" }}>
                                    {e.user_fullname} ({t("group.coordinator").toLowerCase()})
                                </p>
                            </Col>
                            <Col md={2} xs={4}></Col>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Col md={8} xs={6}>
                                <Checkbox
                                    className="pt-2"
                                    label={e.user_fullname}
                                    value={e.part_id}
                                    checked={e.checked}
                                    onChange={(event, data) => {
                                        onChangeCheckbox(data, i);
                                    }}
                                />
                            </Col>
                            <Col md={2} xs={4}>
                                <Switch
                                    id={`partAffiliationVerified${i}`}
                                    checked={e.part_affiliation_verified}
                                    onChange={(e) => {
                                        onChangeSwitch(e.target.checked, i);
                                    }}
                                    name="part_affiliation_verified"
                                    disabled={!e.checked ? true : false}
                                    inputProps={{ "aria-label": "primary checkbox" }}
                                />
                            </Col>
                        </Fragment>
                    )}
                </Row>
            ))}
        </Fragment>
    );
};

export default ParticipantsListForGroup;
