import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import $ from "jquery";

const Phone = (props) => {
    const { value, onChangeValue, name, id, req, t } = props;
    return (
        <Fragment>
            <Form.Group>
                <Form.Label>
                    {t("form.phone")} {req && <span className="required"> *</span>}
                </Form.Label>
                <Form.Control
                    id={id}
                    type="text"
                    placeholder={t("form.typePhone")}
                    name={name}
                    value={value}
                    onChange={(e) => {
                        onChangeValue(e);
                        $(`#${id}`).removeClass("danger");
                    }}
                    className={req ? "req" : ""}
                />
            </Form.Group>
        </Fragment>
    );
};

export default Phone;
