import React, { Fragment, Component } from "react";
import { faCheckCircle, faEdit, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

class BasicData extends Component {
    render() {
        const { onChangeNavAndPage, onCheckUserPrivilage, partID, partData, onAccrPaymentReminder, userID, t, currentPageParams, activeEvent, onPaymentReminder } = this.props;

        return (
            <Fragment>
                <Col xs={12}>
                    <p className="h3 my-3 d-inline-block mr-3 align-middle">{t("participant.participationData")}</p>
                    {onCheckUserPrivilage("ACCR_PART_ACTIONS") && (
                        <Fragment>
                            {activeEvent && activeEvent !== "noActiveEvent" ? (
                                <OverlayTrigger placement="top" overlay={<Tooltip id="partEditTooltip">{t("dropdowns.editData")}</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            variant="outline-primary"
                                            className="d-inline-block"
                                            onClick={() =>
                                                onChangeNavAndPage(
                                                    { nav: "participants", subpage: "editParticipant", params: { partID: partID, eventCode: partData[0].event_code } },
                                                    currentPageParams
                                                )
                                            }
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                            ) : (
                                <OverlayTrigger placement="right" overlay={<Tooltip id="partEditDisabledTooltip">{t("dropdowns.editDataDisabled")}</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button variant="outline-primary" style={{ pointerEvents: "none" }} disabled>
                                            <FontAwesomeIcon icon={faEdit} />
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                            )}
                        </Fragment>
                    )}
                </Col>

                <Fragment>
                    <Col lg={8} xs={12}>
                        <Table bordered hover className="table-details" responsive="lg" id={`participationPartDetailBasicTable`}>
                            <tbody>
                                <tr>
                                    <td style={{ width: "60%" }}>{t("participant.partIdent")}</td>
                                    <td>{partData[0].part_ident_number ? partData[0].part_ident_number : <span className="opacity-4">{t("lack")}</span>}</td>
                                </tr>
                                <tr>
                                    <td>{t("participant.partStatus")}</td>
                                    <td>{partData[0].part_stat_name}</td>
                                </tr>
                                <tr>
                                    <td>{t("event.convention")}</td>
                                    <td>{partData[0].event_name}</td>
                                </tr>
                                <tr>
                                    <td>{t("ident.ident")}</td>
                                    <td>{partData[0].ident_value ? t("ident.identAssigned") : <span className="opacity-4">{t("lack")}</span>}</td>
                                </tr>
                                <tr>
                                    <td>{t("participant.partNicknameLabel")}</td>
                                    <td>{partData[0].part_ident_nickname ? partData[0].part_ident_nickname : <span className="opacity-4">{t("lack")}</span>}</td>
                                </tr>
                                <tr>
                                    <td>{t("group.group")}</td>
                                    <td>
                                        {partData[0].part_group_name ? (
                                            <Fragment>
                                                {onCheckUserPrivilage("ACCR_GROUP_DETAILS") ? (
                                                    <Button
                                                        variant="link"
                                                        onClick={() =>
                                                            onChangeNavAndPage(
                                                                {
                                                                    nav: "groups",
                                                                    subpage: "groupDetails",
                                                                    params: { groupID: partData[0].part_group_id, eventCode: partData[0].event_code },
                                                                },
                                                                currentPageParams
                                                            )
                                                        }
                                                    >
                                                        {partData[0].part_group_name}
                                                    </Button>
                                                ) : (
                                                    partData[0].part_group_name
                                                )}
                                                {partData[0].part_affiliation_verified === "Y" ? (
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltipAffiliationVerified`}>{t("group.partVerified")}</Tooltip>}>
                                                        <FontAwesomeIcon icon={faCheckCircle} className="text-success ml-1" />
                                                    </OverlayTrigger>
                                                ) : (
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltipAffiliationVerified`}>{t("group.partNotVerified")}</Tooltip>}>
                                                        <FontAwesomeIcon icon={faTimesCircle} className="text-danger ml-1" />
                                                    </OverlayTrigger>
                                                )}
                                            </Fragment>
                                        ) : (
                                            <span className="opacity-4">{t("lack")}</span>
                                        )}
                                    </td>
                                </tr>

                                <tr>
                                    <td>{t("participant.labels")}</td>
                                    <td>{partData[0].part_labels_list ? partData[0].part_labels_list : <span className="opacity-4">{t("lack")}</span>}</td>
                                </tr>

                                <tr>
                                    <td>{t("participant.partHasLegalAge")}</td>

                                    <td>{partData[0].part_declar_legal_age === "Y" ? t("yes") : <span className="text-danger">{t("no")}</span>}</td>
                                </tr>
                                <tr>
                                    <td>{t("participant.acceptedRegulations")}</td>
                                    <td>{partData[0].part_declar_tac_agreement === "Y" ? t("yes") : <span className="text-danger">{t("no")}</span>}</td>
                                </tr>
                                <tr>
                                    <td>{t("participant.registrationCommentLabel")}</td>
                                    <td>{partData[0].part_comment_form ? partData[0].part_comment_form : <span className="opacity-4">{t("lack")}</span>}</td>
                                </tr>
                            </tbody>
                        </Table>
                        {/* accreditation prices and discounts */}
                        {onCheckUserPrivilage("ACCR_PAYMENTS_LIST") && (
                            <Fragment>
                                <Table bordered hover className="table-details" responsive="lg" id={`participationPartDetailPricesAndDiscounts`}>
                                    <tbody>
                                        <tr>
                                            <td>{t("participant.submittedPriceWithDiscount")}</td>
                                            <td>
                                                {partData[0].part_verified_amount.toFixed(2)} {t("currency")}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>{t("participant.calculatedDiscount")}</td>
                                            <td>{partData[0].part_calculated_discount ? partData[0].part_calculated_discount : 0}%</td>
                                        </tr>
                                        <tr>
                                            <td>{t("participant.finalDiscount")}</td>
                                            <td>{partData[0].part_verified_discount ? partData[0].part_verified_discount : 0}%</td>
                                        </tr>
                                        <tr>
                                            <td>{t("participant.discountAssignedBy")}</td>
                                            <td>{partData[0].verifier_fullname ? partData[0].verifier_fullname : <span className="opacity-4">{t("lack")}</span>}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "60%" }}>{t("participant.mtDeclaration")}</td>
                                            <td>
                                                {partData[0].part_verified_discount >= 100 ? (
                                                    <span className="opacity-4">{t("na")}</span>
                                                ) : partData[0].part_declar_money_transfer === "Y" ? (
                                                    <span className="text-success">{t("yes")}</span>
                                                ) : (
                                                    <span className={partData[0].part_verified_amount === 0 ? "opacity-4" : "text-danger"}>{t("no")}</span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "60%" }}>{t("participant.dateDeclaration")}</td>
                                            <td>
                                                {partData[0].part_verified_discount >= 100 ? (
                                                    <span className="opacity-4">{t("na")}</span>
                                                ) : partData[0].part_declar_mt_date ? (
                                                    `${partData[0].part_declar_mt_date}`
                                                ) : (
                                                    <span className="opacity-4">{t("lack")}</span>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Table bordered hover className="table-details" responsive="lg" id={`participationPartDetailPayments`}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "60%" }}>{t("participant.paymentBalance")}</td>
                                            <td>
                                                {partData[0].payment_amount_accr === null ? (
                                                    t("payment.noPayments")
                                                ) : (
                                                    <span>
                                                        {partData[0].payment_amount_accr.toFixed(2)} {t("currency")}
                                                    </span>
                                                )}
                                                {partData[0].part_verified_amount - partData[0].payment_amount_accr > 0 && onCheckUserPrivilage("ACCR_SEND_PAYMENT_REMINDER") && (
                                                    <Button
                                                        className="my-2 d-block"
                                                        variant="outline-secondary"
                                                        size="sm"
                                                        onClick={!partData[0].payment_amount_accr ? onAccrPaymentReminder : () => onPaymentReminder("accrNotEnough")}
                                                    >
                                                        {!partData[0].payment_amount_accr ? t("payment.remindAboutPayment") : t("payment.remindAboutPaymentNotEnough")}
                                                    </Button>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t("participant.accrPaymentStatuses")}</td>
                                            <td>
                                                {partData[0].part_verified_amount - partData[0].payment_amount_accr === 0 ? (
                                                    <span className="text-success">{t("payment.paid")}</span>
                                                ) : partData[0].part_verified_amount - partData[0].payment_amount_accr > 0 ? (
                                                    <span className="text-danger">{t("payment.underpaid")}</span>
                                                ) : (
                                                    <span className="text-primary">{t("payment.overpaid")}</span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t("participant.allPaymentBalance")}</td>
                                            <td>
                                                {partData[0].payment_amount_all || partData[0].payment_amount_all === 0 ? (
                                                    <Fragment>
                                                        <p className="mb-0">
                                                            {partData[0].payment_amount_all.toFixed(2)} {t("currency")}
                                                        </p>
                                                        <Button
                                                            size="sm"
                                                            className="my-2"
                                                            variant="outline-secondary"
                                                            onClick={() =>
                                                                onChangeNavAndPage({ nav: "participants", subpage: "paymentsList", params: { partID: partID, userID: userID } }, currentPageParams)
                                                            }
                                                        >
                                                            {t("participant.showPaymentsList")}
                                                        </Button>

                                                        {partData[0].payment_amount_all > 0 && onCheckUserPrivilage("ACCR_SEND_PAYMENT_REMINDER") && (
                                                            <Button className="my-2 d-block" variant="outline-secondary" size="sm" onClick={() => onPaymentReminder("over")}>
                                                                {t("payment.overpaiedReminder")}
                                                            </Button>
                                                        )}
                                                        {partData[0].payment_amount_all < 0 && onCheckUserPrivilage("ACCR_SEND_PAYMENT_REMINDER") && (
                                                            <Button className="my-2 d-block" variant="outline-secondary" size="sm" onClick={() => onPaymentReminder("under")}>
                                                                {t("payment.underpaiedReminder")}
                                                            </Button>
                                                        )}
                                                    </Fragment>
                                                ) : (
                                                    <span className={!partData[0].payment_amount_all ? "" : "text-danger"}>{t("payment.noPayments")}</span>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Table bordered hover className="table-details" responsive="lg" id={`participationPartDetailEventsTable`}>
                                    <tbody>
                                        {partData[0].part_stat_code === "PRESENT" && (
                                            <tr>
                                                <td>{t("participant.arrivalPartDate")}</td>
                                                <td>{partData[0].part_arrival_date ? partData[0].part_arrival_date : <span className="opacity-4">{t("lack")}</span>}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td style={{ width: "60%" }}>{t("participant.registerPartDate")}</td>
                                            <td>{partData[0].create_date}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("participant.modifyDate")}</td>
                                            <td>{partData[0].modify_date}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Fragment>
                        )}
                    </Col>

                    <Col lg={4} xs={12}>
                        {onCheckUserPrivilage("ACCR_INDV_EDIT") && (
                            <Fragment>
                                <p className="h5 mb-3" style={{ lineHeight: "0.9" }}>
                                    {t("comments")}
                                </p>
                                <p>{partData[0].part_comment_org ? <i>{partData[0].part_comment_org}</i> : <span className="opacity-4">{t("lack")}</span>}</p>
                            </Fragment>
                        )}
                    </Col>
                </Fragment>
            </Fragment>
        );
    }
}

export default BasicData;
