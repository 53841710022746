import React, { Suspense, Fragment } from "react";
import App from "./accr-admin/App";

export default function LoadApp() {
    return (
        <Suspense fallback={<Fragment></Fragment>}>
            <App />
        </Suspense>
    );
}
