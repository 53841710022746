import React, { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmModal = (props) => {
    const { show, onClose, onChangeView, navName, navPage, t } = props;
    return (
        <Fragment>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("confirmModal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("confirmModal.text")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={onClose}>
                        {t("cancel")}
                    </Button>
                    <Button variant="primary" type="button" onClick={onChangeView.bind(this, navName, navPage, "")}>
                        {t("yes")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default ConfirmModal;
