import React, { Fragment } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";
import { Form } from "react-bootstrap";
import $ from "jquery";
registerLocale("pl", pl);

const CalendarDate = (props) => {
    const { txtLabel, onChangeCalendarDate, value, id, t } = props;
    $(`#${id}`).on("click", function () {
        $(this).removeClass("danger");
    });
    return (
        <Fragment>
            <Form.Group>
                <Form.Label>{txtLabel}</Form.Label>
                <DatePicker placeholderText={t("calendar.selectDate")} selected={value} onChange={(date) => onChangeCalendarDate(date)} dateFormat="yyyy-MM-dd" locale="pl" id={id} />
            </Form.Group>
        </Fragment>
    );
};

export default CalendarDate;
