import React, { Fragment } from "react";
import { Alert, Row, Col, Button } from "react-bootstrap";
import $ from "jquery";

const AlertBasic = (props) => {
    const { variant, mssg, onCloseAlert, fading, contactBtn, onShowContactModal, t } = props;
    $(document).ready(function () {
        $(".alert-basic").fadeIn(0);
        if (fading) {
            $(".alert-basic").delay(fading).fadeOut(400);
            setTimeout(() => {
                onCloseAlert();
            }, fading + 400);
        }
    });

    return (
        <Fragment>
            <Row>
                <Col>
                    <Alert className="alert-basic" variant={variant} onClose={onCloseAlert} dismissible style={{ display: "none" }}>
                        {mssg}
                        {contactBtn && (
                            <Button variant={`outline-${variant}`} onClick={onShowContactModal} className="d-block mt-2">
                                {t("contactWithAdminModal.title")}
                            </Button>
                        )}
                    </Alert>
                </Col>
            </Row>
        </Fragment>
    );
};

export default AlertBasic;
