import React, { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";

const CreateScheduleConfirmModal = (props) => {
    const { partsList, onClose, onConfirm, t } = props;
    return (
        <Fragment>
            <Modal show={true} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("duties.confirmModal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {partsList.partsWithNoDuties.length > 0 && (
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: t("duties.confirmModal.noDuties") }}></div>

                            <ul>
                                {partsList.partsWithNoDuties.map((e, i) => (
                                    <Fragment key={i}>
                                        <li>{e}</li>
                                    </Fragment>
                                ))}
                            </ul>
                        </div>
                    )}

                    {partsList.partsWithToMuchDuties.length > 0 && (
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: t("duties.confirmModal.tooMuchDuties") }}></div>
                            <ul>
                                {partsList.partsWithToMuchDuties.map((e, i) => (
                                    <Fragment key={i}>
                                        <li>{e}</li>
                                    </Fragment>
                                ))}
                            </ul>
                        </div>
                    )}
                    {t("duties.confirmModal.confirm")}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={onClose} type="button">
                        {t("no")}
                    </Button>
                    <Button variant="primary" type="button" onClick={onConfirm}>
                        {t("yes")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default CreateScheduleConfirmModal;
