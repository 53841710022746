import React, { Fragment } from "react";
import BarBalanceList from "../../pages/transactions/BarBalanceList";
import TransactionsList from "../../pages/transactions/TransactionsList";

const Transactions = (props) => {
    return (
        <Fragment>
            {props.currentPage.nav === "transactions" && (
                <Fragment>
                    {props.currentPage.subpage === "transactionsList" && (
                        <TransactionsList
                            onResizeWindow={props.onResizeWindow}
                            fetchURL={props.fetchURL}
                            onCheckUserPrivilage={props.onCheckUserPrivilage}
                            onChangeNavAndPage={props.onChangeNavAndPage}
                            currentPage={props.currentPage}
                            activeEvent={props.activeEvent}
                            onShowAlert={props.onShowAlert}
                            onGetDataList={props.onGetDataList}
                            dataList={props.dataList}
                            numberOfRecords={props.numberOfRecords}
                            onChangeNOR={props.onChangeNOR}
                            eventFilterValue={props.eventFilterValue}
                            eventsList={props.eventsList}
                            onChangeEventFilter={props.onChangeEventFilter}
                            t={props.t}
                            onShowHomePage={props.onShowHomePage}
                        />
                    )}
                    {props.currentPage.subpage === "barBalanceList" && (
                        <BarBalanceList
                            onResizeWindow={props.onResizeWindow}
                            fetchURL={props.fetchURL}
                            onCheckUserPrivilage={props.onCheckUserPrivilage}
                            onChangeNavAndPage={props.onChangeNavAndPage}
                            currentPage={props.currentPage}
                            activeEvent={props.activeEvent}
                            onShowAlert={props.onShowAlert}
                            onGetDataList={props.onGetBarBalanceList}
                            dataList={props.barBalanceList}
                            numberOfRecords={props.numberOfRecords}
                            onChangeNOR={props.onChangeNOR}
                            eventFilterValue={props.eventFilterValue}
                            eventsList={props.eventsList}
                            onChangeEventFilter={props.onChangeEventFilter}
                            t={props.t}
                            onShowHomePage={props.onShowHomePage}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Transactions;
