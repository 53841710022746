import React, { Fragment } from "react";
import { Col, Row, Table } from "react-bootstrap";
import Moment from "react-moment";
import "moment-timezone";

const InactiveEventsList = (props) => {
    const { t, inactiveEventsList } = props;
    return (
        <Fragment>
            <Row>
                <Col xs={12}>
                    <p className="h4 mb-4 mt-3 form-title">{t("settings.inactiveConventionsList")}</p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Table bordered hover className="table-list" responsive="lg" id="inactiveEventsTable">
                        <thead>
                            <tr>
                                <th>{t("settings.convention.name")}</th>
                                <th>{t("settings.convention.code")}</th>
                                <th>{t("settings.convention.price")}</th>
                                <th>{t("settings.convention.startConv")}</th>
                                <th>{t("settings.convention.endConv")}</th>
                                <th>{t("settings.convention.startLarp")}</th>
                                <th>{t("settings.convention.endLarp")}</th>
                                <th style={{ width: "12%" }}>{t("settings.convention.amount")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {inactiveEventsList.map((e, i) => (
                                <tr key={i}>
                                    <td>{e.event_name}</td>
                                    <td>{e.event_code}</td>
                                    <td>{e.event_price ? `${e.event_price.toFixed(2)} ${t("currency")}` : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{e.event_start_date ? <Moment format="YYYY-MM-DD">{e.event_start_date}</Moment> : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{e.event_end_date ? <Moment format="YYYY-MM-DD">{e.event_end_date}</Moment> : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{e.larp_start_datetime ? <Moment format="YYYY-MM-DD HH:mm">{e.larp_start_datetime}</Moment> : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{e.larp_end_datetime ? <Moment format="YYYY-MM-DD HH:mm">{e.larp_end_datetime}</Moment> : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{e.present_participants}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Fragment>
    );
};

export default InactiveEventsList;
