import React, { Fragment } from "react";
import MailsList from "../../pages/mails/MailsList";
import AddEditMail from "../../pages/mails/AddEditMail";
const Mails = (props) => {
    const {
        fetchURL,
        onCheckUserPrivilage,
        onChangeValuesInFormes,
        onCancelChangeValuesInFormes,
        scannerHeight,
        currentPage,
        windowHeight,
        onResizeWindow,
        activeEvent,
        onChangeNavAndPage,
        onBackToPreviousPage,
        reloadEventInfo,
        blockSendingEmails,
        onBlockSendingEmails,
        onUnblockSendingEmails,
        onShowAlert,
        dataList,
        onGetDataList,
        t,
        onShowHomePage,
    } = props;
    return (
        <Fragment>
            {currentPage.nav === "mails" && (
                <Fragment>
                    {currentPage.subpage === "mailsList" && (
                        <MailsList
                            fetchURL={fetchURL}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            scannerHeight={scannerHeight}
                            currentPage={currentPage}
                            windowHeight={windowHeight}
                            onResizeWindow={onResizeWindow}
                            activeEvent={activeEvent}
                            onChangeNavAndPage={onChangeNavAndPage}
                            reloadEventInfo={reloadEventInfo}
                            blockSendingEmails={blockSendingEmails}
                            onBlockSendingEmails={onBlockSendingEmails}
                            onUnblockSendingEmails={onUnblockSendingEmails}
                            onShowAlert={onShowAlert}
                            dataList={dataList}
                            onGetDataList={onGetDataList}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                    {(currentPage.subpage === "addMail" || currentPage.subpage === "editMail") && (
                        <AddEditMail
                            fetchURL={fetchURL}
                            onCheckUserPrivilage={onCheckUserPrivilage}
                            onChangeValuesInFormes={onChangeValuesInFormes}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                            scannerHeight={scannerHeight}
                            currentPage={currentPage}
                            windowHeight={windowHeight}
                            onResizeWindow={onResizeWindow}
                            activeEvent={activeEvent}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onBackToPreviousPage={onBackToPreviousPage}
                            onShowAlert={onShowAlert}
                            t={t}
                            onShowHomePage={onShowHomePage}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Mails;
