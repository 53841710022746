import React, { Fragment } from "react";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import BtnNav from "./BtnNav";

const MainNavbar = (props) => {
    const { changedValuesInFormes, currentPage, onChangeNavAndPage, onShowConfirmModal, onCheckUserPrivilage, t } = props;

    return (
        <Fragment>
            <Navbar expand="xl" id="mainNavbar">
                <Container fluid>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            {onCheckUserPrivilage("ACCR_LOGIN") && (
                                <BtnNav
                                    navName="participants"
                                    pageName="participantsList"
                                    title={t("mainNavbar.accr")}
                                    currentPage={currentPage}
                                    changedValuesInFormes={changedValuesInFormes}
                                    onShowConfirmModal={onShowConfirmModal}
                                    onChangeNavAndPage={onChangeNavAndPage}
                                />
                            )}
                            {onCheckUserPrivilage("ACCR_USERS_VIEW") && (
                                <BtnNav
                                    navName="users"
                                    pageName="usersList"
                                    title={t("mainNavbar.users")}
                                    currentPage={currentPage}
                                    changedValuesInFormes={changedValuesInFormes}
                                    onShowConfirmModal={onShowConfirmModal}
                                    onChangeNavAndPage={onChangeNavAndPage}
                                />
                            )}
                            {onCheckUserPrivilage("ACCR_TRANSACTIONS") && (
                                <Fragment>
                                    <NavDropdown title={t("mainNavbar.transactions")} id="basic-nav-dropdown" className={currentPage === "transactions" ? "active" : ""}>
                                        <NavDropdown.Item
                                            id="transactionsList"
                                            onClick={() => {
                                                changedValuesInFormes
                                                    ? onShowConfirmModal({ nav: "transactions", subpage: "transactionsList", params: "" })
                                                    : onChangeNavAndPage({ nav: "transactions", subpage: "transactionsList", params: "" }, null);
                                            }}
                                        >
                                            {t("mainNavbar.allTransactions")}
                                        </NavDropdown.Item>

                                        <NavDropdown.Item
                                            id="shopOrdersListNav"
                                            onClick={() => {
                                                changedValuesInFormes
                                                    ? onShowConfirmModal({ nav: "transactions", subpage: "barBalanceList", params: "" })
                                                    : onChangeNavAndPage({ nav: "transactions", subpage: "barBalanceList", params: "" }, null);
                                            }}
                                        >
                                            {t("mainNavbar.barBalance")}
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </Fragment>
                            )}

                            {onCheckUserPrivilage("ACCR_IDENTS_VIEW") && (
                                <BtnNav
                                    navName="idents"
                                    pageName="identsList"
                                    title={t("mainNavbar.idents")}
                                    currentPage={currentPage}
                                    changedValuesInFormes={changedValuesInFormes}
                                    onShowConfirmModal={onShowConfirmModal}
                                    onChangeNavAndPage={onChangeNavAndPage}
                                />
                            )}
                            {onCheckUserPrivilage("ACCR_GROUPS_VIEW") && (
                                <BtnNav
                                    navName="groups"
                                    pageName="groupsList"
                                    title={t("mainNavbar.groups")}
                                    currentPage={currentPage}
                                    changedValuesInFormes={changedValuesInFormes}
                                    onShowConfirmModal={onShowConfirmModal}
                                    onChangeNavAndPage={onChangeNavAndPage}
                                />
                            )}
                            {/* POKOJE WYŁĄCZONE NA RAZIE Z UŻYCIA */}
                            {/* onCheckUserPrivilage("ACCR_ROOMS_NAV") && (
                                <NavDropdown title={t("mainNavbar.rooms")} id="basic-nav-dropdown" className={currentPage === "rooms" ? "active" : ""}>
                                    <NavDropdown.Item
                                        id="roomBookingsNav"
                                        onClick={() => {
                                            changedValuesInFormes
                                                ? onShowConfirmModal({ nav: "rooms", subpage: "roomBookings", params: "" })
                                                : onChangeNavAndPage({ nav: "rooms", subpage: "roomBookings", params: "" }, null);
                                        }}
                                    >
                                        {t("mainNavbar.roomBookings")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        id="roomsListNav"
                                        onClick={() => {
                                            changedValuesInFormes
                                                ? onShowConfirmModal({ nav: "rooms", subpage: "roomsList", params: "" })
                                                : onChangeNavAndPage({ nav: "rooms", subpage: "roomsList", params: "" }, null);
                                        }}
                                    >
                                        {t("mainNavbar.roomsList")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        id="roomsRequestsListNav"
                                        onClick={() => {
                                            changedValuesInFormes
                                                ? onShowConfirmModal({ nav: "rooms", subpage: "roomsRequestsList", params: "" })
                                                : onChangeNavAndPage({ nav: "rooms", subpage: "roomsRequestsList", params: "" }, null);
                                        }}
                                    >
                                        {t("mainNavbar.roomsReq")}
                                    </NavDropdown.Item>
                                </NavDropdown>
                            ) */}
                            {onCheckUserPrivilage("ACCR_APPLICATIONS_VIEW") && (
                                <BtnNav
                                    navName="applications"
                                    pageName="applicationsList"
                                    title={t("mainNavbar.forms")}
                                    currentPage={currentPage}
                                    changedValuesInFormes={changedValuesInFormes}
                                    onShowConfirmModal={onShowConfirmModal}
                                    onChangeNavAndPage={onChangeNavAndPage}
                                />
                            )}
                            {onCheckUserPrivilage("ACCR_DUTIES_VIEW") && (
                                <BtnNav
                                    navName="duties"
                                    pageName="dutiesList"
                                    title={t("mainNavbar.duties")}
                                    currentPage={currentPage}
                                    changedValuesInFormes={changedValuesInFormes}
                                    onShowConfirmModal={onShowConfirmModal}
                                    onChangeNavAndPage={onChangeNavAndPage}
                                />
                            )}

                            {onCheckUserPrivilage("ACCR_SHOP_VIEW") && (
                                <NavDropdown title={t("mainNavbar.shop")} id="basic-nav-dropdown" className={currentPage === "shop" ? "active" : ""}>
                                    <NavDropdown.Item
                                        id="shopItemsListNav"
                                        onClick={() => {
                                            changedValuesInFormes
                                                ? onShowConfirmModal({ nav: "shop", subpage: "shopItemsList", params: "" })
                                                : onChangeNavAndPage({ nav: "shop", subpage: "shopItemsList", params: "" }, null);
                                        }}
                                    >
                                        {t("mainNavbar.products")}
                                    </NavDropdown.Item>

                                    <NavDropdown.Item
                                        id="shopOrdersListNav"
                                        onClick={() => {
                                            changedValuesInFormes
                                                ? onShowConfirmModal({ nav: "shop", subpage: "shopOrdersList", params: "" })
                                                : onChangeNavAndPage({ nav: "shop", subpage: "shopOrdersList", params: "" }, null);
                                        }}
                                    >
                                        {t("mainNavbar.orders")}
                                    </NavDropdown.Item>
                                </NavDropdown>
                            )}
                            {onCheckUserPrivilage("ACCR_LARP_NAV") && (
                                <BtnNav
                                    navName="characters"
                                    pageName="characterApplicationsList"
                                    title={t("mainNavbar.characterApplications")}
                                    currentPage={currentPage}
                                    changedValuesInFormes={changedValuesInFormes}
                                    onShowConfirmModal={onShowConfirmModal}
                                    onChangeNavAndPage={onChangeNavAndPage}
                                />
                            )}
                            {onCheckUserPrivilage("ACCR_MAILS_VIEW") && (
                                <BtnNav
                                    navName="mails"
                                    pageName="mailsList"
                                    title={t("mainNavbar.mails")}
                                    currentPage={currentPage}
                                    changedValuesInFormes={changedValuesInFormes}
                                    onShowConfirmModal={onShowConfirmModal}
                                    onChangeNavAndPage={onChangeNavAndPage}
                                />
                            )}
                            {onCheckUserPrivilage("ACCR_SETTINGS_VIEW") && (
                                <BtnNav
                                    navName="settings"
                                    pageName="settingsEdit"
                                    title={t("mainNavbar.settings")}
                                    currentPage={currentPage}
                                    changedValuesInFormes={changedValuesInFormes}
                                    onShowConfirmModal={onShowConfirmModal}
                                    onChangeNavAndPage={onChangeNavAndPage}
                                />
                            )}
                            {onCheckUserPrivilage("ACCR_LOGS") && (
                                <BtnNav
                                    navName="logs"
                                    pageName="logsList"
                                    title={t("mainNavbar.logs")}
                                    currentPage={currentPage}
                                    changedValuesInFormes={changedValuesInFormes}
                                    onShowConfirmModal={onShowConfirmModal}
                                    onChangeNavAndPage={onChangeNavAndPage}
                                />
                            )}
                        </Nav>
                        <Nav>
                            {/* onCheckUserPrivilage("ACCR_RECEPTIONISTS_VIEW") && (
                                <BtnNav
                                    navName="receptionists"
                                    pageName="receptionistsList"
                                    title={t("mainNavbar.receptionists")}
                                    currentPage={currentPage}
                                    changedValuesInFormes={changedValuesInFormes}
                                    onShowConfirmModal={onShowConfirmModal}
                                    onChangeNavAndPage={onChangeNavAndPage}
                                />
                            ) */}

                            <BtnNav
                                navName="account"
                                pageName="myAccount"
                                title={t("mainNavbar.myAccount")}
                                currentPage={currentPage}
                                changedValuesInFormes={changedValuesInFormes}
                                onShowConfirmModal={onShowConfirmModal}
                                onChangeNavAndPage={onChangeNavAndPage}
                            />
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div id="borderNavbar"></div>
        </Fragment>
    );
};

export default MainNavbar;
