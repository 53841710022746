import React, { Fragment, Component } from "react";
import { Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";

class RoomsListTable extends Component {
    state = {};

    render() {
        const { roomBookingsList, displayNOR, onChangeNavAndPage, currentPageParams, t } = this.props;
        const sortedData = roomBookingsList;

        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th>{t("room.bookingsList.code")}</th>
                            <th>{t("room.bookingsList.event")}</th>
                            <th>{t("room.bookingsList.roomName")}</th>
                            <th>{t("room.bookingsList.reservation")}</th>
                            <th>{t("room.bookingsList.group")}</th>
                            <th>{t("room.bookingsList.keeper")}</th>
                            <th>{t("room.bookingsList.reservationBy")}</th>
                            <th>{t("room.bookingsList.date")}</th>
                            <th>{t("dropdowns.actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData
                            .map((p, i) => (
                                <tr key={i}>
                                    <td>{p.room_code ? p.room_code : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{p.event_name}</td>
                                    <td>{p.room_name}</td>
                                    <td>{p.room_request ? t("yes") : t("no")}</td>
                                    <td>{p.part_group_name ? p.part_group_name : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{p.room_keeper}</td>
                                    <td>{p.create_user}</td>
                                    <td>{p.create_date}</td>
                                    <td className="text-center">
                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`roomBookingTooltip${i}`}>{t("room.reservationDetails")}</Tooltip>}>
                                            <span className="d-inline-block">
                                                <Button
                                                    variant="outline-primary"
                                                    className="mx-1"
                                                    onClick={() => onChangeNavAndPage({ nav: "rooms", subpage: "roomBookingDetails", params: { roomBookingID: p.room_booking_id } }, currentPageParams)}
                                                >
                                                    <FontAwesomeIcon icon={faFileAlt} />
                                                </Button>
                                            </span>
                                        </OverlayTrigger>
                                    </td>
                                </tr>
                            ))
                            .slice(0, displayNOR)}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}

export default RoomsListTable;
