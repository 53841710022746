import React, { Fragment, Component } from "react";
import { Button, Row, Col, Form, InputGroup, Container /* OverlayTrigger, Tooltip */ } from "react-bootstrap";
import TableShopItemsList from "./TableShopItemsList";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";
import "react-widgets/dist/css/react-widgets.css";
import $ from "jquery";
import EventFilter from "../../../forms/dropdowns/EventFilter";
import { faEraser, faLongArrowAltLeft, faLongArrowAltRight, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoPermission from "../../../other/NoPermission";
import RowsDropdown from "../../../forms/dropdowns/RowsDropdown";

class ShopItemsList extends Component {
    state = {
        showNOR: this.props.numberOfRecords,
        displayNOR: this.props.numberOfRecords,
        shopItemsList: this.props.dataList,
        originalShopItemsList: this.props.dataList,
        searchedPhrase: "",
        loading: this.props.dataList ? false : true,
        selectedEvent: this.props.eventFilterValue /* this.props.activeEvent && this.props.activeEvent !== "noActiveEvent" ? this.props.activeEvent.code : "all" */,
        eventsList: [],
        noPermission: false,
    };

    handleSearch = (e) => {
        const searchedPhrase = e.target.value.toLowerCase();
        const { originalShopItemsList } = this.state;
        this.setState({ searchedPhrase: searchedPhrase });
        if (searchedPhrase !== "") {
            let filteredShopItemss = originalShopItemsList;
            //console.log(filteredShopItemss)
            filteredShopItemss = filteredShopItemss.filter(
                (el) =>
                    el.shit_name.toString().toLowerCase().includes(searchedPhrase) ||
                    el.shit_code.toString().toLowerCase().includes(searchedPhrase) ||
                    el.shcat_name.toString().toLowerCase().includes(searchedPhrase) ||
                    el.shit_price.toString().toLowerCase().includes(searchedPhrase) ||
                    (el.shit_quantity && el.shit_quantity.toString().toLowerCase().includes(searchedPhrase))
            );
            this.setState({ shopItemsList: filteredShopItemss });
        } else {
            this.setState({ shopItemsList: originalShopItemsList });
        }
    };

    handleDownloadData = () => {
        this.props.onResizeWindow("tableNotLoaded");
        this.setState({ loading: true });
        this.getShopItemsList();
    };

    getShopItemsList = async () => {
        const { fetchURL, onShowAlert, onGetDataList, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/shop-items-list`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const shopItemsList = fetchedData.shopItemsList;
                        shopItemsList.forEach((item) => {
                            if (item.blob_data) {
                                let blob = item.blob_data;
                                let buffer = Buffer.from(blob);
                                let bufferBase64 = buffer.toString("base64");

                                item.blob_data = bufferBase64;
                            } else item.blob_data = null;
                        });

                        this.setState({ shopItemsList: shopItemsList, originalShopItemsList: shopItemsList }, () => {
                            this.setState({ loading: false });
                            onGetDataList(shopItemsList);
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.getShopItemsList();
        const { onResizeWindow } = this.props;
        onResizeWindow("tableNotLoaded");
        $(window).on("resize", function () {
            onResizeWindow("tableLoaded");
        });
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if (!this.state.loading) {
            $("#loadTable").fadeIn(200);
            if ($(".light-border").outerHeight() && $(".rest-info").outerHeight()) {
                onResizeWindow("tableLoaded");
            }
        }
    }

    render() {
        const { shopItemsList, searchedPhrase, loading, displayNOR, showNOR, selectedEvent, noPermission } = this.state;
        const { onCheckUserPrivilage, onDisplaySubPage, onChangeNavAndPage, t, eventFilterValue } = this.props;

        //wyfiltrowana lista
        let filteredShopItemsList = null;
        if (shopItemsList) filteredShopItemsList = shopItemsList.filter((e) => eventFilterValue === "all" || e.event_code === eventFilterValue);
        const currentPageParams = { nav: "shop", subpage: "shopItemsList", params: "" };

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="oneNavbar" />
                ) : (
                    <Fragment>
                        <Container fluid className="pt-2 second-bar">
                            <Row>
                                <Col lg={3} xs={12}>
                                    <Form.Label>{t("form.searching")}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="text" placeholder={t("form.search")} onChange={this.handleSearch} value={searchedPhrase} />
                                        {searchedPhrase && (
                                            <InputGroup.Append>
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() =>
                                                        this.setState({
                                                            searchedPhrase: "",
                                                            shopItemsList: this.state.originalShopItemsList,
                                                        })
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faEraser} />
                                                </Button>
                                            </InputGroup.Append>
                                        )}
                                    </InputGroup>
                                </Col>
                                <Col xl={1} lg={2} md={2} xs={4}>
                                    <RowsDropdown
                                        t={t}
                                        defaultValue={this.props.numberOfRecords}
                                        onChangeValue={(event, { value }) => {
                                            this.setState({
                                                showNOR: value,
                                                displayNOR: value,
                                            });
                                            this.props.onChangeNOR(value);
                                        }}
                                    />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <EventFilter t={t} eventFilterValue={this.props.eventFilterValue} eventsList={this.props.eventsList} onChangeEventFilter={this.props.onChangeEventFilter} />
                                </Col>
                                <Col lg={{ span: 2, offset: 4 }} xs={12} className="text-left text-lg-right mt-lg-3 mt-lg-0 mb-3">
                                    <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync mr-3">
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                    {onCheckUserPrivilage("ACCR_SHOP_ITEMS_ACTIONS") && (
                                        <Button
                                            variant="outline-primary"
                                            type="button"
                                            onClick={() => {
                                                onChangeNavAndPage({ nav: "shop", subpage: "addShopItem", params: "" }, currentPageParams);
                                            }}
                                            disabled={loading}
                                        >
                                            {t("shop.addProduct")}
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="content-body">
                            {loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <div id="loadTable" style={{ display: "none" }}>
                                    <Row>
                                        <Col>
                                            <div className="table-responsive table-fixed-head">
                                                {filteredShopItemsList.length > 0 ? (
                                                    <Fragment>
                                                        <TableShopItemsList
                                                            shopItemsList={filteredShopItemsList}
                                                            displayNOR={displayNOR}
                                                            onDisplaySubPage={onDisplaySubPage}
                                                            onChangeNavAndPage={onChangeNavAndPage}
                                                            selectedEvent={selectedEvent}
                                                            onCheckUserPrivilage={onCheckUserPrivilage}
                                                            t={t}
                                                        />
                                                        <div className="light-border"></div>
                                                    </Fragment>
                                                ) : (
                                                    <div className="text-center">{t("notFound")}</div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="rest-info py-3">
                                        <Col>
                                            {t("loaded")} {displayNOR > filteredShopItemsList.length ? filteredShopItemsList.length : displayNOR} {t("from")} {filteredShopItemsList.length}{" "}
                                            {t("records")}
                                        </Col>
                                        <Col className="text-center">
                                            {displayNOR >= filteredShopItemsList.length ? (
                                                <p className="opacity-4">
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                    <span className="px-3">{t("thatsAll")}</span>
                                                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                </p>
                                            ) : (
                                                <Button onClick={() => this.setState({ displayNOR: showNOR + displayNOR })} variant="outline-primary">
                                                    {t("loadMore")}
                                                </Button>
                                            )}
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </div>
                            )}
                        </Container>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default ShopItemsList;
