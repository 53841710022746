import React, { Fragment } from "react";
import { faSadTear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import { Button } from "react-bootstrap";

const NoPermission = (props) => {
    const { option, t } = props;
    let height = $(window).height();
    if (option === "oneNavbar") {
        height -= $("#mainNavbar").outerHeight();
    } else if (option === "twoNavbars") {
        height -= $("#mainNavbar").outerHeight() - $(".nav-details").outerHeight();
    }
    const width = $(window).width();
    return (
        <Fragment>
            <div style={{ width: width, height: height }} id="noPermission">
                <div style={{ top: "50%", left: "50%", position: "absolute", transform: "translate(-50%, -50%)" }} className="text-center">
                    <img src="https://media2.giphy.com/media/2sdLxid6Y31kNFVPvr/giphy.gif" alt="Itysl 104 GIF by Vulture.com" />
                    <p className="my-4 h2 text-uppercase">{t("permissionDenied.title")}</p>

                    <p className="mt-4">
                        {t("permissionDenied.text1")} <FontAwesomeIcon icon={faSadTear} />
                        <br />
                        {t("permissionDenied.text2")}
                    </p>

                    <Button size="lg" className="mt-3" variant="primary" onClick={() => window.location.reload()}>
                        {t("permissionDenied.reload")}
                    </Button>
                </div>
            </div>
        </Fragment>
    );
};

export default NoPermission;
