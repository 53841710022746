import React, { Fragment, Component } from "react";
import { Button, Row, Col, Form, InputGroup, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import TableIdentsList from "./TableIdentsList";
import "react-widgets/dist/css/react-widgets.css";
import { Dropdown } from "semantic-ui-react";
import $ from "jquery";
import { faBookReader, faEraser, faHashtag, faLongArrowAltLeft, faLongArrowAltRight, faPlus, faQrcode, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import NoPermission from "../../other/NoPermission";
import RowsDropdown from "../../forms/dropdowns/RowsDropdown";
import ShowQRCode from "../../other/ShowQRCode";
import GenerateCodesModal from "../../modals/GenerateCodesModal";
import QRCodeDetailsModal from "../../modals/QRCodeDetailsModal";

class IdentsList extends Component {
    state = {
        showNOR: this.props.numberOfRecords,
        displayNOR: this.props.numberOfRecords,
        identsList: this.props.dataList,
        originalIdents: this.props.dataList,
        searchedPhrase: "",
        loading: this.props.dataList ? false : true,
        identActivity: [
            {
                value: "default",
                text: this.props.t("selectAll"),
                key: 1,
            },
            {
                value: "Y",
                text: this.props.t("active"),
                key: 2,
            },
            {
                value: "N",
                text: this.props.t("inactive"),
                key: 3,
            },
        ],
        identActivitySelected: { value: "Y" },
        noPermission: false,
        showScanner: false,
        qrCode: null,
        showQRCodeDetailsModal: false,
        showGenerateCodesModal: false,
        qrCodeDetails: null,
    };

    handleSearch = (e) => {
        const searchedPhrase = e.target.value.toLowerCase();
        this.setState({ searchedPhrase: searchedPhrase });
        if (searchedPhrase !== "") {
            let filteredIdents = this.state.originalIdents;
            filteredIdents = filteredIdents.filter(
                (el) =>
                    el.ident_object.toString().toLowerCase().includes(searchedPhrase) ||
                    el.object_name.toString().toLowerCase().includes(searchedPhrase) ||
                    el.auth_user_name.toString().toLowerCase().includes(searchedPhrase)
            );
            this.setState({ identsList: filteredIdents });
        } else {
            this.setState({ identsList: this.state.originalIdents });
        }
    };

    handleDownloadData = () => {
        this.setState({ loading: true });
        this.getIdentsList();
    };

    getIdentsList = async () => {
        const { fetchURL, onShowAlert, onGetDataList, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/idents-list`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const identsList = fetchedData.identsList;
                        this.setState(
                            {
                                identsList: identsList,
                                originalIdents: identsList,
                            },
                            () => {
                                onGetDataList(identsList);
                                this.setState({ loading: false });
                            }
                        );
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    getQRCodeDetails = async (qrCode) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            qrCode: qrCode,
        };
        try {
            const response = await fetch(`${fetchURL}/get-qr-code-details`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === "doesNotExist") onShowAlert({ variant: "warning", message: t(`warning.${fetchedData.code}`), fading: 2500 });
                    else {
                        this.setState({
                            showQRCodeDetailsModal: true,
                            qrCodeDetails: fetchedData.identDetails,
                        });
                        onShowAlert("");
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleHashIdents = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/hash-idents`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else this.handleDownloadData();
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        this.getIdentsList();
        const { onResizeWindow } = this.props;
        onResizeWindow("tableNotLoaded");
        $(window).on("resize", function () {
            onResizeWindow("tableLoaded");
        });
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if (!this.state.loading) {
            $("#loadTable").fadeIn(200);
            if ($(".light-border").outerHeight() && $(".rest-info").outerHeight()) {
                onResizeWindow("tableLoaded");
            }
        }
    }

    render() {
        const { identsList, searchedPhrase, loading, identActivity, showNOR, displayNOR, identActivitySelected, noPermission, showScanner, qrCode, showQRCodeDetailsModal, qrCodeDetails } = this.state;
        const { onCheckUserPrivilage, onDisplaySubPage, fetchURL, onChangeNavAndPage, currentPage, scannerHeight, t, onShowHomePage, onShowAlert } = this.props;

        let filteredIdentsList = null;
        if (identsList) filteredIdentsList = identsList.filter((p) => identActivitySelected.value === "default" || identActivitySelected.value === p.ident_active);
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: "" };

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : (
                    <Fragment>
                        <Container fluid className="pt-2 second-bar">
                            <Row>
                                <Col lg={3} xs={12}>
                                    <Form.Label>{t("form.searching")}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="text" placeholder={t("form.search")} onChange={this.handleSearch} value={searchedPhrase} />
                                        {searchedPhrase && (
                                            <InputGroup.Append>
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() =>
                                                        this.setState({
                                                            searchedPhrase: "",
                                                            identsList: this.state.originalIdents,
                                                        })
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faEraser} />
                                                </Button>
                                            </InputGroup.Append>
                                        )}
                                    </InputGroup>
                                </Col>
                                <Col lg={3} xs={5}>
                                    <RowsDropdown
                                        t={t}
                                        defaultValue={this.props.numberOfRecords}
                                        onChangeValue={(event, { value }) => {
                                            this.setState({
                                                showNOR: value,
                                                displayNOR: value,
                                            });
                                            this.props.onChangeNOR(value);
                                        }}
                                    />
                                </Col>
                                <Col lg={3} xs={7}>
                                    <Form.Label>{t("ident.showIdentStatus")}:</Form.Label>
                                    <Dropdown
                                        className="mb-3"
                                        placeholder={t("form.choose")}
                                        fluid
                                        selection
                                        defaultValue={"Y"}
                                        options={identActivity}
                                        onChange={(event, { value }) => {
                                            this.setState({
                                                identActivitySelected: {
                                                    value: value,
                                                },
                                            });
                                        }}
                                    />
                                </Col>
                                <Col lg={3} xs={12} className="text-center text-lg-right mt-lg-3 mt-lg-0 mb-3">
                                    <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync mr-3">
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltipScanQRCode">{t("ident.scanQRCode")}</Tooltip>}>
                                        <Button
                                            variant="outline-primary"
                                            className="mr-3"
                                            onClick={() => {
                                                this.props.onShowAlert("");
                                                this.setState({ showScanner: true });
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faBookReader} />
                                        </Button>
                                    </OverlayTrigger>

                                    {onCheckUserPrivilage("COMMONT_IDENTS_ACTIONS") && (
                                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltipAddQRCode">{t("ident.addIdent")}</Tooltip>}>
                                            <Button
                                                className="mr-3"
                                                variant="outline-primary"
                                                type="button"
                                                onClick={() => onChangeNavAndPage({ nav: "idents", subpage: "addIdent", params: "" }, currentPageParams)}
                                            >
                                                <FontAwesomeIcon icon={faPlus} />
                                            </Button>
                                        </OverlayTrigger>
                                    )}
                                    {onCheckUserPrivilage("ACCR_GENERATE_QR_CODES") && (
                                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltipGenerateQRCode">{t("ident.generateCodes")}</Tooltip>}>
                                            <Button className="mr-3" variant="outline-primary" type="button" onClick={() => this.setState({ showGenerateCodesModal: true })}>
                                                <FontAwesomeIcon icon={faQrcode} />
                                            </Button>
                                        </OverlayTrigger>
                                    )}
                                    {onCheckUserPrivilage("COMMONT_IDENTS_ACTIONS") && (
                                        <Button
                                            variant="outline-primary"
                                            type="button"
                                            onClick={() => {
                                                this.setState({ loading: true }, () => this.handleHashIdents());
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faHashtag} />
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="content-body">
                            {loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <div id="loadTable" style={{ display: "none" }}>
                                    <Row>
                                        <Col>
                                            <div className="table-responsive table-fixed-head">
                                                {filteredIdentsList.length > 0 ? (
                                                    <Fragment>
                                                        <TableIdentsList
                                                            identActivitySelected={identActivitySelected}
                                                            idents={filteredIdentsList}
                                                            numOfRecords={displayNOR}
                                                            onDisplaySubPage={onDisplaySubPage}
                                                            fetchURL={fetchURL}
                                                            onRefreshData={this.handleDownloadData}
                                                            t={t}
                                                            onShowHomePage={onShowHomePage}
                                                            currentPageParams={currentPageParams}
                                                            onChangeNavAndPage={onChangeNavAndPage}
                                                            onCheckUserPrivilage={onCheckUserPrivilage}
                                                            onShowAlert={onShowAlert}
                                                        />
                                                        <div className="light-border"></div>{" "}
                                                    </Fragment>
                                                ) : (
                                                    <div className="text-center">{t("notFound")}</div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="rest-info py-3">
                                        <Col>
                                            {t("loaded")} {displayNOR > filteredIdentsList.length ? filteredIdentsList.length : displayNOR} {t("from")} {filteredIdentsList.length} {t("records")}
                                        </Col>
                                        <Col className="text-center ">
                                            {displayNOR >= filteredIdentsList.length ? (
                                                <p className="opacity-4">
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                    <span className="px-3">{t("thatsAll")}</span>
                                                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                </p>
                                            ) : (
                                                <Button onClick={() => this.setState({ displayNOR: showNOR + displayNOR })} variant="outline-primary">
                                                    {t("loadMore")}
                                                </Button>
                                            )}
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </div>
                            )}
                        </Container>
                    </Fragment>
                )}

                {showScanner && (
                    <ShowQRCode
                        qrcode={qrCode}
                        onScanQRCode={(code) => {
                            onShowAlert({ variant: "info", message: t(`loading`) });
                            this.setState({ showScanner: false }, () => this.getQRCodeDetails(code));
                        }}
                        qrReaderHeight={scannerHeight}
                        showScanner={showScanner}
                        onHideScanner={() => this.setState({ showScanner: false })}
                        t={t}
                        onShowHomePage={onShowHomePage}
                        onShowAlert={onShowAlert}
                    />
                )}

                {this.state.showGenerateCodesModal && <GenerateCodesModal show={this.state.showGenerateCodesModal} onClose={() => this.setState({ showGenerateCodesModal: false })} t={t} />}
                {showQRCodeDetailsModal && (
                    <QRCodeDetailsModal
                        onClose={() => this.setState({ showQRCodeDetailsModal: false })}
                        details={qrCodeDetails}
                        t={t}
                        currentPageParams={currentPageParams}
                        onChangeNavAndPage={onChangeNavAndPage}
                    />
                )}
            </Fragment>
        );
    }
}

export default IdentsList;
