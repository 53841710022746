import React, { Fragment, Component } from "react";
import { Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons";

class TableShopOrdersList extends Component {
    render() {
        const { shopOrdersList, displayNOR, t, onChangeNavAndPage } = this.props;

        function itemsValue(items) {
            let value = 0;
            items.forEach((e) => {
                value += e.shordit_price * e.shordit_quantity;
            });
            return value.toFixed(2);
        }

        function itemsAmount(items) {
            let amount = 0;
            items.forEach((e) => {
                amount += e.shordit_quantity;
            });
            return amount;
        }
        const currentPageParams = { nav: "shop", subpage: "shopOrdersList", params: "" };

        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th>{t("on")}</th>
                            <th>{t("shop.ordersList.code")}</th>
                            <th>{t("shop.ordersList.orderedBy")}</th>
                            <th>{t("shop.ordersList.value")}</th>
                            <th>{t("shop.ordersList.amount")}</th>
                            <th>{t("shop.ordersList.orderPaied")}</th>
                            <th>{t("shop.ordersList.date")}</th>
                            <th style={{ width: "170px", minWidth: "170px" }}>{t("dropdowns.actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {shopOrdersList
                            .map((p, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{p.shord_code}</td>
                                    <td>{p.user_fullname}</td>
                                    <td>
                                        {itemsValue(p.items)} {t("currency")}
                                    </td>
                                    <td>{itemsAmount(p.items)}</td>
                                    <td>
                                        {p.payment_amount === parseFloat(itemsValue(p.items)) || itemsValue(p.items) === "0.00" ? (
                                            <span className="text-success">{t("shop.ordersList.paied")}</span>
                                        ) : p.payment_amount > parseFloat(itemsValue(p.items)) ? (
                                            <span className="text-info">{t("shop.ordersList.overpaied")}</span>
                                        ) : p.payment_amount < parseFloat(itemsValue(p.items) && p.payment_amount > 0) ? (
                                            <span className="text-warning">{t("shop.ordersList.underpaied")}</span>
                                        ) : (
                                            <span className="text-danger">{t("shop.ordersList.notpaied")}</span>
                                        )}
                                    </td>
                                    <td>{p.create_date}</td>

                                    <td className="text-center">
                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`shopOrderView${i}`}>{t("preview")}</Tooltip>}>
                                            <span className="d-inline-block">
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() => onChangeNavAndPage({ nav: "shop", subpage: "shopOrderDetails", params: { orderCode: p.shord_code } }, currentPageParams)}
                                                    className="mx-1"
                                                >
                                                    <FontAwesomeIcon icon={faEye} />
                                                </Button>
                                            </span>
                                        </OverlayTrigger>
                                        {p.payment_amount !== parseFloat(itemsValue(p.items)) && parseFloat(itemsValue(p.items)) > 0 && (
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`shopRegisterTransaction${i}`}>{t("payment.registerTransaction")}</Tooltip>}>
                                                <span className="d-inline-block">
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() =>
                                                            onChangeNavAndPage(
                                                                {
                                                                    nav: "participants",
                                                                    subpage: "postPayment",
                                                                    params: { partID: p.part_id, partName: p.user_fullname, partIdentNumber: p.part_ident_number },
                                                                },
                                                                currentPageParams
                                                            )
                                                        }
                                                        className="mx-1"
                                                    >
                                                        <FontAwesomeIcon icon={faHandHoldingUsd} />
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>
                                        )}
                                    </td>
                                </tr>
                            ))
                            .slice(0, displayNOR)}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}

export default TableShopOrdersList;
