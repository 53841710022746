import React, { Fragment } from "react";
import { Table } from "react-bootstrap";

const CharacterDetails = (props) => {
    const { t, details, appView } = props;
    return (
        <Fragment>
            <Table bordered hover className="table-details" responsive="lg" id="charDetailsTable">
                <tbody>
                    <tr>
                        <td width="40%">{t("characters.characterName")}</td>
                        <td>{details.char_name}</td>
                    </tr>

                    <tr>
                        <td>{t("characters.characterFraction")}</td>
                        <td>{details.char_fraction ? details.char_fraction : <span className="opacity-4">{t("lack")}</span>}</td>
                    </tr>
                    <tr>
                        <td>{t("characters.characterDescr")}</td>
                        <td>{details.char_description ? details.char_description : <span className="opacity-4">{t("lack")}</span>}</td>
                    </tr>
                    <tr>
                        <td>{t("characters.characterContinuation")}</td>
                        <td>{details.char_is_continued === "Y" ? t("yes") : t("no")}</td>
                    </tr>
                    <tr>
                        <td>{t("characters.characterIsLarper")}</td>
                        <td>{details.char_is_crafter === "Y" ? t("yes") : t("no")}</td>
                    </tr>
                    <tr>
                        <td>{t("characters.characterAssets")}</td>
                        <td>{details.char_assets ? details.char_assets : <span className="opacity-4">{t("lack")}</span>}</td>
                    </tr>
                    <tr>
                        <td>{t("characters.characterSkills")}</td>
                        <td>{details.char_skills ? details.char_skills : <span className="opacity-4">{t("lack")}</span>}</td>
                    </tr>
                    <tr>
                        <td>{t("characters.characterExpectation")}</td>
                        <td>{details.char_expectation ? details.char_expectation : <span className="opacity-4">{t("lack")}</span>}</td>
                    </tr>

                    <tr>
                        <td>{t("characters.createDate")}</td>
                        <td>{details.create_date}</td>
                    </tr>
                    <tr>
                        <td>{t("characters.modifyDate")}</td>
                        <td>{details.modify_date}</td>
                    </tr>
                </tbody>
            </Table>
            {appView && (
                <Table bordered hover className="table-details" responsive="lg" id="charDetailsTable">
                    <tbody>
                        <tr>
                            <td width="40%">{t("characters.status")}</td>
                            <td>
                                <b>
                                    <span
                                        className={
                                            details.stat_code === "ACCEPTED"
                                                ? "text-success"
                                                : details.stat_code === "REJECTED"
                                                ? "text-danger"
                                                : details.stat_code === "CANCELED"
                                                ? "opacity-4"
                                                : "text-primary"
                                        }
                                    >
                                        {details.stat_name}
                                    </span>
                                </b>
                            </td>
                        </tr>

                        <tr>
                            <td width="40%">{t("characters.comment")}</td>
                            <td>
                                <i>{details.comment ? details.comment : <span className="opacity-4">{t("lack")}</span>}</i>
                            </td>
                        </tr>

                        {details.stat_code === "ACCEPTED" && (
                            <Fragment>
                                <tr>
                                    <td>{t("characters.acceptUser")}</td>
                                    <td>{details.accept_user ? details.accept_user : <span className="opacity-4">{t("lack")}</span>}</td>
                                </tr>
                                <tr>
                                    <td>{t("characters.acceptDateApp")}</td>
                                    <td>{details.accept_date ? details.accept_date : <span className="opacity-4">{t("lack")}</span>}</td>
                                </tr>
                            </Fragment>
                        )}
                        <tr>
                            <td>{t("characters.createDateApp")}</td>
                            <td>{details.application_date}</td>
                        </tr>
                    </tbody>
                </Table>
            )}
        </Fragment>
    );
};

export default CharacterDetails;
