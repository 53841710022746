import React, { Component, Fragment } from "react";
import { Table, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertNoPermission from "../../../alerts/AlertNoPermission";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";

class Account extends Component {
    state = {
        userDetails: [],
        loading: true,
        noPermission: false,
        allowActionUserLvl: null,
    };

    checkUserLevel = async (userID) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = { userID: userID };
        try {
            const response = await fetch(`${fetchURL}/compare-users-role-level`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    this.setState({ allowActionUserLvl: fetchedData.allowAction ? "Y" : "N" });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    getUserData = async (userID) => {
        const { fetchURL, onShowAlert, t, onCheckUserPrivilage } = this.props;
        const userIdData = {
            userID: userID,
        };
        try {
            const response = await fetch(`${fetchURL}/${onCheckUserPrivilage("COMMON_USER_DETAILS") ? "get-user-details" : "get-basic-user-data"}`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userIdData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ userDetails: fetchedData.userDetails, loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        this.getUserData(this.props.userID);
        this.checkUserLevel(this.props.userID);
    }

    render() {
        const { userDetails, loading, noPermission, allowActionUserLvl } = this.state;
        const { onChangeNavAndPage, onCheckUserPrivilage, currentPageParams, t } = this.props;

        return (
            <Fragment>
                <Col xs={12}>
                    <p className="h3 my-3 d-inline-block mr-3 align-middle">{t("participant.accountData.data")}</p>
                    {allowActionUserLvl && (
                        <Fragment>
                            {onCheckUserPrivilage("COMMON_USER_ACTIONS") && allowActionUserLvl === "Y" && (
                                <OverlayTrigger placement="top" overlay={<Tooltip id="accountEditTooltip">{t("dropdowns.editData")}</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            variant="outline-primary"
                                            className="d-inline-block mr-2"
                                            onClick={() => onChangeNavAndPage({ nav: "users", subpage: "editUser", params: { userID: currentPageParams.params.userID } }, currentPageParams)}
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                            )}
                        </Fragment>
                    )}
                    {onCheckUserPrivilage("COMMON_USER_DETAILS") && (
                        <OverlayTrigger placement="top" overlay={<Tooltip id="accountDetailsTooltip">{t("participant.seeMoreDetails")}</Tooltip>}>
                            <span className="d-inline-block">
                                <Button
                                    variant="outline-primary"
                                    className="d-inline-block"
                                    onClick={() => onChangeNavAndPage({ nav: "users", subpage: "userDetails", params: { userID: currentPageParams.params.userID } }, currentPageParams)}
                                >
                                    <FontAwesomeIcon icon={faEye} />
                                </Button>
                            </span>
                        </OverlayTrigger>
                    )}
                </Col>
                {noPermission ? (
                    <Col lg={6} xs={12}>
                        <AlertNoPermission t={t} />
                    </Col>
                ) : (
                    <Fragment>
                        {loading ? (
                            <Col xs={12}>
                                <LoadingProgressBar t={t} />
                            </Col>
                        ) : (
                            <Fragment>
                                <Col lg={8} xs={12}>
                                    {userDetails.map((u, i) => (
                                        <Fragment key={i}>
                                            <Table bordered hover className="table-details" responsive="lg" id="userPartDetailBasicTable">
                                                <tbody>
                                                    <tr>
                                                        <td>{t("user.email")}</td>
                                                        <td>{u.user_email}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("user.phone")}</td>
                                                        <td>{u.user_phone ? u.user_phone : <span className="opacity-4">{t("lack")}</span>}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("user.fbLabel")}</td>
                                                        <td>{u.user_facebook_link ? u.user_facebook_link : <span className="opacity-4">{t("lack")}</span>}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("user.medLabel")}</td>
                                                        <td>{u.user_medical_info ? u.user_medical_info : <span className="opacity-4">{t("lack")}</span>}</td>
                                                    </tr>
                                                    {onCheckUserPrivilage("COMMON_USER_DETAILS") && (
                                                        <Fragment>
                                                            <tr>
                                                                <td>{t("user.registerDate")}</td>
                                                                <td>{u.create_date}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{t("user.modifyDate")}</td>
                                                                <td>{u.modify_date}</td>
                                                            </tr>
                                                        </Fragment>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Fragment>
                                    ))}
                                </Col>
                                <Col lg={4} xs={12}>
                                    {onCheckUserPrivilage("COMMON_USER_ACTIONS") && (
                                        <Fragment>
                                            <p className="h5 mb-3" style={{ lineHeight: "0.9" }}>
                                                {t("comments")}
                                            </p>
                                            <p>{userDetails[0].user_comment ? <i>{userDetails[0].user_comment}</i> : <span className="opacity-4">{t("lack")}</span>}</p>
                                        </Fragment>
                                    )}
                                </Col>
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default Account;
