import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

const EventFilter = (props) => {
    const { eventsList, eventFilterValue, onChangeEventFilter, t } = props;

    return (
        <Fragment>
            <Form.Label>{t("event.convention")}:</Form.Label>
            {eventsList && eventFilterValue ? (
                <Dropdown className="mb-3" placeholder={t("form.choose")} fluid selection value={eventFilterValue} options={eventsList} onChange={onChangeEventFilter} />
            ) : (
                <LoadingProgressBar t={t} />
            )}
        </Fragment>
    );
};

export default EventFilter;
