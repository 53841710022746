import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";

class IdentObject extends Component {
    state = {
        identObjects: [
            {
                value: "USER",
                key: "1",
                text: this.props.t("ident.user"),
            },
            {
                value: "PART",
                key: "2",
                text: this.props.t("ident.part"),
            },
            {
                value: "ITEM",
                key: "3",
                text: this.props.t("ident.item"),
                disabled: true,
            },
            {
                value: "OTHER",
                key: "4",
                text: this.props.t("ident.other"),
                disabled: true,
            },
        ],
    };
    render() {
        const { identObjects } = this.state;
        const { onIdentObjectSelected, defaultValue, t } = this.props;
        return (
            <Fragment>
                <Form.Label>
                    {t("ident.identObject")} <span className="required">*</span>
                </Form.Label>

                {defaultValue && (
                    <Dropdown
                        defaultValue={defaultValue}
                        id="identObjectDropdown"
                        className="req mb-3"
                        placeholder={t("form.choose")}
                        fluid
                        selection
                        options={identObjects}
                        onChange={(event, { value }) => onIdentObjectSelected(event, value)}
                    />
                )}
            </Fragment>
        );
    }
}

export default IdentObject;
