import React from "react";
import { Fragment } from "react";
import { Form, Radio } from "semantic-ui-react";

const PaymentSides = (props) => {
    const { onChangeValue, paymentSide, name, t } = props;
    return (
        <Fragment>
            <Form.Field>
                <Radio label={t("payment.side.in")} name={name} value="IN" checked={paymentSide === "IN"} onChange={onChangeValue} />
            </Form.Field>
            <Form.Field>
                <Radio label={t("payment.side.out")} name={name} value="OUT" checked={paymentSide === "OUT"} onChange={onChangeValue} />
            </Form.Field>
            <Form.Field>
                <Radio label={t("payment.side.innerTrans")} name={name} value="INNERTRANS" checked={paymentSide === "INNERTRANS"} onChange={onChangeValue} />
            </Form.Field>
        </Fragment>
    );
};

export default PaymentSides;
