import React, { Fragment } from "react";
import { Table } from "react-bootstrap";
import $ from "jquery";

const ScheduleTable = (props) => {
    const { duties, tbody, thead, dutyTypeCode } = props;

    function addClassLarp(i, j) {
        $(document).ready(function () {
            $(`#${dutyTypeCode}dutyCell${i}${j}`).addClass("larp");
        });
    }

    return (
        <Fragment>
            <div>
                <Table className="table-schedule mt-3" bordered>
                    <tbody>
                        {tbody.map((b, i) => (
                            <Fragment key={i}>
                                <tr>
                                    {thead.map((h, j) => (
                                        <Fragment key={j}>
                                            {i === 0 ? (
                                                <Fragment>{j > 0 ? <td>{h.label}</td> : <td></td>}</Fragment>
                                            ) : (
                                                <Fragment>
                                                    {j === 0 ? (
                                                        <td>{b.label}</td>
                                                    ) : (
                                                        <td id={`${dutyTypeCode}dutyCell${i}${j}`}>
                                                            {duties.map((d, k) => (
                                                                <Fragment key={k}>
                                                                    {d.date === h.date && d.start === b.start && (
                                                                        <Fragment>
                                                                            {d.is_larp === "Y" && addClassLarp(i, j)}
                                                                            {d.participants.map((p, l) => (
                                                                                <Fragment key={l}>
                                                                                    <p>{p.user_fullname}</p>
                                                                                </Fragment>
                                                                            ))}
                                                                        </Fragment>
                                                                    )}
                                                                </Fragment>
                                                            ))}
                                                        </td>
                                                    )}
                                                </Fragment>
                                            )}
                                        </Fragment>
                                    ))}
                                </tr>
                            </Fragment>
                        ))}
                    </tbody>
                </Table>
            </div>
        </Fragment>
    );
};

export default ScheduleTable;
